<template>
  <b-row :class="category">
    <b-col>
      <b-row class="identifier">
        <b-col
          v-if="!['null', ''].includes(item.external_id)"
          :cols="12"
        >
          {{ item.custom_parameters.external_type }}
          <AisHighlight
            attribute="external_id"
            :class-names="highlight"
            :hit="item"
          />
        </b-col>
      </b-row>

      <b-row class="name">
        <b-col>
          <b-link
            :href="item.custom_parameters.public_url"
            @click.prevent="viewAccount(item, 'Link')"
          >
            <AisHighlight
              v-if="item.display_name"
              attribute="display_name"
              :class-names="highlight"
              :hit="item"
            />
            <template v-else>
              Unknown
            </template>
          </b-link>
        </b-col>
      </b-row>

      <b-row
        v-if="showDescription"
        class="description"
      >
        <b-col
          v-if="accountDisplayNames.length > 0"
          cols="12"
        >
          <span
            v-for="(account, index) of accounts.slice(0, accountCutoff)"
            :key="account.objectID"
          >
            <span v-if="index > 0">, </span>
            <AisHighlight
              :attribute="account._path + '.display_name'"
              :class-names="highlight"
              :hit="item"
            />
          </span>
          <span v-if="accounts.length > accountCutoff + 1"> &hellip;</span>
        </b-col>

        <b-col
          v-if="billDisplayNames.length > 0"
          cols="12"
        >
          <span
            v-for="(bill, index) of bills.slice(0, billCutoff)"
            :key="bill.objectID"
          >
            <span v-if="index > 0">, </span>
            <AisHighlight
              :attribute="bill._path + '.display_name'"
              :class-names="highlight"
              :hit="item"
            />
          </span>
          <span v-if="bills.length > billCutoff + 1"> &hellip;</span>
        </b-col>
      </b-row>

      <b-row v-if="matchedAddress">
        <b-col
          v-if="matchedAddress"
          class="address"
        >
          <div class="label">{{ matchedAddress.external_type }}</div>

          <HighlightedEntity
            :entity="matchedAddress"
            :item="item"
            :path="matchedAddress._path"
          />
        </b-col>
      </b-row>

      <b-row v-if="mainAddress">
        <b-col
          v-if="mainAddress"
          class="address"
          cols="auto"
        >
          <div class="label">{{ mainAddress.external_type }}</div>

          <AisHighlight
            :attribute="mainAddress._path + '.external_id'"
            :class-names="highlight"
            :hit="item"
          />
        </b-col>
      </b-row>
    </b-col>

    <b-col
      cols="auto"
      class="d-flex align-items-center justify-content-end"
    >
      <b-button
        variant="primary"
        @click.prevent="viewAccount(item, 'Button')"
      >
        View
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { AisHighlight } from 'vue-instantsearch'
import { createNamespacedHelpers } from 'vuex'
import HighlightedEntity from './HighlightedEntity.vue'
import { payables } from '../mixins/payables.js'
import '@grantstreet/bootstrap/icons/js/print.js'
/*
  There are incompatibilities between @vue/compat and the Vue 3 versions
  (vue-instantsearch/vue3/es) of the Algolia components. Since we can't use
  the Vue 3 versions, the next best option is to opt into Vue 3 mode by default
  (MODE: 3) and only enable compat for certain features when necessary.
*/
AisHighlight.compatConfig = { MODE: 3 }

const { mapMutations, mapState } = createNamespacedHelpers('GovHubSearch')

export default {
  components: {
    AisHighlight,
    HighlightedEntity,
  },

  mixins: [payables],

  props: {
    item: {
      required: true,
      type: Object,
    },

    sendInsightsEvent: {
      required: true,
      type: Function,
    },
  },

  computed: {
    accountCutoff () {
      const length = this.accounts.length
      return length > 2 ? 2 : length
    },

    accountDisplayNames () {
      return this.accounts.filter((account) => account.display_name)
    },

    accounts () {
      return this.children('accounts')
    },

    billCutoff () {
      const length = this.bills.length
      return length > 2 ? 2 : length
    },

    billDisplayNames () {
      return this.bills.filter((bill) => bill.display_name)
    },

    bills () {
      return this.children('bills')
    },

    mainAddress () {
      if (!this.item?.custom_parameters?.entities) {
        return
      }

      const main = this.item?.custom_parameters?.entities[0]

      if (this.matchedAddress && (this.matchedAddress === main || !this.alwaysShowMainAddress)) {
        return null
      }

      if (!main.hasOwnProperty('name')) {
        return null
      }

      main._path = 'custom_parameters.entities.0'

      return main
    },

    matchedAddress () {
      const match = this.findPayablesMatch(this.item)
      const object = match?.object

      if (object && /entities/.test(match.attribute) && object.hasOwnProperty('name')) {
        object._path = match.path.replace(/\.\D+$/, '')
        return object
      }

      const main = this.item?.custom_parameters?.entities[0]

      if (main.hasOwnProperty('name')) {
        main._path = 'custom_parameters.entities.0'
        return main
      }

      return null
    },

    showDescription () {
      return this.accountDisplayNames.length + this.billDisplayNames.length > 0
    },

    ...mapState([
      'alwaysShowMainAddress',
      'category',
      'highlight',
      'sendEvents',
    ]),
  },

  methods: {
    ...mapMutations([
      'setRedirect',
    ]),

    children (type) {
      const childGroups = this.item.child_groups
      const children = []

      if (childGroups) {
        childGroups.forEach((group, groupIndex) => {
          if (group.children[0].custom_parameters.custom_payable_type === type) {
            group.children.forEach((child, childIndex) => {
              child._path = 'child_groups.' + groupIndex + '.children.' + childIndex
              children.push(child)
            })
          }
        })
      }

      return children
    },

    toCurrency (amount) {
      return new Intl.NumberFormat(
        'en-US', {
          style: 'currency',
          currency: 'USD',
        },
      ).format(amount)
    },

    toDate (epoch) {
      return new Date(epoch * 1000).toLocaleDateString()
    },

    viewAccount (item, element) {
      if (this.sendEvents) {
        this.sendInsightsEvent('click', item, 'Account ' + element + ' Clicked')
      }

      this.setRedirect(item)
    },
  },
}
</script>

<style lang="scss" scoped>
.address {
  margin-top: .5rem;

  .label {
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }
}

.description {
  margin-bottom: .5rem;
  margin-top: .25rem;
}

.identifier {
  font-weight: $font-weight-semibold;
}

.name {
  font-size: $h3-font-size;
  font-weight: $font-weight-semibold;
  margin-top: .25rem;
}
</style>
