/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // ebilling-ui-prod
    prod: 'https://ae68cb9b34f8460f8727642f9520fa96@o168195.ingest.sentry.io/5937660',
    // ebilling-ui-nonprod
    nonprod: 'https://636d4e0b852e49798c519bdee8cc49d9@o168195.ingest.sentry.io/5937647',
  },
})
