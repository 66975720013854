<template>
  <div v-if="hasLinks">
    <ul class="list-unstyled my-1">
      <li
        v-for="link of links"
        :key="link.href"
        class="mb-1"
      >
        <!-- If the link is a govhub path, render a router link rather than an
        anchor. Anchors will hard redirect the browser to the href. This
        prevents an unnecessary govhub re-installation, that will break when
        govhub is embedded in another site.-->
        <component
          :is="link.internalPath && !newTab ? 'router-link' : 'a'"
          v-bind="link.internalPath && !newTab ? {
            // router-link props
            to: link.internalPath
          } : {
            // anchor props
            href: link.href,
            target: newTab ? '_blank' : ''
          }"
          class="align-middle"
        >
          <svgicon
            :icon="iconMap[link.rel]"
            class="text-body mr-1"
          />
          <span class="align-middle">
            {{ link.title }}
          </span>
        </component>
      </li>
    </ul>
  </div>
</template>

<script>
import Payable from '../models/Payable.ts'
import '@grantstreet/bootstrap/icons/js/business-tax.js'
import '@grantstreet/bootstrap/icons/js/document.js'
import '@grantstreet/bootstrap/icons/js/mail.js'
import '@grantstreet/bootstrap/icons/js/edit-outline.js'
import { sentryException } from '../sentry.ts'
import { isUrlInternal } from '@grantstreet/psc-environment'

export default {
  props: {
    payable: {
      type: Payable,
      required: true,
    },
    shouldDisplayTag: {
      type: String,
      required: true,
    },
    newTab: { // whether links should open in a new tab
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      // The keys here are the supported `payable.links[].rel` values. This maps
      // those rel values to bootstrap-vue icon names.
      iconMap: {
        'bt-express': 'business-tax',
        'details': 'document',
        'bill-express': 'mail',
        'confirm-business-status': 'edit-outline',
        'installment-plan': 'edit-outline',
        'print-receipt': 'print',
      },
    }
  },

  mounted () {
    if (this.hasLinks) {
      const brokenLinks = this.payable.links.filter(link => !this.iconMap[link.rel])
      if (brokenLinks.length) {
        const rels = brokenLinks.map(link => link.rel).join(', ')
        sentryException(`No payables action link icons found for rel values (${rels}) from adaptor ${this.payable.adaptor}`)
      }
    }
  },

  computed: {
    hasLinks () {
      return this.links?.length
    },

    links () {
      return this.payable?.links
        ?.filter(link => link.display_tags?.includes(this.shouldDisplayTag))
        ?.map(link => {
          /*
           * If the href is a govhub domain, the link should be rendered as a
           * router link rather than an anchor. If this renders as an anchor,
           * the browser will hard redirect the page, requiring govhub to
           * re-install. This would result in GH installation failures for sites
           * that run in embedded mode because no attach parameters would be
           * sent to the govhub installer.
           * To indicate that these need rendered as router-links, the path will
           * be provided in the 'internalPath' key.
           */
          if (isUrlInternal(link.href)) {
            const url = new URL(link.href)
            link.internalPath = url.pathname
          }
          return link
        })
    },
  },
}
</script>
