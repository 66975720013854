<template>
  <div class="govhub-search">
    <RedirectView v-if="showRedirect" />
    <ConfidentialPage v-else-if="confidentialPage" />
    <AlgoliaView v-else />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import AlgoliaView from './components/AlgoliaView.vue'
import ConfidentialPage from './components/ConfidentialPage.vue'
import RedirectView from './components/RedirectView.vue'
import { sentryException } from '@grantstreet/govhub-vue/src/sentry.js'
import { routing } from './mixins/routing.js'

const { mapMutations, mapState } = createNamespacedHelpers('GovHubSearch')

export default {

  data: () => ({
    redirectError: false,
  }),

  components: {
    AlgoliaView,
    ConfidentialPage,
    RedirectView,
  },

  mixins: [
    routing,
  ],

  computed: {
    ...mapState([
      'adminKey',
      'confidentialPage',
      'redirect',
      'suggestionsVisible',
      'parameters',
    ]),

    redirectUrl () {
      try {
        const url = new URL(this.redirect.custom_parameters.public_url, window.location.href)

        if (this.redirect.__queryID) {
          url.searchParams.set('qid', this.redirect.__queryID)
        }

        return url.toString()
      }
      catch (error) {
        sentryException(new Error('Error getting Algolia Redirect URL: ' + error + ' Search Parameters: ' + this.parameters.search_query))
        return ''
      }
    },

    showRedirect () {
      return this.redirect && !this.suggestionsVisible && !this.redirectError
    },
  },

  /*
   * Handles the various ways that we can navigate from/to the redirect skeleton
   * page, and makes sure we always end up in a clean state.
   */
  created () {
    this.$router.beforeEach((to, from, next) => {
      if (!from.query.redirect) {
        this.saveLastRoute(from)
      }

      const reset = (from.path === to.path && (from.query.confidentialBill || from.query.redirect)) ||
                    (from.path !== to.path && to.query.redirect)

      if (reset) {
        this.reset()
      }

      next()
    })

    window.addEventListener('pageshow', this.cacheCheck)
  },

  unmounted () {
    window.removeEventListener('pageshow', this.cacheCheck)
  },

  methods: {
    ...mapMutations([
      'saveLastRoute',
      'setConfidentialPage',
      'setParameters',
      'setRedirect',
      'setSearchMatch',
    ]),

    activateRedirect () {
      if (this.redirectUrl) {
        const img = document.getElementsByClassName('banner-image-wrapper')

        if (img.length === 1) {
          img[0].style.display = 'none'
        }

        if (!this.adminKey) {
          setTimeout(() => {
            window.location.href = this.redirectUrl
          }, 1000)
        }

        this.pushRoute({
          query: {
            ...this.$route.query,
            redirect: this.redirect.external_id,
          },
        })
      }
      else {
        this.redirectError = true
        this.activateSearch()
      }
    },

    activateSearch () {
      const img = document.getElementsByClassName('banner-image-wrapper')

      if (img.length === 1) {
        img[0].style.display = 'block'
      }
    },
    cacheCheck (event) {
      // Page was cached and then reloaded, leaving a frozen redirect page.
      if (event.persisted) {
        this.reset()
      }
    },

    reset () {
      this.setParameters({})
      this.setSearchMatch(null)
      this.setConfidentialPage(null)
      this.setRedirect(null)
    },
  },

  watch: {
    redirect: function (to) {
      if (to) {
        this.activateRedirect()
      }
      else {
        this.activateSearch()
      }
    },
  },
}
</script>
