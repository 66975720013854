<template>
  <component
    v-bind="$attrs"
    :is="component"
    ref="component"
    data-test="payable-description"
    :payable="payable"
    :variation="variation"
    :dark-display="darkDisplay"
    :small-description="smallDescription"
    :read-only="readOnly"
    :show-plate-fees="showPlateFees"
    :class="textClasses.fontSize"
  />
</template>

<script>
import PropertyDescription from './display-types/property-tax/PayableDescription.vue'
import VehicleDescription from './display-types/vehicle-registration/PayableDescription.vue'
import RExVehicleDescription from './display-types/rex-vehicle-registration/PayableDescription.vue'
import BaseDescription from './display-types/base/PayableDescription.vue'

export default {
  compatConfig: {
    // This is necessary for child-emitted events to propagate via $attrs
    // (https://github.com/vuejs/core/issues/4566#issuecomment-917997056)
    INSTANCE_LISTENERS: false,
  },
  props: {
    payable: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: '',
    },
    darkDisplay: {
      type: Boolean,
      default: false,
    },
    smallDescription: {
      type: Boolean,
      default: false,
    },
    // When true, the item display name will have a larger font size.
    // True for parent saved payables, false for children payables
    largeTitle: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showPlateFees: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      component: null,
    }
  },

  computed: {
    textClasses () {
      return {
        fontSize: `${this.largeTitle ? 'largeTitle' : ''}`,
      }
    },
  },

  created () {
    if (this.payable.configDisplayType && this.payable.configDisplayType.name === 'property-tax') {
      this.component = PropertyDescription
    }
    else if (this.payable.configDisplayType && this.payable.configDisplayType.name === 'vehicle-registration') {
      this.component = VehicleDescription
    }
    else if (this.payable.configDisplayType && this.payable.configDisplayType.name === 'rex-vehicle-registration') {
      this.component = RExVehicleDescription
    }
    else {
      this.component = BaseDescription
    }
  },

  updated () {
    const descriptionAttribute = this.variation + 'GeneratedDescription'
    this.payable[descriptionAttribute] = this.$el.textContent
  },

  methods: {
    validate () {
      return this.$refs.component?.validate?.() ?? true
    },

    setRExRenewalDuration (renewalDuration) {
      return this.$refs.component.setRExRenewalDuration(renewalDuration)
    },

    setRExIsInRentalPark (isInRentalPark) {
      return this.$refs.component.setRExIsInRentalPark(isInRentalPark)
    },
  },
}
</script>

<style lang="scss" scoped>
.largeTitle {
  ::v-deep h4{
    font-size: 24px;
    font-weight: 400;
    color: $headings-color !important;
  }
}

</style>
