/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // payables-fe-prod
    prod: 'https://dc73d31fd1ef4ff6a24c3d1d3b3ab14c@o168195.ingest.sentry.io/6137795',
    // payables-fe-nonprod
    nonprod: 'https://55c74568a1604d9b8656da14d70b8cc4@o168195.ingest.sentry.io/6137796',
  },
})
