<template>

  <div
    v-if="loaded"
  >

    <!-- TODO PSC-17127 this should be reusable in eg. Direct Charge Widget -->
    <!-- Until then, we are probably copying this block there -->
    <b-alert
      v-for="banner in activeBanners"
      :key="banner.id"
      v-dompurify-html="banner.localizedText"
      :data-test="`banner-id-${banner.id}`"
      show
      variant="danger"
      class="mb-0 rounded-0"
    />

    <b-alert
      v-if="suggestNewBookmark"
      key="suggest-new-bookmark"
      v-dompurify-html="$t('suggest.new.bookmark')"
      data-test="suggest-new-bookmark-banner"
      show
      variant="warning"
      class="mb-0 rounded-0"
    />

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { sortByProp } from '@grantstreet/psc-js/utils/sort.js'

export default {
  data () {
    return {
      loaded: false,
    }
  },

  computed: {
    activeBanners () {
      return Object.values(this.banners)
        .filter((banner) => banner.active)
        .sort(sortByProp('createdAt', banner => ({
          ...banner,
          createdAt: new Date(banner.createdAt).getTime(),
        })))
        .reverse()
    },

    suggestNewBookmark () {
      return 'suggestNewBookmark' in (this.$route?.query || {})
    },

    ...mapGetters('Announcements', ['banners']),
  },

  async mounted () {
    await this.$store.getters['Announcements/loadPromise']
    this.loaded = true
  },

}
</script>
