// Explicitly import bootstrap-vue components. This is tedious, but it reduced
// the parsed bootstrap-vue bundle from 174KB to 74KB. Importing the pre-built
// component GROUPS only reduced the size by 14KB.
// As of V2.0.2 the /es/ dir is no longer
import { BAlert } from 'bootstrap-vue/esm/components/alert/alert.js'
import { BBadge } from 'bootstrap-vue/esm/components/badge/badge.js'
import { BButton } from 'bootstrap-vue/esm/components/button/button.js'
// import { BCard } from 'bootstrap-vue/esm/components/card/card.js'
import { BCollapse } from 'bootstrap-vue/esm/components/collapse/collapse.js'
import { BCol } from 'bootstrap-vue/esm/components/layout/col.js'
import { BDropdown } from 'bootstrap-vue/esm/components/dropdown/dropdown.js'
import { BDropdownItem } from 'bootstrap-vue/esm/components/dropdown/dropdown-item.js'
import { BRow } from 'bootstrap-vue/esm/components/layout/row.js'
import { BContainer } from 'bootstrap-vue/esm/components/layout/container.js'
import { BForm } from 'bootstrap-vue/esm/components/form/form.js'
import { BFormRow } from 'bootstrap-vue/esm/components/layout/form-row.js'
import { BFormCheckbox } from 'bootstrap-vue/esm/components/form-checkbox/form-checkbox.js'
import { BFormCheckboxGroup } from 'bootstrap-vue/esm/components/form-checkbox/form-checkbox-group.js'
import { BFormGroup } from 'bootstrap-vue/esm/components/form-group/form-group.js'
import { BFormInput } from 'bootstrap-vue/esm/components/form-input/form-input.js'
import { BFormRadio } from 'bootstrap-vue/esm/components/form-radio/form-radio.js'
import { BFormRadioGroup } from 'bootstrap-vue/esm/components/form-radio/form-radio-group.js'
import { BFormSelect } from 'bootstrap-vue/esm/components/form-select/form-select.js'
import { BFormTextarea } from 'bootstrap-vue/esm/components/form-textarea/form-textarea.js'
import { BImg } from 'bootstrap-vue/esm/components/image/img.js'
import { BInputGroup } from 'bootstrap-vue/esm/components/input-group/input-group.js'
// import { BInputGroupText } from 'bootstrap-vue/esm/components/input-group/input-group-text.js'
import { BLink } from 'bootstrap-vue/esm/components/link/link.js'
import { BModal } from 'bootstrap-vue/esm/components/modal/modal.js'
import { ModalPlugin } from 'bootstrap-vue/esm/components/modal/index.js'
import { VBModal } from 'bootstrap-vue/esm/directives/modal/modal.js'
import { BOverlay } from 'bootstrap-vue/esm/components/overlay/index.js'
// import { BNavbar } from 'bootstrap-vue/esm/components/navbar/navbar.js'
// import { BNavbarBrand } from 'bootstrap-vue/esm/components/navbar/navbar-brand.js'
// import { BNavbarNav } from 'bootstrap-vue/esm/components/navbar/navbar-nav.js'
// import { BNavbarToggle } from 'bootstrap-vue/esm/components/navbar/navbar-toggle.js'
// import { BNavItem } from 'bootstrap-vue/esm/components/nav/nav-item.js'
// import { BNavItemDropdown } from 'bootstrap-vue/esm/components/nav/nav-item-dropdown.js'
import { BPagination } from 'bootstrap-vue/esm/components/pagination/pagination.js'
import { BPaginationNav } from 'bootstrap-vue/esm/components/pagination-nav/pagination-nav.js'
import { BTab } from 'bootstrap-vue/esm/components/tabs/tab.js'
import { BTable } from 'bootstrap-vue/esm/components/table/table.js'
import { BTd } from 'bootstrap-vue/esm/components/table/td.js'
import { BTabs } from 'bootstrap-vue/esm/components/tabs/tabs.js'
import { BToast } from 'bootstrap-vue/esm/components/toast/toast.js'
import { ToastPlugin } from 'bootstrap-vue/esm/components/toast/index.js'
import { BTooltip } from 'bootstrap-vue/esm/components/tooltip/tooltip.js'
import { VBTooltip } from 'bootstrap-vue/esm/directives/tooltip/tooltip.js'
import { VBToggle } from 'bootstrap-vue/esm/directives/toggle/toggle.js'
// import { BTr } from 'bootstrap-vue/esm/components/table/tr.js'
import { BVConfigPlugin } from 'bootstrap-vue/esm/bv-config.js'
import { ProgressPlugin } from 'bootstrap-vue/esm/components/progress/index.js'
import { BProgress } from 'bootstrap-vue/esm/components/progress/progress.js'
import { BSidebar } from 'bootstrap-vue/esm/components/sidebar/sidebar.js'
import { BFormText } from 'bootstrap-vue/esm/components/form/form-text.js'

BFormCheckbox.options.compatConfig = {
  // This is necessary for child-emitted events to propagate via $attrs
  // (https://github.com/vuejs/core/issues/4566#issuecomment-917997056)
  INSTANCE_LISTENERS: false,
}

// Add emits so vue doesn't treat these as native browser events
BFormCheckbox.options.emits = [
  'change',
]

export default function install (Vue) {
  // Stolen from bootstrap-vue. This is a more reliable way to check for
  // subsequent install attempts compared to testing for the presence of a
  // global component.
  // https://github.com/bootstrap-vue/bootstrap-vue/blob/67395382447141680a73a3bd986f71198ecd653c/src/utils/plugins.js#L117
  if (install.installed) {
    return
  }

  install.installed = true

  // Change default colors:
  Vue.use(BVConfigPlugin, {
    BBadge: { variant: 'gray-secondary' },
    BModal: { cancelVariant: 'gray-secondary' },
  })

  Vue.component('b-alert', BAlert)
  Vue.component('b-badge', BBadge)
  Vue.component('b-button', BButton)
  // Vue.component('b-card', BCard)
  Vue.component('b-collapse', BCollapse)
  Vue.component('b-col', BCol)
  Vue.component('b-dropdown', BDropdown)
  Vue.component('b-dropdown-item', BDropdownItem)
  Vue.component('b-row', BRow)
  Vue.component('b-container', BContainer)
  Vue.component('b-form', BForm)
  Vue.component('b-form-row', BFormRow)
  Vue.component('b-form-checkbox', BFormCheckbox)
  Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
  Vue.component('b-form-group', BFormGroup)
  Vue.component('b-form-input', BFormInput)
  Vue.component('b-form-radio', BFormRadio)
  Vue.component('b-form-radio-group', BFormRadioGroup)
  Vue.component('b-form-select', BFormSelect)
  Vue.component('b-form-textarea', BFormTextarea)
  Vue.component('b-form-text', BFormText)
  Vue.component('b-img', BImg)
  Vue.component('b-input-group', BInputGroup)
  // Vue.component('b-input-group-text', BInputGroupText)
  Vue.component('b-link', BLink)
  Vue.component('b-modal', BModal)
  Vue.use(ModalPlugin)
  Vue.directive('b-modal', VBModal)
  Vue.component('b-overlay', BOverlay)
  // Vue.component('b-navbar', BNavbar)
  // Vue.component('b-navbar-brand', BNavbarBrand)
  // Vue.component('b-navbar-nav', BNavbarNav)
  // Vue.component('b-navbar-toggle', BNavbarToggle)
  // Vue.component('b-nav-item', BNavItem)
  // Vue.component('b-nav-item-dropdown', BNavItemDropdown)
  Vue.component('b-pagination', BPagination)
  Vue.component('b-pagination-nav', BPaginationNav)
  Vue.component('b-tab', BTab)
  Vue.component('b-table', BTable)
  Vue.component('b-tabs', BTabs)
  Vue.component('b-td', BTd)
  Vue.component('b-toast', BToast)
  Vue.use(ToastPlugin)
  Vue.component('b-tooltip', BTooltip)
  Vue.directive('b-tooltip', VBTooltip)
  Vue.directive('b-toggle', VBToggle)
  // Vue.component('b-tr', BTr)
  Vue.use(ProgressPlugin)
  Vue.component('b-progress', BProgress)
  Vue.component('b-sidebar', BSidebar)
}
