<template>
  <div class="star d-inline-block">
    <div
      ref="starContainer"
      class="position-relative d-inline-block"
    >
      <transition name="fade">
        <div
          v-if="showLoader"
          class="msi-loading-spinner"
        >
          <LoadingSpinner small />
        </div>
      </transition>

      <b-button
        ref="star"
        :class="{ 'opacity-0': showLoader }"
        :disabled="loading || (disableMSIStar || disabled)"
        :aria-label="ariaLabelText"
        :data-test-is-starred="String(isStarred)"
        class="star-button p-0 transition-opacity-200 text-primary"
        variant="link"
        tabindex="0"
        data-test="saved-item-star"
        @click.prevent="toggle($event)"
        @mouseenter="$root.$emit('bv::hide::tooltip', `mobile-tooltip-${componentId}`)"
        @mouseleave="$root.$emit('bv::hide::tooltip', `hover-tooltip-${componentId}`)"
      >
        <svgicon
          v-if="isStarred"
          :height="size"
          :width="size"
          fill
          name="star-fill"
          class="fill-rounded"
        />
        <svgicon
          v-else
          :fill="false"
          :height="size"
          :width="size"
          name="star-outline"
        />
      </b-button>
    </div>
    <b-tooltip
      :id="`hover-tooltip-${componentId}`"
      :target="() => $refs.starContainer"
      :container="tooltipContainer"
      boundary="viewport"
      :variant="errorMessage ? 'danger': ''"
      triggers="hover"
      placement="bottomleft"
      :title="hoverTooltip"
    />

    <!-- The mobile tooltip needs a separate target to work properly -->
    <b-tooltip
      :id="`mobile-tooltip-${componentId}`"
      ref="mobileTooltip"
      v-model:show="showMobileTooltip"
      :target="() => $refs.star"
      :container="tooltipContainer"
      boundary="viewport"
      :variant="errorMessage ? 'danger': ''"
      triggers="manual"
      placement="bottomleft"
      :title="mobileTooltip"
    />
  </div>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/star-fill.js'
import '@grantstreet/bootstrap/icons/js/star-outline.js'
import LoadingSpinner from '@grantstreet/loaders-vue/LoadingSpinner.vue'
import { v4 as uuid } from 'uuid'

export default {
  components: {
    LoadingSpinner,
  },

  props: {
    isStarred: {
      type: Boolean,
      required: true,
    },
    toggleFunction: {
      type: [Function, Promise],
      required: true,
    },
    size: {
      type: String,
      default: '1rem',
    },
    tooltipContainer: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    activeTitle: {
      type: String,
      default: '',
    },
    inactiveTitle: {
      type: String,
      default: '',
    },
    disabledTitle: {
      type: String,
      default: '',
    },
    addedTitle: {
      type: String,
      default: '',
    },
    removedTitle: {
      type: String,
      default: '',
    },
    ariaLabelText: {
      type: String,
      default: '',
    },
    savedItemsCount: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      componentId: uuid(),
      loading: false,
      showLoader: false,
      showLoaderTimeoutId: null,
      showMobileTooltip: false,
      mobileTooltip: this.addedTitle,
      mobileTooltipTimeoutId: null,
      // Cap the number of items a user can save in MSI to 40
      maximumSavedItems: 40,
    }
  },

  computed: {
    hoverTooltip () {
      return this.errorMessage || (
        this.disableMSIStar ? this.disabledTitle : (
          this.isStarred ? this.activeTitle : this.inactiveTitle
        )
      )
    },
    disableMSIStar () {
      return this.savedItemsCount >= this.maximumSavedItems && !this.isStarred
    },
  },

  methods: {
    async toggle () {
      try {
        // Trigger the loading state to disable the button, but only show a
        // loading spinner after a delay (more visually pleasing than an
        // immediate spinner)
        if (this.showLoaderTimeoutId) {
          clearTimeout(this.showLoaderTimeoutId)
        }
        this.loading = true
        this.showLoaderTimeoutId = setTimeout(() => {
          if (this.loading) {
            this.showLoader = true
          }
        }, 1000)

        const removing = this.isStarred

        const success = await this.toggleFunction()

        // If the process was unsuccessful (or aborted), don't show the mobile
        // tooltip
        if (success === false) {
          return
        }

        // Temporarily show the mobile-friendly tooltip
        if (window.innerWidth <= 768) {
          this.mobileTooltip = this.disableMSIStar ? this.disabledTitle : (removing ? this.removedTitle : this.addedTitle)
          this.$root.$emit('bv::hide::tooltip', `hover-tooltip-${this.componentId}`)
          this.showMobileTooltip = true

          if (this.mobileTooltipTimeoutId) {
            clearTimeout(this.mobileTooltipTimeoutId)
          }
          this.mobileTooltipTimeoutId = setTimeout(() => {
            this.showMobileTooltip = false
          }, 2000)
        }
      }
      catch (error) {
        console.error(error)
      }
      finally {
        this.loading = false
        this.showLoader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.msi-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.star, .star-button {
  line-height: 0 !important;
}

::v-deep .tooltip-inner {
  min-width: 9rem;
  text-align: center !important;
}
</style>
