<template>
  <div class="scrollable-container">
    <p
      v-dompurify-html="$t('ebilling.terms_of_use.wording_first_section', {
        clientTitle: clientTitle,
      })"
      class="d-inline"
    />
    <p
      v-if="paperless"
      v-dompurify-html="$t('ebilling.terms_of_use.wording_paperless')"
      class="d-inline"
    />
    <p
      v-dompurify-html="$t('ebilling.terms_of_use.wording_second_section')"
      class="d-inline"
    />
  </div>
</template>

<script>
export default {
  props: {
    paperless: {
      type: Boolean,
      required: true,
    },
    clientTitle: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .scrollable-container {
    max-height: 300px;
    overflow-y: auto;
  }
</style>
