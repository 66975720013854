<template>

  <div>
    <div
      data-test="footer-headline"
      class="mb-2"
    >
      {{ footer.localizedHeadline }}
    </div>
    <div
      v-dompurify-html="footer.localizedContent"
      data-test="footer-content"
      class="mb-4"
    />
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('Announcements', ['footer']),
  },
}
</script>
