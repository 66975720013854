<template>
  <div class="font-italic text-center">
    <div
      class="loading-bars"
      :class="{
        'white-bars': whiteBars,
        'small-bars': small,
      }"
    >
      <div /><div /><div /><div />
    </div>
    <div
      v-if="includeText"
      class="lead mt-2"
    >
      <slot>{{ $t("loading.default") }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    includeText: {
      type: Boolean,
      default: true,
    },
    whiteBars: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
