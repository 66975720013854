<template>
  <div>
    <h4>{{ hasEBillingSubscription ? $t('ebilling.manage.auth') : $t('ebilling.get_future_bills') }}</h4>
    <!-- Successful signup message -->
    <template v-if="mode === 'subscribe' && !!subscription">
      <span
        v-dompurify-html="$t('ebilling.signup.success', {email_address:
          subscription?.email})"
      />
      <slot name="dashboard-reference" />
      <div class="mt-2">
        <b-link
          data-test="ebilling-hide"
          @click.prevent="$emit('change-e-billing-mode', 'closed')"
        >
          {{ $t('ebilling.signup.hide') }}
        </b-link>
      </div>
    </template>
    <!-- Subscription Creation & Management -->
    <template v-else>
      <b-form-group>
        <b-form-radio-group
          v-model="selected"
          stacked
          :name="`subscriptionOptions${payableSavePath}-${id}`"
          :options="subscriptionOptions"
        />
      </b-form-group>
      <!-- Conditional Terms of Use -->
      <!-- Only show link if terms of use is turned on and explicit terms of use
        requirements haven't been met -->
      <span
        v-if="showTermsOfUse && !(requireExplicitTermsOfUse && hasSubscriptionChanged)"
      >
        <p>{{ $t('ebilling.terms_of_use.anon_preface') }}
          <b-button
            data-test="auth-ebilling-terms-of-use-link"
            variant="link"
            class="p-0 m-0 text-decoration-none btn-sm align-baseline shadow-none"
            @click="userViewTermsOfUse = !userViewTermsOfUse"
          >
            {{ $t('ebilling.terms_of_use.link') }}
          </b-button>.</p>
      </span>
      <!-- Only show terms of use wording if user chooses to from link above or
        or if explicit terms of use is on and user chooses paperless option-->
      <span v-if="userViewTermsOfUse || (requireExplicitTermsOfUse && hasSubscriptionChanged)">
        <TermsOfUse
          :paperless="selected === 'email'"
          :client-title="clientTitle"
        />
        <br>
        <!-- Only show checkbox if requiring it for paperless subs -->
        <span
          v-if="(requireExplicitTermsOfUse && hasSubscriptionChanged)"
        >
          <b-form-checkbox
            v-model="termsOfUseAgreement"
            data-test="auth-terms-of-use-checkbox"
            @click="termsOfUseAgreement = !termsOfUseAgreement"
          >
            {{ $t('ebilling.terms_of_use.checkbox') }}
          </b-form-checkbox>
          <br>
        </span>
      </span>
      <b-form-row>
        <b-button
          variant="outline-primary"
          @click.prevent="$emit('change-e-billing-mode', 'closed')"
        >{{ $t('common.cancel')
        }}</b-button>
        <ProgressButton
          ref="start"
          :waiting="$wait.is(`e-billing subscription ${payableSavePath}`)"
          data-test="e-billing-subscribe"
          variant="primary"
          class="ml-2"
          :disabled="!hasSubscriptionChanged || (requireExplicitTermsOfUse && !termsOfUseAgreement)"
          @click="changeSubscriptionOption"
        >
          {{ $t('my-settings.save') }}
        </ProgressButton>
      </b-form-row>
    </template>
  </div>
</template>
<script>
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import { v4 as uuid } from 'uuid'
import TermsOfUse from './TermsOfUse.vue'

export default {
  emits: ['subscribe', 'unsubscribe', 'change-e-billing-mode', 'modify'],
  components: {
    ProgressButton,
    TermsOfUse,
  },
  props: {
    payableSavePath: {
      type: String,
      required: true,
    },
    subscription: {
      type: Object,
      required: false,
      default: undefined,
    },
    eBillingMode: {
      type: String,
      required: true,
    },
    hasEBillingSubscription: {
      type: Boolean,
      required: true,
    },
    allowPaperlessEbilling: {
      type: Boolean,
      required: true,
    },
    disablePaperless: {
      type: Boolean,
      required: true,
    },
    paperlessText: {
      type: String,
      required: true,
    },
    showTermsOfUse: {
      type: Boolean,
      required: true,
    },
    explicitTermsOfUse: {
      type: Boolean,
      required: true,
    },
    clientTitle: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    selected: null,
    mode: '',
    id: uuid(),
    userViewTermsOfUse: false,
    termsOfUseAgreement: false,
  }),
  computed: {
    requireExplicitTermsOfUse () {
      return this.explicitTermsOfUse && this.selected === 'email'
    },
    subscriptionOptions () {
      return [
        {
          text: this.$t('ebilling.preference.both'),
          value: 'mailAndEmail',
        },
        {
          // The text shown for the paperless option changes based
          // on whether the option is disabled or not
          text: this.paperlessText,
          value: 'email',
          // Only disable the email option if a user verification is off or if it's on and
          // the user hasn't verified the payable yet
          disabled: this.disablePaperless,
        },
        {
          text: this.$t('ebilling.preference.mail'),
          value: 'mail',
        },
      ].filter(option => {
        // The mail option means nothing for someone who is unsubscribed.
        // They're already getting their bills by mail.
        const noMail = option.value !== 'mail'
        // Don't show paperless options if we don't allow it.
        const noPaperless = option.value !== 'email'
        // You're unsubscribed and you can't do paperless.
        const noMailOrPaperless = noMail && noPaperless

        if (!this.hasEBillingSubscription && !this.allowPaperlessEbilling) {
          return noMailOrPaperless
        }
        else if (!this.hasEBillingSubscription) {
          return noMail
        }
        else if (!this.allowPaperlessEbilling) {
          return noPaperless
        }
        return true
      })
    },
    hasSubscriptionChanged () {
      const currentOption = this.subscription
        ? this.subscription.paperless
          ? 'email'
          : 'mailAndEmail'
        : 'mail'

      return currentOption !== this.selected
    },
  },
  methods: {
    /**
     * Resets the subscription radio option.
     * If a subscription exists, this updates to both mail and email, or
     * paperless
     * If a subscription does not exist, this defaults to mail and email, since
     * the user is creating a subscription
     */
    resetSubscriptionOption () {
      this.selected = this.subscription?.paperless
        ? 'email'
        : 'mailAndEmail'
    },
    /**
     * This will either create, or delete the subscription.
     * When the PIN verification is in place, this will also update the
     * subscription.
     */
    changeSubscriptionOption () {
      if (!this.hasSubscriptionChanged) {
        this.$emit('change-e-billing-mode', 'closed')
        return
      }
      switch (this.selected) {
      case 'mailAndEmail':
        if (this.hasEBillingSubscription) {
          // Modifies the subscription. Toggle paperless.
          this.$emit('modify', { paperless: false, subscriptionId: this.subscription.subscriptionId, payableSavePath: this.payableSavePath })
          this.$emit('change-e-billing-mode', 'closed')
        }
        else {
          // Create the subscription. The e-billing BE does not need name or email
          // for authenticated subscription creations.
          this.$emit('subscribe', { name: '', email: '', paperless: false })
        }
        break
      // Paperless
      case 'email':
        if (this.hasEBillingSubscription) {
          this.$emit('modify', { paperless: true, subscriptionId: this.subscription.subscriptionId, payableSavePath: this.payableSavePath })
          this.$emit('change-e-billing-mode', 'closed')
        }
        else {
          this.$emit('subscribe', { name: '', email: '', paperless: true })
        }
        break
      case 'mail':
        this.$emit('unsubscribe')
        break
      }
    },
  },
  /** Set the initial subscription selection */
  mounted () {
    this.resetSubscriptionOption()
  },
  watch: {
    // Reset the subscription selection on any subscription change.
    subscription () {
      this.resetSubscriptionOption()
    },
    /**
     * Update the local mode when eBillingMode is updated. Ignoring the 'closed'
     * state fixes the template switching as the dropdown animates the close
     * event.
     */
    eBillingMode (newMode, oldMode) {
      if (newMode === 'closed') {
        return
      }
      this.mode = newMode
    },

    // Whenever the selected option changes, make sure the terms of use aren't pre-expanded
    selected () {
      this.userViewTermsOfUse = false
    },
  },
}
</script>
