<template>
  <div>
    <template v-if="variation === 'short'">
      <h4
        class="mb-0"
        :class="textClasses.displayName"
      >
        {{ payable.displayName }}
      </h4>
      <div
        v-if="payable.description"
        :class="textClasses.description"
        class="preserve-newlines small"
      >
        {{ payable.description }}
      </div>
    </template>
    <div v-else>
      <h4
        class="mb-0"
        :class="textClasses.displayName"
      >
        {{ payable.displayName }}
      </h4>
      <div
        class="preserve-newlines small pb-2"
        :class="textClasses.description"
      >{{ description }}</div>
      <!-- Don't duplicate description -->
      <span
        v-if="payable.customParameters.address && payable.description"
        class="mr-1"
        :class="textClasses.description"
      ><b>{{ payable.description }}</b></span>
      <span
        v-if="payable.customParameters.alternate_key"
        class="mr-1"
        :class="textClasses.description"
      >| <span class="text-nowrap">{{ $t("alternate.label") }} <b>{{ payable.customParameters.alternate_key }}</b></span></span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    textClasses () {
      return {
        displayName: `text-${this.darkDisplay ? 'dark' : 'body'}`,
        description: this.smallDescription ? 'small' : '',
      }
    },

    description () {
      const address = this.payable.customParameters?.address
      if (typeof address === 'object') {
        // Some Sunshine fixtures use the standard object-based address, so show
        // a bit of it here
        return address.address1
      }
      else if (address) {
        // Some older fixtures just use a string for the address
        return address
      }
      return this.payable.description
    },
  },
  props: {
    payable: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: '',
    },
    darkDisplay: {
      type: Boolean,
      default: false,
    },
    smallDescription: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
