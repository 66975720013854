import Vue, { createApp } from 'vue'
import { setEnvironment } from '@grantstreet/psc-environment/environment.js'
import { checkRequiredOpts } from '@grantstreet/psc-vue/utils/install-utils.js'
import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import { createVueWait } from 'vue-wait'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import '@grantstreet/bootstrap/icons/js/caret.js'
import { i18n, loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import { vueErrorHandler } from './sentry.ts'

import App from './components/App.vue'

Vue.use(VueSVGIcon)

export const attach = (elSelector, opts) => {
  checkRequiredOpts({
    opts,
    required: [
      'environment',
      'parentWhisperer',
      // This is a temporary fix for backwards compatibility, fix in PSC-17744
      // 'payablePath',
    ],
    moduleName: 'Payment Choices Button',
  })

  let {
    environment,
    parentWhisperer,
    payablePath,
    payable,
    namespace = 'taxsysIframe',
    showSingleDisplayName = false,
    allowRemoveItem = false,
  } = opts

  if (!payable && !payablePath) {
    throw new TypeError('A payablePath is required.')
  }
  if (payable) {
    payablePath = payable.path
  }

  loadTranslations()

  setEnvironment(environment)

  initBootstrapVue(Vue)

  createApp(App, {
    parentWhisperer,
    payablePath,
    namespace,
    showSingleDisplayName,
    allowRemoveItem,
  })
    .use(vueErrorHandler)
    .use(i18n)
    .use(createVueWait())
    .mount(elSelector)
}
