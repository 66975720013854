<template>
  <div
    :id="'msi-iframe-star-' + id"
    class="msi-iframe-star iframe-styles"
    :class="{
      'msi-disabled': !useMySavedItems
    }"
  >
    <!-- These tooltips are not translated yet because TaxSys does not support
    Spanish in its account details iframes. If we want to use this MSI star
    component in a translatable setting in the future we should figure out a way
    to translate these four strings without pulling in the entire GovHub
    translations files. -->
    <StarButton
      v-if="useMySavedItems"
      :is-starred="isStarred"
      :toggle-function="toggle"
      :disabled="loading"
      :inactive-title="loading ? 'Loading' : 'Add to My Items'"
      :active-title="loading ? 'Loading' : 'Remove from My Items'"
      :disabled-title="loading ? 'Loading' : 'You have reached the maximum number of saved items. If you’d like to save this item, remove one of your currently saved items by navigating to My Items in your Dashboard and clicking the solid star icon next to the item you wish to remove.'"
      :error-message="errorMessage"
      :tooltip-container="'msi-iframe-star-' + id"
      :aria-label-text="isStarred ? 'Remove the following item from My Saved Items' : 'Add the following item to My Saved Items'"
      :saved-items-count="savedItemsCount"
      added-title="Added to My Items"
      removed-title="Removed from My Items"
    />
  </div>
</template>

<script>
import StarButton from '@grantstreet/psc-vue/components/StarButton.vue'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import { v4 as uuid } from 'uuid'

export default {
  components: {
    StarButton,
  },

  props: {
    payablePath: {
      type: String,
      required: true,
    },
    parentWhisperer: {
      type: iframeWhisperer.ToParent,
      required: true,
    },
  },

  data: () => ({
    isStarred: false,
    loading: true,
    errorMessage: '',
    namespace: 'msiIframeStar',
    // When this is false, the star button will not be rendered
    // This gets updated in the mounted lifecycle to reflect the actual site setting.
    useMySavedItems: false, // MSI enabled/disabled site setting
    id: uuid(),
    savedItemsCount: 0,
  }),

  computed: {
    /**
     * Stars whisper to their parent using the vanilla namespace.
     * Stars register their handlers using this uidNamespace.
     * The parent will whisper directly to each child using the uidNamespace.
     */
    uidNamespace () {
      return `${this.namespace}-${this.id}`
    },
  },

  async mounted () {
    this.parentWhisperer.on(`${this.uidNamespace}.savedStateChanged`, ({ isStarred, savedItemsCount }) => {
      this.isStarred = isStarred
      this.savedItemsCount = savedItemsCount || 0
      this.errorMessage = ''
    })

    try {
      // The parent listens to register events to know each star's uid
      // namespace.
      this.parentWhisperer.message({
        action: `${this.namespace}.register`,
        payload: {
          payablePath: this.payablePath,
          iframeId: this.id,
        },
      })
      const result = await this.parentWhisperer.message({
        action: `${this.namespace}.isStarred`,
        payload: {
          payablePath: this.payablePath,
        },
      })
      this.isStarred = result?.isStarred
      this.useMySavedItems = Boolean(result?.useMySavedItems)
      this.loading = false
    }
    catch (error) {
      this.handleError(error)
    }
  },

  methods: {
    async toggle () {
      try {
        this.errorMessage = ''

        const result = await this.parentWhisperer.message({
          action: `${this.namespace}.toggle`,
          payload: {
            payablePath: this.payablePath,
          },
        })

        if (!result) {
          // The parent aborted
          return
        }

        this.isStarred = result?.isStarred
      }
      catch (error) {
        this.handleError(error)
      }
    },

    handleError (error) {
      console.error(error)
      this.errorMessage = 'We were unable to complete your request. Please try again later.'
    },
  },
}
</script>
