import BulkUploadConfig, { PaymentOptionType, UploadResult, ValidatedUploadResults } from '../BulkUploadConfig.ts'
import templateFile from '../example-templates/california.js'

class SanBernardinoConfig extends BulkUploadConfig {
  constructor () {
    super()

    this.paymentOptions = [
      {
        type: PaymentOptionType.Online,
      },
      {
        type: PaymentOptionType.WireTransfer,
        description: [
          {
            label: 'bulk_upload.wire_transfer.beneficiary_bank',
            value: 'Wells Fargo Bank, N.A.',
          },
          {
            label: 'bulk_upload.wire_transfer.bank_location',
            value: 'Los Angeles, CA',
          },
          {
            label: 'bulk_upload.wire_transfer.wire_routing_number',
            value: '121000248',
          },
          {
            label: 'bulk_upload.wire_transfer.international_transfers',
            value: 'International SWIFT BIC WFBIUS6S',
          },
          {
            label: 'bulk_upload.wire_transfer.account_number',
            value: '4662552868',
          },
          {
            label: 'bulk_upload.wire_transfer.account_name',
            value: 'San Bernardino County Treasurer',
          },
          {
            label: 'bulk_upload.wire_transfer.account_type',
            value: 'Checking',
          },
          {
            label: 'bulk_upload.wire_transfer.to',
            value: 'Accounting',
          },
        ],
      },
      {
        type: PaymentOptionType.InPerson,
        description: [
          {
            label: 'bulk_upload.in_person.office_location',
            value: '268 W Hospitality Ln\nFirst Floor\nSan Bernardino, CA 92408',
          },
          {
            label: 'bulk_upload.in_person.office_hours',
            value: 'Mon-Fri 8:00am - 5:00pm',
          },
        ],
      },
    ]
    this.exampleTemplate = templateFile
    this.csvColumns = {
      parcelNumber: {
        columnKey: 'parcelNumber',
        columnName: 'Parcel Number',
        sortOrder: 100,
      },
      billNumber: {
        columnKey: 'billNumber',
        columnName: 'Bill Number',
        sortOrder: 200,
      },
      installment: {
        columnKey: 'installment',
        columnName: 'Installment',
        sortOrder: 300,
      },
    }

    this.bulkSearchBatchSize = 50
  }

  /**
   * Overrides the validateSearchResults method from the base BulkUploadConfig
   * class to provide extra validation for payment choice payables. This ensures
   * that search results with multiple payment choices from the same base
   * payable conflict with each other.
   *
   * E.g., A CSV file with multiple unpaid installments for the same account
   * (like with San Bernardino County) will display those installments as errors
   * when included in the same CSV file.
   *
   * @returns A set of validated bulk search results containing successes and
   * failures
   */
  validateSearchResults (searchResults: UploadResult[]): ValidatedUploadResults {
    // Unfortunately, we need to loop through the results twice: First to build
    // a map of installments encountered using the payable's base source ID
    // (which is shared amongst common payment choice payables), and again to
    // actually catch those conflicting payment choices.
    //
    // This first lap is to create a map of base source IDs mapping to paths.
    const installmentsSeen = searchResults.reduce((installments, searchResult) => {
      const { payable } = searchResult
      if (payable?.paymentChoices?.length > 0) {
        const baseSourceId = payable.baseSourceId
        const path = payable.path

        if (!installments.hasOwnProperty(baseSourceId)) {
          installments[baseSourceId] = {}
        }

        installments[baseSourceId][path] = true
      }

      return installments
    }, {})

    const validatedResults: ValidatedUploadResults = {
      successes: [],
      failures: [],
    }

    // This second lap is to look for payment choice payables that we've seen
    // more than one base source ID of.
    for (const searchResult of searchResults) {
      const { displayItem, payable } = searchResult

      if (payable?.paymentChoices?.length > 0) {
        const baseSourceId = payable.baseSourceId

        // More than one payment choice for a base payable seen
        if (Object.keys(installmentsSeen[baseSourceId]).length > 1) {
          displayItem.errorKey = 'bulk_upload.installment.error'
          validatedResults.failures.push(displayItem)
        }
        else {
          validatedResults.successes.push(searchResult)
        }
      }
      // Payable without payment choices doesn't need this validation
      else {
        validatedResults.successes.push(searchResult)
      }
    }

    return validatedResults
  }
}

export default SanBernardinoConfig
