/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'caret': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 9.306',
    data: '<path pid="0" data-name="Artwork 10" d="M15 8.306L8 1 1 8.306" _fill="none" _stroke="#0077b3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>'
  }
})
