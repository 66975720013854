<template>
  <b-form-group
    class="mt-2"
    :label="field.label[$i18n.locale] || field.label"
    :label-class="{'align-self-start': field.textArea}"
    label-cols="12"
  >
    <div class="d-flex flex-row no-gutters">
      <div class="d-flex flex-column col-7 col-sm-8 col-lg-7 col-xl-9">
        <div>
          <b-form-input
            v-if="!field.textArea"
            v-model="model"
            :class="{'bg-white': light}"
            data-test="freeform-input"
            :state="!v$.model.$error"
            :readonly="!currentlyEditable"
            :placeholder="stringFromTranslatable(field.placeholder)"
            @change="updateValue"
          />
          <b-form-textarea
            v-else
            v-model="model"
            :placeholder="stringFromTranslatable(field.placeholder)"
            :readonly="!currentlyEditable"
            :state="!v$.model.$error"
            no-resize
            rows="2"
            @change="updateValue"
          />
          <ValidationErrors
            :validator="v$.model"
            class="auto-top"
            :errors="{
              maxlength: $t('cart.character.limit.error', { limit: field.charLimit }),
              required: $t('cart.required'),
            }"
          />
        </div>
        <div
          v-if="field.charLimit"
          class="d-flex justify-content-end mt-1 pr-1"
        >
          {{ $t('cart.character.limit.default') + " " + model.length + '/' + field.charLimit }}
        </div>
      </div>
      <svgicon
        v-if="stringFromTranslatable(field.tooltip)"
        v-b-tooltip.hover.top="stringFromTranslatable(field.tooltip)"
        color="#adadad"
        class="ml-2 mt-3"
        icon="question-mark-circle"
        :fill="false"
      />
    </div>
  </b-form-group>
</template>

<script>
import ValidationErrors from './ValidationErrors.vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'

export default {
  emits: ['field-update'],
  components: {
    ValidationErrors,
  },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    /**
     * field expects an object like this:
     *  {
     *     value: <string>, // Required. Initial contents of field
     *
     *     // If from a payables adaptor freeform field
     *     reportingKey: <string>, // Required if no `pexKey`; the user
     *                             // parameter field, as expected by a 3rd
     *                             // party (like PEx)
     *
     *     // If from payable sources via site settings
     *     pexKey: <string>, // Required if no `reportingKey`; the user
     *                       // parameter field, as expected by PEx
     *
     *     label: <object> || <string>, // Required. Translatable text object or string
     *     placeholder: <object> || <string>, // Translatable text object or string
     *     tooltip: <object> || <string>, // Translatable text object or string
     *     textArea: <boolean>,
     *     charLimit: <number>,
     *     required: <boolean>,
     *  }
     */
    field: {
      type: Object,
      required: true,
      validator: ({ reportingKey, pexKey, value, label }) =>
        typeof value === 'string' && (
          typeof reportingKey === 'string' ||
          typeof pexKey === 'string'
        ) && (
          typeof label === 'string' ||
          typeof label === 'object'
        ),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    cartItem: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
    light: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    internalValue: null,
  }),

  computed: {
    // The model is used for the input's v-module. By doing this,
    // 1. The field value acts as a default
    // 2. Updates are reflected internally but not externally
    // 3. The watcher will update the internal state to reflect any prop updates
    // 4. .reset() can be used by a parent to update the model
    model: {
      get () {
        return this.internalValue ?? this.field.value ?? ''
      },
      set (value) {
        this.internalValue = value
      },
    },

    currentlyEditable () {
      return !this.readOnly && !this.$wait.is('modifying freeform field')
    },
  },

  watch: {
    'field.value': function (value) {
      this.reset()
    },
  },

  validations: {
    model: {
      maxlength: function (value) {
        const parsedLimit = parseInt(this.field.charLimit)
        return value.length <= parsedLimit || !parsedLimit
      },
      required: requiredIf(function () {
        return this.field.required
      }),
    },
  },

  methods: {
    stringFromTranslatable (translatable) {
      // null values return `typeof` as 'object'
      if (!translatable) {
        return ''
      }
      if (typeof translatable === 'object') {
        return translatable[this.$i18n.locale] || ''
      }
      if (typeof translatable === 'string') {
        return translatable
      }
      return ''
    },

    reset (value = null) {
      this.internalValue = value
    },

    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },

    async updateValue () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      const fieldKey = this.field.reportingKey
        ? 'reportingKey'
        : 'pexKey'

      this.$emit('field-update', {
        [fieldKey]: this.field[fieldKey],
        value: this.model,
      })
    },
  },
}
</script>

<style lang="scss" scoped>

// This might not be necessary
.auto-top {
  top: auto !important
}

</style>
