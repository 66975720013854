<template>
  <!-- Here, we're *intentionally* overriding the default container margins -->
  <div
    :id="payable.sanitizedPath"
    class="container-fluid"
    :class="`${colLimit} bg-white rounded px-4 px-xxl-8 py-4 mb-2`"
  >

    <PayableAlert :payable="payable" />

    <slot
      name="cornerIndicator"
      :payable="payable"
    />

    <div class="row">
      <div class="col-12 col-sm-5 col-xxl-6 d-block">
        <!--
          Vehicle renewals with children/payment choices can be displayed as a
          single row with a dropdown for children (as variants of the primary).

          Don't show any plate fees in the description because we show a full
          breakdown here.
        -->
        <PayableDescription
          ref="payableDescription"
          :payable="selectedPayable"
          :show-plate-fees="false"
          @change-payable="selectedPayable = $event"
          @change-plate="$emit('change-plate', $event)"
          @change-rental-park-status="$emit('change-rental-park-status', $event)"
          @update-insurance="$emit('update-insurance', $event)"
        />
      </div>

      <slot name="amount">
        <div class="col-12 col-sm-7 col-xxl-6 text-right border-xxl-left">

          <div
            v-if="selectedPayable.displayAmount"
            class="fee-grid"
          >
            <div><!-- Spacer for css grid --></div>
            <div class="amount mb-1 mb-md-2">
              {{
                displayFeeBreakdown
                  ? selectedPayable.selectedRExFees?.totalFees.display
                  : `$${selectedPayable.displayAmount}`
              }}
            </div>

            <template v-if="selectedPayable.selectedRExFees?.delinquentFee.number > 0">
              <div class="sub-fee">
                {{ $t('rex.item.delinquent_fee') }}
              </div>
              <div class="sub-fee">
                {{ selectedPayable.selectedRExFees?.delinquentFee.display }}
              </div>
            </template>

            <!-- If there's a plate change then display a fee breakdown -->
            <template v-if="selectedPayable.rexHubCustomParameters.plateChange">
              <template v-if="selectedPayable.selectedPlateChangeFees.annualFee.number > 0">
                <div class="sub-fee">
                  {{ $t('rex.plate_change.fee_breakdown.annual_fee') }} {{ selectedPayable.selectedPlateChangeFees?.multiplier }}
                </div>
                <div class="sub-fee">
                  {{ selectedPayable.selectedPlateChangeFees?.annualFee.display }}
                </div>
              </template>

              <template v-if="selectedPayable.selectedPlateChangeFees.annualProcessingFee.number > 0">
                <div class="sub-fee">
                  {{ $t('rex.plate_change.fee_breakdown.annual_processing_fee') }} {{ selectedPayable.selectedPlateChangeFees?.multiplier }}
                </div>
                <div class="sub-fee">
                  {{ selectedPayable.selectedPlateChangeFees?.annualProcessingFee.display }}
                </div>
              </template>
              <template v-if="selectedPayable.selectedPlateChangeFees.newPlateReplacementFee.number > 0">
                <div class="sub-fee">
                  {{ $t('rex.plate_change.fee_breakdown.plate_replacement_fee') }}
                </div>
                <div class="sub-fee">
                  {{ selectedPayable.selectedPlateChangeFees?.newPlateReplacementFee.display }}
                </div>
              </template>
            </template>

            <!-- The final line is the full payable amount including all
            previous lines, only display this if we are displaying a fee breakdown -->
            <div
              v-if="displayFeeBreakdown"
              class="pt-1 pt-md-2 subtotal-grid"
            >
              {{ $t('payments.subtotal') }}
              <span class="amount">
                ${{ selectedPayable.displayAmount }}
              </span>
            </div>
          </div>
          <div
            v-if="selectedPayable.dueAt"
            :class="{ 'pt-2': selectedPayable.rexHubCustomParameters.plateChange }"
          >
            <span class="text-uppercase mr-2">{{ $t("due") }}</span>
            <b>{{ formatDate(selectedPayable.dueAt, false, $i18n.locale) }}</b>
          </div>
          <div v-if="selectedPayable.customParameters && selectedPayable.customParameters.effective_date">
            {{ $t("effective.date") }} <b>{{ selectedPayable.customParameters.effective_date }}</b>
          </div>
          <div v-if="selectedPayable.customParameters && selectedPayable.customParameters.receipt_number">
            {{ $t("receipt.num") }} <b>{{ selectedPayable.customParameters.receipt_number }}</b>
          </div>

        </div>
      </slot>

    </div>

    <slot
      name="payableAncillary"
      :payable="selectedPayable"
    />

    <b-row>
      <div class="col-12 mt-3 d-flex justify-content-end">
        <slot
          name="payableActions"
          :payable="selectedPayable"
          :validate="() => $refs.payableDescription.validate()"
        />
      </div>
    </b-row>

    <PayableNotice
      :payable="selectedPayable"
    />

  </div>
</template>

<script>
import PayableDescription from '../description/PayableDescription.vue'
import PayableAlert from '@grantstreet/psc-vue/components/PayableAlert.vue'
import PayableNotice from '@grantstreet/psc-vue/components/PayableNotice.vue'

import { formatDate } from '@grantstreet/psc-js/utils/date.js'

export default {
  components: {
    PayableDescription,
    PayableAlert,
    PayableNotice,
  },
  emits: [
    'change-plate',
    'change-rental-park-status',
    'update-insurance',
  ],
  props: {
    payable: {
      type: Object,
      required: true,
    },
    titleClass: {
      type: String,
      default: '',
    },
    colLimit: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      selectedPayable: this.payable,
    }
  },

  computed: {
    // Determine if we need to display a fee breakdown or just the renewal total.
    // If there's a plate change or other fee then use this method to display the base renewal
    // amount for this payable and the associated fees. Otherwise display the full displayAmount as normal.
    displayFeeBreakdown () {
      return this.selectedPayable.rexHubCustomParameters.plateChange || this.selectedPayable.selectedRExFees?.delinquentFee.number > 0
    },
  },

  methods: {
    formatDate,

    // For the parent to change this value manually when search results change
    setRExIsInRentalPark (isInRentalPark) {
      return this.$refs.payableDescription.setRExIsInRentalPark(isInRentalPark)
    },

    // For the parent to change this value manually when search results change
    setRExRenewalDuration (renewalDuration) {
      return this.$refs.payableDescription.setRExRenewalDuration(renewalDuration)
    },

  },
}
</script>

<style lang="scss" scoped>

.amount {
  font-size: $font-size-big;
  font-weight: 100;
}

.fee-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  justify-content: end;
  align-items: end;

  .sub-fee {
    font-size: 1rem;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
    }
  }

  .subtotal-grid {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 3;
    // Use subgrid when we can
    grid-template-columns: inherit;
    grid-column-gap: 1rem;
    align-items: baseline;
  }
}

</style>
