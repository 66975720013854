<template>
  <b-link
    v-if="!hasVerified"
    :id="'show-user-verification-accordion' + _uid"
    :key="'show-user-verification-accordion' + _uid"
    ref="closeStart"
    :aria-label="$t('user_verification.verify_ownership')"
    aria-controls="pin-ui"
    href="#"
    class="d-flex flex-row user-verification-accordion-link"
    data-test="show-user-verification-accordion"
    @click.prevent="$emit('change-user-verification-mode')"
  >
    <div class="icon-link mr-2">
      <!-- Applying a stroke color to this svg via a prop fails to style all of
      the path elements. (Only the last path in the group becomes colored). The
      scss below will override all of the strokes to be blue. -->
      <svgicon
        name="padlock-green-large"
        :original="true"
        class="lock-icon"
        aria-hidden="true"
      />
    </div>
    <span>
      {{ $t('user_verification.verify_ownership') }}
    </span>
    <!-- FIXME: within an iframe, this doesn't work -->
    <span
      v-if="showTooltip"
      :id="'tooltip-' + _uid"
      class="ml-2 small align-self-center d-flex flex-row"
    >
      <span class="d-flex align-items-center">
        <svgicon
          :original="true"
          :fill="false"
          icon="question-mark-circle"
          :aria-label="$t('user_verification.sell_verify')"
          tabindex="0"
          role="tooltip"
        />
      </span>
      <b-tooltip
        :target="'tooltip-' + _uid"
        placement="bottom"
        triggers="hover"
        :title="$t('user_verification.sell_verify')"
      />
    </span>
  </b-link>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/padlock-green-large.js'
import '@grantstreet/bootstrap/icons/js/question-mark-circle.js'

export default {
  emits: ['change-user-verification-mode'],
  props: {
    hasVerified: {
      type: Boolean,
      required: true,
    },
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<!-- Scope the scss to not affect other components.-->
<style lang="scss" scoped>
.user-verification-accordion-link {

  // since this scss is scoped, we need to use the deep selector to style the
  // elements contained in the svgicon component
  .lock-icon:deep(path),
  .lock-icon:deep(ellipse),
  .lock-icon:deep(circle),
  .lock-icon:deep(line) {
    stroke: currentColor;
  }

  .lock-icon:deep(circle) {
    fill: currentColor;
  }

  // The icon should darken in color when hovering over the link just like the
  // text darkens.
  &:hover .lock-icon:deep(path) {
    stroke: brightness(85%);
  }
}
</style>
