// It's critical that we avoid using .toFixed() to prevent floating point errors
// Take a look at these:
// https://stackoverflow.com/a/41716722/3454144
// https://stackoverflow.com/a/18358056/3454144
// https://bitbucket.grantstreet.com/projects/UX/repos/gsg-payhub-ui-mono/pull-requests/268/overviewexport
/**
 * Safely rounds to the required decimal places without floating point errors.
 * @param {Number} number     Number to be formatted.
 * @param {Number} [places=2] Places to round to
 * @throws {TypeError}        Throws if a non-number is passed.
 * @returns {Number}          A rounded version of the passed number.
 */
export function safeRound (number, places = 2) {
  if (typeof number !== 'number') {
    throw new TypeError('safeRound requires first argument to be a Number')
  }
  // Add EPSILON (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/EPSILON)
  // Scale and round
  // This *WILL* result in a proper round without floating point errors 🤞
  const scalar = 10 ** places
  return (Math.round((number + Number.EPSILON) * scalar) / scalar)
}

/**
 * Parses a currency string to a float. It accepts dollar signs, commas, and
 * leading/trailing spaces. If it fails to convert into a number,
 * it will throw an error. If given a number, it will just return the number.
 *
 * @param {String|Number} currency Currency string/number to be parsed.
 * @returns {Number} A number.
 */
export function parseCurrency (currency) {
  if (typeof currency === 'number') {
    return currency
  }
  if (typeof currency !== 'string') {
    throw new TypeError(`Parameter must either be a string or number: ${currency}`)
  }
  currency = currency.trim().replace(/,|\$/g, '')
  if (!currency || !/^\d*(?:\.\d{1,2})?$/.test(currency)) {
    throw new Error(`The parameter does not match expected currency format: ${currency}`)
  }
  const number = parseFloat(currency)

  //  Just in case of some weird edge case that returns NaN.
  if (isNaN(number)) {
    throw new Error(`The parameter's parsed numeric value is NaN: ${currency}`)
  }
  return number
}

/**
 * Parses a string to a float. Removes commas. This is important, use it.
 * Numbers are passed through.
 * @param {String} string String to be parsed.
 * @returns {Number}      A number.
 */
export function parseNumber (string) {
  if (typeof string === 'string') {
    string = string.replace(/,|\s|\$/g, '')
  }
  return parseFloat(string)
}

/**
 * Formats a number or string to a two decimal place string with trailing zeros.
 * Rounds without floating point errors. Will add zeroes unless truncated.
 * @param {Number} amount Number to be formatted.
 * @param {Boolean} [truncateZeroes=false] Truncates double trailing zeros.
 * @returns {String}      A string version of the passed number.
 */
export function decimalFormat (amount, truncateZeroes = false) {
  // Explicit parse to a Number so that adding Number.EPSILON will not concat
  // Add EPSILON (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/EPSILON)
  // Scale and round
  // toFixed is only used to add trailing zeros after properly rounding
  // This *WILL* result in a proper round without floating point errors 🤞
  // eslint-disable-next-line @grantstreet/no-unsafe-round
  const formatted = safeRound(parseNumber(amount)).toFixed(2)
  return truncateZeroes ? truncateZeroPennies(formatted) : formatted
}

/**
 * Formats a number or string to a two decimal place string with commas and
 * trailing zeros. Rounds without floating point errors.
 * @param {Number|String} number Number to be formatted.
 * @param {Boolean} [truncateZeroes=false] Truncates double trailing zeros.
 * @returns {String} A string version of the passed number.
 */
export function displayFormat (number, truncateZeroes = false) {
  if (isNaN(number)) {
    return number
  }

  const formatted = decimalFormat(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return truncateZeroes ? truncateZeroPennies(formatted) : formatted
}

export const amountIsNonzero = amount => {
  if (typeof amount === 'number') {
    return amount !== 0
  }
  if (typeof amount !== 'string') {
    return false
  }
  return amount && !/^\s*\$?\s*0+(\.00)?\s*$/i.test(amount)
}

/**
 * @function truncateZeroPennies
 * @param {String} string The string to truncate
 * @return {String} Truncates '.00' from strings.
 */
export function truncateZeroPennies (string) {
  // More efficient than regex
  if (string.endsWith('.00')) {
    return string.slice(0, -3)
  }
  return string
}

const numbersToWordsMapEn = {
  '0': 'zero',
  '1': 'one',
  '2': 'two',
  '3': 'three',
  '4': 'four',
  '5': 'five',
  '6': 'six',
  '7': 'seven',
  '8': 'eight',
  '9': 'nine',
  '10': 'ten',
  '11': 'eleven',
  '12': 'twelve',
  '13': 'thirteen',
  '14': 'fourteen',
  '15': 'fifteen',
  '16': 'sixteen',
  '17': 'seventeen',
  '18': 'eighteen',
  '19': 'nineteen',
  '20': 'twenty',
  '21': 'twenty-one',
  '22': 'twenty-two',
  '23': 'twenty-three',
  '24': 'twenty-four',
  '25': 'twenty-five',
  '26': 'twenty-six',
  '27': 'twenty-seven',
  '28': 'twenty-eight',
  '29': 'twenty-nine',
  '30': 'thirty',
}
const numbersToWordsMapEs = {
  '0': 'cero',
  '1': 'uno',
  '2': 'dos',
  '3': 'tres',
  '4': 'cuatro',
  '5': 'cinco',
  '6': 'seis',
  '7': 'siete',
  '8': 'ocho',
  '9': 'nueve',
  '10': 'diez',
  '11': 'once',
  '12': 'doce',
  '13': 'trece',
  '14': 'catorce',
  '15': 'quince',
  '16': 'dieciséis',
  '17': 'de diecisiete',
  '18': 'dieciocho',
  '19': 'diecinueve',
  '20': 'veinte',
  '21': 'veintiuno',
  '22': 'veintidós',
  '23': 'veintitres',
  '24': 'veinticuatro',
  '25': 'veinticinco',
  '26': 'veintiseis',
  '27': 'veintisiete',
  '28': 'veintiocho',
  '29': 'veintinueve',
  '30': 'treinta',
}
/**
 * Swaps numerals <= 30 for english words inside a given string.
 * This is a bit hacky, but the npm library to do this crashes IE for some
 * reason. We most likely don't have to worry about numbers greater than 30 for
 * this, but incase any come through, we default to showing the number instead.
 * @function numbersToWords
 * @param  {type} string A string containing numerals < 30
 * @return {type} The string with numerals < 30 swapped for their english words
 */
export const numbersToWords = (string, lang = 'en') => {
  const map = lang === 'en' ? numbersToWordsMapEn : numbersToWordsMapEs
  return string.split(' ').map(
    (word) => (isNaN(word) || !map[word])
      ? word
      : map[word],
  ).join(' ')
}
