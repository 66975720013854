/**
 * Client for the Announcements API
 */

import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment'
import { configState } from '@grantstreet/psc-config'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)
    this.axios.defaults.headers.post['Content-Type'] = 'application/json'
    this.axios.defaults.headers.put['Content-Type'] = 'application/json'
    this.baseUrl = process.env?.GSG_ANNOUNCEMENTS_SERVICE || `${baseGovHubUrl}/svc/announcements/v1`
  }

  ping () {
    return this.get('/ping')
  }

  // 0=========0
  // | Banners |
  // 0=========0
  //
  getBanners (filters) {
    const queryParams = []
    if (filters.clients) {
      const clients = filters.clients.join(',')
      queryParams.push(`clients=${clients}`)
    }
    if (filters.sites) {
      const sites = filters.sites.join(',')
      queryParams.push(`sites=${sites}`)
    }
    // XXX: Don't use prior to PSC-5014
    // if (filters.methods) {
    //   const methods = filters.methods.join(',')
    //   queryParams.push(`methods=${methods}`)
    // }
    if (filters.modules) {
      const modules = filters.modules.join(',')
      queryParams.push(`modules=${modules}`)
    }
    if (filters.includeInactive) queryParams.push('includeInactive=true')

    const queryString = queryParams.length
      ? '?' + queryParams.join('&')
      : ''
    return this.get(`/super/banners${queryString}`)
  }

  insertBanner (banner) {
    const headers = {}
    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }

    return this.post('/super/banners', banner, { headers })
  }

  getBanner (id) {
    return this.get(`/super/banners/${id}`)
  }

  updateBanner (banner) {
    const headers = {}

    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }

    return this.put(`/super/banners/${banner.id}`, banner, { headers })
  }

  deleteBanner (id) {
    const headers = {}
    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }
    return this.delete(`/super/banners/${id}`, { headers })
  }

  // 0===============0
  // | Notifications |
  // 0===============0
  //
  getNotifications (filters) {
    const queryParams = []
    if (filters.clients) {
      const clients = filters.clients.join(',')
      queryParams.push(`clients=${clients}`)
    }
    if (filters.sites) {
      const sites = filters.sites.join(',')
      queryParams.push(`sites=${sites}`)
    }
    if (filters.paymentMethods) {
      const methods = filters.paymentMethods.join(',')
      queryParams.push(`paymentMethods=${methods}`)
    }
    if (filters.includeInactive) queryParams.push('includeInactive=true')

    const queryString = queryParams.length
      ? '?' + queryParams.join('&')
      : ''
    return this.get(`/super/notifications${queryString}`)
  }

  insertNotification (notification) {
    const headers = {}
    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }
    return this.post('/super/notifications', notification, { headers })
  }

  getNotification (id) {
    return this.get(`/super/notifications/${id}`)
  }

  updateNotification (notification) {
    const headers = {}
    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }
    return this.put(`/super/notifications/${notification.id}`, notification, { headers })
  }

  deleteNotification (id) {
    const headers = {}
    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }
    return this.delete(`/super/notifications/${id}`, { headers })
  }

  // 0=========0
  // | Footers |
  // 0=========0
  //
  getFooters (filters) {
    const queryParams = []
    if (filters.clients) {
      const clients = filters.clients.join(',')
      queryParams.push(`clients=${clients}`)
    }
    if (filters.sites) {
      const sites = filters.sites.join(',')
      queryParams.push(`sites=${sites}`)
    }
    if (filters.includeInactive) queryParams.push('includeInactive=true')

    const queryString = queryParams.length
      ? '?' + queryParams.join('&')
      : ''
    return this.get(`/super/footers${queryString}`)
  }

  insertFooter (footer) {
    const headers = {}
    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }
    return this.post('/super/footers', footer, { headers })
  }

  getFooter (id) {
    return this.get(`/super/footers/${id}`)
  }

  updateFooter (footer) {
    const headers = {}
    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }
    return this.put(`/super/footers/${footer.id}`, footer, { headers })
  }

  deleteFooter (id) {
    const headers = {}
    if (configState.adminUserJwt) {
      headers['X-Remote-User'] = configState.adminUserJwt
    }
    return this.delete(`/super/footers/${id}`, { headers })
  }

  getTags () {
    return this.get('/mgmt/tags')
  }

  // 0============0
  // | Public API |
  // 0============0
  //
  // NOTE!
  // Until PSC-17127 is complete, Direct Charge Widget relies directly on this!!
  // Be extremely careful making changes.
  getPublicBanners (filters) {
    const queryParams = []
    let client, site
    if (filters.sites && filters.sites.length > 0) {
      client = filters.sites[0].client
      site = filters.sites[0].site
    }

    const queryString = queryParams.length
      ? '?' + queryParams.join('&')
      : ''
    return this.get(`/pub/${client}/${site}/banners${queryString}`)
  }

  getPublicNotifications (filters) {
    // As of PSC-4982, notifications no longer have payment methods to filter
    let client, site
    if (filters.sites && filters.sites.length > 0) {
      client = filters.sites[0].client
      site = filters.sites[0].site
    }

    return this.get(`/pub/${client}/${site}/notifications`)
  }

  getPublicFooters (filters) {
    // Footers have no query parameters to filter with
    let client, site
    if (filters.sites && filters.sites.length > 0) {
      client = filters.sites[0].client
      site = filters.sites[0].site
    }

    return this.get(`/pub/${client}/${site}/footers`)
  }
}
