/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'business-tax': {
    width: 16,
    height: 16,
    viewBox: '0 0 21.458 21.5',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Rectangle 12" d="M7.694.75h13.015v19.958H7.694z"/><path pid="1" data-name="Rectangle 13" d="M.75 5.989h6.944V20.75H.75z"/><path pid="2" data-name="Rectangle 14" d="M3.37 8.567h4.324v2.62H3.37z"/><path pid="3" data-name="Rectangle 15" d="M3.37 13.806h4.324v2.62H3.37z"/><path pid="4" data-name="Path 23" d="M16.384 20.708H12.06v-3.492a.834.834 0 01.832-.832h2.661a.834.834 0 01.832.832z"/><path pid="5" data-name="Line 28" d="M10.313 3.37h.873"/><path pid="6" data-name="Line 29" d="M13.806 3.37h.832"/><path pid="7" data-name="Line 30" d="M17.257 3.37h.873"/><path pid="8" data-name="Line 31" d="M10.313 5.989h.873"/><path pid="9" data-name="Line 32" d="M13.806 5.989h.832"/><path pid="10" data-name="Line 33" d="M17.257 5.989h.873"/><path pid="11" data-name="Line 34" d="M10.313 8.567h.873"/><path pid="12" data-name="Line 35" d="M13.806 8.567h.832"/><path pid="13" data-name="Line 36" d="M17.257 8.567h.873"/><path pid="14" data-name="Line 37" d="M10.313 11.187h.873"/><path pid="15" data-name="Line 38" d="M13.806 11.187h.832"/><path pid="16" data-name="Line 39" d="M17.257 11.187h.873"/><path pid="17" data-name="Line 40" d="M10.313 13.806h.873"/><path pid="18" data-name="Line 41" d="M13.806 13.806h.832"/><path pid="19" data-name="Line 42" d="M17.257 13.806h.873"/></g>'
  }
})
