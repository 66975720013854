import GenericApiClient from '@grantstreet/api-client'

export default class Client extends GenericApiClient {
  getEnvironment () {
    return this.get('/environment')
  }

  // origin should be validated by eg. origin-match's isGovHubOrigin
  // before blindly attempting to send requests to it
  getEnvironmentFromOrigin (origin) {
    return this.axios.get(origin + '/environment')
  }
}
