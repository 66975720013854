<template>
  <span id="search-icon">
    <span
      v-show="stalled"
      class="spinner"
    />

    <svgicon
      v-show="!stalled"
      class="text-primary"
      icon="magnifying-glass"
      height="100%"
      width="1.5rem"
      :fill="false"
    />
  </span>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch'
import { createNamespacedHelpers } from 'vuex'
import '@grantstreet/bootstrap/icons/js/magnifying-glass.js'
/*
  There are incompatibilities between @vue/compat and the Vue 3 versions
  (vue-instantsearch/vue3/es) of the Algolia components. Since we can't use
  the Vue 3 versions, the next best option is to opt into Vue 3 mode by default
  (MODE: 3) and only enable compat for certain features when necessary.
*/
createWidgetMixin.compatConfig = { MODE: 3 }

const connector = (renderFn, unmountFn) => (widgetParams = {}) => ({
  init () {
    renderFn({ searchMetadata: {} }, true)
  },

  render ({ searchMetadata }) {
    renderFn({ searchMetadata }, false)
  },

  dispose () {
    unmountFn()
  },
})

const { mapState } = createNamespacedHelpers('GovHubSearch')

export default {
  mixins: [
    createWidgetMixin({ connector }),
  ],

  computed: {
    ...mapState([
      'activeQueries',
    ]),

    stalled () {
      return this.activeQueries || (this.state && this.state.searchMetadata.isSearchStalled)
    },
  },
}
</script>

<style lang="scss" scoped>
#search-icon {
  height: 2.29rem;

  .spinner {
    top: .4rem;
  }
}
</style>
