import Vue from 'vue'
import Vuex from 'vuex'
import Submission from '../models/Submission.ts'
import { sentryException } from '../sentry.ts'
import FormsServiceApi from '../api.js'
import type { User } from '@grantstreet/user'

Vue.use(Vuex)

type Setting = {[key: string]: string | Setting}

export type InstallParams = {
  formConfig: Setting
  user: User
}
class State {
  submissions: Submission[] = []
  formConfig: Setting | undefined
  user: User | undefined
  api = new FormsServiceApi({ exceptionLogger: sentryException })
}

export default new Vuex.Store({
  state: new State(),
  getters: {
    formConfigs: (state) => {
      const formConfig = state.formConfig
      return formConfig?.formConfigurations || []
    },

    formConfigsBySlug: (_, getters) => {
      const bySlug = {}
      for (const formConfig of getters.formConfigs) {
        bySlug[formConfig.urlSlug] = formConfig
      }

      return bySlug
    },

    formConfigFromSlug: (_, getters) => (slug) => {
      return getters.formConfigsBySlug[slug]
    },
  },

  mutations: {
    initialize (state, {
      formConfig,
      user,
    }: InstallParams) {
      state.formConfig = formConfig
      state.user = user
      state.api = new FormsServiceApi({
        getJwt: () => user.getAccessToken(),
        exceptionLogger: sentryException,
      })
    },
    setSubmissions (state, submissions) {
      state.submissions = submissions
    },
  },

  actions: {
    async getSubmissions ({ state, commit }, opts) {
      const { data } = await state.api.listSubmissions(opts)

      commit('setSubmissions', data.data.map(submission => {
        return new Submission(submission)
      }))

      return { submissions: state.submissions }
    },
  },
})
