/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert-exclamation-triangle': {
    width: 16,
    height: 16,
    viewBox: '1445.186 1122.644 21.629 18.713',
    data: '<g transform="translate(1445.619 1123.064)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M12.13 1.032a2 2 0 00-3.5 0L.105 16.426a1 1 0 00.894 1.447h18.764a1 1 0 00.894-1.447zM10.381 3.937v6.5" stroke-width=".84"/><circle pid="1" cx="1" cy="1" r="1" transform="translate(9.381 12.937)"/></g>'
  }
})
