/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // msi-iframe-star-prod
    prod: 'https://735a17bbe6514a20a3bb6ed7776404c0@o168195.ingest.sentry.io/6642031',
    // msi-iframe-star-nonprod
    nonprod: 'https://5c763ff93aa243349102cc58b65bc4e2@o168195.ingest.sentry.io/6642029',
  },
})
