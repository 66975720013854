<!--
  This renders a generic "Part of this site failed to load" error (small or
  large) when a module fails to load.
-->

<template>
  <div class="my-2 d-flex flex-column justify-content-center align-items-center">
    <svgicon
      :id="`error-${id}`"
      icon="broken-link-icon"
      :width="large ? '26' : '16'"
      height="auto"
      :class="{
        'mb-3': large,
        'cursor-pointer': !large
      }"
      :original="true"
      :fill="true"
    />
    <b-alert
      v-if="large"
      show
      class="rounded-pill border-0 px-3 py-2"
      variant="danger"
    >
      <i18n-t
        keypath="module-load-error"
        scope="global"
      />
    </b-alert>
    <b-tooltip
      v-else
      :target="`error-${id}`"
      triggers="hover"
    >
      <i18n-t
        :i18n="i18n"
        keypath="module-load-error"
      />
    </b-tooltip>
  </div>
</template>

<script setup>
import { v4 as uuid } from 'uuid'
import { useI18n } from 'vue-i18n'

defineProps({
  large: {
    type: Boolean,
    default: false,
  },
})

const i18n = useI18n()
const id = uuid()
</script>
