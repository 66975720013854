import { getFormsUrl } from '../api.js'
import axios from 'axios'

type User = {
  userId: string
  name: string
  email: string
  phoneNumber: string
  language: string
}

type DecisionInfo = {
  decisionUser: User
  decisionComment: string
  decidedAt: Date
}

type Field = {
  questionText: string
  answer: string
}

export default class {
  isDownloading = false
  hasError = false
  id: string
  jotformFormTitle: string
  jotformSubmissionId: string
  createdAt: Date
  user: User
  fields: Field[]
  status: string
  decisionInfo: DecisionInfo
  assignedUser: User

  constructor ({
    id,
    jotformFormTitle,
    jotformSubmissionId,
    createdAt,
    user,
    fields,
    status = 'Open',
    decisionInfo,
    assignedUser,
  }) {
    this.id = id
    this.jotformFormTitle = jotformFormTitle
    this.jotformSubmissionId = jotformSubmissionId
    this.createdAt = createdAt ? new Date(createdAt) : createdAt
    this.user = user
    this.fields = fields
    this.status = status
    this.decisionInfo = decisionInfo
    this.assignedUser = assignedUser
  }

  get decisionUser () {
    return this.decisionInfo?.decisionUser
  }

  get decisionComment () {
    return this.decisionInfo?.decisionComment
  }

  get decidedAt () {
    return this.decisionInfo?.decidedAt
  }

  get email () {
    return this.fields?.find(({ questionText }) => questionText === 'Email')?.answer ||
      this.user?.email
  }

  async downloadPdf (accessToken) {
    // Block concurrent downloads
    if (this.isDownloading) {
      return
    }

    this.isDownloading = true
    this.hasError = false

    try {
      const response = await axios.get(`${getFormsUrl()}/v1/submissions/${this.id}/pdf`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      })
      // Based off of https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.id}.pdf`)
      link.click()
    }
    catch (error) {
      this.hasError = true
    }

    this.isDownloading = false
  }
}
