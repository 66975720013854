/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-checkmark': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<g data-name="Ellipse 454" _stroke="#fff" _fill="#5aa017"><circle pid="0" cx="17" cy="17" r="17" _stroke="none"/><circle pid="1" cx="17" cy="17" r="16.5" _fill="none"/></g><path pid="2" data-name="Artwork 23" d="M10 17l5.625 5 8.75-10" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" _fill="none" _stroke="#fff"/>'
  }
})
