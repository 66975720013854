<!-- https://bitbucket.grantstreet.com/projects/UX/repos/vue-typeahead-bootstrap -->
<!-- Functionally, this should be the same compatible except with some minor
  tweaks to make it Vue 3 compatible. -->
<template>
  <div
    :id="`typeahead-${id}`"
    class="typeahead-main"
    role="combobox"
    aria-haspopup="listbox"
    :aria-owns="`result-list-${id}`"
    :aria-expanded="(isFocused && data.length > 0) ? 'true' : 'false'"
  >
    <div :class="inputGroupClasses">
      <input
        :id="`typeahead-input-${id}`"
        ref="input"
        type="text"
        role="searchbox"
        :class="`form-control ${inputClass}`"
        :aria-labelledby="ariaLabelledBy"
        aria-multiline="false"
        aria-autocomplete="list"
        :aria-controls="`result-list-${id}`"
        :aria-activedescendant="`selected-option-${id}`"
        :name="inputName"
        :placeholder="placeholder"
        :aria-label="(!ariaLabelledBy) ? placeholder : false"
        :value="inputValue"
        :disabled="disabled"
        @focus="handleFocusIn"
        @blur="handleFocusOut"
        @input="handleInput($event.target.value)"
        @keydown.esc="handleEsc($event.target.value)"
        @keyup="handleKeyup"
      >
      <div
        v-if="$slots.append || append"
        class="input-group-append"
      >
        <slot name="append">
          <span class="input-group-text">{{ append }}</span>
        </slot>
      </div>
    </div>
    <TypeaheadList
      v-show="isFocused && data.length > 0"
      :id="`result-list-${id}`"
      ref="list"
      class="vbt-autcomplete-list"
      :query="inputValue"
      :data="formattedData"
      :max-matches="maxMatches"
      :min-matching-chars="minMatchingChars"
      :disable-auto-select="disableAutoSelect"
      :disable-sort="disableSort"
      :show-on-focus="showOnFocus"
      :show-all-results="showAllResults"
      :highlight-class="highlightClass"
      :disabled-values="disabledValues"
      :vbt-unique-id="id"
      role="listbox"
      @hit="handleHit"
      @list-item-blur="handleChildBlur"
    >
      <template
        v-if="$slots.suggestion"
        #suggestion="{data : slotData, htmlText}"
      >
        <slot
          name="suggestion"
          :data="slotData"
          :html-text="htmlText"
        />
      </template>
    </TypeaheadList>
  </div>
</template>

<script>
import TypeaheadList from './TypeaheadList.vue'

export default {
  name: 'Typeahead',

  components: {
    TypeaheadList,
  },

  props: {
    ariaLabelledBy: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
      validator: size => ['lg', 'md', 'sm'].includes(size),
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
      validator: d => d instanceof Array,
    },
    serializer: {
      type: Function,
      default: (d) => d,
      validator: d => d instanceof Function,
    },
    // Don't call this method, use _screenReaderTextSerializer()
    // Using _screenReaderTextSerializer allows for defaulting based on .serializer
    screenReaderTextSerializer: {
      type: Function,
      default: () => {},
      validator: d => d instanceof Function,
    },
    disabledValues: {
      type: Array,
      default: () => [],
    },
    inputClass: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: undefined,
    },
    maxMatches: {
      type: Number,
      default: 10,
    },
    minMatchingChars: {
      type: Number,
      default: 2,
    },
    disableAutoSelect: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    showOnFocus: {
      type: Boolean,
      default: false,
    },
    showAllResults: {
      type: Boolean,
      default: false,
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Type text here...',
    },
    append: {
      type: String,
      default: null,
    },
    highlightClass: {
      type: String,
      default: null,
    },
  },
  emits: ['input', 'hit', 'focus', 'blur', 'keyup'],
  computed: {
    id () {
      return Math.floor(Math.random() * 100_000)
    },
    inputGroupClasses () {
      return this.size ? `input-group input-group-${this.size}` : 'input-group'
    },

    formattedData () {
      if (!(this.data instanceof Array)) {
        return []
      }
      return this.data.map((d, i) => {
        return {
          id: i,
          data: d,
          screenReaderText: this._screenReaderTextSerializer(d),
          text: this.serializer(d),
        }
      })
    },
  },

  methods: {
    _screenReaderTextSerializer (d) {
      if (typeof d === 'object' && !Array.isArray(d) && d !== null) {
        if (this.screenReaderTextSerializer) {
          return this.screenReaderTextSerializer(d)
        }
        else {
          return this.serializer(d)
        }
      }
      else {
        return d
      }
    },
    resizeList (el) {
      const rect = el.getBoundingClientRect()
      const listStyle = this.$refs.list.$el.style

      // Set the width of the list on resize
      listStyle.width = rect.width + 'px'
    },

    handleHit (evt) {
      if (typeof this.value !== 'undefined') {
        this.$emit('input', evt.text)
      }

      this.inputValue = evt.text
      this.$emit('hit', evt.data)

      if (this.autoClose) {
        this.$refs.input.blur()
        this.runFocusOut()
      }
    },

    handleChildBlur () {
      this.$refs.input.focus()
      this.runFocusOut()
    },

    runFocusIn () {
      this.isFocused = true
      this.$emit('focus')
    },

    runFocusOut (tgt) {
      if (tgt && tgt.classList.contains('vbst-item')) {
        return
      }
      this.isFocused = false
      this.$emit('blur')
    },

    handleFocusIn (evt) {
      this.runFocusIn()
    },

    handleFocusOut (evt) {
      this.runFocusOut(evt.relatedTarget)
    },

    handleInput (newValue) {
      this.$refs.list.resetActiveListItem()
      this.runFocusIn()
      this.inputValue = newValue

      // If v-model is being used, emit an input event
      if (typeof this.value !== 'undefined') {
        this.$emit('input', newValue)
      }
    },

    handleEsc (inputValue) {
      if (inputValue === '') {
        this.$refs.input.blur()
        this.runFocusOut()
      }
      else {
        this.handleInput('')
      }
    },

    handleKeyup (event) {
      this.$refs.list.handleParentInputKeyup(event)
      this.$emit('keyup', event)
    },
  },

  data () {
    return {
      isFocused: false,
      inputValue: this.value || '',
    }
  },
  watch: {
    value: function (val) {
      this.inputValue = val
    },
  },
}
</script>

<style scoped>
  .typeahead-main {
    position: relative;
  }
  .vbt-autcomplete-list {
    padding-top: 5px;
    position: absolute;
    max-height: 350px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: auto;
    z-index: 999;
    width: 100%;
  }
  .vbt-autcomplete-list >>> .vbt-matched-text{
    font-weight: bold;
  }
</style>
