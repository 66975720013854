<!--
  This component is a wrapper to the UserVerificationPIN component.
  The UserVerificationPIN component must be mountable within a TaxSys iframe;
  the vuex store and api client are not available to components mounted within
  the iframe.

  This provides user-verification-public Vuex state via props to the User
  Verification status, and makes requests to the User Verification api when
  events are emitted.
-->
<template>
  <div>
    <!-- Pass an array with the payable here -->
    <UserVerificationPIN
      :payable-save-path="payable.raw.save_path"
      :payables="[payable]"
      :user-verification-mode="userVerificationMode"
      :has-requested-verification="hasRequestedVerification"
      :status="status"
      :is-authenticated="loggedIn"
      :loading="loading"
      :status-error="statusError"
      :status-error-text="statusErrorText"
      @change-user-verification-mode="$emit('change-user-verification-mode', $event)"
      @request-pin="requestPIN($event)"
      @enter-pin="enterPIN($event)"
    />
  </div>
</template>

<script>
import store from '../store.ts'
import UserVerificationPIN from './UserVerificationPIN.vue'
import { sentryException } from '../sentry.ts'
export default {
  emits: ['change-user-verification-mode'],
  components: {
    UserVerificationPIN,
  },
  data: () => ({
    loading: true,
    statusError: false,
    statusErrorText: '',
  }),
  props: {
    payable: {
      type: Object,
      required: true,
    },
    userVerificationMode: {
      type: String,
      default: 'closed',
    },
    queryPayablePath: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasRequestedVerification () {
      return Boolean(store.getters.hasRequestedVerification(this.payable.raw.save_path))
    },
    status () {
      return store.getters.hasRequestedVerification(this.payable.raw.save_path, true)
    },
    loggedIn () {
      return store.state.user?.loggedIn
    },
  },
  methods: {
    async requestPIN (event) {
      this.$wait.start(`user verification status ${event.rootPayablePath}`)

      const payload = {
        payable: this.payable,
        rootPayablePath: event.rootPayablePath,
        accountPayableSavePath: event.payableSavePath,
      }

      try {
        await store.dispatch('requestVerification', payload)
      }
      // Any non-200 response will lead to an error so handle error messages
      // in the catch block
      catch (error) {
        sentryException(error)
        this.statusError = error
        this.statusErrorText = this.$t('user_verification.error.pin_request')
      }
      finally {
        this.$wait.end(`user verification status ${this.payable.raw.save_path}`)
      }
    },

    async enterPIN (event) {
      this.$wait.start(`user verification status ${event.rootPayablePath}`)

      try {
        await store.dispatch('enterVerification', {
          payable: this.payable,
          pin: event.PIN,
          rootPayablePath: event.rootPayablePath,
          accountPayableSavePath: event.payableSavePath,
        })
      }
      // Any non-200 response will lead to an error so handle error messages
      // in the catch block
      catch (error) {
        sentryException(error)
        this.statusError = error
        this.statusErrorText = error.response?.status === 403 ? this.$t('user_verification.invalid') : this.statusErrorText = this.$t('user_verification.issue')
      }
      finally {
        this.$wait.end(`user verification status ${this.payable.raw.save_path}`)
      }
    },
  },
  async mounted () {
    try {
      await store.dispatch('loadData')
    }
    // If loading data (i.e. user's statuses) errors out, report error to Sentry but
    // don't show errors on the page since this will cause it to show for every payable
    catch (error) {
      sentryException(error)
      store.state.loaded = true
    }
    finally {
      this.loading = false
    }
  },
  watch: {
    userVerificationMode (newMode, oldMode) {
      // User switched between requesting or entering a PIN
      if ((oldMode === 'requestPIN' && newMode === 'enterPIN') ||
      (oldMode === 'enterPIN' && newMode === 'requestPIN') ||
      // User closed the request PIN window. Clear any errors.
      (oldMode === 'requestPIN' && newMode === 'closed')) {
        this.statusError = false
        this.statusErrorText = ''
      }
    },
  },
}
</script>
