import { mapByKey } from './objects.js'

// Returns a function that sorts objects ASC by the passed prop
export function sortByProp (prop, transform) {
  return (a, b) => {
    if (transform) {
      a = transform(a)
      b = transform(b)
    }
    return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0
  }
}

// Case insensitive string search
export const caseInsensitive = (a, b) => {
  a = a.toLowerCase()
  b = b.toLowerCase()
  if (a < b) {
    return -1
  }
  if (b < a) {
    return 1
  }
  return 0
}

// Fuzzy match strings
export const fuzzyMatch = (query, option) => {
  // Build a regex that searches the string for each caracter in the query (in
  // any position)
  const pattern = query.split('').map((char) => {
    // If the character is a regex special char escape it
    if ('[\\^/$.|?*+()[]{}'.includes(char)) {
      char = '\\' + char
    }
    return `(?=.*${char})`
  }).join('')
  // Search for each character anywhere in the string, case insensitive
  const regex = new RegExp(pattern, 'gi')
  return option.match(regex)
}

// Array difference (symmetrical)
export const difference = (a, b) => a.filter(i => !b.includes(i)).concat(
  b.filter(i => !a.includes(i)),
)

// Array intersection
export const intersection = (a, b) => a.filter(i => b.includes(i))

/**
 * @function reorderByPropIndex
 * Takes an array of objects and reorders them using an ordered array of one
 * property found on the objects. `orderedValues` is an array of values found on
 * the objects under `key`.
 *
 * "Take objects, where the set object[key] is equal to the set orderedValues,
 * and reorder objects (indexed by key) to match the order of orderedValues."
 *
 * @param  {Array} objects       - The objects to be ordered.
 * @param  {Array} orderedValues - The list of ordered properties.
 * @param  {string} key          - The key used for ordering.
 * @return {Array}               - The reordered objects.
 */
export const reorderByPropIndex = (objects, orderedValues, key) => {
  const mapped = mapByKey(objects, key)
  return orderedValues.map(k => mapped[k])
}
