<template>
  <div
    data-test="my-forms"
    class="mx-auto"
  >

    <LoadingBars
      v-if="!loaded"
      class="py-6"
    />

    <Alert
      v-else-if="unknownError"
      variant="warning"
      :dismissible="false"
    >
      <div>{{ $t('forms.myforms.unknown_error') }}</div>
    </Alert>

    <b-container
      v-else-if="!store.state.user?.loggedIn || store.state.submissions.length === 0"
      class="bg-white rounded-xl p-0"
      fluid
    >
      <b-row
        align-v="center"
        no-gutters
      >
        <b-col
          data-test="my-forms-warning"
          cols="12"
          sm="5"
          class="px-3 pt-5 pt-sm-0 text-center"
        >
          {{ !store.state.user?.loggedIn ? $t('forms.login.description') : $t('forms.myforms.no_forms') }}
        </b-col>
        <b-col
          cols="12"
          sm="7"
        >
          <b-img
            class="max-height-img my-forms-example"
            :src="MyFormsExample"
            :alt="!store.state.user?.loggedIn ? $t('forms.login.description') : $t('forms.myforms.no_forms')"
            fluid
          />
        </b-col>
      </b-row>
    </b-container>

    <div
      v-for="(submission, index) of store.state.submissions"
      :key="submission.id"
      class="py-2 py-md-4 border-top px-6 bg-white my-forms-submission"
    >
      <b-row>
        <b-col
          sm="8"
          cols="12"
          class="col d-flex flex-column pt-3 pt-md-0"
        >
          <h4 class="mb-0">{{ submission.jotformFormTitle }}</h4>
          <div
            class="preserve-newlines small"
          >{{
            $t("forms.submitted_at", {
              createdAt: formatDate(submission.createdAt, false, $i18n.locale)
            })
          }}
          </div>
        </b-col>
        <b-col class="col d-flex flex-column pt-3 pt-md-0 justify-content-center align-items-center">
          <b-link
            v-if="!submission.isDownloading"
            :data-test="'my-forms-download-' + index"
            @click.prevent="submission.downloadPdf(store.state.user?.getAccessToken())"
          >{{ $t("forms.download") }}</b-link>
          <LoadingSpinner v-else />
        </b-col>
      </b-row>
      <Alert
        v-if="submission.hasError"
        data-test="my-forms-download-error"
        variant="danger"
        :show-icon="false"
        :dismissible="false"
      >
        {{ $t('api.error') }}
      </Alert>
    </div>
  </div>
</template>

<script>
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import LoadingSpinner from '@grantstreet/loaders-vue/LoadingSpinner.vue'
import { formatDate } from '@grantstreet/psc-js/utils/date.js'
import { isInIframe } from '@grantstreet/psc-js/utils/iframe.js'
import { sentryException } from '../sentry.ts'
import store from '../store/index.ts'
import MyFormsExample from '../assets/my-forms.png'

export default {
  name: 'MyFormsView',

  components: {
    LoadingBars,
    Alert,
    LoadingSpinner,
  },

  data: () => ({
    store,
    loaded: false,
    unknownError: false,
    MyFormsExample,
  }),

  methods: {
    formatDate,
  },

  async mounted () {
    if (isInIframe()) {
      await new iframeWhisperer.ToParent().message({
        action: 'gsgForms.unnest',
        payload: {
          path: window.location.pathname + window.location.hash,
        },
      })

      sentryException(new Error('Failed to navigate parent frame to the Forms Dashboard, this user might have an interesting experience'))
    }

    if (store.state.user?.getAccessToken()) {
      try {
        await store.dispatch('getSubmissions')
      }
      catch (error) {
        console.error('Could not get user submissions', error)
        sentryException('Could not get user submissions: ' + error)
        this.unknownError = true
      }
    }

    this.loaded = true
  },
}
</script>

<style scoped lang="scss">
.my-forms-example {
  max-width: 75%;
}
</style>
