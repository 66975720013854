/* eslint-disable vue/one-component-per-file */
import { createApp } from 'vue'
import { isProd, isValidEnvironment, setEnvironment } from '@grantstreet/psc-environment'
import store from './store/index.ts'
import { isGovHubOrigin } from '@grantstreet/psc-js/utils/origin-match.js'
import LargeError from '@grantstreet/psc-vue/components/LargeError.vue'
import Jotform from './components/Jotform.vue'
import { vueErrorHandler, sentryException } from './sentry.ts'
import { i18n, loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        attachEvent(event: string, listener: EventListener): boolean
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Document {
        mozCancelFullScreen(): Promise<void>
        webkitExitFullscreen(): Promise<void>
        msExitFullscreen(): Promise<void>
    }
}

/** listenForJotformMessage ()
 *
 * Adds event handling for the expected iframe messages from Jotform
 */
export const listenForJotformMessage = () => {
  // TODO: This should have corresponding a removeEventListener
  if (window.addEventListener) {
    window.addEventListener('message', handleJotformMessage, false)
  }
  else if (window.attachEvent) {
    window.attachEvent('onmessage', handleJotformMessage)
  }
}

export const generateChildOriginValidator = (parentOrigin, envApi, errHandler) => {
  return async childOrigin => {
    if (childOrigin === parentOrigin) {
      return true
    }

    // These messages come from the Jotform iframe
    // which may link back to GovHub once a submission is complete.
    // Jotform offers no means of setting the link based on environment,
    // so this will usually be prod, but may occasionally be nonprod during testing.
    // If you link Jotform to a *sandbox* (during development)
    // you will need to uncomment this check to test.
    if (!isGovHubOrigin(childOrigin)) {
      errHandler('Ignoring message from non-GovHub child origin ' + childOrigin)
      return false
    }

    // Check the child's environment
    const response = await envApi.getEnvironmentFromOrigin(childOrigin)
    const childEnv = response?.data?.environment
    if (!childEnv) {
      errHandler('Ignoring message from child origin ' + childOrigin + ': could not determine environment')
      return false
    }

    // Always accdept instructions from prod
    if (childEnv === 'prod') {
      return true
    }

    // Prod GovHub can only receive instructions from prod (already checked)
    // But nonprod can receive instructions from anything it recognizes as a GovHub origin
    if (isProd()) {
      errHandler('Ignoring message from nonprod child origin ' + childOrigin)
      return false
    }

    return true
  }
}

// This file is based on the suggested embed code provided by jotform.js
//
// To see the raw code, you can look at the commit that introduced this file.
// Alternatively, if you have a Jotform account (they're free!) you can
// go to https://www.jotform.com/build/$form_id/publish/embed and select "IFRAME"

/** iframeSrcParams (src)
 *
 * Grabs all query parameters from the current URL
 * and appends them to the provided src
 */
export const iframeSrcParams = (src) => {
  let iframeParams: string[] = []
  if (window.location.href && window.location.href.includes('?')) {
    iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf('?') + 1).split('&'))
  }
  if (src && src.includes('?')) {
    iframeParams = iframeParams.concat(src.substr(src.indexOf('?') + 1).split('&'))
    src = src.substr(0, src.indexOf('?'))
  }
  iframeParams.push('isIframeEmbed=1')
  return src + '?' + iframeParams.join('&')
}

const handleJotformMessage = e => {
  if (typeof e.data === 'object') {
    return
  }
  let iframe: HTMLElement | null = null
  const args = e.data.split(':')
  if (args.length > 2) {
    iframe = document.getElementById('JotFormIFrame-' + args[(args.length - 1)])
  }
  else {
    iframe = document.getElementById('JotFormIFrame')
  }
  if (!iframe) {
    return
  }
  switch (args[0]) {
  case 'scrollIntoView':
    iframe.scrollIntoView()
    break
  case 'setHeight':
    // For some reason, Jotform is picking heights *slightly* too small
    // which causes just enough scrolling on the child
    // to mess up scrolling the parent
    // 8px is *also* the margin (top and bottom) of the default user-stylesheet
    // The default height here will match the iframe body's inner height (excluding margin)
    // which doesn't sound like mere coincidence
    iframe.style.height = (parseInt(args[1]) + 8) + 'px'
    break
  case 'collapseErrorPage':
    if (iframe.clientHeight > window.innerHeight) {
      iframe.style.height = window.innerHeight + 'px'
    }
    break
  case 'reloadPage':
    window.location.reload()
    break
  case 'loadScript':
    // We're not going to let jotform load scripts into the parent
    break
  case 'exitFullscreen':
    if (window.document.exitFullscreen) window.document.exitFullscreen()
    else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen()
    else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen()
    else if (window.document.msExitFullscreen) window.document.msExitFullscreen()
    break
  }
}

type ExtraParam = {
  key: string
  value: string
}

type AttachParams = {
  jotformId?: string
  client?: string
  environment?: string
  extraParams?: ExtraParam[]
}

export const attach = (elSelector: string, {
  jotformId,
  client,
  environment,
  extraParams,
}: AttachParams) => {
  if (isValidEnvironment(environment)) {
    setEnvironment(environment)
  }
  else {
    createApp(LargeError, {
      type: 'loadFailure',
    }).mount(elSelector)

    return
  }

  loadTranslations(sentryException)

  store.commit('initialize', {
    formConfig: {
      formConfigurations: [{
        urlSlug: jotformId,
        formID: jotformId,
        nonprodFormID: jotformId,
        formTitle: '',
      }],
    },
  })

  listenForJotformMessage()

  createApp(Jotform, {
    slug: jotformId,
    client,
    extraParams,
  })
    .use(store)
    .use(i18n)
    .use(vueErrorHandler)
    .mount(elSelector)
}
