<!-- XXX PSC-18425 maybe delete this (and related) file(s) -->
<template>
  <div
    class="px-4 py-3 px-md-6 row no-gutters border-bottom align-items-center"
    data-test="payable"
  >
    <div class="col-12 col-md-7">
      <span style="font-size: 1.125rem; font-weight: bold">{{ payable.displayName }}</span>
      <span
        v-if="payable.displayAmount"
        class="px-3"
      >${{ payable.displayAmount }}</span>
    </div>

    <div class="item-inputs col-12 col-sm-9 col-md-5 pt-3 pt-md-0 d-flex align-items-center justify-content-end">
      <template v-if="payable.isQuantityModifiable">
        <div>{{ $t("quantity.shorthand") }}</div>
        <input
          v-model.number="quantity"
          :aria-label="$t('quantity.longhand')"
          type="number"
          min="1"
          data-test="item-quantity"
          class="quantity form-control d-inline text-center mx-2"
        >
      </template>
      <template v-if="freeformFields.length">
        <div>
          <b-button
            v-if="!expanded"
            data-test="expand-button"
            variant="primary"
            @click="expandHandler(true)"
          >
            {{ $t('expand.purchase') }}
          </b-button>
          <b-button
            v-if="expanded"
            data-test="collapse-button"
            variant="outline-primary"
            @click="expandHandler(false)"
          >
            {{ $t('expand.close') }}
          </b-button>
        </div>
      </template>
      <slot
        v-else
        name="addButton"
        class="flex-grow-1 w-100"
        :payable="payable"
        :quantity="Number(quantity)"
      />
    </div>

    <template v-if="freeformFields.length && expanded">
      <div class="row w-100 bg-grey rounded-xl my-3 mx-0">
        <div class="col col-12 col-md-7">
          <FreeformField
            v-for="field of freeformFields"
            :key="field.reportingKey || field.pexKey"
            ref="freeformFields"
            :field="field"
            light="true"
            @field-update="updateUserParameters"
          />
        </div>
        <div class="col col-12 col-sm-9 col-md-5 pt-3 pt-md-0 mt-2 d-flex align-items-start justify-content-end">
          <slot
            name="addButton"
            :payable="payable"
            :quantity="Number(quantity)"
            :validate="validateFreeformFields"
            :user-parameters="userParameters"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import FreeformField from '@grantstreet/psc-vue/components/FreeformField.vue'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
import store from '../../store/index.ts'

export default {

  components: {
    FreeformField,
  },

  props: {
    payable: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    quantity: 1,
    expanded: false,
    userParameters: {},
  }),

  async mounted () {
    EventBus.$on('freeformFields.expanded', this.setExpansion)
  },

  beforeUnmount () {
    EventBus.$off('freeformFields.expanded', this.setExpansion)
  },

  computed: {
    freeformFields () {
      if (this.payable.freeformFields) {
        return this.payable.freeformFields
      }

      return store.getters.getPayableSource(this.payable.configDisplayType?.name)?.freeformFields || []
    },
  },

  methods: {
    setExpansion (expanded) {
      this.expanded = expanded
    },

    expandHandler (expanded) {
      // Keeping consistent with plates/vouchers UI, we only want one freeform
      // fields panel open at a time, so we close any open ones first before
      // performing any action
      EventBus.$emit('freeformFields.expanded', false)
      this.expanded = expanded
    },

    validateFreeformFields () {
      // .reduce, not .some or .every because we want to validate all fields
      return this.$refs.freeformFields.reduce(
        // Order matters. Validate all fields.
        (valid, component) => component.validate() && valid
        , true)
    },

    updateUserParameters ({ pexKey, reportingKey, value }) {
      const key = reportingKey || pexKey
      this.userParameters[key] = value
    },
  },
}
</script>
