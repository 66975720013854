export default {
  en: [
    [ 'General Instructions:' ],
    [ '1. Your file should be in csv format.' ],
    [ '2. Headers are not required:' ],
    [ '3. File columns should be in the order as shown in columns A, B, and C of this sheet. Additional columns will be ignored.' ],
    [ '4. Account/Parcel numbers should be in the exact format as shown on your bill, including: special characters, leading zeroes, and spaces.' ],
    [ '' ],
    [ 'Advanced Instructions for importing data into this template from an existing file (for Windows):' ],
    [ '1. Go to the template file\'s "Data" tab.' ],
    [ '2. Select "Get Data" > "From File" and choose the format of the file being imported.' ],
    [ '3. Locate and "Open" the file.' ],
    [ '4. Select the file you want to import and the preview will appear in the correct format that needs to be imported:' ],
    [ '    1. If not, update the data as needed in the original file or by clicking "Transform Data."' ],
    [ '        1. Make sure you only are importing in the year, property type, and parcel number for your accounts in that order. You can do this by using the "choose columns" button at the top or by right clicking the header of a column and removing said column.' ],
    [ '    2. When correct, click the "Close and load" button in the top left corner.' ],
    [ '    3. The data will be imported into a new worksheet in the template file.' ],
    [ '5. Remaining on the newly added worksheet, go to "File" > "Save As" and update the name of the file that is being saved for upload. Note: It is important that the file extension remains .csv for proper uploading.' ],
    [ '6. Click "Save."' ],
    [ '7. You will receive a message stating that the workbook cannot contain multiple sheets. At this prompt, click "OK" to save the active sheet.' ],
    [ '8. The file is now prepared for upload on the public site.' ],
    [ '' ],
    [ 'Advanced Instructions for importing data into this template from an existing file (for Mac):' ],
    [ '1. Go to the template file\'s "Data" tab.' ],
    [ '2. Select "Get Data (Power Query)" > and choose the format of the file being imported.' ],
    [ '3. Click "Browse", locate the file and select it, click "Get Data".' ],
    [ '4. Click "Next" and your file should preview on the left hand side.' ],
    [ '5. The preview of the data should now appear in the correct format that needs to be imported:' ],
    [ '    1. If not, update the data as needed in the original file or by clicking "Transform Data."' ],
    [ '        1. Make sure you only are importing in the year, property type, and parcel number for your accounts in that order. You can do this by using the "choose columns" button at the top of your "Power Query Editor" or by right clicking the header of a column and removing said column.' ],
    [ '    2. When correct, click the "Close and load" button in the top left corner.' ],
    [ '    3. The data will be imported into a new worksheet in the template file.' ],
    [ '6. Remaining on the newly added worksheet, go to "File" > "Save As" and update the name of the file that is being saved for upload. Note: It is important that the file extension remains .csv for proper uploading.' ],
    [ '7. Click "Save."' ],
    [ '8. You will receive a message stating that the workbook cannot contain multiple sheets. At this prompt, click "OK" to save the active sheet.' ],
    [ '9. The file is now prepared for upload on the public site.' ],
  ],
  es: [
    [ 'Instrucciones generales' ],
    [ '1. Tu archivo debe estar en formato csv.' ],
    [ '2. Los encabezados no son necesarios.' ],
    [ '3. Las columnas del archivo deben estar en el orden que se muestra en las columnas A, B y C de esta hoja. Se omitirán las columnas adicionales.' ],
    [ '4. Los números de cuenta/parcela deben tener el formato exacto que se muestra en su factura, incluidos: caracteres especiales, ceros a la izquierda y espacios.' ],
    [ '' ],
    [ 'Instrucciones avanzadas para importar datos a esta plantilla desde un archivo existente (para Windows):' ],
    [ '1. Vaya a la pestaña "Datos" del archivo de plantilla.' ],
    [ '2. Seleccione "Obtener datos" > "Desde archivo" y elija el formato del archivo que se está importando.' ],
    [ '3. Localice y "Abra" el archivo.' ],
    [ '4. Seleccione el archivo que desea importar y la vista previa aparecerá en el formato correcto que debe ser importado:' ],
    [ '    1. Si no es así, actualice los datos según sea necesario en el archivo original o haciendo clic en "Transformar datos".' ],
    [ '        1. Asegúrese de importar solo el año, el tipo de propiedad y el número de parcela para sus cuentas en ese orden. Puede hacerlo usando el botón "Seleccionar columnas" en la parte superior o haciendo clic derecho en el encabezado de una columna y eliminando dicha columna.' ],
    [ '    2. Cuando esté correcto, haga clic en el botón "Cerrar y cargar" en la esquina superior izquierda.' ],
    [ '    3. Los datos se importarán en una nueva hoja de cálculo en el archivo de plantilla.' ],
    [ '5. Permanezca en la hoja de cálculo recién agregada, vaya a "Archivo" > "Guardar como" y actualice el nombre del archivo que se está guardando para cargarlo. Nota: Es importante que la extensión del archivo permanezca en .csv para una carga adecuada.' ],
    [ '6. Haga clic en "Guardar".' ],
    [ '7. Recibirá un mensaje que indica que el libro de trabajo no puede contener varias hojas. En este mensaje, haga clic en "Aceptar" para guardar la hoja activa.' ],
    [ '8. El archivo está ahora preparado para cargarlo en el sitio público.' ],
    [ '' ],
    [ 'Instrucciones avanzadas para importar datos a esta plantilla desde un archivo existente (para Mac):' ],
    [ '1. Vaya a la pestaña "Datos" del archivo de plantilla.' ],
    [ '2. Seleccione "Obtener datos (Consulta de energía)" > y elija el formato del archivo que se está importando.' ],
    [ '3. Haga clic en "Examinar", localice el archivo y selecciónelo, luego haga clic en "Obtener datos".' ],
    [ '4. Haga clic en "Siguiente" y su archivo debería aparecer en la vista previa en el lado izquierdo.' ],
    [ '5. La vista previa de los datos debería aparecer ahora en el formato correcto que debe ser importado:' ],
    [ '    1. Si no es así, actualice los datos según sea necesario en el archivo original o haciendo clic en "Transformar datos".' ],
    [ '        1. Asegúrese de importar solo el año, el tipo de propiedad y el número de parcela para sus cuentas en ese orden. Puede hacerlo usando el botón "Seleccionar columnas" en la parte superior de su "Editor de consulta de energía" o haciendo clic derecho en el encabezado de una columna y eliminando dicha columna.' ],
    [ '    2. Cuando esté correcto, haga clic en el botón "Cerrar y cargar" en la esquina superior izquierda.' ],
    [ '    3. Los datos se importarán en una nueva hoja de cálculo en el archivo de plantilla.' ],
    [ '6. Permanezca en la hoja de cálculo recién agregada, vaya a "Archivo" > "Guardar como" y actualice el nombre del archivo que se está guardando para cargarlo. Nota: Es importante que la extensión del archivo permanezca en .csv para una carga adecuada.' ],
    [ '7. Haga clic en "Guardar".' ],
    [ '8. Recibirá un mensaje que indica que el libro de trabajo no puede contener varias hojas. En este mensaje, haga clic en "Aceptar" para guardar la hoja activa.' ],
    [ '9. El archivo está ahora preparado para cargarlo en el sitio público.' ],
  ],
}
