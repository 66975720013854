<template>
  <Alert
    v-if="payableAlert"
    :dismissible="false"
    :show-icon="false"
    variant="warning"
    class="mb-3 d-really-inline-block"
  >
    <div v-dompurify-html="payableAlert" />
  </Alert>
</template>

<script>
import Alert from './Alert.vue'

export default {
  components: {
    Alert,
  },

  props: {
    payable: {
      type: Object,
      required: true,
    },
  },

  computed: {
    payableAlert () {
      if (this.payable.creditBalanceExceeded) {
        const credit = this.$t('account.has_credit.default', { amount: this.payable.absoluteDisplayAmount })
        const funds = this.$t('account.has_credit.additional_funds')
        return `${credit} ${funds}`
      }
      return this.payable.isPayableMessageDisplay
    },
  },
}
</script>

<style scoped>
.d-really-inline-block {
  display: inline-block !important;
}
</style>
