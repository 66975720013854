<template>
  <div v-if="footer">

    <LoadingBars
      v-if="!loaded"
      class="py-6"
    />
    <template
      v-else
    >
      <FooterContent />
    </template>

  </div>
</template>

<script>
import FooterContent from './FooterContent.vue'
import { mapGetters } from 'vuex'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'

export default {
  emits: ['error'],
  components: {
    FooterContent,
    LoadingBars,
  },

  data () {
    return {
      loaded: false,
    }
  },

  computed: {
    ...mapGetters('Announcements', ['footer']),
  },

  async mounted () {
    try {
      await this.$store.getters['Announcements/loadPromise']
    }
    catch (error) {
      this.$emit('error', error)
    }
    this.loaded = true
  },

}
</script>
