<template>
  <div>
    <template v-if="entity.name">
      <AisHighlight
        :attribute="path + '.name'"
        :class-names="highlight"
        :hit="item"
      /><br>
    </template>

    <!-- TODO: Multi-row addresses. -->
    <template v-if="entity.address">
      <AisHighlight
        :attribute="path + '.address'"
        :class-names="highlight"
        :hit="item"
      /><br>
    </template>

    <template v-if="Object.keys(fieldPrefix).length">
      <template v-if="entity.city">
        <AisHighlight
          :attribute="path + '.city'"
          :class-names="highlight"
          :hit="item"
        />
      </template>

      <template v-if="entity.province">
        <span v-if="fieldPrefix.province">{{ fieldPrefix.province }}</span>
        <AisHighlight
          :attribute="path + '.province'"
          :class-names="highlight"
          :hit="item"
        />
      </template>

      <template v-if="entity.state">
        <span v-if="fieldPrefix.state">{{ fieldPrefix.state }}</span>
        <AisHighlight
          :attribute="path + '.state'"
          :class-names="highlight"
          :hit="item"
        />
      </template>

      <template v-if="entity.zip">
        <span v-if="fieldPrefix.zip">{{ fieldPrefix.zip }}</span>
        <AisHighlight
          :attribute="path + '.zip'"
          :class-names="highlight"
          :hit="item"
        />
      </template>

      <template v-if="isForeign">
        <span v-if="fieldPrefix.country">{{ fieldPrefix.country }}</span>
        <AisHighlight
          :attribute="path + '.country'"
          :class-names="highlight"
          :hit="item"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { AisHighlight } from 'vue-instantsearch'
import { createNamespacedHelpers } from 'vuex'
/*
  There are incompatibilities between @vue/compat and the Vue 3 versions
  (vue-instantsearch/vue3/es) of the Algolia components. Since we can't use
  the Vue 3 versions, the next best option is to opt into Vue 3 mode by default
  (MODE: 3) and only enable compat for certain features when necessary.
*/
AisHighlight.compatConfig = { MODE: 3 }

const { mapState } = createNamespacedHelpers('GovHubSearch')

export default {
  components: {
    AisHighlight,
  },

  props: {
    entity: {
      required: true,
      type: Object,
    },

    item: {
      required: true,
      type: Object,
    },

    path: {
      required: true,
      type: String,
    },
  },

  computed: {
    fieldPrefix () {
      const prefix = {}
      let cszc = ''
      const entity = this.entity

      if (entity.city) {
        cszc += entity.city
        prefix.city = ''
      }

      if (entity.state) {
        if (cszc.length) {
          prefix.state = ', '
        }

        cszc += prefix.state
      }

      if (entity.province) {
        if (cszc.length) {
          prefix.province = ', '
        }

        cszc += prefix.province
      }

      if (entity.zip) {
        if (cszc.length) {
          prefix.zip = ' '
        }

        cszc += prefix.zip
      }

      if (entity.country) {
        if (cszc.length) {
          prefix.country = ', '
        }

        cszc += prefix.country
      }

      return prefix
    },

    isForeign () {
      return (this.entity?.country || 'US') !== 'US'
    },

    ...mapState([
      'highlight',
    ]),
  },
}
</script>
