/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'question-mark-circle': {
    width: 16,
    height: 16,
    viewBox: '1794.5 1320.5 23 23',
    data: '<g data-name="Group 1693" transform="translate(1795 1321)" _fill="none" _stroke="#666"><circle pid="0" data-name="Ellipse 374" cx="11" cy="11" r="11" stroke-linecap="round" stroke-linejoin="round"/><circle pid="1" data-name="Ellipse 375" cx="1" cy="1" r="1" transform="translate(10 15)" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" data-name="Path 1111" d="M7.5 5.5a3.851 3.851 0 013-1.414c1.937 0 3.2 1.046 3.2 2.744 0 2.246-3.087 3.155-2.78 5.556" stroke-miterlimit="10"/></g>'
  }
})
