/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star-fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 .5l3 9h8l-6 5 3 9-8-6-8 6 3-9-6-5h8l3-9z" _fill="#666" _stroke="#666" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
