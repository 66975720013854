import GenericConfigClient from '.'

export default class ProdConfigClient extends GenericConfigClient {
  constructor (opts) {
    super(opts)

    // If the JWT is a sandbox token, we have to use the `payhub-sandbox-prod`
    // config service app in beta (this simulates the prod data bucket in beta
    // for the purposes of sandbox/e2e testing). Otherwise we use `payhub` in
    // prod, which affects real prod PayHub data.
    if (this.isSandbox) {
      this.app = 'payhub-sandbox-prod'
      this.baseUrl = 'https://beta-config.grantstreet.com/api/v1'
      this.lambdaFunction = 'SiteSettingsImageUploadNonProd'
      this.lambdaBucket = 'test-cdn-grantstreet-com'
    }
    else {
      this.app = 'payhub'
      this.baseUrl = 'https://config.grantstreet.com/api/v1'
      this.lambdaFunction = 'SiteSettingsImageUploadProd'
      this.lambdaBucket = 'cdn-grantstreet-com'
      this.Cdn = 'https://site-settings.grantstreet-cdn.com'
    }
  }

  getMeta () {
    return this.get(`/${this.app}/data-pub/meta`)
  }

  // This will create the /clients path if no clients exist.
  putClient ({ id, name, logoUrl, useClientOnlyUrl, defaultSiteUrl, adminUserJwt }) {
    const headers = {}
    if (adminUserJwt) {
      headers['X-Remote-User'] = adminUserJwt
    }

    return this.put(`/${this.app}/data-pub/meta/clients/${id}`, {
      id,
      name,
      logoUrl,
      useClientOnlyUrl,
      defaultSiteUrl,
    },
    { headers })
  }

  putClientRaw ({ id, data, adminUserJwt }) {
    const headers = {}
    if (adminUserJwt) {
      headers['X-Remote-User'] = adminUserJwt
    }

    return this.put(`/${this.app}/data-pub/meta/clients/${id}`, data, { headers })
  }

  putClientSitesRaw ({ id, data, adminUserJwt }) {
    const headers = {}
    if (adminUserJwt) {
      headers['X-Remote-User'] = adminUserJwt
    }

    return this.put(`/${this.app}/data-pub/meta/sites/${id}`, data, { headers })
  }

  // Sites are stored separately from clients because otherwise the config
  // service would return separate records for the client AND every site when
  // accessing /clients instead of only returning records for just the clients.
  putSite ({
    id,
    clientId,
    title,
    type,
    inNonProd,
    inProd,
    lastUpdated,
    adminUserJwt,
  }) {
    const headers = {}
    if (adminUserJwt) {
      headers['X-Remote-User'] = adminUserJwt
    }

    return this.put(`/${this.app}/data-pub/meta/sites/${clientId}/${id}`, {
      id,
      clientId,
      title,
      type,
      inNonProd,
      inProd,
      lastUpdated,
    },
    { headers })
  }

  saveClientSetting ({ clientId, module, setting, config, adminUserJwt }) {
    const headers = {}
    if (adminUserJwt) {
      headers['X-Remote-User'] = adminUserJwt
    }

    return this.put(
      `/${this.app}/data-pub/settings/${clientId}/${module}/${setting}`,
      config,
      { headers },
    )
  }

  // Client settings are stored in prod because they have no concept of
  // environment. Only _site_ settings are published to environments. When a
  // client setting is changed, it does not affect anything until the site
  // inheriting it is published.
  getClientSettings ({ clientId }) {
    return this.get(`/${this.app}/data-pub/settings/${clientId}`)
  }

  getClientMetadata ({ clientId }) {
    return this.get(`/${this.app}/data-pub/meta/clients/${clientId}`)
  }

  getClientSiteMetadata ({ clientId }) {
    return this.get(`/${this.app}/data-pub/meta/sites/${clientId}`)
  }

  getSiteMetadata ({ clientId, siteId }) {
    return this.get(`/${this.app}/data-pub/meta/sites/${clientId}/${siteId}`)
  }

  uploadAWS ({ fileObject, token, callback }) {
    return this.uploadAWSGeneric({ fileObject, token, callback, isProd: true })
  }

  // ---------------------------------------------------------------------------
  // <DANGER ZONE> (deletions - modify carefully!):

  // Deletes all payhub-sandbox-prod settings. Make sure this ONLY EVER deletes
  // from the payhub-sandbox-prod sandbox.
  deleteSandboxSettings () {
    return this.delete('/payhub-sandbox-prod/data-pub/settings')
  }

  // Deletes all payhub-sandbox-prod metadata
  deleteSandboxMeta () {
    return this.delete('/payhub-sandbox-prod/data-pub/meta')
  }

  // </DANGER ZONE>
  // ---------------------------------------------------------------------------
}
