/**
 * Client for the Config service API
 */

import GenericApiClient from '@grantstreet/api-client'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

export default class GenericConfigClient extends GenericApiClient {
  constructor (opts = {}) {
    super(opts)

    this.cacheGETs = opts.cacheGETs
    this.axios.defaults.headers.post['Content-Type'] = 'application/json'
  }

  get (path, opts) {
    if (this.cacheGETs === false) {
      opts = opts || {}
      opts.params = {
        // We add a timestamp to avoid config-service caching. In the admin
        // widget we want to always get the most up-to-date settings (especially
        // for when we do setting patches by downloading a whole setting,
        // changing one of its values, and re-saving).
        noCache: Date.now(),
        ...(opts.params || {}),
      }
    }
    return super.get(path, opts)
  }

  // Is the JWT a sandbox token? If so it will only use sandbox apps in the
  // config service. This way it cannot affect real GovHub data.
  get isSandbox () {
    if (!this.jwt) {
      return false
    }
    let jwt
    try {
      jwt = jwtDecode(this.jwt)
    }
    catch (error) {
      console.error('Error decoding jwt')
    }
    return jwt?.scp[0]?.includes('payhub-sandbox')
  }

  // The config service returns 404s if a client or site
  isException (error) {
    if (
      error.response &&
      error.response.status === 404 &&
      error.request &&
      /config.*\/(meta|settings)(\/|$)/.test(error.request.responseURL)
    ) {
      return false
    }
    return error
  }

  handleException (error) {
    // If this is a typical 403 error, tell the user to reload the page. This
    // shouldn't happen because of token auto-refreshing, but sometimes it still
    // does.
    if (error && error.response && error.response.status === 403) {
      error.message = 'Either your token has expired or you are not on the VPN. Please reload the page and ensure you\'re on VPN. Reach out to #psp-help if the issue persists.'
    }

    return super.handleException(error)
  }

  ping () {
    return this.get('/ping')
  }

  // Gets all site settings for prod or non-prod (depending on the baseUrl)
  getAllSettings () {
    return this.get(`/${this.app}/data-pub/settings`, {
      timeout: 60 * 1000 * 5,
    })
  }

  // Gets the site settings for prod or non-prod (depending on the baseUrl)
  getSiteSettings ({ clientId, siteId }) {
    return this.get(`/${this.app}/data-pub/settings/${clientId}/${siteId}`)
  }

  // Gets the settings of all sites for a given client for prod or non-prod
  // (depending on the baseUrl)
  getAllClientSiteSettings ({ clientId }) {
    return this.get(`/${this.app}/data-pub/settings/${clientId}`)
  }

  // Gets one site setting for prod or non-prod (depending on the baseUrl)
  // // NOTE!
  // Until PSC-17126 is complete, Direct Charge Widget relies directly on this!!
  // Be extremely careful making changes.
  getSiteSetting ({ clientId, siteId, module, setting }) {
    return this.get(`/${this.app}/data-pub/settings/${clientId}/${siteId}/${module}/${setting}`)
  }

  /**
   * Get settings with the given constraints. Any missing constraint
   * will default to '*', the wildcard value.
   */
  getSettings ({ clientId = '*', siteId = '*', module = '*', setting = '*' }) {
    return this.get(`/${this.app}/data-pub/settings/${clientId}/${siteId}/${module}/${setting}`)
  }

  // Publishes a setting to prod or non-prod (depending on the baseUrl)
  publishClientSettingsRaw ({ id, data, adminUserJwt }) {
    const headers = {}
    if (adminUserJwt) {
      headers['X-Remote-User'] = adminUserJwt
    }

    return this.put(
      `/${this.app}/data-pub/settings/${id}`,
      data,
      { headers },
    )
  }

  // Publishes a setting to prod or non-prod (depending on the baseUrl)
  publishSetting ({ clientId, siteId, module, setting, config, adminUserJwt }) {
    const headers = {}
    if (adminUserJwt) {
      headers['X-Remote-User'] = adminUserJwt
    }

    return this.put(
      `/${this.app}/data-pub/settings/${clientId}/${siteId}/${module}/${setting}`,
      config,
      { headers },
    )
  }

  // Gets global flags prod or non-prod (depending on the baseUrl)
  getGlobalFlags () {
    return this.get(`/${this.app}/data-pub/globals/`)
  }

  // Accepts a callback that's given the image url on success, null on failure.
  async uploadAWSGeneric ({ fileObject, token, callback }) {
    // make sure our filename is unique
    const fileName = uuidv4() + fileObject.name
    const contentType = fileObject.type
    // setup an error handler for the various erroness states we can hit
    const errorHandler = (err) => {
      const message = 'AWS lambda failure ' + JSON.stringify(err)
      this.handleException(new Error(message))
      callback(null)
    }
    // call lambda function
    const payload = {
      jwt: token,
      key: fileName,
    }
    try {
      // we use a fresh axios here because we don't want our url
      // or headers tampered with by payhub/site-settings
      const ax = axios.create()
      const status = await ax.post('https://kyo307aet0.execute-api.us-east-1.amazonaws.com/' + this.lambdaFunction, payload)
      // if we succeed, the data will be a presigned URL that allows a one-time upload
      if (!status || status.status !== 200 || !status.data) {
        errorHandler(status)
      }
      else {
        // required to prevent axios from reformatting our url
        const url = String(status.data)
        // upload image
        const success = await ax.put(url, fileObject, {
          headers: {
            'Content-Type': contentType,
          },
        })
        if (success.status === 200) {
          const isImage = /^image/i.test(contentType)
          if (isImage && this.Cdn) {
            // Right now, we only use the CDN for images.
            // eslint-disable-next-line n/no-callback-literal
            callback(`${this.Cdn}/${fileName}`)
          }
          else {
            // this is our bucket url and we know the file is just a path in it
            // eslint-disable-next-line n/no-callback-literal
            callback('https://' + this.lambdaBucket + '.s3.amazonaws.com/' + fileName)
          }
        }
        else {
          errorHandler(success)
        }
      }
    }
    catch (error) {
      errorHandler(error)
    }
  }

  async googleLocation (text) {
    // call lambda function
    const payload = {
      text,
    }
    return axios.post('https://e78k5dqdt8.execute-api.us-east-1.amazonaws.com/GooglePlacesApi', payload)
  }
}
