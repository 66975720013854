//           __   _,--="=--,_   __
//          /  \."    .-.    "./  \
//         /  ,/  _   : :   _  \/` \
//         \  `| /o\  :_:  /o\ |\__/
//          `-'| :="~` _ `~"=: |
//             \`     (_)     `/
//      .-"-.   \      |      /   .-"-.
// .---{     }--|  /,.-'-.,\  |--{     }---.
//  )  (_)_)_)  \_/`~-===-~`\_/  (_(_(_)  (
// (  This bulk upload config file is used )
//  ) for E2E tests. Please do not remove (
// (  this file! It's been a good boy...   )
// '---------------------------------------'

import BulkUploadConfig, { PaymentOptionType } from '../BulkUploadConfig.ts'

class SunshineConfig extends BulkUploadConfig {
  constructor () {
    super()

    this.paymentOptions = [{ type: PaymentOptionType.Online }]
    this.exampleTemplate = [
      [ 'Dog License #' ],
      [ '13827' ],
      [ '48293' ],
    ]
    this.csvColumns = {
      dogLicense: {
        columnKey: 'dogLicense',
        columnName: 'Dog License',
        sortOrder: 100,
      },
    }
  }
}

export default SunshineConfig
