/**
 * Client for the Payable service API
 */

import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment'
import { encodePayablePath } from './utils.ts'
import { isRef } from 'vue'

function createConfig ({ language }) {
  return {
    headers: {
      'Accept-Language': isRef(language) ? language.value : language,
    },
  }
}

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = () => process.env?.GSG_PAYABLES_SERVICE || `${baseGovHubUrl}/svc/payables`
  }

  // Payables doesn't currently want end-user auth
  getAuthHeader () {
    return ''
  }

  ping () {
    return this.get('/v0/ping')
  }

  find (path, language) {
    const config = createConfig({ language })
    return this.get('/v0/' + encodePayablePath(path), config)
  }

  findMultiple (paths, language) {
    const config = createConfig({ language })
    return this.post('/v0/retrieve', paths, config)
  }

  search (path, args, language) {
    const config = createConfig({ language })
    if (path[0] === '/') {
      path = path.substring(1)
    }
    // path: "/$adaptor/v0"
    // args: {
    //   query: {...},
    //   offset: n,
    //   limit: n,
    //   sort: "...",
    //   options: {...}
    // }
    return this.post('/v0/' + path, args, config)
  }

  bulkSearch (path, args, language) {
    const config = createConfig({ language })
    if (path[0] === '/') {
      path = path.substring(1)
    }

    const lastIndex = path.length - 1
    if (path[lastIndex] === '/') {
      path = path.substring(0, lastIndex)
    }

    return this.post(`/v0/${path}/bulk_search`, args, config)
  }

  taxsysAddItems (path, args, language) {
    const config = createConfig({ language })
    if (path[0] === '/') {
      path = path.substring(1)
    }
    return this.post(`/v0/${path}/events/taxsys_add_items`, args, config)
  }

  /**
   * convert will convert a query into a Payable. This is normally used
   * m2m by redirects, but can be used to create temporary records
   * for payables that have not yet been synced to the system of record.
   *
   * @param {string} adaptor - The payable adaptor. A URL path
   * (/Sunshine-PropertyTax/v0)
   * @param {object} payable - The payable data.
   * @param {string} accessToken - The access token for authorization
   * @param {string} adminUserToken - The token identifying the actual
   *    user making the request. For when the accessToken is a proxy for
   *    an admin user.
   * @returns {Promise<object>} The created Payable
   */
  async convert (adaptor, payable, accessToken = '', adminUserToken = '') {
    // Remove any extra leading slashes so the Payables Gateway doesn't
    // try to redirect us.
    if (adaptor[0] === '/') {
      adaptor = adaptor.substring(1)
    }

    const headers = {}
    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`
    }
    if (adminUserToken) {
      headers['X-Remote-User'] = adminUserToken
    }

    const body = {
      query: {
        0: payable,
      },
    }
    const res = await this.post(`/v0/${adaptor}/convert`, body, { headers })
    return res.data.payables[0]
  }
}
