<template>
  <div v-if="payableNotice">
    <div
      class="row justify-content-end"
    >
      <div
        v-dompurify-html="payableNotice"
        class="col-7 col-md-6 border-md-left pt-2"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    payable: {
      type: Object,
      required: true,
    },
  },

  computed: {
    payableNotice () {
      if (this.payable.creditBalanceExceeded) {
        const credit = this.$t('account.has_credit.bolded', { amount: this.payable.absoluteDisplayAmount })
        const funds = this.$t('account.has_credit.paid')
        return `${credit}<br/>${funds}`
      }
      else {
        return ''
      }
    },
  },
}
</script>
