export default {
  methods: {
    // This returns a user-friendly error from an axios error. If the error does
    // not explicitly have an error string set, this returns the generic API
    // error message (which relies on this.$t and an `api.error` translation).
    // As a side-effect, it logs the raw error to the console and the user error
    // to Kibana.
    userFriendlyError (error) {
      if (!error) {
        return ''
      }

      const friendly = error.response?.data?.displayMessage || this.$t('api.error')

      this.$store.dispatch('PayHub/logDiagnostics', {
        errors: [
          String(error),
          friendly,
        ],
      })

      return friendly
    },
  },
}
