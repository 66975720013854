<template>
  <div class="mt-3 pb-4 mx-auto">
    <LargeError
      v-if="showError"
      class="border-top pt-6"
      type="notFound"
    />
    <div v-else>
      <h1
        class="my-3 px-4 px-md-0"
        data-test="form-title"
      >{{ formConfig.formTitle }}</h1>
      <div class="wrapper w-100 mb-3 px-4 px-md-7 py-1 py-md-4 shadow-sm bg-white rounded">
        <div id="form">
          <!-- Jotform actually wants the following:
            // This one seems too dangerous:
            allow="geolocation; microphone; camera"
            // This one is deprecated, so we use overflow-y: hidden later
            scrolling=0

            We deliberately do not `sandbox` this iframe.
            Doing so breaks PDF previews
            eg. <iframe sandbox="$allow-things" srcdoc="<iframe src='pdf'/>"/>
            See https://bugs.chromium.org/p/chromium/issues/detail?id=413851
            which has been open since September 2014.
            The biggest loss here is allow-top-navigation, which we were preventing.
            (we would allow-same-origin for eg. Jotform downloads to work)
          -->
          <InceptionFrame
            :id="'JotFormIFrame-'+formID"
            data-test="form-iframe"
            :actions="iframeActions"
            :allow-receive-origin="iframeAllowOrigin"
            class="border-0 form-frame"
            :title="formConfig.formTitle"
            allowfullscreen="true"
            scrolling="no"
            :src="jotformSource"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sentryException } from '../sentry.ts'
import { iframeSrcParams, generateChildOriginValidator } from '../utils.ts'
import { isProd } from '@grantstreet/psc-environment'
import EnvironmentApi from '@grantstreet/psc-environment-api/api-client.js'
import store from '../store/index.ts'
import InceptionFrame from '@grantstreet/psc-vue/components/InceptionFrame.vue'
import LargeError from '@grantstreet/psc-vue/components/LargeError.vue'

export default {
  emits: ['unnest'],
  components: {
    InceptionFrame,
    LargeError,
  },

  props: {
    slug: {
      type: String,
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
    extraParams: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    showError () {
      return Boolean(!this.formConfig || !this.formID)
    },
    formID () {
      return this.formConfig[isProd() ? 'formID' : 'nonprodFormID']
    },
    formConfig () {
      return store.getters.formConfigFromSlug(this.slug)
    },
    jotformSource () {
      return iframeSrcParams(
        'https://grantstreet.jotform.com/' + this.formID +
        '?gsgClient=' + this.client +
        '&gsgUser=' + encodeURIComponent(store.state.user?.id || '') +
        this.extraParams.map(extraParam => `&${extraParam.key}=${extraParam.value}`).join(''),
      )
    },

    iframeActions () {
      return {
        'gsgForms.unnest': payload => {
          if (!payload?.path?.match(/^\//)) {
            sentryException(new Error('Absolute path required to break out of Jotform iframe, got ' + payload?.path))
            return false
          }

          this.$emit('unnest', payload.path)
          return true
        },
      }
    },

    iframeAllowOrigin () {
      return generateChildOriginValidator(
        window?.location?.origin,
        new EnvironmentApi({ exceptionLogger: sentryException }),
        sentryException,
      )
    },
  },
}
</script>
<style lang="scss" scoped>
  .form-frame {
    ::v-deep iframe {
      /* the iframe automatically scales its height in jotform-embed.js */
      overflow-y: hidden;
    }
  }
</style>
