<template>
  <div>
    <h4>{{ $t('user_verification.verify_ownership') }}</h4>
    <!-- Successful PIN claim message after first request -->
    <template v-if="mode === 'enterPIN' && !!status && status.status === 'payable_claimed'">
      <p data-test="user-verification-successful-verify">{{ $t('user_verification.verify_success') }}</p>
      <div class="mt-2">
        <b-link
          data-test="user-verification-hide"
          @click.prevent="$emit('change-user-verification-mode', 'closed')"
        >
          {{ $t('ebilling.signup.hide') }}
        </b-link>
      </div>
    </template>
    <!-- Successful PIN request message after first request -->
    <template v-else-if="mode === 'requestPIN' && !!status && status.status === 'pin_requested'">
      <p data-test="user-verification-successful-request">{{ $t('user_verification.request_success') }}</p>
      <div class="mt-2">
        <b-link
          data-test="user-verification-hide"
          @click.prevent="$emit('change-user-verification-mode', 'closed')"
        >
          {{ $t('ebilling.signup.hide') }}
        </b-link>
      </div>
    </template>
    <!-- Enter PIN -->
    <template v-else-if="mode === 'enterPIN'">
      <b-collapse :visible="Boolean(statusError)">
        <div
          class="user-verification-error rounded d-flex align-items-center mt-3 mb-3 p-3"
          data-test="statusError"
        >
          <svgicon
            :original="true"
            class="mr-3"
            icon="x-circle"
            width="2rem"
            height="2rem"
          />
          {{ statusErrorText || (hasRequestedVerification ? $t('user_verification.issue') : 'There was an issue verifying.') }}
        </div>
      </b-collapse>
      <p>{{ $t('user_verification.pins_included') }} {{ $t('user_verification.enter_below') }}</p>
      <b-form-group
        v-if="payableOptions.length > 1"
        label="Ownership Period"
        label-cols-md="3"
        class="mb-3"
      >
        <b-form-select
          v-model="selected"
          data-test="user-verification-select-period"
          :options="payableOptions"
        />
      </b-form-group>
      <b-form-group
        id="pin-input-label"
        :label="$t('user_verification.enter_pin')"
        label-cols-md="3"
      >
        <b-form-input
          v-model="PIN"
          aria-labelledby="pin-input-label"
          data-test="user-verification-pin-input"
        />
      </b-form-group>
      <b-form-group
        class="mb-3"
        label-cols-md="3"
      >
        <b-link
          v-if="!hasRequestedVerification"
          data-test="user-verification-switch-to-request"
          @click.prevent="$emit('change-user-verification-mode', 'requestPIN')"
        >
          {{ $t('user_verification.request_pin') }}
        </b-link>
      </b-form-group>
      <b-form-row>
        <b-button
          variant="outline-primary"
          @click.prevent="$emit('change-user-verification-mode', 'closed')"
        >{{ $t('common.cancel')
        }}</b-button>
        <ProgressButton
          ref="start"
          :waiting="$wait.is(`user verification status ${rootPayablePath}`)"
          data-test="user-verification-enter"
          variant="primary"
          class="ml-2"
          @click="enterPIN"
        >
          {{ $t('user_verification.enter_pin') }}
        </ProgressButton>
      </b-form-row>
    </template>
    <!-- PIN Request -->
    <template v-else>
      <b-collapse :visible="Boolean(statusError)">
        <div
          class="user-verification-error rounded d-flex align-items-center mt-3 mb-3 p-3"
          data-test="statusError"
        >
          <svgicon
            :original="true"
            class="mr-3"
            icon="x-circle"
            width="2rem"
            height="2rem"
          />
          {{ statusErrorText || (hasRequestedVerification ? $t('user_verification.error.pin_request') : 'There was an issue requesting a PIN.') }}
        </div>
      </b-collapse>
      <p>{{ $t('user_verification.pins_included') }} <b-link
        data-test="user-verification-switch-to-enter"
        @click.prevent="$emit('change-user-verification-mode', 'enterPIN')"
      >
        {{ $t('user_verification.enter_here') }}
      </b-link></p>
      <p v-if="payableOptions.length > 1">{{ $t('user_verification.request_new') }} <span class="font-weight-bold">{{ $t('user_verification.request_new2') }}</span>, {{ $t('user_verification.request_new3') }}</p>
      <b-form-group
        v-if="payableOptions.length > 1"
        label="Ownership Period"
        label-cols-md="3"
        class="mb-3"
      >
        <b-form-select
          v-model="selected"
          :options="payableOptions"
        />
      </b-form-group>
      <p>{{ $t('user_verification.mail_a_pin') }}</p>
      <b-form-row>
        <b-button
          variant="outline-primary"
          @click.prevent="$emit('change-user-verification-mode', 'closed')"
        >{{ $t('common.cancel')
        }}</b-button>
        <ProgressButton
          ref="start"
          :waiting="$wait.is(`user verification status ${rootPayablePath}`)"
          data-test="user-verification-request"
          variant="primary"
          class="ml-2"
          @click="requestPIN"
        >
          {{ $t('user_verification.request_pin') }}
        </ProgressButton>
      </b-form-row>
    </template>
  </div>
</template>
<script>
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import { v4 as uuid } from 'uuid'
import '@grantstreet/bootstrap/icons/js/x-circle.js'

export default {
  emits: ['change-user-verification-mode', 'request-pin', 'enter-pin'],
  components: {
    ProgressButton,
  },
  props: {
    payables: {
      type: Array,
      required: true,
    },
    status: {
      type: Object,
      required: false,
      default: undefined,
    },
    userVerificationMode: {
      type: String,
      required: true,
    },
    hasRequestedVerification: {
      type: Boolean,
      required: true,
    },
    statusError: {
      type: Boolean,
      required: true,
    },
    statusErrorText: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => ({
    mode: '',
    id: uuid(),
    PIN: '',
    selected: null,
    rootPayablePath: '',
  }),
  computed: {
    selectedPayable () {
      const selectedPath = this.selected || this.payables[0].raw.save_path
      return this.payables.find(payable => payable.raw.save_path === selectedPath)
    },
    payableOptions () {
      return this.payables.map(payable => ({
        value: payable.raw.save_path,
        text: payable.raw.display_name,
      }))
    },
  },
  methods: {
    requestPIN () {
      this.$emit('request-pin', { payableSavePath: this.selected, payables: this.payables, rootPayablePath: this.rootPayablePath })
    },
    enterPIN () {
      this.$emit('enter-pin', { PIN: this.PIN, payableSavePath: this.selected, payables: this.payables, rootPayablePath: this.rootPayablePath })
    },
  },
  watch: {
    /**
     * Update the local mode when userVerificationMode is updated. Ignoring the 'closed'
     * state fixes the template switching as the dropdown animates the close
     * event.
     */
    userVerificationMode (newMode, oldMode) {
      if (newMode === 'closed') {
        return
      }
      this.mode = newMode
    },
  },
  mounted () {
    this.rootPayablePath = this.selectedPayable.raw.save_path
    this.selected = this.selectedPayable.raw.save_path
  },
}
</script>

<style lang="scss" scoped>
.user-verification-error {
  color: #721C27;
  background-color: #F9DCDF;
  border: 1px solid #F5C6CB;
}
</style>
