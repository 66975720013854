import { firstToUpperCase } from '@grantstreet/psc-js/utils/cases.js'

/**
 * Constructs a new footer.
 * @class Footer
 */
export default class Footer {
  /**
   * @constructor
   * @param  {object} params  A parameters object.
   * @return {object}         A new instance of class Footer.
   */
  constructor ({
    client,
    site,
    // This will intentionally throw if an id is not provided and neither are
    // client or site
    id,
    title = {
      en: '',
      es: '',
    },
    headline = {
      en: '',
      es: '',
    },
    content = {
      en: '',
      es: '',
    },
    active = false,
    locale = 'en',
    clientSiteText = '',
  }) {
    Object.assign(this, {
      id,
      client,
      site,
      title,
      headline,
      content,
      locale,
      active,
      clientSiteText,
    })
  }
}

// This factory returns a getter function that will return the localized version
// of a given prop.
const getLocalizedGetter = (prop) => function () {
  return this[prop][this.locale] || this[prop].en
}

// Set localization getters for several properties
// This will allow for things like:
// footer.localizedText // 'I'm localized text'
// footer.locale = 'es'
// footer.localizedText // 'I'm text in Espanol but you can't tell because
//                            the author of this comment doesn't speak Spanish.'
for (const prop of ['title', 'headline', 'content']) {
  const localizedProp = 'localized' + firstToUpperCase(prop)
  Object.defineProperty(Footer.prototype, localizedProp, {
    get: getLocalizedGetter(prop),
  })
}
