<template>
  <div
    class="bg-white rounded-xl p-0"
    data-test="search-results"
  >
    <ItemListItem
      v-for="payable in payables"
      :key="payable.path"
      :payable="payable"
    >
      <template #addButton="props">
        <slot
          name="addButton"
          v-bind="props"
        />
      </template>
    </ItemListItem>
  </div>
</template>

<script>
import store from '../../store/index.ts'
import ItemListItem from './ItemListItem.vue'

export default {
  components: {
    ItemListItem,
  },

  props: {
    payablesAdaptor: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      payables: [],
    }
  },

  async mounted () {
    this.payables = (await store.dispatch('searchPayables', {
      payablesAdaptor: this.payablesAdaptor,
      data: {},
      language: this.$i18n.locale,
    })).payables
  },
}
</script>
