import VueCookies from 'vue-cookies'
import Session from './session.js'

// Convenience method to use in vuex stores. Logs information to Kibana.
//
// Depends on VueCookies being installed. Tightly coupled with the architecture
// of GovHub/PSC ecosystem. The core reason this is separated out is so that we
// can include it in multiple modules without duplication and drift, as logging
// is too important to risk.
export const log = (data, { appUser, defaultClient, defaultSite = 'unknown' }, { logData, isDiagnostic }) => {
  data = {
    // eslint-disable-next-line camelcase
    client_ip: VueCookies.get('client_ip'),
    // TODO: Looks like this is never set???
    // eslint-disable-next-line camelcase
    client_host: VueCookies.get('client_host'),
    // eslint-disable-next-line camelcase
    http_session: Session.id,
    // eslint-disable-next-line camelcase
    correlation_id: '+' + Session.id,
    // eslint-disable-next-line camelcase
    user_agent: navigator.userAgent,
    // eslint-disable-next-line camelcase
    app_user: appUser,
    ...data,
  }

  if (isDiagnostic) {
    // If a client or site are passed, those will be logged to Kibana; if not
    // then the current config client and site will be used. This is so that the
    // client and site can still be logged if this is called before the config
    // has been loaded.

    const client = data.client || defaultClient
    let site = data.site || defaultSite

    if (client) {
      site = client + '.' + site
    }

    // This is a better way to remove props than the delete operator.
    // See psc-js/utils/objects.js for more.
    const { client: c, site: s, ...sanitized } = data
    data = sanitized

    data = {
      site,
      diagnostics: data,
    }
  }

  logData(data)
}

export const logActionFactory = ({ logData, isDiagnostic }) => ({ data = {}, appUser, defaultClient, defaultSite }) => log(data, { appUser, defaultClient, defaultSite }, { logData, isDiagnostic })
