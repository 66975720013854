<template>
  <OverlaySidebar
    :id="id"
    right
    title="License Plate Selection"
    :on-change="onChange"
  >
    <template #default>
      <div class="justify-content-center mx-3 mb-5 mt-1">
        <div v-if="plateCategories.length > 0">
          <b-tabs
            content-class="px-3 mx-3"
            class="d-none d-sm-block"
          >
            <b-tab
              v-for="(tab) in plateCategories"
              :key="tab"
              title-item-class="plate-category"
              :title="plateCategoryMapping[tab]"
              :active="tab === 'standard'"
              @click="paginate(1)"
            >
              <SearchResults
                :results="pageCategoryResults(tab)"
                :page="page"
                display-children-as-dropdown
                class="mt-2 mt-md-3"
                :total="platesByCategory[tab].length"
                :page-size="pageSize"
                @page-change="paginate"
              >
                <template #result="{ payable }">
                  <PlateChangePayableDescription
                    :payable="payable"
                    :renewal="renewal"
                    :selected-plate-code="selectedPlateCode"
                    :selected-duration="selectedDuration"
                    @new-plate-selected="newPlateSelected"
                  />
                </template>
              </SearchResults>
            </b-tab>
          </b-tabs>
          <b-form-select
            v-model="selectedMobilePlateCategory"
            class="d-block d-sm-none"
            :options="plateCategories.map((category) => ({value: category, text: plateCategoryMapping[category]}))"
            @change="paginate(1)"
          />
          <div class="d-block d-sm-none">
            <SearchResults
              :results="pageCategoryResults(selectedMobilePlateCategory)"
              :page="page"
              display-children-as-dropdown
              class="mt-2 mt-md-3"
              :total="platesByCategory[selectedMobilePlateCategory].length"
              :page-size="pageSize"
              @page-change="paginate"
            >
              <template #result="{ payable }">
                <PlateChangePayableDescription
                  :payable="payable"
                  :renewal="renewal"
                  :selected-plate-code="selectedPlateCode"
                  :selected-duration="selectedDuration"
                  @new-plate-selected="newPlateSelected"
                />
              </template>
            </SearchResults>
          </div>
        </div>
        <div v-else>
          <LoadingBars />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="px-3 py-2 d-flex justify-content-center">
        {{ $t('rex.plate_change.disclaimer') }}
      </div>
      <div class="my-3 px-3 py-2 d-flex justify-content-end">
        <b-button
          v-b-toggle="id"
          class="mx-1 col col-sm-3"
          variant="outline-primary"
        >
          {{ $t('common.cancel') }}
        </b-button>
        <b-button
          id="plate-change-save"
          class="mx-1 col col-sm-3"
          variant="primary"
          :disabled="!selectedPlateCode"
          @click="saveSelection"
        >
          {{ $t('rex.plate_change.save') }}
        </b-button>
      </div>
    </template>
  </OverlaySidebar>
</template>

<script>
import OverlaySidebar from '@grantstreet/psc-vue/components/OverlaySidebar.vue'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import PlateChangePayableDescription from '../description/display-types/rex-vehicle-registration/PlateChangePayableDescription.vue'

import store from '../../store/index.ts'
import { sentryException } from '../../sentry.ts'

export default {
  emits: ['new-plate-saved'],
  props: {
    renewal: {
      type: Object,
      required: true,
    },
    selectedDuration: {
      type: String,
      default: '12',
    },
    id: {
      type: String,
      required: true,
    },
  },

  components: {
    OverlaySidebar,
    LoadingBars,
    SearchResults: () => import('../search/SearchResults.vue'),
    PlateChangePayableDescription,
  },

  data () {
    return {
      plateCategories: [],
      platesByCategory: {},
      page: 1,
      pageSize: 6,

      selectedPlateCode: '',
      selectedMobilePlateCategory: 'standard',
    }
  },

  computed: {
    plateCategoryMapping () {
      return {
        'collegiate': this.$t('rex.plate_change.categories.collegiate'),
        'environmental_wildlife': this.$t('rex.plate_change.categories.environmental_wildlife'),
        'military': this.$t('rex.plate_change.categories.military'),
        'motorcycle': this.$t('rex.plate_change.categories.motorcycle'),
        'special_interest': this.$t('rex.plate_change.categories.special_interest'),
        'sports': this.$t('rex.plate_change.categories.sports'),
        'standard': this.$t('rex.plate_change.categories.standard'),
      }
    },
  },

  mounted () {
    this.plateCategories = []
    this.platesByCategory = {}
    this.getPlates()
  },

  methods: {
    // On change is called every time the sidebar slides out or slides in
    async onChange () {
      this.selectedPlateCode = ''
    },

    // Get Plates from the Payables adaptor
    async getPlates () {
      try {
        const { payables: plates } = await store.dispatch('searchPayables', {
          payablesAdaptor: 'Taxsys-DmvItems-Plates/v0',
          data: {
            query: {
              client: this.renewal.client,
              renewalCustomParameters: this.renewal?.customParameters,
              // includeStandard tells the adaptor we want specialty and standard plates
              includeStandard: true,
            },
          },
          language: this.locale || this.$i18n.locale,
        })

        for (const plate of plates) {
          const category = plate.customParameters.category

          if (this.platesByCategory[category]?.length > 0) {
            this.platesByCategory[plate.customParameters.category].push(plate)
          }
          else {
            this.platesByCategory[category] = [plate]
          }
        }

        this.plateCategories = Object.keys(this.platesByCategory).sort()
      }
      catch (error) {
        if (/client sunshine not supported/.test(error.message)) {
          sentryException(error)
        }
      }
    },

    pageCategoryResults (category) {
      const results = this.platesByCategory[category]
      const pagedResults = [null, []]
      let i = 0
      while (i < results.length) {
        // Pages are 1 indexed
        const pageCursor = Math.floor(i / this.pageSize) + 1
        if (!pagedResults[pageCursor]) {
          pagedResults[pageCursor] = []
        }

        pagedResults[pageCursor][i % this.pageSize] = results[i]
        i++
      }
      return pagedResults[this.page]
    },

    async paginate (page) {
      this.page = page
    },

    // Handles the event new-plate-selected
    // Occurs when the select button is chosen for a plate
    newPlateSelected (newPlateCode) {
      this.selectedPlateCode = newPlateCode
    },

    // Emits the event new-plate-saved
    // Occurs when the save button is selected on the slide out
    saveSelection () {
      if (!this.selectedPlateCode) {
        return
      }
      this.$emit('new-plate-saved', this.selectedPlateCode)
      this.$root.$emit('bv::toggle::collapse', this.id)
    },
  },
}
</script>

<style scoped>
  .plate-category .nav-link {
    background-color: #F8F8F8 !important;
    font-weight: normal !important;
    border-bottom: none;
  }

</style>
