<template>
  <div :id="id">
    <Dropdown
      ref="popper"
      data-test="notifications-nav"
      :shown="forceShow"
      :container="`#${id}`"
      class="d-inline"
      @show="handleShow"
      @hide="trackClose('Notifications closed')"
      @auto-hide="stopForceShow"
    >
      <b-link
        class="position-relative py-1"
        data-test="notification-toggle"
        :class="{
          'cursor-pointer': sorted.length,
        }"
      >
        <span class="sr-only">{{ $t('notifications.default') }}</span>
        <svgicon
          :fill="false"
          icon="megaphone"
          class="megaphone-icon"
          width="1rem"
          height="1rem"
        />

        <transition
          name="bump-down"
          mode="out-in"
        >
          <span
            v-if="sorted.length"
            :key="sorted.length"
            class="badge badge-pill badge-primary-link"
            data-test="cart-quantity"
          >
            {{ sorted.length }}
          </span>
        </transition>
      </b-link>

      <template #popper>
        <div
          class="popper overflow-hidden"
          :class="{
            'show-fade-out': !atBottom && canScroll,
          }"
        >
          <!-- If popper inits without children it throws a very hard to debug:
          "Cannot read property '0' of undefined" -->
          <template
            v-if="loaded && sorted.length"
          >
            <b-link
              class="position-absolute close-button"
              @click="closePopper"
            >
              <svgicon
                :fill="false"
                icon="x"
                width=".75rem"
                height=".75rem"
              />
            </b-link>
            <h4
              class="border-bottom border-gray font-weight-bold px-5 py-2 mb-0 "
            >
              {{ $t('notifications.title') }}
            </h4>
            <div
              ref="list"
              class="notification-list mb-2"
              @scroll.passive="handleScroll"
            >
              <Notification
                v-for="(notification, index) of sorted"
                :key="notification.id"
                :data-test="`notification-id-${notification.id}`"
                :debug="debug"
                class="notification-item border-bottom border-gray"
                collapsible
                :init-open="index === 0"
                :notification="notification"
                @toggled="updateCanScroll"
              />
            </div>
          </template>

          <div
            v-else
            class="p-4 pr-8"
          >
            <b-link
              class="position-absolute close-button-none"
              @click="closePopper"
            >
              <svgicon
                :fill="false"
                icon="x"
                width=".75rem"
                height=".75rem"
              />
            </b-link>
            {{ $t('notifications.none') }}
          </div>

          <div class="fade-out" />
        </div>

      </template>

    </Dropdown>

  </div>
</template>

<script>
// This needs to be imported for the public widgets which don't bring in all of
// them.
import '@grantstreet/bootstrap/icons/js/megaphone.js'
import { Dropdown } from 'floating-vue'
import Notification from './Notification.vue'
import { mapGetters } from 'vuex'
import { sortNotificationsByTags } from '../../store/utils.js'
import { sortByProp } from '@grantstreet/psc-js/utils/sort.js'
import { mapConfigState } from '@grantstreet/psc-config'
import { v4 as uuid } from 'uuid'

export default {
  components: {
    Dropdown,
    Notification,
  },

  props: {
    tags: {
      type: Array,
      default: () => null,
    },
    debug: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      id: `notification-popper-${uuid()}`,
      forceShow: false,
      loaded: false,
      canScroll: false,
      atBottom: false,
      viewStart: null,
      deferClosedEvent: false,
    }
  },

  computed: {
    sorted () {
      const sort = this.tags
        // filter tags by allowed tags set in site settings
        ? sortNotificationsByTags(this.tags.filter(tag => (this.config.announcements?.tags || []).includes(tag)))
        : sortByProp('dateToUse')
      return Object.values(this.notifications).sort(sort).reverse()
    },

    ...mapGetters('Announcements', ['notifications']),
    ...mapConfigState(['config']),
  },

  async mounted () {
    await this.$store.getters['Announcements/loadPromise']

    let seenExpanded
    try {
      seenExpanded = window.sessionStorage.getItem('notifications-have-been-seen-pre-expanded')
    }
    catch (error) {
      console.error('Cannot access session storage due to incognito window')
    }

    if (seenExpanded !== 'yes' &&
      this.config.announcements?.preExpandNotifications &&
      this.sorted.length &&
      window.innerWidth > 991
    ) {
      this.forceShow = true

      this.$gtag.event(
        'Notifications pre-expanded',
        {
          'event_category': 'Announcements',
          'event_label': 'Notifications count:',
          'value': this.sorted.length,
        },
      )
      this.viewStart = Date.now()
    }

    this.$nextTick(() => {
      this.updateCanScroll()
    })
    this.loaded = true
  },

  methods: {

    handleScroll () {
      // How far from the bottom of the notifications list should we let the
      // user scroll position be when we consider them "at the bottom" and hide
      // the "see more" gradient? We have to give some margin of error because
      // at different browser zoom levels the scrollTop values will be
      // sub-pixels, so exact matching won't work (PSC-6066).
      const atBottomMarginOfError = 10

      const list = this.$refs.list
      this.atBottom = list.scrollTop >
        list.scrollHeight - list.offsetHeight - atBottomMarginOfError

      this.updateCanScroll()
    },

    updateCanScroll () {
      const list = this.$refs.list
      if (!list) {
        this.canScroll = false
        return
      }
      this.canScroll = list.offsetHeight >= Math.floor((window.innerHeight / 2))
    },

    // This event will not be triggered by the intro modal because it uses
    // event.stopProppagation()
    stopForceShow () {
      if (!this.forceShow) {
        return
      }

      // If the tooltip is pre-expanded, close it
      this.deferClosedEvent = true
      try {
        window.sessionStorage.setItem('notifications-have-been-seen-pre-expanded', 'yes')
      }
      catch (error) {
        console.error('Cannot access session storage due to incognito window')
      }
      this.forceShow = false
      this.sendCloseEvent('Notifications closed (pre-expanded)')
    },

    handleShow () {
      this.trackOpen()
      this.$nextTick(this.updateCanScroll)
    },

    trackOpen () {
      // Don't double track pre-expand events
      if (this.forceShow) {
        return
      }

      this.$gtag.event(
        'Notifications opened',
        {
          'event_category': 'Announcements',
          'event_label': 'Notifications count:',
          'value': this.sorted.length,
        },
      )
      this.viewStart = Date.now()
    },

    trackClose () {
      // Don't double track pre-expand events
      if (this.deferClosedEvent || this.forceShow) {
        // Defer closed is here to ensure that @auto-hide and @hide can't
        // make stopForceShow and trackClose race. I've never seen them do it
        // but this is safe and predictable.
        this.deferClosedEvent = false
        return
      }
      // If there are no notifications then the user wont see anthing and might
      // try to open it again. Track that event as another "open".
      if (this.sorted.length === 0) {
        this.trackOpen()
        return
      }

      this.sendCloseEvent('Notifications closed')
    },

    sendCloseEvent (action) {
      const viewTime = this.viewStart ? Date.now() - this.viewStart : 'unknown'

      this.$gtag.event(
        action,
        {
          'event_category': 'Announcements',
          'event_label': 'Viewed time:',
          'value': viewTime,
        },
      )

      this.viewStart = null
    },

    closePopper () {
      this.$refs.popper.hide()
    },
  },
}
</script>

<style lang="scss" scoped>

:deep(.v-popper__popper) {
  border-radius: 5px;
  box-shadow: 0 0 1.25rem rgba($black, .30);
  // This puts it 10 below the intro modal
  z-index: 1030;

  @include media-breakpoint-up(md) {
    width: 34rem;
  }
}
.popper {
  font-size: 1rem;
  color: $body-color;

  .fade-out {
    content: ' ';
    background: linear-gradient(transparent 0%, $input-bg 65%, $input-bg 100%);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5rem;
    opacity: 0;
    // .35s is the b-collapse toggle time. The element will necessarily already
    // be expeanded by now but we might as well use a consistent timing
    transition: opacity .35s;
    pointer-events: none;
  }

  &.show-fade-out {
    .fade-out {
      opacity: 1;
    }
  }
}

.notification-item:last-child {
  border-bottom: 0 !important;
}

.notification-list {
  max-height: calc(87vh - 127px);
  overflow: auto;

  @include media-breakpoint-up(sm) {
    max-height: 50vh;
  }
}

.badge {
  right: -.6rem;
  position: absolute;
  top: 0.2rem;
  padding: 0.15em .5em
}

// TODO: Remove this once TaxSys can fix their styles to accommodate our nav redesign. See PSC-8716
.public-v2 {
  .megaphone-icon {
    width: 1.4rem !important;
    height: 1.4rem !important;
  }

  .badge {
    top: 0;
    right: -1rem;
  }
}

// Also in CartIconPopper.vue
.fade-delay-enter-active {
  transition: all .4s ease-out;
}
.fade-delay-leave-active {
  transition: all .35s ease-in;
}
.fade-delay-enter-from,
.fade-delay-leave-to {
  opacity: 0;
}

.close-button {
  top: .25rem;
  right: 2rem;
}

.close-button-none {
  top: 1.3rem;
  right: 1.5rem;
}
</style>
