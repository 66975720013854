import Vue, { createApp } from 'vue'
import VueCookies from 'vue-cookies'
import { createRouter, createWebHistory } from 'vue-router'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import '@grantstreet/bootstrap/scss/gsg-bootstrap.scss'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import GovHubSearch from './GovHubSearch.vue'
import SearchStore from './store/index.js'
import { vueErrorHandler } from './sentry.ts'

sanitizeConfig.namedConfigurations = {
  confidential: {
    USE_PROFILES: {
      html: true,
      svg: true,
    },
  },
}

sanitizeConfig.default.ALLOWED_TAGS.push('img')

Vue.use(VueCookies)
Vue.use(VueSVGIcon)
Vue.use(VueDOMPurifyHTML, sanitizeConfig)

initBootstrapVue(Vue)

export default {
  attach: function (input, data) {
    createApp(GovHubSearch)
      .use(vueErrorHandler)
      .use(new SearchStore(data))
      .use(createRouter({
        history: createWebHistory(),
        routes: [{
          path: '/',
          name: 'home',
          component: GovHubSearch,
        }],
      }))
      .mount(input)
  },
}
