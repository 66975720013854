import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'

export default {
  methods: {
    scrollTo (selector, {
      container = 'body',
      offset = -30,
      speed = 250,
      // Explicitly search for known fixed/sticky elements and allow consuming
      // components to override.
      offsetElementSelectors = ['.nav-steps.sticky-top', '.top-nav'],
      stickyElementOffset = Array.prototype.reduce.call(
        document.querySelectorAll(offsetElementSelectors.join(', ')),
        (sum, element) => sum - element.offsetHeight,
        0,
      ),
    } = {}) {
      Vue.nextTick().then(() => {
        VueScrollTo.scrollTo(selector, speed, {
          container,
          offset: offset + stickyElementOffset,
          x: false,
          y: true,
        })
      })
    },

    // Note this won't work for a modal within a modal, etc
    scrollWithinModal (selector, options = {}) {
      options.container = options.container || document.getElementsByClassName('modal').item(0)
      this.scrollTo(selector, options)
    },
  },
}
