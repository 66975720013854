<!--
⚠️ After making changes to this component, please go to
@grantstreet/widget-bundles, run `yarn build`, and copy
widget-bundles/dist/current/taxsys-iframe-public.[js|css] to
govhub-ui/public/[js|css]/current/taxsys-iframe-public.[js|css]. This is used by the TaxSys
demo page, viewable in a sandbox at /sunshine/demo/taxsys
-->
<template>
  <div
    class="iframe-styles"
  >
    <!-- This button is not localized (although translations exist) because
    TaxSys does not supportZ Spanish in its account details iframes. If we want
    to use this payment options component in a translatable setting in the
    future we should figure out a way to translate it without pulling in the
    entire GovHub translations files. -->
    <AddPaymentOptionButton
      v-if="loaded"
      :payable="payable"
      :payment-option-in-cart="selectedPaymentOption"
      :show-single-display-name="showSingleDisplayName"
      :allow-remove-item="allowRemoveItem"
      :error-message="errorMessage"
      @selected="selectHandler"
      @removed="removeHandler"
    />
    <LoadingBars v-else />
  </div>
</template>

<script>
import AddPaymentOptionButton from '@grantstreet/psc-vue/components/AddPaymentOptionButton.vue'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import { Payable } from '@grantstreet/payables'

export default {
  components: {
    AddPaymentOptionButton,
    LoadingBars,
  },

  props: {
    // The path of the payable to associate with this particular payment options
    // button. This will be fetched by the button.
    payablePath: {
      type: String,
      required: true,
    },
    // The iframe-whisperer object created outside of this button wrapper. This
    // tries to ensure only one instance of the iframe-whisperer exists at a
    // time.
    parentWhisperer: {
      type: iframeWhisperer.ToParent,
      required: true,
    },
    // The namespace the iframe-whisperer should send its messages to and from.
    // If not specified, this uses the "taxsysIframe" namespace.
    namespace: {
      type: String,
      required: false,
      default: 'taxsysIframe',
    },
    // When set to true, `showSingleDisplayName` will result in the button
    // displaying "Add [display name] to Cart" when a payable has only one
    // payment choice
    showSingleDisplayName: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Allows the button to flip and remove payment options from the cart when
    // clicked to add an item
    allowRemoveItem: {
      type: Boolean,
      required: false,
    },
  },

  data: () => ({
    selectedPaymentOption: '',
    errorMessage: '',
    payable: undefined,
    loaded: false,
  }),

  async mounted () {
    // Fetch payable
    const response = await this.parentWhisperer.message({
      action: `${this.namespace}.getPayable`,
      payload: {
        path: this.payablePath,
      },
    })
    const { payload: payable } = Array.isArray(response) ? response[0] : response

    // Hydrate payable
    this.payable = new Payable({ raw: payable })

    this.loaded = true

    this.parentWhisperer.on(`${this.namespace}.itemRemoved`, ({ path, externalId, error }) => {
      // If this isn't relevant to this button, return
      if (this.payable.externalId !== externalId) {
        return
      }

      if (error) {
        this.handleError(error)
      }
      else {
        this.selectedPaymentOption = ''
      }

      this.endButtonAnimation()
    })

    // Check if a payment option is already in the cart
    const parent = {
      amount: this.payable.amount,
      path: this.payable.path,
    }
    const paymentOptions = this.payable.paymentChoices || []
    const items = paymentOptions.reduce((allOptions, paymentOption) => {
      allOptions.push({
        amount: paymentOption.amount,
        path: paymentOption.path,
      })
      return allOptions
    }, [parent])
    const payload = {
      // TODO PSC-20200 remove communicateByException
      communicateByException: false,
      items,
    }
    this.parentWhisperer
      .message({ action: `${this.namespace}.initItemsInCart`, payload })
      .then(({ items }) => {
        items.forEach(item => {
          // This is a bit weird
          // but there's logic in @grantstreet/govhub-ui's TaxSysIframe.vue
          // that guarantees this will be one item long
          this.selectedPaymentOption = item.path
        })
      })
  },

  methods: {
    async selectHandler (paymentOption) {
      const { amount, path } = paymentOption
      this.startButtonAnimation()

      try {
        this.errorMessage = ''
        const { error } = await this.parentWhisperer.message({
          action: `${this.namespace}.addToCart`,
          payload: [{
            // TODO PSC-20200 remove communicateByException
            communicateByException: false,
            amount,
            path,
            allowRemoveItem: this.allowRemoveItem,

            // This must be fully serializable to be able to be sent via
            // postMessage
            details: JSON.parse(JSON.stringify(this.payable.raw)),
          }],
        })

        if (error) {
          this.handleError(error)
        }
        else {
          this.selectedPaymentOption = path
        }

        this.endButtonAnimation()
      }
      catch (error) {
        this.handleError(error)
        this.endButtonAnimation()
      }
    },

    async removeHandler (paymentOption) {
      const { amount, path } = paymentOption
      this.startButtonAnimation()

      try {
        this.errorMessage = ''

        // We allow our `itemRemoved` handler to handle the response here since it also handles other removals, via eg.
        // * different Payment Options Iframe Buttons
        // * the mini-cart
        // but we still `await` so that we don't miss exceptions
        await this.parentWhisperer.message({
          action: `${this.namespace}.removeFromCart`,
          payload: [{
            amount,
            path,
            externalId: this.payable.externalId,
          }],
        })
      }
      catch (error) {
        this.handleError(error)
        this.endButtonAnimation()
      }
    },

    // This is a little smelly. We expect this to be a payable path for things
    // to work correctly elsewhere in govhub. In this case we are using the
    // parent's payable path every time it or any child is added/removed. That
    // feels a little funky to me but I'm not sure if that is actual smell or
    // not, and I don't see a better alternative given:
    // 1. We can't parse the paths for a "base path". See PSC-16930.
    // 2. We shouldn't use something other than a path; we want to stay
    //    consistent with the api we've set up.
    startButtonAnimation () {
      this.$wait.start(`adding ${this.payable.path} to cart`)
    },

    endButtonAnimation () {
      this.$wait.end(`adding ${this.payable.path} to cart`)
    },

    handleError (error) {
      console.error(error)
      this.errorMessage = 'We were unable to complete your request. Please try again later.'
    },
  },
}
</script>
