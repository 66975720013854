<template>
  <button
    type="submit"
    @click="$emit('click')"
  >
    <svgicon
      icon="magnifying-glass"
      class="svg-primary"
      height="1.5rem"
      width="1.5rem"
    />
  </button>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/magnifying-glass.js'
export default {
  emits: ['click'],
}
</script>

<style lang="scss" scoped>
  button {
    background: none;
    border: none;
    cursor: pointer;
    display: block;
    padding: 0 .625rem;
    z-index: 100;
  }
</style>
