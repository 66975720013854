<template>
  <b-badge
    :variant="hasVerified ? 'success' : 'warning'"
  >
    <span data-test="user-verification-status-badge">
      {{ statusMessage }}
    </span>
  </b-badge>
</template>

<script>

export default {
  props: {
    hasRequestedVerification: {
      type: Boolean,
      required: true,
    },
    hasVerified: {
      type: Boolean,
      required: true,
    },
    verificationStatus: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    statusMessage () {
      let dateString = ''
      if (this.verificationStatus && this.verificationStatus.updatedAt) {
        dateString = ': ' + new Date(this.verificationStatus.updatedAt).toLocaleDateString()
      }
      if (this.hasVerified) {
        return this.$t('user_verification.verified') + dateString
      }
      else if (this.hasRequestedVerification) {
        return this.$t('user_verification.requested') + dateString
      }
      return this.$t('user_verification.pending')
    },
  },
}
</script>
