<template>
  <div data-test="expandables-container">
    <div
      v-for="itemCategory of itemCategories"
      :key="itemCategory.categoryKey"
      class="accordion"
    >

      <b-row
        no-gutters
        type="button"
        data-test="item-category"
        @click="itemCategoriesOpen[itemCategory.categoryKey] = !itemCategoriesOpen[itemCategory.categoryKey]"
      >
        <b-col class="mt-2">
          <h2>{{ itemCategory.label[$i18n.locale] || '' }}</h2>
        </b-col>
        <b-col class="my-auto">
          <span class="float-right btn-link">
            {{ itemCategoriesOpen[itemCategory.categoryKey] ? $t('expand.hide') : $t('expand.view') }}
          </span>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <b-collapse
            :visible="itemCategoriesOpen[itemCategory.categoryKey]"
            role="tabpanel"
          >
            <div
              v-for="payable in itemsInCategory(itemCategory.categoryKey)"
              :key="payable.path"
              class="bg-white rounded-xl my-2"
            >
              <a
                :ref="payable.customParameters.key"
                class="anchor"
              />
              <ExpandableListItem
                :value="currentlyExpandedItem === payable.customParameters.key"
                :payable="payable"
                :expand-component-key="expandComponentKey"
                @input="expandChanged($event, payable.customParameters.key)"
              >
                <template #freeformField="props">
                  <slot
                    name="freeformField"
                    v-bind="props"
                  />
                </template>
                <template #addButton="props">
                  <slot
                    name="addButton"
                    v-bind="props"
                  />
                </template>
              </ExpandableListItem>
            </div>
          </b-collapse>
        </b-col>
      </b-row>
    </div>

    <div v-if="!itemCategories || !itemCategories.length">
      <div
        v-for="payable in payables"
        :key="payable.path"
        class="bg-white rounded-xl my-2"
      >
        <a
          :id="payable.customParameters.key"
          :ref="payable.customParameters.key"
          class="anchor"
        />
        <ExpandableListItem
          :value="currentlyExpandedItem === payable.customParameters.key"
          :payable="payable"
          :expand-component-key="expandComponentKey"
          @input="expandChanged($event, payable.customParameters.key)"
        >
          <template #freeformField="props">
            <slot
              name="freeformField"
              v-bind="props"
            />
          </template>
          <template #addButton="props">
            <slot
              name="addButton"
              v-bind="props"
            />
          </template>
        </ExpandableListItem>
      </div>
    </div>
  </div>
</template>

<script>
import ExpandableListItem from './ExpandableListItem.vue'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'
import store from '../../store/index.ts'

export default {
  components: {
    ExpandableListItem,
  },

  mixins: [
    scrollToMixin,
  ],

  props: {
    payablesAdaptor: {
      type: String,
      required: true,
    },
    displayType: {
      type: String,
      required: true,
    },
    expandComponentKey: {
      type: String,
      required: true,
    },
    payablesQuery: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      payables: [],
      currentlyExpandedItem: null,
      itemCategoriesOpen: {},
    }
  },

  methods: {
    expandChanged (isExpanded, key) {
      this.currentlyExpandedItem = isExpanded ? key : null
    },

    itemsInCategory (category) {
      return this.payables
        .filter(payable => payable.customParameters.category === category)
    },
  },

  computed: {
    itemCategories () {
      return store.getters.getPayableSource(this.displayType).expandItemCategories
    },
  },

  async mounted () {
    this.payables = (await store.dispatch('searchPayables', {
      payablesAdaptor: this.payablesAdaptor,
      data: { query: this.payablesQuery },
      language: this.$i18n.locale,
    })).payables

    this.itemCategoriesOpen = this.itemCategories.reduce((categories, category) => {
      categories[category.categoryKey] = false
      return categories
    }, {})

    // Anchor tags - expand item specified.
    // If there are no item categories, expected `#<item_key>`.
    // Otherwise, expects `#<category_key>.<item_key>`.
    // the category_key is included here so it knows which category
    // to expand before searching for the item. An alternative solution
    // would be to looked up the payable from the item_key and get
    // the category from the payable.

    // Guarantees the entire view has been rendered
    await this.$nextTick()

    // Takes off '#'
    let anchor = this.$route?.hash?.slice(1)
    if (!anchor) {
      return
    }
    const parts = anchor.split('.')
    if (parts.length === 2) {
      this.itemCategoriesOpen[parts[0]] = true
      anchor = parts[1]
    }
    const element = this.$refs[anchor]?.[0]
    if (!element) {
      return
    }
    this.currentlyExpandedItem = anchor
    this.scrollTo(element)
  },
}
</script>

<style scoped>
a.anchor {
    display: block;
    position: relative;
    visibility: hidden;
}
.accordion {
  border-bottom: 1px solid #EAEAEA;
}
</style>
