<template>
  <div
    class="align-items-center justify-content-center"
    :class="responsiveClasses"
  >
    <transition name="fade">
      <div
        v-if="spinning"
        class="loading-spinner d-flex align-items-center position-absolute h-100"
      >
        <LoadingSpinner class="bg-white" />
      </div>
    </transition>
    <SearchIcon
      :aria-label="$t('search.submit.label')"
      :class="{ 'opacity-0 cursor-default': spinning }"
      class="search-icon h-100"
      data-test="search-button"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import LoadingSpinner from '@grantstreet/loaders-vue/LoadingSpinner.vue'
import SearchIcon from './SearchIcon.vue'

export default {
  emits: ['click'],
  components: {
    SearchIcon,
    LoadingSpinner,
  },

  props: {
    spinning: {
      type: Boolean,
      required: true,
    },

    /**
     * Hide this search button up until a certain breakpoint (e.g., sm, md, lg).
     * This is necessary because we need to remove the default 'd-flex' class if
     * we want to hide before a breakpoint.
     */
    hideBeforeBreakpoint: {
      type: String,
      default: '',
    },
  },

  computed: {
    responsiveClasses () {
      return {
        'd-flex': !this.hideBeforeBreakpoint,
        // Add, e.g., 'd-sm-flex' if a hideBeforeBreakpoint value was passed
        [`d-none d-${this.hideBeforeBreakpoint}-flex`]: this.hideBeforeBreakpoint,
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.loading-spinner {
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active,
.search-icon {
  transition: opacity .25s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
