<template>
  <!-- Here, we're *intentionally* overriding the default container margins -->
  <div
    :id="payable.sanitizedPath"
    class="container-fluid"
    :class="`${colLimit} ${isChildItem || isParentItem ? 'px-0' : 'bg-white rounded px-4 px-md-8 py-4 mb-2'}`"
  >

    <PayableAlert
      :payable="payable"
    />

    <slot
      name="cornerIndicator"
      :payable="payable"
    />

    <div class="row">
      <div class="col-7 col-md-6 d-block">
        <div v-if="isChildItem">
          {{ payable.displayName }}
        </div>
        <PayableDescription
          v-else-if="!isParentItem"
          :payable="payable"
        />
      </div>

      <slot name="amount">
        <div
          class="col-5 col-md-3 col-lg-3 text-right text-md-left"
          :class="{'border-md-left': !isChildItem && !isParentItem}"
        >
          <div
            v-if="payable.displayAmount"
            class="amount mb-1"
          >${{ payable.displayAmount }}</div>
          <div v-if="isParentItem">
            <span class="text-uppercase mr-2">{{ $t("total_due") }}</span>
          </div>
          <div v-else-if="payable.dueAt">
            <span class="text-uppercase mr-2">{{ $t("due.default") }}</span>
            <b>{{ formatDate(payable.dueAt, false, $i18n.locale) }}</b>
          </div>
          <div v-if="payable.customParameters && payable.customParameters.effective_date">
            {{ $t("effective.date") }} <b>{{ payable.customParameters.effective_date }}</b>
          </div>
          <div v-if="payable.customParameters && payable.customParameters.receipt_number">
            {{ $t("receipt.num") }} <b>{{ payable.customParameters.receipt_number }}</b>
          </div>
        </div>
      </slot>

      <div class="col-12 col-md-3 mt-3 mt-lg-0">
        <slot
          name="payableActions"
          :payable="payable"
        />
      </div>
    </div>

    <slot
      name="payableAncillary"
      :payable="payable"
    />

    <PayableNotice
      :payable="payable"
    />

    <div
      v-if="showAddFundsLink"
      class="row"
    >
      <div class="col-7 col-md-6 details d-md-block d-none" />
      <div class="col-5 col-md-6 border-md-left pt-3">
        <div v-if="payable.amount < 0">
          {{ $t('account.has_credit.default', { amount: payable.absoluteDisplayAmount }) }}
        </div>
        <div>
          <!-- There appears to be a bug where bools have to be passed
          explicitly (maybe just with nested slots?) -->
          <slot
            name="addButton"
            :payable="payable"
            :show-add-funds-link="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayableDescription from '../description/PayableDescription.vue'
import PayableAlert from '@grantstreet/psc-vue/components/PayableAlert.vue'
import PayableNotice from '@grantstreet/psc-vue/components/PayableNotice.vue'

import { formatDate } from '@grantstreet/psc-js/utils/date.js'

export default {
  components: {
    PayableDescription,
    PayableAlert,
    PayableNotice,
  },

  props: {
    payable: {
      type: Object,
      required: true,
    },
    titleClass: {
      type: String,
      default: '',
    },
    colLimit: {
      type: String,
      default: '',
    },
    isParentItem: {
      type: Boolean,
      default: false,
    },
    isChildItem: {
      type: Boolean,
      default: false,
    },
  },

  computed: {

    showAddFundsLink () {
      return this.payable.isPayable &&
        // null amounts need to be checked using raw.amount
        this.payable.raw.amount != null &&
        (this.payable.amount <= 0 || typeof this.payable.amount === 'undefined')
    },

  },

  methods: {
    formatDate,
  },
}
</script>

<style lang="scss" scoped>

.amount {
  font-size: $font-size-big;
  font-weight: 100;
}

</style>
