<!--
The `AddPaymentOptionButton` is not meant to be used directly as it has many
hooks that are not initialized or owned by this component.

If using in an iframe context, like within the TaxSys iframe, please use
`App.vue` in the `@grantstreet/payment-options-iframe-button` package or use
the exported function `paymentOptionsIframeButton` from the taxsys-iframe-public widgets
bundle.

If using in a GovHub context, please use `AddToCartButtonWrapper.vue` within
the `@grantstreet/govhub-vue` package instead. This will automatically handle
payables with payment options for you.

⚠️ After making changes to this component, please go to
@grantstreet/widget-bundles, run `yarn build`, and copy
widget-bundles/dist/current/taxsys-iframe-public.[js|css] to
govhub-ui/public/[js|css]/current/taxsys-iframe-public.[js|css]. This is used by the TaxSys
demo page, viewable in a sandbox at /sunshine/demo/taxsys
-->
<template>
  <ErrorPopperButton ref="popper">
    <template #button>
      <div>
        <!-- Multiple payment options dropdown button -->
        <template v-if="paymentOptions.length > 1">
          <ProgressButton
            v-if="!paymentOptionInCart"
            ref="addButton"
            class="payment-options-button"
            data-test="payment-options-button"
            :waiting="$wait.is(`adding ${payable.path} to cart`)"
            :disabled="!canAddToCart"
            variant="primary"
            block
            @click="showOptions($event)"
          >
            {{ maybeLocalize('Payment Options', 'button.payment_options') }}
            <b-dropdown
              ref="dropdownPaymentOptions"
              variant="link"
              size="sm"
              right
              data-test="dropdown-payment-options"
              no-caret
              @show="dropdownActive = true"
              @hide="dropdownActive = false"
            >
              <template #button-content>
                <b-link
                  id="dropdown-link-caret"
                  class="d-flex align-items-center"
                >
                  <svgicon
                    id="dropdown-caret-icon"
                    :fill="false"
                    name="caret"
                    color="#ffffff"
                    :dir="dropdownActive ? 'up' : 'down'"
                    height="0.75rem"
                    width="0.75rem"
                  />
                </b-link>
              </template>
              <b-dropdown-item
                v-for="paymentOption of paymentOptions"
                :key="paymentOption.id"
                :data-test="'select-payment-option-' + paymentOption.id"
                @click="selectPaymentOption(paymentOption)"
              >
                {{ paymentOptionLabel(paymentOption.display_name) }}
              </b-dropdown-item>
            </b-dropdown>
          </ProgressButton>

          <button
            v-else-if="!allowRemoveItem"
            data-test="in-cart"
            type="button"
            class="in-cart-button btn btn-block btn-outline-primary text-wrap"
            disabled
          >
            {{ maybeLocalize('In Cart', 'button.in_cart.default') }}
          </button>

          <ProgressButton
            v-else
            class="remove-item-button"
            data-test="remove-item-button"
            variant="outline-primary"
            :waiting="$wait.is(`adding ${payable.path} to cart`)"
            block
            @click="findAndRemovePaymentOption(paymentOptionInCart)"
          >
            {{ maybeLocalize('Remove', 'remove.default') }}
          </ProgressButton>
        </template>

        <!-- Payment option button with only one option -->
        <template v-else>
          <ProgressButton
            v-if="!paymentOptionInCart"
            ref="addButton"
            class="payment-options-button"
            data-test="payment-options-button"
            :waiting="$wait.is(`adding ${payable.path} to cart`)"
            :disabled="!canAddToCart"
            variant="primary"
            block
            @click="selectPaymentOption(getSinglePaymentOption)"
          >
            {{ singlePaymentOptionLabel }}
          </ProgressButton>

          <button
            v-else-if="!allowRemoveItem"
            data-test="in-cart"
            type="button"
            class="in-cart-button btn btn-block btn-outline-primary text-wrap"
            disabled
          >
            {{ maybeLocalize('In Cart', 'button.in_cart.default') }}
          </button>

          <ProgressButton
            v-else
            class="remove-item-button"
            data-test="remove-item-button"
            variant="outline-primary"
            :waiting="$wait.is(`adding ${payable.path} to cart`)"
            block
            @click="removePaymentOption(getSinglePaymentOption)"
          >
            {{ maybeLocalize('Remove', 'remove.default') }}
          </ProgressButton>
        </template>
        <!-- Sadly, we need to copy floating-vue's Dropdown CSS classes here. -->
        <div
          v-if="errorMessage"
          class="popper v-popper--theme-dropdown popper-danger"
        >
          <div class="v-popper__inner">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </template>
  </ErrorPopperButton>
</template>

<script>
import ProgressButton from '../components/ProgressButton.vue'
import ErrorPopperButton from '../components/ErrorPopperButton.vue'

export default {
  emits: ['selected', 'removed'],
  components: {
    ProgressButton,
    ErrorPopperButton,
  },

  props: {
    payable: {
      type: Object,
      required: true,
    },
    paymentOptionInCart: {
      type: String,
      required: true,
    },
    // When set to true, `showSingleDisplayName` will result in the button
    // displaying "Add [display name] to Cart" when a payable has only one
    // payment choice
    showSingleDisplayName: {
      type: Boolean,
      required: false,
    },
    // Allows the button to flip and remove payment options from the cart when
    // clicked to add an item
    allowRemoveItem: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },

  computed: {
    canAddToCart () {
      if (!this.payable) {
        return false
      }

      if (!this.payable?.is_payable && !this.payable?.isPayable) {
        return false
      }

      return !this.$wait.is(`adding ${this.payable.path} to cart`)
    },

    paymentOptions () {
      return this.payable?.paymentChoices || this.payable?.payment_choices || []
    },

    getSinglePaymentOption () {
      // Return the base payable itself if there are no payment options
      if (!this.paymentOptions.length) {
        return this.payable
      }
      // Otherwise return the first payment option; we don't care if there are
      // others
      else {
        return this.paymentOptions[0]
      }
    },

    singlePaymentOptionLabel () {
      // Show a single display name and only one payment option
      if (this.showSingleDisplayName && this.paymentOptions.length === 1) {
        return this.paymentOptionLabel(this.paymentOptions[0].display_name)
      }
      // Show a single display name and no payment options
      else if (this.showSingleDisplayName && this.paymentOptions.length === 0) {
        return this.paymentOptionLabel(this.payable.display_name)
      }
      // Don't show a single display name or multiple payment options
      else {
        return this.maybeLocalize('Add to Cart', 'button.add_cart.default')
      }
    },
  },

  data () {
    return {
      dropdownActive: false,
    }
  },

  methods: {
    showOptions (event) {
      // Show options when the parent ProgressButton is clicked, not the child
      // dropdown-items
      if (!event.target.classList.contains('dropdown-item')) {
        this.$refs.dropdownPaymentOptions.show()
      }
    },

    selectPaymentOption (paymentOption) {
      this.$emit('selected', paymentOption)
    },

    removePaymentOption (paymentOption) {
      this.$emit('removed', paymentOption)
    },

    findAndRemovePaymentOption (optionPath) {
      const paymentOption = this.paymentOptions.find(option => option.path === optionPath)
      this.removePaymentOption(paymentOption)
    },

    // TODO: TaxSys doesn't offer localization currently, so displayed payment
    // options are limited to English at the moment. We may need to localize
    // this for non-TaxSys usage some day, and it can be easily added here with
    // `this.maybeLocalize`.
    paymentOptionLabel (displayName) {
      return `Add ${displayName} to Cart`
    },

    // The AddPaymentOptionButton doesn't have access to the full (and giganto)
    // localization files that vue-i18n uses for the entirety of GovHub when
    // embedded in an iframe, so we can just try/catch `this.$t` to see if
    // localization is available.
    maybeLocalize (englishString, templateKey, opts) {
      try {
        return this.$t(templateKey, opts)
      }
      catch (error) {
        return englishString
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-toggle {
  padding-right: 0;
}

.payment-options-button {
  min-width: calc($progress-button-min-width + 2rem);
}
</style>

<style lang="scss">
.dropdown-toggle-no-caret {
  &::after {
    content: none !important;
  }
}

.popper {
  z-index: 2999;
}

.dropdown-menu {
  z-index: 3000;
}
</style>
