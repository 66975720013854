/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'megaphone': {
    width: 16,
    height: 16,
    viewBox: '0 0 21 18',
    data: '<path pid="0" class="st0" d="M12.7 14.8l-.6 1.9c-.1.4-.6.7-1.1.6L6.6 16c-.2-.1-.4-.2-.5-.5-.1-.2-.1-.5 0-.7l.5-1.3"/><path pid="1" class="st1" d="M18.5.8l-.6.6L3.1 5.9H1.4c-.5 0-.9.4-.9.9v4.5c0 .5.4.9.9.9h1.7l14.8 4.5.6.6c.2.2.4.3.6.3h.5c.5 0 .9-.4.9-.9V1.4c0-.5-.4-.9-.9-.9h-.5c-.2 0-.4.1-.6.3zM3.1 5.9v6.2"/>'
  }
})
