<template>
  <section class="text-center pb-6 px-3">

    <h1 class="mt-4 mb-3">
      {{ error.title }}
    </h1>

    <p>
      {{ error.description }}
    </p>

  </section>
</template>

<script>

const errors = {
  notFound: {
    title: '404 - Page Not Found',
    description: 'We can’t find the page you’re looking for.',
  },
  loginError: {
    title: 'Login Error',
    description: 'There was a problem logging you into our site.',
  },
  loadFailure: {
    title: 'Site Error',
    description: 'There was a problem loading the site. Please try again later.',
  },
  loginRequired: {
    title: 'Login Required',
    description: 'You must log in to view this page.',
  },
}

const errorTypes = Object.keys(errors)

export default {

  props: {
    type: {
      type: String,
      default: 'notFound',
      validator: value => errorTypes.includes(value),
    },
  },

  data: () => ({
    errors,
  }),

  computed: {
    error () {
      return this.errors[this.type]
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 2rem;

  @include media-breakpoint-up(sm) {
    font-size: 3rem !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 5rem !important;
  }
}
</style>
