<template>
  <div v-dompurify-html:confidential="content" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('GovHubSearch')

const dom = new DOMParser()

export default {
  computed: {
    ...mapState([
      'confidentialPage',
    ]),

    content () {
      return dom.parseFromString(this.confidentialPage, 'text/html').querySelector('section.account').outerHTML
    },
  },
}
</script>

<style lang="scss">
#content, .page-content {
  .govhub-search {
    .account {
      h1 {
        font-size: $h2-font-size;
        font-weight: $font-weight-bold;
        margin: 0;
        margin-bottom: .5rem;
      }

      h2 {
        margin-bottom: 1.5rem;
      }
    }
  }
}
</style>
