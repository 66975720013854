/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'x': {
    width: 16,
    height: 16,
    viewBox: '1495.793 1071.793 20.414 20.414',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1496.5 1072.5l19 19M1496.5 1091.5l19-19"/></g>'
  }
})
