<template>
  <AisInstantSearch
    :key="index"
    :index-name="index"
    :middlewares="middlewares"
    :routing="routing"
    :search-client="searchClient"
    :search-function="searchFunction"
  >
    <AisConfigure
      :hits-per-page.camel="perPage"
      :query="query"
    />

    <h1>{{ categoryDescription }}</h1>

    <section class="content-card m-3">
      <b-row class="align-items-center title">
        <b-col>
          <h2 id="title">{{ title }}</h2>
        </b-col>

        <template v-if="adminKey">
          <b-col cols="auto">
            <b-form-checkbox
              :checked="alwaysShowMainAddress"
              @change="toggleMainAddress"
            >
              Show Main Address
            </b-form-checkbox>
          </b-col>

          <b-col cols="auto">
            <label for="demo-index">Index</label>
          </b-col>

          <b-col cols="auto">
            <b-form-select
              id="demo-index"
              :options="indexes"
              :value="index"
              @input="changeIndex($event)"
            />
          </b-col>
        </template>
      </b-row>
      <b-row>
        <b-col>
          <AisAutocomplete>
            <template #default="{ currentRefinement, indices, refine }">
              <Typeahead
                size="lg"
                :aria-labelled-by="'title placeholder'"
                :data="indexData(currentRefinement, indices)"
                :disable-auto-select="true"
                :disable-sort="true"
                :max-matches="perPage"
                :min-matching-chars="1"
                :placeholder="placeholder"
                :serializer="item => ''"
                :show-all-results="true"
                :value="query"
                @blur="blur"
                @focus="showSuggestions"
                @hit="itemSelected($event, indices[0].sendEvent)"
                @input="searchUpdated($event, refine)"
                @keyup.enter.stop
              >
                <template #suggestion="{ data }">
                  <SearchSuggestion :suggestion="data" />
                </template>

                <template #append>
                  <SearchIcon />
                </template>
              </Typeahead>
            </template>
          </AisAutocomplete>
        </b-col>
      </b-row>

      <b-row
        v-if="hint"
        no-gutters
        class="align-items-center justify-content-center hint"
      >

        <b-col
          cols="auto"
          class="rounded-circle"
        >
          <svgicon
            :icon="hint.icon"
            :fill="false"
            height="1.275rem"
            width="1.275rem"
          />
        </b-col>

        <b-col>
          <b-link :href="hint.href">
            <span v-dompurify-html="hint.label" />
          </b-link>
          <div>
            {{ hint.value }}
          </div>
        </b-col>
      </b-row>

      <CollapsibleSection
        v-if="confidential"
        class="confidential-form"
      >
        <template #title>
          Pay for confidential accounts
        </template>

        <template #default>
          <ConfidentialForm />
        </template>
      </CollapsibleSection>
    </section>

    <section
      v-show="showResults"
      class="content results"
    >
      <div
        v-if="(query.length >= minCharacters || helpText) && !activeQueries"
        class="px-3"
      >
        <AisStats>
          <template #default="{ hitsPerPage, nbPages, nbHits, page }">
            <!-- We sometimes (in the web panel) limit the results returned by
              algolia. When we do that nbHits is still the total number of
              *potential possible* hits. The actual number of results returned
              is nbPages * hitsPerPage. If the numbers are different we show an
              alert to the user letting them know their query is vague. -->
            <Alert
              v-if="nbHits > (hitsPerPage * nbPages)"
              variant="warning"
              class="shadow mb-2"
            >
              Your query has a total of {{ displayFormat(nbHits, true) }} possible matches. Try a more specific query to limit the number of results.
            </Alert>
            <SearchStats
              :query="query"
              :pages="nbPages"
              :page="page"
              class="pt-3"
            />
          </template>
        </AisStats>
      </div>

      <AisHits
        v-if="query && !activeQueries"
        class="category-search-results"
      >
        <template #default="{ items, sendEvent }">
          <div
            v-for="item of items"
            :key="item.objectID"
            class="content-card my-2 mx-3 py-3 px-4"
          >
            <SearchResult
              :item="item"
              :send-insights-event="sendEvent"
            />
          </div>
        </template>
      </AisHits>

      <b-row align-h="center">
        <b-col cols="auto">
          <AisPagination v-if="hitCount > 0 && !activeQueries">
            <template #default="{ createURL, currentRefinement, isFirstPage, isLastPage, nbPages, refine }">
              <b-pagination-nav
                :link-gen="(page) => { return createURL(page - 1) }"
                :next-class="{ disabled: isLastPage }"
                :number-of-pages="nbPages || 1"
                :prev-class="{ disabled: isFirstPage }"
                :value="currentRefinement + 1"
                active-class="active"
                center
                hide-ellipsis
                hide-goto-end-buttons
                limit="10"
                use-router
                @change="(page) => { navigate(page - 1, refine) }"
              >
                <template #next-text>
                  <svgicon
                    icon="caret"
                    dir="right"
                    height=".875rem"
                    :fill="false"
                  />
                </template>

                <template #prev-text>
                  <svgicon
                    icon="caret"
                    dir="left"
                    height=".875rem"
                    :fill="false"
                  />
                </template>
              </b-pagination-nav>
            </template>
          </AisPagination>
        </b-col>
      </b-row>
    </section>
  </AisInstantSearch>
</template>

<script>
import {
  AisAutocomplete,
  AisConfigure,
  AisHits,
  AisInstantSearch,
  AisPagination,
  AisStats,
} from 'vue-instantsearch'

import Typeahead from '@grantstreet/psc-vue/components/typeahead/Typeahead.vue'
import { createNamespacedHelpers } from 'vuex'
import '@grantstreet/bootstrap/icons/js/caret.js'
import '@grantstreet/bootstrap/icons/js/edit-outline.js'
import CollapsibleSection from './CollapsibleSection.vue'
import ConfidentialForm from './ConfidentialForm.vue'
import SearchIcon from './SearchIcon.vue'
import SearchResult from './SearchResult.vue'
import SearchSuggestion from './SearchSuggestion.vue'
import SearchStats from './SearchStats.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import '@grantstreet/bootstrap/icons/js/alert-exclamation-triangle.js'
import { displayFormat } from '@grantstreet/psc-js/utils/numbers.js'
import { apiClient } from '../mixins/apiClient.js'
import { routing } from '../mixins/routing.js'
import { payables } from '../mixins/payables.js'

/*
  There are incompatibilities between @vue/compat and the Vue 3 versions
  (vue-instantsearch/vue3/es) of the Algolia components. Since we can't use
  the Vue 3 versions, the next best option is to opt into Vue 3 mode by default
  (MODE: 3) and only enable compat for certain features when necessary.
*/
AisAutocomplete.compatConfig = { MODE: 3 }
AisConfigure.compatConfig = {
  MODE: 3,
  INSTANCE_SCOPED_SLOTS: true,
}
AisPagination.compatConfig = { MODE: 3 }
AisStats.compatConfig = { MODE: 3 }

const { mapActions, mapGetters, mapMutations, mapState } = createNamespacedHelpers('GovHubSearch')

export default {
  components: {
    AisAutocomplete,
    AisConfigure,
    AisHits,
    AisInstantSearch,
    AisPagination,
    AisStats,
    CollapsibleSection,
    ConfidentialForm,
    SearchIcon,
    SearchResult,
    SearchSuggestion,
    SearchStats,
    Typeahead,
    Alert,
  },

  mixins: [
    apiClient,
    routing,
    payables,
  ],

  created () {
    this.$cookies.config('1d', '/', '', true, 'Strict')
    this.listIndexes()
  },

  computed: {
    ...mapState([
      'activeQueries',
      'adminKey',
      'alwaysShowMainAddress',
      'category',
      'categoryDescription',
      'confidential',
      'helpText',
      'hint',
      'hitCount',
      'index',
      'indexes',
      'lastRoute',
      'minCharacters',
      'parameters',
      'perPage',
      'placeholder',
      'redirect',
      'searchMatch',
      'sendEvents',
      'suggestionsVisible',
      'title',
    ]),

    ...mapGetters([
      'query',
      'showResults',
    ]),
  },

  methods: {
    // Removes focus and hides the autocomplete list.
    blur () {
      document.querySelectorAll('.ais-Autocomplete input')[0].blur()
      this.hideSuggestions()
    },

    // Resets the demo page when the user selects a new index.
    changeIndex (index) {
      this.pushRoute({ query: {} })
      this.setIndex(index)
    },

    // Handle the results returned from Algolia.
    indexData (currentRefinement, indices) {
      if (this.redirect) {
        return []
      }

      const hits = indices[0].hits
      const hitCount = hits.length

      const complete = currentRefinement === this.query
      let redirect = null

      this.searchResults({ complete, hitCount })

      if (!this.suggestionsVisible && complete) {
        // If there is a single result, prepare to navigate to its target.
        if (hitCount === 1 && hits[0].custom_parameters.public_url) {
          indices[0].sendEvent('click', hits[0], 'Redirected to Account')
          redirect = hits[0]
        }

        this.$cookies.set('search_category', this.category)

        // eslint-disable-next-line testcafe-community/noSkip
        if (this.parameters.skip) {
          // eslint-disable-next-line testcafe-community/noSkip
          this.$cookies.set('search_skip', this.parameters.skip)
        }

        // Avoid returning to a query that triggers a redirect, to prevent looping.
        if (redirect) {
          let query = this.$router.currentRoute?.value?.query?.search_query || ''

          if (query === currentRefinement) {
            query = this.lastRoute?.query?.search_query || ''
          }

          this.$cookies.set('search_query', query)
          this.$cookies.set('search_target', currentRefinement)
        }
        else {
          this.$cookies.set('search_query', currentRefinement)
          this.$cookies.set('search_target', currentRefinement)

          if (!this.searchMatch) {
            this.pushRoute({ query: this.parameters })
          }
        }
      }

      this.setRedirect(redirect)

      return hits
    },

    // Run a stricter search after the user chooses an autocomplete suggestion.
    itemSelected (suggestion, sendInsightsEvent) {
      const match = this.findPayablesMatch(suggestion)

      if (this.sendEvents) {
        sendInsightsEvent('click', suggestion, 'Autocomplete ' + match.type + ' Clicked')
      }

      this.$cookies.set('search_target', match.value)
      this.setSearchMatch(match)
    },

    // Display the next set of results and scroll up so the earliest is in view.
    navigate (page, refine) {
      refine(page)
      document.getElementsByClassName('govhub-search')[0].scrollIntoView()
    },

    // Update the query as the user types or when an item is selected.
    searchUpdated (query, refine) {
      this.setParameters(query || {})
      this.searching()

      if (query) {
        refine(query)
      }

      if (this.searchMatch) {
        this.setSearchMatch(null)
      }
    },

    ...mapActions([
      // Load available indexes for the demo page to select among.
      'listIndexes',
    ]),

    ...mapMutations([
      // Marks the autocomplete component as inactive.
      'hideSuggestions',

      // Adjusts state based on the query/results.
      'searchResults',

      // Notify of in-flight search requests.
      'searching',

      // Allows the demo page to select a specific index.
      'setIndex',

      // Saves the various search parameters.
      'setParameters',

      // Send the user to an account summary page.
      'setRedirect',

      // One-time adjustment of the search attribute.
      'setSearchMatch',

      // Marks the autocomplete component as active.
      'showSuggestions',

      // Lets the demo page toggle displaying the extra address.
      'toggleMainAddress',
    ]),

    displayFormat,

  },
}
</script>

<style lang="scss">
// Need this top-level ID to override the demo page styles.
#content, .page-content {
  .govhub-search {
    h1 {
      margin-top: 2rem;
      margin-left: 1rem;
    }

    h2, label {
      margin: 0;
    }

    label {
      color: $black;
      font-size: $h2-font-size;
      font-weight: $font-weight-bold;
    }

    .ais-Autocomplete {
      .input-group {
        border-radius: $border-radius;

        input {
          border-right: none;
        }

        input:focus {
          box-shadow: none;
        }

        .input-group-append {
          background-color: $input-bg;
          border: 1px solid $input-border-color;
          border-left: none;
          border-bottom-right-radius: $border-radius;
          border-top-right-radius: $border-radius;
          margin-left: 0;
          padding: 0 .5rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
      }

      .input-group:focus-within {
        box-shadow: $input-focus-box-shadow;
        z-index: 3;

        .input-group-append {
          border-color: $input-focus-border-color;
          outline: 0;
        }
      }
    }

    .ais-Highlight-highlighted.search-key {
      color: inherit;
      background-color: inherit;
      font-weight: $font-weight-bold;
      margin: 0 -.15em;
    }

    .label {
      font-weight: $font-weight-bold;
    }

    .results {
      margin: 0;
      margin-top: 1rem;

      ul.pagination {
        margin-bottom: 0;
        margin-top:    .5rem;

        li {
          height: 2.375rem;
          margin: .125rem;
          text-align: center;
          width: 2.375rem;
        }
      }

    }

    .title {
      margin-bottom: .5rem;
    }
  }
}

.confidential-form {
  margin-top: 1rem;
}

.content-card {
  background-color: $white;
  border-radius: $border-radius-xl;
  box-shadow: $box-shadow;
  padding: 2rem;
}

.hint {
  margin-top: 1rem;

  a {
    span {
      font-weight: $font-weight-bold;
    }
  }

  .rounded-circle {
    border: 1px solid $body-color;
    margin-right: .5rem;
  }

  .svg-icon {
    margin: .5rem;
  }
}
</style>
