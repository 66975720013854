/* eslint-disable vue/one-component-per-file */
import Vue, { createApp } from 'vue'
import { setEnvironment } from '@grantstreet/psc-environment/environment.js'
import { sentryException, vueErrorHandler } from './sentry.js'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import { checkRequiredOpts } from '@grantstreet/psc-vue/utils/install-utils.js'
import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'

import UserVerificationIframeAccordionLink from './components/UserVerificationIframeAccordionLink.vue'
import UserVerificationIframePIN from './components/UserVerificationIframePIN.vue'
import UserVerificationIframeStatus from './components/UserVerificationIframeStatus.vue'
import { v4 as uuid } from 'uuid'

import { i18n, loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'

import { createVueWait } from 'vue-wait'

Vue.config.productionTip = false
Vue.use(VueDOMPurifyHTML, sanitizeConfig)

export const attach = (linkSelector, verifySelector, statusSelector, opts) => {
  checkRequiredOpts({
    opts,
    required: [
      'environment',
      'payables',
    ],
    moduleName: 'User Verification iframe',
  })

  const {
    environment,
    payables,
    parentWhisperer,
  } = opts

  setEnvironment(environment)

  initBootstrapVue(Vue)

  Vue.use(VueSVGIcon)
  loadTranslations(sentryException)

  const sharedUUID = uuid()

  const payableSavePath = payables[0].raw.save_path

  // Create the vue instance for the Accordion link
  createApp(UserVerificationIframeAccordionLink, {
    // payableSavePath is for the iframe whisperer.
    // This isn't the selected payable, instead it's an ID for the widget.
    payableSavePath,
    payables,
    parentWhisperer,
    sharedUUID,
  })
    .use(i18n)
    .use(createVueWait())
    .use(vueErrorHandler)
    .mount(linkSelector)

  // Create the vue instance for the Status Badge
  createApp(UserVerificationIframeStatus, {
    payableSavePath,
    payables,
    parentWhisperer,
    sharedUUID,
  })
    .use(i18n)
    .use(createVueWait())
    .use(vueErrorHandler)
    .mount(statusSelector)

  // Create the vue instance for the PIN Management
  createApp(UserVerificationIframePIN, {
    payableSavePath,
    payables,
    parentWhisperer,
    sharedUUID,
  })
    .use(i18n)
    .use(createVueWait())
    .use(vueErrorHandler)
    .mount(verifySelector)
}
