<template>
  <b-row>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <div class="font-weight-bold pb-2">{{ $t('plates.purchaser_info.header') }}</div>
      <div
        v-if="keys.includes('purchaser_plate')"
        class="mb-2"
      >
        {{ $t('plates.purchaser_info.description') }}
      </div>
      <slot
        v-if="keys.includes('purchaser_plate')"
        name="freeformField"
        light
        data-test="purchaser-plate-field"
        :field="{
          pexKey: 'purchaser_plate',
          value: '',
          label: $t('vouchers.license_plate.label'),
          required: true,
        }"
      />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <div
        v-if="keys.includes('recipient_plate') || keys.includes('recipient_name')"
        class="font-weight-bold pb-2"
      >
        {{ $t('plates.recipient_info.header') }}
      </div>
      <div
        v-if="keys.includes('recipient_plate') || keys.includes('recipient_name')"
        class="mb-2"
      >
        {{ $t('plates.recipient_info.description') }}
      </div>
      <slot
        v-if="keys.includes('recipient_plate')"
        name="freeformField"
        light
        data-test="recipient-plate-field"
        :field="{
          pexKey: 'recipient_plate',
          value: '',
          label: $t('vouchers.license_plate.label'),
          placeholder: $t('vouchers.optional'),
        }"
      />
      <slot
        v-if="keys.includes('recipient_name')"
        name="freeformField"
        light
        data-test="recipient-name-field"
        :field="{
          pexKey: 'recipient_name',
          value: '',
          label: $t('vouchers.full_name.label'),
          placeholder: $t('vouchers.optional'),
        }"
      />
    </b-col>
    <b-col
      class="col-xl-4 col-12"
    >
      <slot
        name="addButton"
        :payable="payable"
        :item-added="() => $emit('item-added')"
        class="flex-grow-1 w-100"
      />
    </b-col>
  </b-row>
</template>

<script>

import store from '../../../store/index.ts'

export default {
  emits: ['input', 'item-added'],
  props: {
    payable: {
      type: Object,
      required: true,
    },
  },

  computed: {
    keys () {
      const fields = store.getters.getPayableSource(this.payable.configDisplayType?.name)?.freeformFields || []
      return fields.map(field => field.pexKey)
    },
  },

}
</script>
