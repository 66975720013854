/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  // psc-config-prod & -nonprod projects
  dsns: {
    prod: 'https://7ad98cadf94e4d9896fa3ec502c0a70f@o168195.ingest.sentry.io/6046181',
    nonprod: 'https://1afa25d0aa2648c78c5bd255f6075fc9@o168195.ingest.sentry.io/6046179',
  },
})
