<template>
  <div
    class="px-4 py-3 px-md-6"
    data-test="expandable-item"
  >
    <div class="row">
      <div class="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-3 details d-block">
        <PayableDescription
          :payable="payable"
        />
      </div>

      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 py-2 py-md-0 py-lg-0">
        <!-- Use background image div over transparent image so we have
          correct image height allocated before image load. This lets
          anchor tag scrolling work correctly. -->
        <div
          :style="{
            'background-image': 'url(' + payable.customParameters['image_url'] + ')',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
          }"
        >
          <img
            class="expand-image"
            data-test="expandable-item-image"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAMCAQAAAA9+97AAAAAE0lEQVR42mNkIBkwjmoZ1UIKAAAR7gANHVmTswAAAABJRU5ErkJggg=="
            :alt="payable.displayName"
          >
        </div>
      </div>

      <div
        class="col-12 col-sm-12 col-5 col-md-4 col-lg-4 col-xl-2 text-md-right"
        data-test="amount"
      >
        <h1
          v-if="payable.displayAmount"
          class="amount mb-1"
        >${{ payable.displayAmount }}</h1>
      </div>

      <div class="col-12 col-xl-4 mt-3 mt-xl-0">
        <b-button
          v-if="!value"
          data-test="expand-button"
          class="flex-grow-1 w-100"
          variant="primary"
          @click="$emit('input', true)"
        >
          {{ $t('expand.purchase') }}
        </b-button>
        <b-button
          v-if="value"
          data-test="collapse-button"
          class="flex-grow-1 w-100"
          variant="outline-primary"
          @click="$emit('input', false)"
        >
          {{ $t('expand.close') }}
        </b-button>
      </div>
    </div>
    <div
      v-if="value"
      class="bg-grey rounded-xl px-2 py-2 mt-2"
    >

      <!-- expand component -->
      <component
        :is="expandTypeComponent"
        :payable="payable"
        @item-added="$emit('input', false)"
      >
        <template #freeformField="props">
          <slot
            name="freeformField"
            v-bind="props"
          />
        </template>
        <template #addButton="props">
          <slot
            name="addButton"
            v-bind="props"
          />
        </template>
      </component>

    </div>
  </div>
</template>

<script>
import PayableDescription from '../description/PayableDescription.vue'
import VoucherExpand from './expand-components/VoucherExpand.vue'
import PlateExpand from './expand-components/PlateExpand.vue'

const allowedExpandTypes = {
  'voucher-expand': VoucherExpand,
  'plate-expand': PlateExpand,
}

export default {
  emits: ['input'],
  components: {
    PayableDescription,
    VoucherExpand,
    PlateExpand,
  },

  props: {
    payable: {
      type: Object,
      required: true,
    },
    value: {
      // Whether item is expanded
      type: Boolean,
      default: false,
    },
    expandComponentKey: {
      type: String,
      required: true,
    },
  },

  computed: {

    expandTypeComponent () {
      const component = allowedExpandTypes[this.expandComponentKey]
      if (!component) {
        console.error(`PayHub Error: Unknown expand component "${this.expandComponentKey}"`)
        return null
      }
      return component
    },
  },

}
</script>

<style scoped>
.expand-image {
  max-width: 250px;
  width: 100%;
  height: auto;
  display: block;
}
</style>
