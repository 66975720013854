import templateInstructions from './example-templates/template-instructions.js'
import { mergeCSVFiles, stringsToCSV } from '@grantstreet/psc-js/utils/csv.ts'
import Payable from '../Payable.ts'

/**
 * The PaymentOptionType enum defines what payment options are available to use
 * for the bulk add to cart workflow.
 */
export enum PaymentOptionType {
  Online = 'online',
  InPerson = 'in-person',
  WireTransfer = 'wire-transfer',
}

/**
 * The PaymentOptionDescription type defines the information shown to the user
 * upon successfully submitting a bulk upload payment option. The `label` should
 * be an i18n key that can be localized. The `value` is the direct value of the
 * label that will not be localized.
 *
 * The "Online" payment option does not use these values and will ignore any
 * description created through this configuration.
 *
 * @property {string} label The localizable i18n key that describes the `value`.
 * @property {string} value The value of the label that is not localized.
 * @example
 * description = {
 *   label: 'bank.bank_name',
 *   value: 'Deutsche Bank AG',
 * }
 *
 * This will localize the `label` as "Bank Name" or "Nombre del Banco" with a
 * value of "Deutsche Bank AG".
 */
type PaymentOptionDescription = {
  label: string
  value: string
}

/**
 * The BulkUploadPaymentOption type defines a bulk add to cart payment option
 * along with the information shown on successful submission.
 *
 * Please note: A type of `Online` will ignore any description assigned to it.
 *
 * @property {PaymentOptionType} type The type of payment option.
 * @property {PaymentOptionDescription[]} description An ordered list of labels
 *     and values to show the user when successfully submitting a payment
 *     option.
 *
 * @example
 * option = {
 *   type: WireTransfer,
 *   description: [
 *     {
 *       label: 'bank.bank_name',
 *       value: 'Deusche Bank AG',
 *     },
 *   ],
 * }
 */
export type BulkUploadPaymentOption = {
  type: PaymentOptionType
  description?: PaymentOptionDescription[]
}

/**
 * The CSVColumn type is how all CSV columns are defined using the bulk add to
 * cart workflow.
 *
 * @property {string} columnKey The name of the key by which a parsed value will
 *     map to when making a Payables API bulk search request. E.g., a column key
 *     of "propertyType" with a CSV row value of "Real Estate" will map to a
 *     JSON value of `{ "propertyType": "Real Estate"}`.
 * @property {string} columnName The actual display name of the column. This is
 *     used in determining if a CSV file contains a header row.
 * @property {number} sortOrder The position of the column in the CSV file's
 *     headers. This is similar to how Site Settings modules are ordered.
 *     E.g., 100.
 *
 * The "columnKey" is a string by which a parsed value will map
 * to when making a Payables API bulk search.
 */
type CSVColumn = {
  columnKey: string
  columnName: string
  sortOrder: number
}

/**
 * The CSVFormat type is a default object-type that takes a simple string key
 * that maps to a CSVColumn type. It can be extended via `implements` to create
 * different standardized formats.
 */
type CSVFormat = { [type: string]: CSVColumn }

// ======== Types for post-search validation ======== //

/**
 * The UploadResult type is the format of data used for displaying bulk search
 * results from the Bulk Add to Cart workflow. It can be used for further
 * validation in consumer file formats.
 *
 * Please note, this is not to be confused with the "BulkSearchResult" type that
 * is returned by the Payables API for the /bulk_search endpoint.
 */
export type UploadResult = {
  /** The search result formatted for display in a <b-table> */
  displayItem: { [type: string]: string }

  /** The payable associated with the search result */
  payable: Payable
}

/**
 * The ValidatedResults type is used to return UploadResults that have been
 * processed for further validation.
 */
export type ValidatedUploadResults = {
  /** Valid search results to display in the successes <b-table> */
  successes: UploadResult[]

  /**
   * Invalid search results to display in the failures <b-table>. Errors should
   * use i18n keys assigned to an `errorKey` field. Additional error args can
   * be assigned to an `errorArgs` key. This will ultimately be localized in the
   * consuming Vue component as `this.$t(failure.errorKey, failure.errorArgs)`.
   */
  failures: { [type: string]: string }[]
}

/**
 * The BulkUploadConfig type is an interface by which client-specific file
 * formats should follow. It will contain the payment options available for the
 * client, an example CSV string array that will be used for example file
 * downloads, and a map of CSV columns containing their column key (for Payables
 * API bulk searches), column name (for determining if a file's row is the
 * header row), and sort order (for placing the right value with the right
 * column).
 *
 * @example
 * import BulkUploadConfig, { PaymentOptionType } from '../BulkUploadConfig'
 *
 * class SunshineConfig extends BulkUploadConfig {
 *   constructor () {
 *     super()
 *
 *     this.paymentOptions = [
 *       {
 *         type: PaymentOptionType.Online,
 *       },
 *       {
 *         type: PaymentOptionType.WireTransfer,
 *         description: [
 *           {
 *             label: 'bank.bank_name',
 *             value: 'Deutsche Bank AG',
 *           },
 *         ],
 *       },
 *       {
 *         type: PaymentOptionType.InPerson,
 *         description: [
 *           {
 *             label: 'office.office_label',
 *             value: 'Department of Revenue - Downtown',
 *           },
 *         ],
 *       },
 *     ]
 *     this.exampleTemplate = [
 *       'Account Number',
 *       '80000000',
 *       '80000001',
 *       '80000002',
 *     ]
 *     this.csvColumns = {
 *       accountNumber: {
 *         columnKey: 'accountNumber',
 *         columnName: 'Account Number',
 *         sortOrder: 100,
 *       },
 *     }
 *
 *     this.bulkSearchBatchSize = 30
 *   }
 * }
 *
 * export default new SunshineConfig()
 */
export default class BulkUploadConfig {
  // ======== Configuration options for CSV parsing ======== //

  /**
   * An array of BulkUploadPaymentOption types for this client format
   */
  paymentOptions: BulkUploadPaymentOption[]

  /**
   * An array of strings to be combined into an example CSV file
   */
  exampleTemplate: string[][]

  /**
   * A map of strings to CSVColumn values that are used for a variety of
   * format-specific actions
   */
  csvColumns: CSVFormat

  // ======== Settings for bulk search (post-parsing) ======== //

  /**
   * An integer indicating the number of queries a single bulk search request
   * can make using the Payables /bulk_search API
   */
  bulkSearchBatchSize: number

  constructor () {
    this.paymentOptions = []
    this.exampleTemplate = [[]]
    this.csvColumns = {}

    this.bulkSearchBatchSize = 0
  }

  /**
   * Converts a CSV row (as a string array) into a search query usable by the
   * Payables API bulk search.
   *
   * This method can be overridden by a subclass if additional functionality is
   * needed.
   *
   * @param {string[]} csvRow A CSV row that has been separated into an array.
   * @returns A string-to-string key/value map with CSV values mapped to their
   *     repsective column key.
   */
  convertCsvToSearchQuery (csvRow: string[]): { [type: string]: string } {
    const properties = this.getColumnOrder.map(column => column.columnKey)
    const searchQuery = {}
    for (let i = 0; i < properties.length; i++) {
      const property = properties[i]
      searchQuery[property] = csvRow[i]?.trim()
    }

    return searchQuery
  }

  /**
   * Performs a simple check that a key/value map contains all the required
   * column keys pertaining to a file format. If additional validations are
   * required, this method should be overridden by a subclass.
   *
   * @param {object} data A simple key/value map of mapped CSV values.
   * @returns A Boolean indicating if the data is valid.
   */
  validate (data: object): boolean {
    const columnKeys = Object.values(this.csvColumns).map(column => column.columnKey)

    let valid = true
    for (const columnKey of columnKeys) {
      valid = valid && data.hasOwnProperty(columnKey)
    }

    return valid
  }

  /**
   * Getter that returns sorted CSV columns.
   */
  get getColumnOrder (): CSVColumn[] {
    return Object.values(this.csvColumns).sort(column => column.sortOrder)
  }

  /**
   * Private method that removes all whitespace, hyphens, and underscores from
   * a string, and lowercases it. Useful for simple header row comparisons, but
   * possibly flawed if a column name contains a hyphen.
   *
   * @param {string} text The string to simplify.
   * @returns A lowercased string with characters removed.
   */
  private simplifyString (text: string): string {
    return text.toLowerCase().replace(/[ _-]/g, '')
  }

  /**
   * Checks if a CSV row is actually the header row by primitively checking if
   * it contains all the column names, after lowercasing and removing special
   * characters.
   *
   * @param {string[]} csvRow A CSV row that has been separated into an array.
   * @returns A Boolean indicating if the CSV row contains all the column names.
   */
  isHeaderRow (csvRow: string[]): boolean {
    const columns: string[] = this.getColumnOrder.map(column => column.columnName)
    const joinedRow = this.simplifyString(csvRow.join(''))

    let isHeader = true
    for (const column of columns) {
      isHeader = isHeader && joinedRow.includes(this.simplifyString(column))
    }

    return isHeader
  }

  /**
   * Checks if a given CSV row looks like the the file format's template
   * instruction file.
   *
   * @param csvRow A CSV row separated into an array
   * @returns A Boolean indicating if the CSV row looks like the template
   * instruction file
   */
  isTemplateFile (csvRow: string[]): boolean {
    const joinedRow = csvRow.join('')

    // See @payables/src/models/bulk-add-to-cart/example-templates/template-instructions.js
    // for how the template instructions are organized. This grabs the first
    // instruction line for both English and Spanish template instructions.
    for (const locale in templateInstructions) {
      const instruction = templateInstructions[locale][0][0]
      if (joinedRow.includes(instruction)) {
        return true
      }
    }

    return false
  }

  /**
   * Returns the example template combined with template instructions as a
   * stringified CSV file. The output is the result of Papa Parse's "unparse"
   * method.
   *
   * @param locale The i18n localization code of the instructions to get
   * @returns A string representation of a CSV file
   */
  getExampleTemplate (locale = 'en'): string {
    const exampleTemplate = this.exampleTemplate
    const instructions = templateInstructions[locale]

    const templateFile = mergeCSVFiles(exampleTemplate, instructions)

    return stringsToCSV(templateFile)
  }

  /**
   * Getter that returns if the file format has "pay online" as its sole option.
   */
  get isPayOnlineOnly (): boolean {
    return this.paymentOptions.length === 1 &&
      this.paymentOptions[0].type === PaymentOptionType.Online
  }

  /**
   * Getter that returns if the file format is able to "pay online". This
   * differs slightly from `isPayOnlineOnly` in that it doesn't need to be the
   * only payment option.
   */
  get canPayOnline (): boolean {
    return this.paymentOptions.some(
      option => option.type === PaymentOptionType.Online,
    )
  }

  /**
   * Allows further processing of bulk search results for extra validation. The
   * default action of the base file format class is to simply return the given
   * display items back to the caller.
   *
   * @returns A map of validated results, where all the given results are
   * successes with no failures.
   */
  validateSearchResults (searchResults: UploadResult[]): ValidatedUploadResults {
    return {
      successes: searchResults,
      failures: [],
    }
  }
}
