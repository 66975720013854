export default [
  {
    value: 'forms',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties with our online forms. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas con nuestros formularios en línea. Si tiene algún problema, vuelva a intentarlo más tarde. Estamos trabajando activamente para restaurar el servicio. Nos disculpamos por las molestias.',
        },
      },
    ],
  },
  {
    value: 'eWallet',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas. Si tiene algún problema, por favor inténtelo otra vez más tarde. Estamos trabajando activamente para restablecer el servicio. Nos disculpamos por el inconveniente.',
        },
      },
    ],
  },
  {
    value: 'help',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'This site\'s "Contact Us" feature is temporarily unavailable. We are working to resolve the issue and sincerely apologize for the inconvenience.',
          es: 'La función \'Contactenos\' del sitio no está disponible temporalmente. Estamos trabajando para resolver el problema y nos disculpamos sinceramente por las molestias.',
        },
      },
    ],
  },
  {
    value: 'login',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently resolving a temporary issue that prevents the creation of new accounts. Existing users can still log in. New users can continue as a guest. We sincerely apologize for the inconvenience!',
          es: 'Actualmente estamos resolviendo un problema temporal que impide la creación de nuevas cuentas. Los usuarios existentes aún pueden iniciar sesión. Los nuevos usuarios pueden continuar como invitados. Nos disculpamos sinceramente el inconveniente!',
        },
      },
    ],
  },
  {
    value: 'messaging',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'Receipts are temporarily delayed, so you may not receive one immediately. We sincerely apologize for the inconvenience.',
          es: 'Los recibos se retrasan temporalmente, por lo que es posible que no reciba uno de inmediato. Nos disculpamos sinceramente por las molestias.',
        },
      },
    ],
  },
  {
    value: 'sms',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'Text message receipts are temporarily delayed, so you may not receive one immediately. We sincerely apologize for the inconvenience.',
          es: 'Los recibos de texto se retrasan temporalmente, por lo que es posible que no reciba uno de inmediato. Nos disculpamos sinceramente por las molestias.',
        },
      },
    ],
  },
  {
    value: 'email',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'Email receipts are temporarily delayed, so you may not receive one immediately. We sincerely apologize for the inconvenience.',
          es: 'Los recibos de correo electrónico se retrasan temporalmente, por lo que es posible que no reciba uno de inmediato. Nos disculpamos sinceramente por las molestias.',
        },
      },
    ],
  },
  {
    value: 'rendering',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties with downloading documents. if you have a problem, please try again later. We apologize for the inconvenience',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas con la descarga de documentos. Si tiene un problema, por favor, intente de nuevo más tarde. Pedimos disculpas por la molestia.',
        },
      },
    ],
  },
  {
    value: 'pdfRendering',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties with downloading documents. if you have a problem, please try again later. We apologize for the inconvenience',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas con la descarga de documentos. Si tiene un problema, por favor, intente de nuevo más tarde. Pedimos disculpas por la molestia.',
        },
      },
    ],
  },
  {
    value: 'myPayments',
    severity: [
      {
        level: 'Minor',
        bannerText: {
          en: 'Records of completed payments currently cannot be viewed through this site while we resolve a temporary issue. We sincerely apologize for the inconvenience.',
          es: 'Los registros de pagos completados actualmente no se pueden ver a través de este sitio mientras resolvemos un problema temporal. Nos disculpamos sinceramente por las molestias.',
        },
      },
      {
        level: 'Major',
        bannerText: {
          en: 'You may temporarily see incorrect information displayed about your past payments as we resolve a temporary issue. We sincerely apologize for the inconvenience.',
          es: 'Es posible que vea temporalmente información incorrecta sobre sus pagos anteriores mientras resolvemos un problema pasajero. Nos disculpamos sinceramente por las molestias.',
        },
      },
    ],
  },
  {
    value: 'mySavedItems',
    severity: [
      {
        level: 'Minor',
        bannerText: {
          en: 'You may temporarily see incorrect information about items you\'ve saved for quick access as we resolve a technical issue. We sincerely apologize for the inconvenience.',
          es: 'Es posible que vea temporalmente información incorrecta sobre los articulos que ha guardado para acceder rápidamente a ellos mientras resolvemos un problema técnico. Nos disculpamos sinceramente por las molestias.',
        },
      },
      {
        level: 'Moderate',
        bannerText: {
          en: 'You may temporarily see incorrect information about items you\'ve saved for quick access as we resolve a technical issue. We sincerely apologize for the inconvenience.',
          es: 'Es posible que vea temporalmente información incorrecta sobre los articulos que ha guardado para acceder rápidamente a ellos mientras resolvemos un problema técnico. Nos disculpamos sinceramente por las molestias.',
        },
      },
    ],
  },
  {
    value: 'payables',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties processing payments. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas para procesar pagos. Si tiene algún problema, vuelva a intentarlo más tarde. Estamos trabajando activamente para restaurar el servicio. Nos disculpamos por cualquier inconveniente.',
        },
      },
    ],
  },
  {
    text: 'Payables Storage',
    value: 'payablesStorage',
    severity: [
      {
        level: 'Minor',
        bannerText: {
          en: 'We are currently experiencing some technical issues that may cause the amount due on your item to display incorrectly. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunos problemas técnicos que pueden hacer que el monto adeudado por su artículo no se muestre correctamente. Si tiene algún problema, vuelva a intentarlo más tarde. Estamos trabajando activamente para restaurar el servicio. Pedimos disculpas por las molestias.',
        },
      },
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas. Si tiene algún problema, vuelva a intentarlo más tarde. Estamos trabajando activamente para restaurar el servicio. Nos disculpamos por cualquier inconveniente.',
        },
      },
    ],
  },
  {
    value: 'schedPay',
    severity: [
      {
        level: 'Moderate',
        bannerText: {
          en: 'You may be temporarily unable to create new scheduled payments or modify your existing automatic payments while we resolve a technical issue. If you have a problem, please complete a real-time payment or try again later. We sincerely apologize for the inconvenience.',
          es: 'Es posible que temporalmente no pueda crear nuevos pagos programados o modificar sus pagos automáticos existentes mientras resolvemos un problema técnico. Si tiene algún problema, por favor, complete un pago en tiempo real o vuelva a intentarlo más tarde. Nos disculpamos sinceramente por las molestias.',
        },
      },
    ],
  },
  {
    value: 'cart',
    severity: [
      {
        level: 'Minor',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties with the checkout process. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas con el proceso de pago. Si tiene algún problema, vuelva a intentarlo más tarde. Estamos trabajando activamente para restaurar el servicio. Nos disculpamos por cualquier inconveniente.',
        },
      },
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties with the checkout process. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas con el proceso de pago. Si tiene algún problema, vuelva a intentarlo más tarde. Estamos trabajando activamente para restaurar el servicio. Pedimos disculpas por la molestias que esto pueda haber casuado.',
        },
      },
    ],
  },
  {
    text: 'Cart Redirect',
    value: 'cartRedirect',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties processing payments. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas para procesar pagos. Si tiene algún problema, vuelva a intentarlo más tarde. Estamos trabajando activamente para restaurar el servicio. Nos disculpamos por cualquier inconveniente.',
        },
      },
    ],
  },
  {
    value: 'search',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas. Si tiene algún problema, vuelva a intentarlo más tarde. Estamos trabajando activamente para restaurar el servicio. Nos disculpamos por cualquier inconveniente.',
        },
      },
    ],
  },
  {
    value: 'delivery',
    severity: [
      {
        level: 'Minor',
        bannerText: {
          en: 'Saved delivery addresses are temporarily unavailable while we resolve an issue. You can still complete your payment by typing in your delivery address. We sincerely apologize for the inconvenience.',
          es: 'Las direcciones de entrega guardadas no están disponibles temporalmente mientras resolvemos un problema. Aún puede completar su pago escribiendo su dirección de entrega. Nos disculpamos sinceramente por las molestias.',
        },
      },
      {
        level: 'Moderate',
        bannerText: {
          en: 'Saved delivery addresses are temporarily unavailable while we resolve an issue. You can still complete your payment by typing in your delivery address. We sincerely apologize for the inconvenience.',
          es: 'Las direcciones de entrega guardadas no están disponibles temporalmente mientras resolvemos un problema. Aún puede completar su pago escribiendo su dirección de entrega. Nos disculpamos sinceramente por las molestias.',
        },
      },
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing a technical issue with delivery addresses. If your item must be delivered, please return later to make your payment. We are working to resolve the issue and sincerely apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas con las direcciones de entrega. Si su artículo debe ser entregado, regrese más tarde para realizar el pago. Estamos trabajando para resolver el problema y nos disculpamos sinceramente por las molestias.',
        },
      },
    ],
  },
  {
    value: 'siteSettings',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties. If you are unable to complete your transaction, please try again later.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas. Si no puede completar su transacción, vuelva a intentarlo más tarde.',
        },
      },
    ],
  },
  {
    value: 'eBilling',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'You may temporarily be unable to sign up for e-billing or unsubscribe from an existing e-billing subscription while we resolve a technical issue. If you have a problem, please try again later. We sincerely apologize for the inconvenience.',
          es: 'Es posible que no pueda inscribirse temporalmente para la facturación electrónica o cancelar la suscripción a una suscripción de facturación electrónica existente. Si tiene algún problema, vuelva a intentarlo más tarde. Pedimos disculpas por el inconveniente.',
        },
      },
    ],
  },
  {
    value: 'userVerification',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'You may temporarily be unable to take actions on your property account which require additional verification while we resolve a technical issue.',
          es: 'Es posible que no pueda realizar acciones en su cuenta de propiedad temporalmente, lo cual requiere verificación adicional mientras resolvemos un problema técnico.',
        },
      },
    ],
  },
  {
    value: 'chatBot',
    severity: [
      {
        level: 'Moderate',
        bannerText: {
          en: 'We are experiencing some technical difficulties with our chat widget. You may not be able to use the chat widget or receive real-time answers to questions. We are actively working to restore the service. We apologize for the inconvenience.',
          es: 'Estamos experimentando algunas dificultades técnicas con nuestro chat. Es posible que no pueda usar el chat o recibir respuestas en tiempo real a las preguntas. Estamos trabajando activamente para restaurar el servicio. Pedimos disculpas por las molestias.',
        },
      },
      {
        level: 'Major',
        bannerText: {
          en: 'We are experiencing some technical difficulties with our chat widget. You may not be able to use the chat widget or receive real-time answers to questions. We are actively working to restore the service. We apologize for the inconvenience.',
          es: 'Estamos experimentando algunas dificultades técnicas con nuestro chat. Es posible que no pueda usar el chat o recibir respuestas en tiempo real a las preguntas. Estamos trabajando activamente para restaurar el servicio. Pedimos disculpas por las molestias.',
        },
      },
    ],
  },
  {
    value: 'renewexpress',
    severity: [
      {
        level: 'Moderate',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties processing vehicle registration payments. If you are unable to complete your transaction, please try again later.',
          es: 'Estamos experimentando algunos dificultades técnicas actualmente al procesar pagos de registro de vehículos. Si no puede completar su transacción, por favor intente nuevamente más tarde.',
        },
      },
    ],
  },
  {
    value: 'taxsys',
    severity: [
      {
        level: 'Moderate',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
          es: 'Actualmente estamos experimentando algunas dificultades técnicas. Si tiene algún problema, por favor inténtelo otra vez más tarde. Estamos trabajando activamente para restablecer el servicio. Nos disculpamos por el inconveniente.',
        },
      },
    ],
  },
  {
    value: 'uploads',
    severity: [
      {
        level: 'Major',
        bannerText: {
          en: 'We are currently experiencing some technical difficulties with uploading documents. If you have a problem, please try again later. We apologize for the inconvenience.',
          es: 'Nos encontramos experimentando algunas dificultades técnicas con el envío de documentos. Si tiene algún problema, por favor inténtelo de nuevo más tarde. Nos disculpamos por el inconveniente.',
        },
      },
    ],
  },
]
