<template>
  <!--
    This '.invalid-tooltip' has to be a direct sibling of a b-form-input. The
    '.invalid-tooltip' is hidden by default, but it gets displayed via this
    selector once the input gets the '.is-invalid' class:
      .form-control.is-invalid ~ .invalid-tooltip
    This can also use:
      .custom-conrol-input
      .form-check-input
      .custom-select
      .custom-file-input
      .validation-errors-target // This is custom
    And will work with either modifier:
      :invalid
      .is-invalid
    Search and see if for yourself:
    [,|/s](\.?(\w+-?\w+)+)[.|:].{0,3}invalid~.invalid\-tooltip
  -->
  <div
    ref="validationErrors"
    class="invalid-tooltip"
    aria-live="assertive"
    tabindex="0"
  >
    <div v-if="typeof errors === 'string'">{{ errors }}</div>
    <template v-else>
      <div
        v-for="(errorMessage, validation) in failingErrors"
        :key="validation"
      >
        {{ errorMessage }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: [Object, String],
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },

  computed: {
    // Returns only the errors for validators that are currently failing.
    failingErrors () {
      if (typeof this.errors === 'string') return ''

      const ret = {}
      for (const [validation, msg] of Object.entries(this.errors)) {
        if (this.validator[validation] === false || this.validator[validation]?.$invalid) {
          ret[validation] = msg
        }
      }
      return ret
    },
  },
}
</script>
