import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  // govhub-ui-prod & -nonprod
  dsns: {
    prod: 'https://10c054b10b974c81b73423a0d835e640@o168195.ingest.sentry.io/1432778',
    nonprod: 'https://0b277d98b53c4c4ab0748b2ede995898@o168195.ingest.sentry.io/5827657',
  },

  // Only send 50% of govhub-ui events to Sentry, to protect our Sentry quotas.
  // At the time of implementation (2023-11-01), this should take the rate down
  // from 600/hr to 300/hr.
  clientOptions: {
    sampleRate: 0.5,
  },
})
