import { firstToUpperCase } from '@grantstreet/psc-js/utils/cases.js'

// Client sites is expectd to be in this format.
// Where each client with at lest one selected site has only the their
// selected sites listed.
// clientSites: {
//   Volusia: [
//     'Water Redirect',
//     'Water Search'
//   ],
// }

/**
 * Constructs a new banner.
 * @class Banner
 */
// NOTE!
// Until PSC-17127 is complete, Direct Charge Widget relies directly on this!!
// Be extremely careful making changes.
export default class Banner {
  /**
   * @constructor
   * @param  {object} params  A parameters object.
   * @return {object}         A new instance of class Banner.
   */
  constructor ({
    id,
    clientSites = {},
    text,
    methods = [],
    modules = [],
    type,
    friendlyType,
    active = false,
    locale = 'en',
    clientsText = '',
    sitesText = '',
    createdAt,
  }) {
    Object.assign(this, {
      id,
      clientSites,
      locale,
      active,
      type,
      friendlyType,
      methods,
      modules,
      text,
      clientsText,
      sitesText,
      createdAt,
    })
  }
}

// This factory returns a getter function that will return the localized version
// of a given prop.
const getLocalizedGetter = (prop) => function () {
  return this[prop][this.locale] || this[prop].en
}

// Set localization getters for several properties
// banner.localizedText // 'I am localized text'
// banner.locale = 'es'
// banner.localizedText // 'I'm text in Espanol but you can't tell because the
//                          author of this comment doesn't speak Spanish.'
for (const prop of ['text']) {
  const localizedProp = 'localized' + firstToUpperCase(prop)
  Object.defineProperty(Banner.prototype, localizedProp, {
    get: getLocalizedGetter(prop),
  })
}
