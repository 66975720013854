import { attach as attachAnnouncements } from '@grantstreet/announcements-vue/src/main.js'
import GovHubSearch from '@grantstreet/govhub-search/src/main.js'
import IframeApi from '@grantstreet/taxsys-iframe-api'
import { attach as attachForms } from '@grantstreet/forms-public-legacy'
import { attach as attachMsiIframeStar } from '@grantstreet/msi-iframe-star/src/main.js'
import { attach as attachPaymentOptionsIframeButton } from '@grantstreet/payment-options-iframe-button/src/main.js'
import { attach as attachEBillingIframeComponents } from '@grantstreet/e-billing-iframe/src/main.js'
import { attach as attachUserVerificationIframeComponents } from '@grantstreet/user-verification-iframe/src/main.js'
import './public.scss'

let initialAnnouncementsPromise

// This block prevents us from both banners and notifications attachment
// racing to initialize the announcement + config stores
// without requiring the widgets client know to use .then()
const loadAnnouncementsOnce = (toAttach) => {
  if (!initialAnnouncementsPromise) {
    initialAnnouncementsPromise = toAttach()
  }
  else {
    initialAnnouncementsPromise.then(toAttach)
  }
}

export default {
  IframeApi,
  algoliaSearch: {
    attach: (selector, opts = {}) => {
      GovHubSearch.attach(selector, opts)
    },
  },
  forms: {
    attach: (selector, opts = {}) => {
      attachForms(selector, opts)
    },
  },
  announcements: {
    banners: { attach: (selector, opts = {}) =>
      loadAnnouncementsOnce(() => {
        return attachAnnouncements(selector, { ...opts, component: 'Banners' })
      }) },
    notifications: { attach: (selector, opts = {}) =>
      loadAnnouncementsOnce(() => {
        return attachAnnouncements(selector, { ...opts, component: 'NotificationPopper' })
      }) },
    footers: {
      attach: (selector, secondaryElSelector, opts = {}) => {
        if (!opts) {
          opts = secondaryElSelector
          secondaryElSelector = null
        }

        return loadAnnouncementsOnce(() => {
          return attachAnnouncements(selector, {
            ...opts,
            component: 'FooterWidget',
            secondaryComponent: 'FooterTitle',
            secondaryElSelector,
          })
        })
      },
    },
  },
  mySavedItemsIframeStar: {
    attach: (selector, opts = {}) => {
      attachMsiIframeStar(selector, opts)
    },
  },
  paymentOptionsIframeButton: {
    attach: (selector, opts = {}) => {
      attachPaymentOptionsIframeButton(selector, opts)
    },
  },
  eBillingIframeComponents: {
    attach: (linkSelector, subscriptionSelector, opts = {}) => {
      attachEBillingIframeComponents(linkSelector, subscriptionSelector, opts)
    },
  },
  userVerificationIframeComponents: {
    attach: (linkSelector, verifySelector, statusSelector, opts = {}) => {
      attachUserVerificationIframeComponents(linkSelector, verifySelector, statusSelector, opts)
    },
  },
}
