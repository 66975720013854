/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'mail': {
    width: 16,
    height: 16,
    viewBox: '1844.5 1173.5 23 17',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1867 1174v14a2.006 2.006 0 01-2 2h-18a2.006 2.006 0 01-2-2v-14"/><path pid="1" d="M1867 1174l-9.824 8.145a2 2 0 01-2.353 0L1845 1174zM1845 1174h22"/></g>'
  }
})
