import Vue from 'vue'
import Vuex from 'vuex'
import algoliaAdmin from 'algoliasearch'

Vue.use(Vuex)

const defaults = {
  activeQueries: false,
  adminKey: null,
  alwaysShowMainAddress: false,
  apiKey: null,
  appId: null,
  category: '',
  categoryDescription: '',
  confidential: null,
  confidentialPage: null,
  helpText: '',
  hint: null,
  hitCount: -1,
  index: null,
  indexes: [],
  lastRoute: { fullPath: window.location.href },
  minCharacters: 1,
  noResults: '',
  parameters: {},
  perPage: 15,
  placeholder: '',
  redirect: null,
  searchMatch: null,
  sendEvents: true,
  suggestionsVisible: false,
  title: 'Search',

  highlight: {
    'ais-Highlight-highlighted': 'search-key',
  },
}

const getters = {
  query: state => {
    return state.parameters.search_query || ''
  },

  showResults: state => {
    // Always show any initial help text.
    if (state.parameters.search_query === '' && state.helpText !== '') {
      return true
    }

    // Show results when autocomplete is not focused, but also wait until the
    // most recent search completes so the results are accurate.
    if (!state.suggestionsVisible && !state.activeQueries) {
      return true
    }

    // Always show help text when there are no matches.
    if (state.hitCount === 0) {
      return true
    }

    return false
  },
}

const actions = {
  async listIndexes (context) {
    const state = context.state

    if (state.adminKey) {
      context.commit(
        'updateIndexes',
        await algoliaAdmin(state.appId, state.adminKey).listIndices(),
      )
    }
  },
}

const mutations = {
  hideSuggestions (state) {
    state.suggestionsVisible = false
  },

  saveLastRoute (state, route) {
    state.lastRoute = route
  },

  searchResults (state, val) {
    if (val.complete && state.activeQueries) {
      state.activeQueries = false
    }

    state.hitCount = val.hitCount
  },

  searching (state) {
    state.activeQueries = true
  },

  setConfidentialPage (state, html) {
    state.confidentialPage = html
  },

  setIndex (state, index) {
    state.activeQueries = false
    state.hitCount = -1
    state.index = index
    state.parameters = {}
  },

  setLastRoute (state, route) {
    state.lastRoute = route
  },

  setParameters (state, parameters) {
    if (typeof parameters === 'string') {
      // eslint-disable-next-line camelcase
      parameters = { search_query: parameters }
    }

    state.parameters = parameters
  },

  setRedirect (state, url) {
    state.redirect = url
  },

  setSearchMatch (state, match) {
    state.searchMatch = match
  },

  showSuggestions (state) {
    state.suggestionsVisible = true
  },

  toggleMainAddress (state, enabled) {
    state.alwaysShowMainAddress = enabled
  },

  updateIndexes (state, indexes) {
    state.indexes = indexes.items.map(index => index.name)
  },
}

export default class SearchStore {
  constructor (data) {
    const state = () => ({ ...defaults, ...data })

    return new Vuex.Store({
      modules: {
        GovHubSearch: {
          namespaced: true,
          state,
          getters,
          actions,
          mutations,
        },
      },
    })
  }
}
