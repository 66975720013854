import Vue, { createApp } from 'vue'
import { setEnvironment } from '@grantstreet/psc-environment/environment.js'
import { vueErrorHandler } from './sentry.js'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import { checkRequiredOpts } from '@grantstreet/psc-vue/utils/install-utils.js'
import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'

import App from './components/App.vue'

export const attach = (elSelector, opts) => {
  checkRequiredOpts({
    opts,
    required: [
      'environment',
      'payablePath',
    ],
    moduleName: 'My Saved Items Star',
  })

  const {
    environment,
    payablePath,
    parentWhisperer,
  } = opts

  setEnvironment(environment)

  initBootstrapVue(Vue)

  Vue.use(VueSVGIcon)

  createApp(App, {
    payablePath,
    parentWhisperer,
  })
    .use(vueErrorHandler)
    .mount(elSelector)
}
