<template>
  <div class="footer-title">

    <template
      v-if="loaded && footer"
    >
      {{ footer.title.en }}
    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loaded: false,
    }
  },

  computed: {
    ...mapGetters('Announcements', ['footer']),
  },

  async mounted () {
    try {
      await this.$store.getters['Announcements/loadPromise']
    }
    catch (error) {}
    this.loaded = true
  },

}
</script>
