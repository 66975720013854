<template>
  <b-link
    :id="'show-e-billing-accordion' + _uid"
    :key="'show-e-billing-accordion' + _uid"
    ref="closeStart"
    :aria-label="$t('ebilling.get_bills')"
    aria-controls="ebilling-ui"
    href="#"
    class="d-flex flex-row e-billing-accordion-link"
    data-test="show-e-billing-accordion"
    @click.prevent="$emit('change-e-billing-mode')"
  >
    <div class="icon-link mr-2">
      <!-- Applying a stroke color to this svg via a prop fails to style all of
      the path elements. (Only the last path in the group becomes colored). The
      scss below will override all of the strokes to be blue. -->
      <svgicon
        name="mail"
        :original="true"
        class="mail-icon"
      />
      <svgicon
        v-if="hasSubscription"
        data-test="subscription-check-icon"
        class="icon-overlay check"
        name="progress-bar-checkmark"
        :original="true"
      />
    </div>
    {{ linkText }}
  </b-link>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/progress-bar-checkmark.js'
import '@grantstreet/bootstrap/icons/js/mail.js'

export default {
  emits: ['change-e-billing-mode'],
  props: {
    payableSavePath: {
      type: String,
      required: true,
    },
    hasSubscription: {
      type: Boolean,
      required: true,
    },
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    linkText () {
      // Manage subscription text
      // grep food: ebilling.manage.auth ebilling.manage.anon
      if (this.hasSubscription) {
        return this.$t(`ebilling.manage.${this.isAuthenticated ? 'auth' : 'anon'}`)
      }
      // Create subscription text
      return this.$t('ebilling.get_bills')
    },
  },
}
</script>

<!-- Scope the scss to not affect other components.-->
<style lang="scss" scoped>
  .e-billing-accordion-link {
    // since this scss is scoped, we need to use the deep selector to style the
    // elements contained in the svgicon component
    .mail-icon:deep(path) {
      stroke: currentColor;
    }

    // The icon should darken in color when hovering over the link just like the
    // text darkens.
    &:hover .mail-icon:deep(path) {
      stroke: brightness(85%);
    }
  }
</style>
