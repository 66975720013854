<template>
  <div>
    <h4
      class="mb-0"
      :class="textClasses.displayName"
    >
      {{ payable.displayName }}
    </h4>
    <div
      v-if="payable.description"
      :class="textClasses.description"
      class="preserve-newlines small"
    >{{ payable.description }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    // If this is a child payable without a description, return the css class
    // for a top margin. This margin vertically centers the display name in the
    // child row.
    displayNameTopMargin () {
      if (this.payable?.description || !this.payable?.isChildFlag) return ''
      return 'mt-3'
    },
    textClasses () {
      return {
        displayName: `${this.displayNameTopMargin} text-${this.darkDisplay ? 'dark' : 'body'}`,
        description: this.smallDescription ? 'small' : '',
      }
    },
  },
  props: {
    payable: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: '',
    },
    darkDisplay: {
      type: Boolean,
      default: false,
    },
    smallDescription: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
