<template>
  <component
    v-bind="$attrs"
    :is="vueComponent"
  />
</template>

<script>
import { sentryException } from './sentry.ts'
import { createModuleLoaderMixin } from '@grantstreet/psc-vue/utils/module-loader-mixin.ts'

export default {
  compatConfig: {
    // This is necessary for child-emitted events to propagate via $attrs
    // (https://github.com/vuejs/core/issues/4566#issuecomment-917997056)
    INSTANCE_LISTENERS: false,
  },

  mixins: [
    createModuleLoaderMixin({
      moduleName: 'FormsPublic',
      supportedComponents: {
        'Jotform': () => import('./components/Jotform.vue'),
        'MyForms': () => import('./components/MyForms.vue'),
      },
      exceptionLogger: sentryException,
    }),
  ],
}
</script>
