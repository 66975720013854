export const firstToUpperCase = (string) => string.length ? string[0].toUpperCase() + string.slice(1) : ''

export const firstToLowerCase = (string) => string.length ? string[0].toLowerCase() + string.slice(1) : ''

// Converts some common cases to "no case" (space delimited lowercase)
export const normalizeCase = (string) => {
  // Lookbehinds aren't supported in IE :/
  // Add a space between camelCase type word boundaries
  string = string.replace(/[a-z0-9][A-Z0-9][a-z0-9]/g, (match) => `${match[0]} ${match.substring(1)}`)
  // Swap non space delimiters for spaces
  string = string.replace(/[^a-z0-9\s][a-z0-9]/gi, (match) => ` ${match[1]}`)
  return string.toLowerCase().trim()
}

// Converts most common cases to Title Case
export const titleCase = (string) => normalizeCase(string).replace(/(?:^|[^a-z0-9])[a-z0-9]/gi, (match) => match.toUpperCase())

// There doesn't appear to be any accepted name for this. I think it looks like
// Title Case standing in a puddle on a rainy day.
// Converts most common cases to Puddle_Case
export const puddleCase = (string) => titleCase(string).replace(/\s/g, '_')

// Converts most common cases to kebab-case
export const kebabCase = (string) => normalizeCase(string).replace(/\s/g, '-')

// Converts most common cases to snake_case
export const snakeCase = (string) => normalizeCase(string).replace(/\s/g, '_')

// Converts most common cases to camelCase
export const camelCase = (string) => normalizeCase(string).replace(/[^a-z0-9][a-z0-9]/gi, (match) => match[1].toUpperCase())

// Converts client/site-case cases to Client/Site Case
export const clientSiteTitleCase = (string) => string.split('/').map(titleCase).join('/')

// Converts Client/Site Case to client/site-case
export const clientSiteCase = (string) => string.split('/').map(kebabCase).join('/')
