import { v4 as uuid } from 'uuid'
import cloneDeep from 'lodash/cloneDeep.js'
import { settingValuesAreSubset } from '../utils.ts'
import type { Module, HistoryObject, Setting } from '../types/settings.ts'

/**
 * A class for holding a GovHub site definition.
 * @class
 */
export default class Site {
  id: string
  clientId: string
  title: string
  type: string
  nonProd: Module
  production: Module
  inNonProd: boolean
  inProd: boolean
  lastUpdated: number
  history: HistoryObject[] = []

  /**
   * @constructor
   *
   * @param {Object}  opts            site options
   * @param {String}  opts.id         unique ID (used in URLs)
   * @param {String}  opts.clientId   ID of client site is under
   * @param {String}  opts.title      site title
   * @param {String}  opts.type       site type ('redirect' or 'account-lookup')
   * @param {Object}  opts.nonProd    modules and settings in nonprod
   * @param {Boolean} opts.inNonProd  has this site been published to nonprod?
   */
  constructor ({
    id,
    clientId,
    title,
    type,
    nonProd = {},
    production = {},
    inNonProd = false,
    inProd = false,
    lastUpdated = Date.now(),
  }) {
    this.id = id
    this.clientId = clientId
    this.title = title
    this.type = type
    this.nonProd = nonProd
    this.production = production
    this.inNonProd = inNonProd
    this.inProd = inProd
    this.lastUpdated = lastUpdated
  }

  // Returns the full prod setting object for the passed setting. This merges
  // the data saved to the config service for this setting (just the source and
  // value) with the spec data from the passed moduleList.
  getProductionValue (mod, setting, moduleList) {
    if (
      this.production &&
      this.production[mod] &&
      this.production[mod].settings &&
      this.production[mod].settings[setting] &&
      moduleList &&
      moduleList[mod].settings &&
      moduleList[mod].settings[setting]
    ) {
      return {
        ...moduleList[mod].settings[setting],
        ...this.production[mod].settings[setting],
      }
    }
    return null
  }

  // Used when user clicks "Convert to client level config" to have the site
  // inherit the client value for this setting
  convertToClientInherit (mod, setting) {
    // Remove from site-specific settings to prevent overwriting client level
    if (this[mod].settings[setting]) {
      delete this[mod].settings[setting]
    }
  }

  // Checks if a module is enabled in Non Prod
  modEnabledNonProd (modName) {
    return this.nonProd &&
      this.nonProd[modName] &&
      this.nonProd[modName].settings &&
      this.nonProd[modName].settings.meta &&
      this.nonProd[modName].settings.meta.enabled
  }

  // Checks if a module is enabled in prod
  modEnabledProd (modName) {
    return this.production &&
      this.production[modName] &&
      this.production[modName].settings &&
      this.production[modName].settings.meta &&
      this.production[modName].settings.meta.enabled
  }

  // This is essentially the noLocalChanges/settingsInProd computed
  // properties but for a single setting rather than an entire list of
  // modules/settings. It returns either "NonProd" if the setting needs to be
  // published to NonProd, "Prod" if it needs publishing to Prod, or "" if
  // neither.
  settingPublishStatus (mod, setting, configCompare) {
    // First, check to see if we are even allowed to publish indivudal settings.
    // To do so, we must be at least in NonProd. Also we cannot publish meta
    // settings.
    if (!this.inNonProd || !this.modEnabledNonProd(mod) || setting === 'meta') {
      return ''
    }

    const nonProdConfig = this.nonProd[mod] && this.nonProd[mod].settings[setting]
    if (
      !nonProdConfig ||
      !settingValuesAreSubset({
        type: configCompare.type,
        subset: configCompare.value,
        superset: nonProdConfig.value,
      })
    ) {
      return 'NonProd'
    }

    // This site must be in production to publish individual settings to
    // production
    if (!this.inProd || !this.modEnabledProd(mod)) {
      return ''
    }

    const prodConfig = this.production[mod] && this.production[mod].settings[setting]
    if (
      !prodConfig ||
      !settingValuesAreSubset({
        type: configCompare.type,
        subset: prodConfig.value,
        superset: nonProdConfig.value,
      })
    ) {
      return 'Prod'
    }

    return ''
  }

  addHistoryEntry (module: string, setting: string, config: Setting) {
    // Keep track of prod history
    if (setting !== 'meta') {
      const historyObj: HistoryObject = {
        id: uuid(),
        author: 'jSnow', // TODO we don't have authors yet
        date: (new Date()).toLocaleString('en-us', {
          timeZone: 'UTC',
          hour12: false,
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          year: '2-digit',
        }),
        mod: module,
        setting: typeof config.name === 'string' ? config.name : '',
        old: cloneDeep(this.production[module].settings[setting]),
        new: config,
      }

      this.history.unshift(historyObj)
    }
  }
}
