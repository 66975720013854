// You can emit events:
//
//    EventBus.$emit('eventName', payload)
//
// Or register handlers:
//
//    EventBus.$on('eventName', data => { ... })
//
// The list of global events that can be emitted/accepted is located at
// @grantstreet/govhub-vue/README.md
// TODO: Convert to typescript and move that list of events in here with types

import mitt from 'mitt'
const emitter = mitt()

export const EventBus = {
  $on: (...args) => emitter.on(...args),
  // polyfill for once. See: https://github.com/developit/mitt/issues/136
  $once: (type, handler) => {
    const wrappedHandler = (...args) => {
      emitter.off(type, wrappedHandler)
      handler(...args)
    }

    emitter.on(type, wrappedHandler)

    // Return wrapped handler so it is possible to remove with $off
    return wrappedHandler
  },
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
}
export default EventBus
