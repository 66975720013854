import GenericApiClient from '@grantstreet/api-client'
import { encodeURI } from 'js-base64'
import { baseGovHubUrl } from '@grantstreet/psc-environment'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = () => process.env?.GSG_EBILLING_SERVICE || `${baseGovHubUrl}/svc/ebilling`
  }

  /**
   * Gets all active subscriptions for the current user.
   */
  getSubscriptions () {
    return this.get('/v1/subscriptions')
  }

  /**
   * Creates a subscription. If the current user is anonymous, the name and
   * email arguments are required. For authenticated users, these values are
   * provided via the login service.
   */
  addSubscription ({
    accountPayableSavePath,
    client,
    site,
    email,
    subscriberName,
    language,
    paperless,
  }) {
    return this.post('/v1/subscriptions', {
      accountPayableSavePath,
      client,
      site,
      email,
      subscriberName,
      language,
      paperless,
    })
  }

  /**
   * Modifies a subscription. This is usually just toggling paperless.
   * This is only for authenticated users.
   */
  modifySubscription ({
    paperless,
    subscriptionId,
  }) {
    return this.patch(`/v1/subscriptions/${subscriptionId}`, {
      paperless,
    })
  }

  /**
   * Deletes a subscription.
   *
   * For authenticated users, this requires:
   *  - accountPayableSavePath
   *  - email
   *
   * For anonymous users, this requires:
   *  - unsubscribeCode
   */
  deleteSubscription ({
    accountPayableSavePath,
    email,
    unsubscribeCode,
    subscriptionId,
  }) {
    // Authenticated unsubscribe
    if (accountPayableSavePath && email) {
      return this.delete(`/v1/subscriptions/${encodeURI(accountPayableSavePath)}/${encodeURI(email)}/unsubscribe`)
    }

    // Anonymous unsubscribe
    if (unsubscribeCode) {
      // unsubscribeCode is 4 digits and does not need encoding
      return this.delete(`/v1/subscriptions/${subscriptionId}/unsubscribe/${unsubscribeCode}`)
    }
  }

  /**
   * Requests ebilling email the user an unsubscribe code for the provided
   * subscription. @see {deleteSubscription} for using this unsubscribe code.
   */
  requestUnsubscribeCode ({
    subscriptionId,
  }) {
    return this.post(`/v1/subscriptions/${subscriptionId}/sendUnsubscribeCode`)
  }

  /**
   * Activates a subscription when provided a valid unexpired authKey. The
   * provided authkey should be an already base64url encoded string.
   */
  activateSubscription ({
    subscriptionId,
    authCode,
  }) {
    return this.post(`/v1/subscriptions/${subscriptionId}/activate/${authCode}`)
  }

  prepNonprodTestSubscription ({
    language,
    email,
    accountPayableSavePath,
    paperless,
  }) {
    return this.post('/v1/prepNonprodTests', {
      language,
      email,
      accountPayableSavePath,
      paperless,
    })
  }
}
