export default [
  [ 'Year', 'Property Type', 'Parcel Number (Same as account #)' ],
  [ '2022', 'Real Estate', '01-0101-040-1020' ],
  [ '2022', 'Real Estate', '01-0101-040-1080' ],
  [ '2022', 'Real Estate', '01-0102-030-1075' ],
  [ '2022', 'Real Estate', '01-0102-030-1080' ],
  [ '2022', 'Real Estate', '01-0102-030-1090' ],
  [ '2022', 'Real Estate', '01-0102-030-1100' ],
  [ '2022', 'Real Estate', '01-0102-080-1011' ],
  [ '2022', 'Real Estate', '01-0102-080-1060' ],
  [ '2022', 'Real Estate', '01-0103-050-1140' ],
  [ '2022', 'Real Estate', '01-0103-050-1150' ],
  [ '2022', 'Real Estate', '01-0104-070-1050' ],
  [ '2022', 'Real Estate', '01-0104-070-1060' ],
  [ '2022', 'Real Estate', '01-0104-070-1110' ],
  [ '2022', 'Real Estate', '01-0104-070-1120' ],
  [ '2022', 'Real Estate', '01-0104-090-1030' ],
  [ '2022', 'Real Estate', '01-0104-090-1050' ],
  [ '2022', 'Real Estate', '01-0105-070-1060' ],
  [ '2022', 'Real Estate', '01-0106-040-1020' ],
  [ '2022', 'Real Estate', '01-0107-000-1060' ],
  [ '2022', 'Real Estate', '01-0107-030-1060' ],
  [ '2022', 'Real Estate', '01-0107-030-1070' ],
  [ '2022', 'Real Estate', '01-0107-030-1080' ],
  [ '2022', 'Real Estate', '01-0107-030-1090' ],
  [ '2022', 'Real Estate', '01-0109-000-1250' ],
  [ '2022', 'Real Estate', '01-0109-000-1260' ],
  [ '2022', 'Real Estate', '01-0110-050-1030' ],
  [ '2022', 'Real Estate', '01-0110-050-1080' ],
  [ '2022', 'Real Estate', '01-0110-050-1140' ],
  [ '2022', 'Real Estate', '01-0110-072-0030' ],
  [ '2022', 'Real Estate', '01-0110-072-0040' ],
  [ '2022', 'Real Estate', '01-0110-072-0080' ],
  [ '2022', 'Real Estate', '01-0111-050-1010' ],
  [ '2022', 'Real Estate', '01-0111-050-1020' ],
  [ '2022', 'Real Estate', '01-0111-060-1010' ],
  [ '2022', 'Real Estate', '01-0111-060-1020' ],
  [ '2022', 'Real Estate', '01-0112-010-1010' ],
  [ '2022', 'Real Estate', '01-0200-040-1130' ],
  [ '2022', 'Real Estate', '01-0200-050-1010' ],
  [ '2022', 'Real Estate', '01-0200-050-1040' ],
  [ '2022', 'Real Estate', '01-0200-050-1060' ],
  [ '2022', 'Real Estate', '01-0200-050-1070' ],
  [ '2022', 'Real Estate', '01-0200-050-1080' ],
  [ '2022', 'Real Estate', '01-0200-070-1130' ],
  [ '2022', 'Real Estate', '01-0201-020-1040' ],
  [ '2022', 'Real Estate', '01-0201-020-1050' ],
  [ '2022', 'Real Estate', '01-0201-090-1210' ],
  [ '2022', 'Real Estate', '01-0202-000-1050' ],
  [ '2022', 'Real Estate', '01-0202-010-1200' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0603-60-R0040' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0604-31-R0040' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0604-31-R040S' ],
  [ '2021', 'Real Estate', 'IL-0232-320-0600-50-R0040' ],
  [ '2021', 'Real Estate', 'IL-0232-320-0600-50-R040S' ],
  [ '2021', 'Real Estate', '30-6030-001-1024' ],
  [ '2021', 'Real Estate', '35-3008-028-0850' ],
  [ '2021', 'Real Estate', '35-3008-028-0870' ],
  [ '2021', 'Real Estate', '35-3008-028-0930' ],
  [ '2021', 'Real Estate', '35-3008-028-1060' ],
  [ '2021', 'Real Estate', '35-3022-047-0360' ],
  [ '2021', 'Real Estate', '35-3022-047-0370' ],
  [ '2021', 'Real Estate', '35-3022-047-0480' ],
  [ '2021', 'Real Estate', '35-3022-047-0490' ],
  [ '2021', 'Real Estate', '35-3022-047-0570' ],
  [ '2020', 'Real Estate', '22-2032-004-0061' ],
  [ '2020', 'Real Estate', '30-4918-001-0710' ],
  [ '2020', 'Real Estate', '30-6030-001-0480' ],
  [ '2018', 'Real Estate', '30-7906-000-0480' ],
  [ '2017', 'Real Estate', '30-6906-000-0041' ],
  [ '2017', 'Real Estate', '30-6906-000-0187' ],
  [ '2017', 'Real Estate', '30-6912-002-0115' ],
  [ '2016', 'Real Estate', '30-6906-000-0139' ],
  [ '2016', 'Real Estate', '30-6906-000-0145' ],
  [ '2016', 'Real Estate', '30-6906-000-0147' ],
  [ '2014', 'Real Estate', '34-2108-004-0122' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0603-60-R0040' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0604-31-R0040' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0604-31-R040S' ],
  [ '2021', 'Real Estate', 'IL-0232-320-0600-50-R0040' ],
  [ '2021', 'Real Estate', 'IL-0232-320-0600-50-R040S' ],
  [ '2022', 'Real Estate', '01-0101-040-1020' ],
  [ '2022', 'Real Estate', '01-0101-040-1080' ],
  [ '2022', 'Real Estate', '01-0102-030-1075' ],
  [ '2022', 'Real Estate', '01-0102-030-1080' ],
  [ '2022', 'Real Estate', '01-0102-030-1090' ],
  [ '2022', 'Real Estate', '01-0102-030-1100' ],
  [ '2022', 'Real Estate', '01-0102-080-1011' ],
  [ '2019', 'Real Estate', '01-4138-161-3710' ],
  [ '2018', 'Real Estate', '01-4138-161-3710' ],
  [ '2018', 'Real Estate', '30-3115-005-6990' ],
  [ '2017', 'Real Estate', '30-3115-005-6990' ],
  [ '2016', 'Real Estate', '30-3115-005-6990' ],
  [ '2015', 'Real Estate', '30-3115-005-6990' ],
  [ '2014', 'Real Estate', '30-3115-005-6990' ],
  [ '2013', 'Real Estate', '30-3115-005-6990' ],
  [ '2012', 'Real Estate', '30-3115-005-6990' ],
  [ '2011', 'Real Estate', '30-3115-005-6990' ],
  [ '2009', 'Real Estate', '30-3115-005-6990' ],
  [ '2008', 'Real Estate', '30-3115-005-6990' ],
  [ '2019', 'Real Estate', '30-3052-075-2620' ],
  [ '2018', 'Real Estate', '30-3052-075-2620' ],
  [ '2017', 'Real Estate', '30-3052-075-2620' ],
  [ '2016', 'Real Estate', '30-3052-075-2620' ],
  [ '2015', 'Real Estate', '30-3052-075-0790' ],
  [ '2015', 'Real Estate', '30-3052-075-2620' ],
  [ '2014', 'Real Estate', '30-3052-075-0790' ],
  [ '2014', 'Real Estate', '30-3052-075-1570' ],
  [ '2014', 'Real Estate', '30-3052-075-2620' ],
  [ '2013', 'Real Estate', '30-3052-075-0790' ],
  [ '2013', 'Real Estate', '30-3052-075-1570' ],
  [ '2013', 'Real Estate', '30-3052-075-2620' ],
  [ '2012', 'Real Estate', '30-3052-075-0790' ],
  [ '2012', 'Real Estate', '30-3052-075-1570' ],
  [ '2012', 'Real Estate', '30-3052-075-2620' ],
  [ '2011', 'Real Estate', '30-3052-075-0790' ],
  [ '2011', 'Real Estate', '30-3052-075-1570' ],
  [ '2011', 'Real Estate', '30-3052-075-2620' ],
  [ '2010', 'Real Estate', '30-3052-075-0790' ],
  [ '2010', 'Real Estate', '30-3052-075-1570' ],
  [ '2009', 'Real Estate', '30-3052-075-0790' ],
  [ '2009', 'Real Estate', '30-3052-075-1570' ],
  [ '2008', 'Real Estate', '30-3052-075-0790' ],
  [ '2008', 'Real Estate', '30-3052-075-1570' ],
  [ '2007', 'Real Estate', '30-3052-075-0790' ],
  [ '2007', 'Real Estate', '30-3052-075-1570' ],
  [ '2018', 'Real Estate', '30-7906-000-0480' ],
  [ '2022', 'Real Estate', '01-3113-050-0330' ],
  [ '2022', 'Real Estate', '01-3122-052-2380' ],
  [ '2022', 'Real Estate', '01-3123-021-0040' ],
  [ '2022', 'Real Estate', '01-3123-037-3220' ],
  [ '2022', 'Real Estate', '01-3124-001-1670' ],
  [ '2022', 'Real Estate', '01-3124-016-1040' ],
  [ '2022', 'Real Estate', '01-3125-019-1490' ],
  [ '2022', 'Real Estate', '01-3126-029-0120' ],
  [ '2022', 'Real Estate', '01-3126-029-0190' ],
  [ '2022', 'Real Estate', '01-3127-018-0080' ],
  [ '2022', 'Real Estate', '01-3134-027-0020' ],
  [ '2022', 'Real Estate', '01-3134-027-0031' ],
  [ '2022', 'Real Estate', '01-3135-046-0183' ],
  [ '2022', 'Real Estate', '01-3230-026-0080' ],
  [ '2022', 'Real Estate', '01-3230-028-0020' ],
  [ '2022', 'Real Estate', '01-3230-091-1110' ],
  [ '2022', 'Real Estate', '01-3231-048-0621' ],
  [ '2022', 'Real Estate', '01-4103-097-0890' ],
  [ '2022', 'Real Estate', '01-4121-324-0280' ],
  [ '2022', 'Real Estate', '01-4138-001-2290' ],
  [ '2022', 'Real Estate', '01-4138-008-0395' ],
  [ '2022', 'Real Estate', '01-4138-080-0020' ],
  [ '2022', 'Real Estate', '01-4138-167-2500' ],
  [ '2022', 'Real Estate', '01-3113-056-0020' ],
  [ '2022', 'Real Estate', '01-3114-023-0260' ],
  [ '2022', 'Real Estate', '01-3114-040-0010' ],
  [ '2022', 'Real Estate', '01-3126-036-0540' ],
  [ '2022', 'Real Estate', '01-3230-105-0020' ],
  [ '2022', 'Real Estate', '01-4102-006-4240' ],
  [ '2022', 'Real Estate', '01-4110-054-0130' ],
  [ '2022', 'Real Estate', '01-4139-123-1980' ],
  [ '2022', 'Real Estate', '08-2121-002-0400' ],
  [ '2022', 'Real Estate', '24-5205-047-0580' ],
  [ '2022', 'Real Estate', '30-2124-000-0191' ],
  [ '2022', 'Real Estate', '30-2232-072-1710' ],
  [ '2022', 'Real Estate', '30-4929-027-0730' ],
  [ '2022', 'Real Estate', '30-6913-000-0060' ],
  [ '2022', 'Real Estate', '30-8912-000-0690' ],
  [ '2022', 'Real Estate', '31-2211-065-1830' ],
  [ '2022', 'Real Estate', '35-3007-008-4600' ],
  [ '2022', 'Real Estate', '36-6008-021-0690' ],
  [ '2021', 'Real Estate', '01-0210-050-4180' ],
  [ '2021', 'Real Estate', '01-3113-056-0020' ],
  [ '2022', 'Real Estate', '01-4139-123-6450' ],
  [ '2022', 'Real Estate', '01-4139-123-6470' ],
  [ '2022', 'Real Estate', '01-4139-123-6500' ],
  [ '2022', 'Real Estate', '01-4139-123-6510' ],
  [ '2022', 'Real Estate', '01-4139-123-6530' ],
  [ '2022', 'Real Estate', '01-4139-123-6570' ],
  [ '2022', 'Real Estate', '01-4139-123-6580' ],
  [ '2022', 'Real Estate', '01-4139-123-6600' ],
  [ '2022', 'Real Estate', '01-4139-123-6620' ],
  [ '2022', 'Real Estate', '01-4139-123-6630' ],
  [ '2022', 'Real Estate', '01-4139-123-6640' ],
  [ '2022', 'Real Estate', '01-4139-123-6660' ],
  [ '2022', 'Real Estate', '01-4139-123-6680' ],
  [ '2022', 'Real Estate', '01-4139-123-6750' ],
  [ '2022', 'Real Estate', '01-4139-123-6760' ],
  [ '2022', 'Real Estate', '01-4139-123-6810' ],
  [ '2022', 'Real Estate', '01-4139-123-6850' ],
  [ '2022', 'Real Estate', '01-4139-123-6860' ],
  [ '2022', 'Real Estate', '01-4139-123-6880' ],
  [ '2022', 'Real Estate', '01-4139-123-6920' ],
  [ '2022', 'Real Estate', '01-4139-123-6940' ],
  [ '2022', 'Real Estate', '01-4139-123-6960' ],
  [ '2022', 'Real Estate', '01-4139-123-6980' ],
  [ '2022', 'Real Estate', '01-4139-123-7000' ],
  [ '2022', 'Real Estate', '01-4139-123-7050' ],
  [ '2022', 'Real Estate', '01-4139-123-7080' ],
  [ '2022', 'Real Estate', '01-4139-123-7100' ],
  [ '2022', 'Real Estate', '01-4139-123-7160' ],
  [ '2022', 'Real Estate', '01-4139-123-7170' ],
  [ '2022', 'Real Estate', '01-4139-123-7190' ],
  [ '2022', 'Real Estate', '01-4139-123-7200' ],
  [ '2022', 'Real Estate', '01-4139-123-7230' ],
  [ '2022', 'Real Estate', '01-4139-123-7240' ],
  [ '2022', 'Real Estate', '01-4139-123-7300' ],
  [ '2022', 'Real Estate', '01-4139-123-7640' ],
  [ '2013', 'Real Estate', '30-5923-018-0660' ],
  [ '2012', 'Real Estate', '30-5923-018-0660' ],
  [ '2011', 'Real Estate', '30-5923-018-0660' ],
  [ '2009', 'Real Estate', '30-5923-018-0660' ],
  [ '2008', 'Real Estate', '30-5923-018-0660' ],
  [ '2022', 'Real Estate', '01-3231-048-0108' ],
  [ '2022', 'Real Estate', '01-4121-013-0310' ],
  [ '2022', 'Real Estate', '03-4107-001-0220' ],
  [ '2022', 'Real Estate', '04-2026-012-0150' ],
  [ '2022', 'Real Estate', '04-2028-061-0520' ],
  [ '2022', 'Real Estate', '08-2122-014-0590' ],
  [ '2022', 'Real Estate', '24-4232-014-1150' ],
  [ '2022', 'Real Estate', '30-2205-037-4500' ],
  [ '2022', 'Real Estate', '30-3205-000-0010' ],
  [ '2022', 'Real Estate', '30-4011-019-0381' ],
  [ '2022', 'Real Estate', '30-4916-011-0590' ],
  [ '2022', 'Real Estate', '30-4933-034-0890' ],
  [ '2022', 'Real Estate', '30-5020-010-0010' ],
  [ '2021', 'Real Estate', '01-3231-048-0108' ],
  [ '2021', 'Real Estate', '01-4121-013-0310' ],
  [ '2021', 'Real Estate', '03-5024-012-0210' ],
  [ '2021', 'Real Estate', '04-3105-010-1630' ],
  [ '2021', 'Real Estate', '30-6932-015-0170' ],
  [ '2020', 'Real Estate', '01-4138-147-1000' ],
  [ '2020', 'Real Estate', '02-4210-016-0210' ],
  [ '2020', 'Real Estate', '03-5024-012-0210' ],
  [ '2020', 'Real Estate', '04-3105-010-1630' ],
  [ '2020', 'Real Estate', '30-4909-002-1570' ],
  [ '2020', 'Real Estate', '30-6932-015-0170' ],
  [ '2019', 'Real Estate', '01-3114-038-0250' ],
  [ '2019', 'Real Estate', '01-3231-062-0590' ],
  [ '2019', 'Real Estate', '01-4139-126-4460' ],
  [ '2019', 'Real Estate', '02-3211-064-0960' ],
  [ '2019', 'Real Estate', '02-4203-334-3120' ],
  [ '2019', 'Real Estate', '02-4210-016-0210' ],
  [ '2019', 'Real Estate', '02-4210-018-0170' ],
  [ '2019', 'Real Estate', '03-4132-028-0010' ],
  [ '2019', 'Real Estate', '03-5024-012-0210' ],
  [ '2019', 'Real Estate', '04-3105-010-1630' ],
  [ '2019', 'Real Estate', '13-2227-084-0030' ],
  [ '2019', 'Real Estate', '30-4017-019-0080' ],
  [ '2019', 'Real Estate', '30-4018-016-0040' ],
  [ '2012', 'Real Estate', '01-0205-010-1100' ],
  [ '2012', 'Real Estate', '01-3231-064-1060' ],
  [ '2012', 'Real Estate', '01-4102-005-6830' ],
  [ '2012', 'Real Estate', '04-2131-031-1000' ],
  [ '2012', 'Real Estate', '04-3106-048-0020' ],
  [ '2012', 'Real Estate', '07-2216-000-0321' ],
  [ '2012', 'Real Estate', '30-1234-015-0110' ],
  [ '2012', 'Real Estate', '30-5003-000-1456' ],
  [ '2012', 'Real Estate', '35-3030-025-0010' ],
  [ '2012', 'Real Estate', '01-4110-009-0320' ],
  [ '2012', 'Real Estate', '30-3109-011-0030' ],
  [ '2012', 'Real Estate', '04-2021-001-0330' ],
  [ '2012', 'Real Estate', '28-1235-078-0260' ],
  [ '2012', 'Real Estate', '28-2210-070-1310' ],
  [ '2013', 'Real Estate', '28-1234-001-0340' ],
  [ '2017', 'Real Estate', '02-3223-026-0680' ],
  [ '2017', 'Real Estate', '02-3223-026-0700' ],
  [ '2017', 'Real Estate', '02-3223-026-0780' ],
  [ '2017', 'Real Estate', '02-3223-026-0790' ],
  [ '2017', 'Real Estate', '02-3223-026-0880' ],
  [ '2017', 'Real Estate', '02-3223-026-0960' ],
  [ '2017', 'Real Estate', '02-3223-026-0990' ],
  [ '2017', 'Real Estate', '02-3223-026-1040' ],
  [ '2017', 'Real Estate', '02-3223-026-1090' ],
  [ '2017', 'Real Estate', '02-3223-026-1120' ],
  [ '2017', 'Real Estate', '02-3223-026-1240' ],
  [ '2017', 'Real Estate', '02-3223-026-1340' ],
  [ '2017', 'Real Estate', '02-3223-026-1380' ],
  [ '2017', 'Real Estate', '02-3223-026-1400' ],
  [ '2019', 'Real Estate', '02-3234-218-4010' ],
  [ '2019', 'Real Estate', '02-3234-218-4080' ],
  [ '2019', 'Real Estate', '12-2226-046-0120' ],
  [ '2019', 'Real Estate', '12-2226-046-0220' ],
  [ '2019', 'Real Estate', '12-2226-046-0280' ],
  [ '2019', 'Real Estate', '12-2226-046-0300' ],
  [ '2019', 'Real Estate', '12-2226-046-0330' ],
  [ '2019', 'Real Estate', '12-2226-046-0340' ],
  [ '2019', 'Real Estate', '12-2226-046-0360' ],
  [ '2019', 'Real Estate', '12-2226-046-0400' ],
  [ '2019', 'Real Estate', '12-2226-046-0410' ],
  [ '2019', 'Real Estate', '12-2226-046-0430' ],
  [ '2019', 'Real Estate', '12-2226-046-0450' ],
  [ '2023', 'Tangible', '01-003000' ],
  [ '2023', 'Tangible', '01-004400' ],
  [ '2023', 'Tangible', '01-008100' ],
  [ '2023', 'Tangible', '01-009275' ],
  [ '2023', 'Tangible', '01-009730' ],
  [ '2023', 'Tangible', '01-017775' ],
  [ '2023', 'Tangible', '01-023000' ],
  [ '2023', 'Tangible', '01-026151' ],
  [ '2023', 'Tangible', '01-028800' ],
  [ '2023', 'Tangible', '01-032400' ],
  [ '2023', 'Tangible', '01-034400' ],
  [ '2023', 'Tangible', '01-050000' ],
  [ '2023', 'Tangible', '01-071000' ],
  [ '2023', 'Tangible', '01-071500' ],
  [ '2023', 'Tangible', '01-071900' ],
  [ '2023', 'Tangible', '01-072010' ],
  [ '2023', 'Tangible', '01-072500' ],
  [ '2023', 'Tangible', '01-095510' ],
  [ '2016', 'Tangible', '01-112000' ],
  [ '2017', 'Tangible', '01-112000' ],
  [ '2018', 'Tangible', '01-112000' ],
  [ '2019', 'Tangible', '01-112000' ],
  [ '2020', 'Tangible', '01-112000' ],
  [ '2021', 'Tangible', '01-112000' ],
  [ '2022', 'Tangible', '01-112000' ],
  [ '2023', 'Tangible', '01-112000' ],
  [ '2023', 'Tangible', '01-116000' ],
  [ '2023', 'Tangible', '01-116800' ],
  [ '2023', 'Tangible', '01-127800' ],
  [ '2023', 'Tangible', '01-129750' ],
  [ '2023', 'Tangible', '01-130550' ],
  [ '2023', 'Tangible', '01-130825' ],
  [ '2023', 'Tangible', '01-132650' ],
  [ '2023', 'Tangible', '01-136515' ],
  [ '2023', 'Tangible', '01-137300' ],
  [ '2023', 'Tangible', '01-137500' ],
  [ '2022', 'Tangible', '90-000647' ],
  [ '2022', 'Tangible', '90-000663' ],
  [ '2022', 'Tangible', '90-000664' ],
  [ '2022', 'Tangible', '90-000685' ],
  [ '2022', 'Tangible', '90-000686' ],
  [ '2022', 'Tangible', '90-000687' ],
  [ '2022', 'Tangible', '90-000700' ],
  [ '2022', 'Tangible', '90-000701' ],
  [ '2022', 'Tangible', '90-000702' ],
  [ '2022', 'Tangible', '90-000718' ],
  [ '2022', 'Tangible', '90-000797' ],
  [ '2022', 'Tangible', '90-000798' ],
  [ '2022', 'Tangible', '90-000799' ],
  [ '2022', 'Tangible', '90-000807' ],
  [ '2006', 'Tangible', '01-710568' ],
  [ '2013', 'Tangible', '01-713187' ],
  [ '2023', 'Tangible', '02-982582' ],
  [ '2023', 'Tangible', '02-983438' ],
  [ '2008', 'Tangible', '04-471125' ],
  [ '2008', 'Tangible', '04-472614' ],
  [ '2008', 'Tangible', '04-764012' ],
  [ '2004', 'Tangible', '05-532997' ],
  [ '2005', 'Tangible', '05-532997' ],
  [ '2007', 'Tangible', '06-668999' ],
  [ '2020', 'Tangible', '12-230000' ],
  [ '2020', 'Tangible', '12-521493' ],
  [ '2020', 'Tangible', '12-823803' ],
  [ '2020', 'Tangible', '14-839292' ],
  [ '2007', 'Tangible', '19-670746' ],
  [ '2009', 'Tangible', '20-589160' ],
  [ '2015', 'Tangible', '20-866582' ],
  [ '2016', 'Tangible', '20-866582' ],
  [ '2021', 'Tangible', '20-883370' ],
  [ '2019', 'Tangible', '21-583901' ],
  [ '2016', 'Tangible', '40-157178' ],
  [ '2017', 'Tangible', '40-157178' ],
  [ '2018', 'Tangible', '40-157178' ],
  [ '2019', 'Tangible', '40-157178' ],
  [ '2020', 'Tangible', '40-157178' ],
  [ '2016', 'Tangible', '40-199956' ],
  [ '2018', 'Tangible', '40-199956' ],
  [ '2016', 'Tangible', '40-249129' ],
  [ '2017', 'Tangible', '40-249129' ],
  [ '2023', 'Tangible', '40-172142' ],
  [ '2016', 'Real Estate', '01-3106-036-1340-HX' ],
  [ '2006', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2007', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2008', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2009', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2010', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2011', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2012', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2013', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2014', 'Real Estate', '01-3112-013-0210-HX' ],
  [ '2003', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2004', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2005', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2006', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2007', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2008', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2009', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2010', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2011', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2012', 'Real Estate', '01-3112-015-0230-HX' ],
  [ '2022', 'Real Estate', '01-3112-018-0141-HX' ],
  [ '2021', 'Real Estate', 'IL-0232-320-0600-50-R0040' ],
  [ '2021', 'Real Estate', 'IL-0232-320-0600-50-R040S' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0603-60-R0040' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0604-31-R0040' ],
  [ '2022', 'Real Estate', 'IL-0232-320-0604-31-R040S' ],
]
