<!--
  This component renders the user verification and PIN management UI.
  Because this UI is needed within a TS iframe, this component cannot access any
  Vuex state or make any API calls to the user verification backend. Instead, the
  necessary information will be passed into this component via props, and all
  changes to payable statuses will be emitted as events such that a parent
  component can submit the api request.

  See
  @grantstreet/user-verification/src/components/UserVerificationPINStoreWrapper
  for the component mounted on GovHub which wraps this component with the
  necessary data and interactions.
-->
<template>
  <div
    id="pin-ui"
    aria-live="polite"
  >
    <b-collapse
      class="user-verification-container"
      :visible="userVerificationMode !== 'closed' ||
        userVerificationMode === 'closed' &&
        Boolean(statusError && !statusErrorText.startsWith('There was an issue') &&
          !statusErrorText.startsWith('Invalid PIN'))"
    >
      <LoadingBars
        v-if="loading && !statusError"
        :include-text="false"
        class="py-2"
      />

      <!-- PIN request & verify UI -->
      <div
        class="user-verification-accordion mt-3 p-3 p-md-4 no-gutters"
        data-test="show-user-verification-accordion-text"
      >
        <!-- PIN creation / management -->
        <PINManagement
          v-if="isAuthenticated"
          :payables="payables"
          :status="status"
          :user-verification-mode="userVerificationMode"
          :has-requested-verification="hasRequestedVerification"
          :status-error="statusError"
          :status-error-text="statusErrorText"
          @change-user-verification-mode="$emit('change-user-verification-mode', $event)"
          @request-pin="$emit('request-pin', $event)"
          @enter-pin="$emit('enter-pin', $event)"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import PINManagement from './PINManagement.vue'

export default {
  emits: ['change-user-verification-mode', 'enter-pin', 'request-pin'],
  components: {
    LoadingBars,
    PINManagement,
  },

  props: {
    payables: {
      type: Array,
      required: true,
    },
    userVerificationMode: {
      type: String,
      default: 'closed',
    },
    hasRequestedVerification: {
      type: Boolean,
      required: true,
    },
    status: {
      type: Object,
      default: null,
    },
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    statusError: {
      type: Boolean,
      required: true,
    },
    statusErrorText: {
      type: String,
      default: '',
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

.user-verification-container {
  width: 100%;
}

.user-verification-accordion {
  width: 100%;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  justify-content: space-between;
}

.user-verification-error {
  color: #721C27;
  background-color: #F9DCDF;
  border: 1px solid #F5C6CB;
}

.loading-bars-btn {
  height: 1.5rem
}

.placeholder {
  height: 4rem
}

</style>
