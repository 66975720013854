import algoliasearch from 'algoliasearch/lite.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares/index.js'
import insightsClient from 'search-insights'

export const apiClient = {
  data () {
    const state = this.$store.state.GovHubSearch
    const algolia = algoliasearch(state.appId, state.apiKey)

    const debouncedSearch = AwesomeDebouncePromise(function (requests) {
      return algolia.search(requests)
    }, 500)

    const middlewares = []

    if (state.sendEvents) {
      insightsClient('init', {
        apiKey: state.apiKey,
        appId: state.appId,
        useCookie: true,
      })

      // Let Algolia generate the token for anonymous users.
      if ((window.app_user_role || 'public') === 'auth_public') {
        insightsClient('setUserToken', window.global_user_id)
      }

      const insightsMiddleware = createInsightsMiddleware({
        insightsClient,

        /*
         * Even though our page size is 15, we're sending batches of 25 to
         * Algolia, which is larger than their max of 20.  This breaks them into
         * separate events as required.
         */
        onEvent: (event, insightsClient) => {
          const { hits, insightsMethod, payload } = event

          // This should be handled by the middleware, but view events aren't
          // getting the queryID without it.
          insightsClient(insightsMethod, {
            ...payload,
            queryID: hits[0].__queryID,
          })
        },
      })

      middlewares.push(insightsMiddleware)
    }

    return {
      insightsClient,
      middlewares,

      searchClient: {
        ...algolia,

        search (requests) {
          const min = state.minCharacters

          // Only query Algolia if the user has entered something.
          if (requests.every(({ params }) => params.query.length < min)) {
            return Promise.resolve({
              results: requests.map(
                () => ({
                  hits: [],
                  hitsPerPage: 0,
                  nbHits: 0,
                  nbPages: 0,
                  page: 0,
                  processingTimeMS: 0,
                }),
              ),
            })
          }

          return debouncedSearch(requests)
        },
      },

      searchFunction (helper) {
        const match = state.searchMatch

        /*
         * If the user chose a suggestion from the autocomplete list, do a
         * strict match against the same type of attribute.
         */
        if (match) {
          // A bit hacky, but Algolia treats . as a special character.
          // Rather than dealing with the . logic we'll just search the whole query
          const quoted = '"' + `${match.value.replace(/[.']/g, ' ')}` + '"'

          helper.setQuery(quoted)
          helper.setQueryParameter('advancedSyntax', true)
          helper.setQueryParameter('restrictSearchableAttributes', [match.attribute])

          // When the query finishes, reset to the default behavior.
          helper.once('search', () => {
            helper.setQuery(match.value)
            helper.setQueryParameter('advancedSyntax', false)
            helper.setQueryParameter('restrictSearchableAttributes', [])
          })
        }

        helper.search()
      },
    }
  },
}
