<template>
  <div>
    <b-sidebar
      :id="id"
      bg-variant="white"
      :backdrop="backdrop"
      :right="position === 'right'"
      shadow
      :width="width"
      header-class="border-bottom"
      footer-class="border-top"
      @shown="onShow"
      @hidden="onHide"
      @change="onChange"
    >
      <template #header>
        <b-row class="w-100 justify-content-between">
          <h1 class="d-none d-sm-block title mx-3 px-3 py-2 col">{{ title }}</h1>
          <h3 class="mobile-title d-flex d-sm-none mx-3 px-3 py-2 col">{{ title }}</h3>
          <b-button
            v-b-toggle="id"
            variant="link"
            class="text-decoration-none ml-3 pl-6 pr-0 py-2 col-2 col-sm-2 text-right"
          >
            <svgicon
              :fill="false"
              icon="x"
              width="24px"
              height="24px"
            />
          </b-button>
        </b-row>
      </template>
      <!-- Your content goes here -->
      <slot />
      <template #footer>
        <slot name="footer">
          <div class="my-3 px-3 py-2 d-flex justify-content-center">
            <b-button
              v-b-toggle="id"
              class="mx-1"
              :variant="'primary'"
            >
              Close
            </b-button>
          </div>
        </slot>
      </template>
    </b-sidebar>
  </div>
</template>

<script>

export default {
  props: {
    // ID is important so you know which sidebar to be toggling
    id: {
      type: String,
      default: 'sidebar',
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '50rem',
    },
    // When position === right, makes the sidebar display on the right
    // any other value and it will display on the left.
    position: {
      type: String,
      default: 'right',
    },
    // Backdrop determines whether the rest of the page is mildly shaded
    backdrop: {
      type: Boolean,
      default: true,
    },
    onShow: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    onHide: {
      type: Function,
      default: () => {},
    },
  },
}

</script>

<style scoped>

.title {
  color: black;
  font-size: 32px;
  font-family: Source Sans Pro;
  font-weight: 300;
  line-height: 40px;
  word-wrap: break-word
}

</style>
