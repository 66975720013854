import Vue from 'vue'
import Vuex from 'vuex'
import ApiClass from './api.js'
import type { User } from '@grantstreet/user'
import { sentryException } from './sentry.ts'
import { searchPayablesPaths } from '@grantstreet/payables'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'

Vue.use(Vuex)

type Subscription = {
  // uuidv4
  subscriptionID: string
  accountPayableSavePath: string
  email: string
  // when true, the user will only be emailed, and will not be snail mailed.
  paperless: boolean
  createdAt: string
  deletedAt: string
  client: string
  site: string
  displayType: string
  payable: object | undefined
  subscriberName: string
}

export type InstallPublicParams = {
  user: User
  client: string
  site: string
  logRequest: () => void
}

class State {
  loaded = false
  loadDataPromise: Promise<void> | undefined
  user: User | undefined
  client = ''
  site = ''
  logRequest: (() => void) | undefined
  api = new ApiClass({ exceptionLogger: sentryException })
  subscriptions : {[key: string]: Subscription} = {}
}

const getters = {
  hasEBillingSubscription: state => (accountPayableSavePath: string, returnSubscription = false) => {
    const sub = state.subscriptions[accountPayableSavePath]
    return returnSubscription ? sub : Boolean(sub)
  },
  hasLoaded (state: State) {
    return state.loaded
  },
}

const mutations = {
  initialize (state, {
    user,
    client,
    site,
    logRequest,
  }: InstallPublicParams) {
    state.user = user
    state.client = client
    state.site = site
    state.logRequest = logRequest
    state.api = new ApiClass({
      getJwt: () => user.getAccessToken(),
      exceptionLogger: sentryException,
    })
  },

  clearSubscriptions (state) {
    state.subscriptions = {}
  },

  setSubscriptions (state, subscriptions) {
    Object.entries(subscriptions).forEach(([savePath, sub]) => {
      Vue.set(state.subscriptions, savePath, sub)
    })
  },

  // Consider changing this to 'addOrUpdateToSubscriptionsLocal'
  addToSubscriptionsLocal (state, sub) {
    Vue.set(state.subscriptions, sub.accountPayableSavePath, sub)
  },

  removeFromSubscriptionsLocal (state, savePath) {
    Vue.delete(state.subscriptions, savePath)
  },
}

const actions = {
  async loadData ({ state, commit }) {
    state.loadDataPromise ||= (async () => {
      commit('clearSubscriptions')

      if (!state.user?.loggedIn) {
        return
      }

      let { data: subscriptions = [] } = await state.api.getSubscriptions()
      subscriptions = Object.fromEntries(subscriptions.map(
        (sub: Subscription) => [sub.accountPayableSavePath, sub],
      ))

      // Get the subscription payables
      ;(await searchPayablesPaths({
        paths: Object.keys(subscriptions),
        language: i18n.global.locale.value,
      })).forEach(payable => {
        const savePath = payable.raw.save_path
        if (payable && subscriptions[savePath]) {
          subscriptions[savePath].payable = payable
        }
      })

      commit('setSubscriptions', subscriptions)
      state.loaded = true
    })()

    await state.loadDataPromise
  },

  async subscribePayable ({ state, commit }, { payable, email, name, paperless }) {
    const { data, status } = await state.api.addSubscription({
      accountPayableSavePath: payable.raw.save_path,
      client: state.client,
      site: state.site,
      // email is defaulted to user email to simulating how e-billing will pull
      // the email from the login service. This is necessary for any use of the
      // mocked api client.
      email: email || state.user?.email,
      subscriberName: name,
      language: i18n.global.locale.value,
      paperless,
    })

    if (status >= 400) {
      return { status, errorMessage: data?.displayMessage }
    }

    commit('addToSubscriptionsLocal', { ...data, payable })
  },

  async modifySubscription ({ state, commit }, { payable, paperless, subscriptionId }) {
    const { data, status } = await state.api.modifySubscription({
      paperless,
      subscriptionId,
    })

    if (status >= 400) {
      return { status, errorMessage: data?.displayMessage }
    }

    commit('addToSubscriptionsLocal', { ...data, payable })
  },

  async unsubscribePayable ({ state, commit }, {
    accountPayableSavePath,
    email,
    unsubscribeCode,
    subscriptionId,
  }) {
    // if no email address is provided, and we have an authenticated user,
    // default to the user email.
    if (!email) {
      email = state.user?.email || ''
    }
    const res = await state.api.deleteSubscription({
      accountPayableSavePath,
      email,
      unsubscribeCode,
      subscriptionId,
    })

    // If authenticated, remove subscription from vuex
    if (accountPayableSavePath && email && res?.status === 200) {
      commit('removeFromSubscriptionsLocal', accountPayableSavePath)
    }

    return res
  },

  async activateSubscription ({ state, commit }, { subscriptionId, authCode }) {
    const res = await state.api.activateSubscription({ subscriptionId, authCode })
    if (res.data) {
      commit('addToSubscriptionsLocal', res.data)
    }
    return res
  },

  async requestUnsubscribeCode ({ state }, subscriptionId) {
    return await state.api.requestUnsubscribeCode({ subscriptionId })
  },
}

export default new Vuex.Store({
  state: new State(),
  getters,
  mutations,
  actions,
})

export { State, getters, mutations, actions }
export type { Subscription }
