// This modifies dompurify's config whitelisting these tags and attrs
// https://github.com/LeSuisse/vue-dompurify-html
export default {
  default: {
    ALLOWED_TAGS: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'u', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'span', 'address',
      'details', 'summary' ],
    ALLOWED_ATTR: [ 'href', 'name', 'target', 'class', 'src', 'id' ],
  },
}
