<template>
  <a
    tabindex="0"
    href="#"
    :class="textClasses"
    @keydown.tab="$emit('listItemBlur')"
    @keydown.esc.stop.prevent="$emit('listItemBlur')"
    @keydown.down.prevent
    @keydown.up.prevent
    @keyup.down="$parent.selectNextListItem($event)"
    @keyup.up="$parent.selectPreviousListItem($event)"
    @blur="processFocusOut"
    @click="handleHit"
  >
    <div class="sr-only">{{ screenReaderText }}</div>
    <div aria-hidden="true">
      <slot
        name="suggestion"
        :data="data"
        :html-text="htmlText"
      >
        <span v-dompurify-html="htmlText" />
      </slot>
    </div>
  </a>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'TypeaheadListItem',
  props: {
    active: {
      type: Boolean,
    },
    data: {
      type: Object,
      default: () => {},
    },
    screenReaderText: {
      type: String,
      default: '',
    },
    htmlText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['hit', 'listItemBlur'],
  data: function () {
    return {
      baseTextClasses: ['vbst-item', 'list-group-item', 'list-group-item-action'],
    }
  },

  computed: {
    textClasses () {
      const classes = [...this.baseTextClasses]
      if (this.disabled) classes.push('disabled')
      return classes.join(' ')
    },
  },

  methods: {
    processFocusOut (evt) {
      const tgt = evt.relatedTarget
      if (tgt && tgt.classList.contains('vbst-item')) {
        return
      }

      this.$emit('listItemBlur')
    },
    handleHit (event) {
      this.$emit('hit')
      event.preventDefault()
    },
  },
}
</script>

<style scoped>
  a:not(.disabled){
    cursor: pointer;
  }
  a.disabled{
    cursor: default;
    pointer-events: none;
  }
</style>
