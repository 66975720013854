/**
 * Client for the forms-service API.
 */
import GenericApiClient from '@grantstreet/api-client'
import { isProd } from '@grantstreet/psc-environment'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = getFormsUrl
  }

  listSubmissions ({
    client,
    site,
    status,
    statusKey = status,
    type,
    formType = type,
    userName,
    limit,
    offset,
  } = {}) {
    return this.get('/v1/submissions', {
      params: {
        client,
        siteName: site,
        statusKey,
        formType,
        userName,
        limit,
        offset,
      },
    })
  }

  ping () {
    return this.get('/v1/ping')
  }
}

export const getFormsUrl = () => process.env?.GSG_FORMS_SERVICE || (
  isProd() ? 'https://govhub.com/svc/forms' : 'https://beta.govhub.com/svc/forms'
)
