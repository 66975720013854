<template>
  <section :aria-label="$t('results.index.label', results.length)">
    <div
      v-if="!results || results.length === 0"
      ref="resultsSection"
      :aria-label="$t('results.empty')"
      tabindex="0"
    >
      <div class="px-4 px-md-8 py-5 font-italic">
        {{ $t("results.empty") }}
      </div>
    </div>
    <div v-else>
      <div class="py-2">
        <slot name="title">
          <div
            v-if="title"
            ref="resultsSection"
            tabIndex="0"
          >
            {{ title }}
          </div>
          <div
            v-else
            ref="resultsSection"
            tabIndex="0"
          >
            <span class="font-weight-bold">{{ $t('results.index.label', results.length) }}</span> <template v-if="results.length > 1">{{ firstIndex }}-</template>{{ lastIndex }} {{ $t("of") }} {{ total }}
          </div>
        </slot>
      </div>

      <div>
        <div
          v-for="(payable, index) in results"
          :key="payable.path"
          class="payable position-relative"
          data-test="payable"
        >

          <!-- Two options
            1. Use default search result and pass your own slots for the various
               pieces of add in content.
            2. Override the result entirely. -->
          <slot
            name="result"
            :payable="payable"
            :index="index"
            :title-class="titleClass"
            :display-children-as-dropdown="displayChildrenAsDropdown"
          >
            <component
              :is="(!payable.canHaveChildren || displayChildrenAsDropdown) ? 'SearchResult' : 'ParentSearchResult'"
              :payable="payable"
              :title-class="titleClass"
              :col-limit="colLimit"
              :display-children-as-dropdown="displayChildrenAsDropdown"
            >
              <!-- Pass slots through to child component -->
              <template #cornerIndicator="props">
                <slot
                  name="cornerIndicator"
                  v-bind="props"
                />
              </template>
              <template #amount="props">
                <slot
                  name="amount"
                  v-bind="props"
                />
              </template>
              <template #payableActions="props">
                <slot
                  name="payableActions"
                  v-bind="props"
                />
              </template>
              <!-- One of the reasons we propagate the props from the child
              component (instead of just providing payable from this scope) is
              that the child component needs that control. The child component
              will pass different payables in different circumstances.  -->
              <template #addButton="props">
                <slot
                  name="addButton"
                  v-bind="props"
                />
              </template>
              <template #payableAncillary="props">
                <slot
                  name="payableAncillary"
                  v-bind="props"
                />
              </template>
            </component>
          </slot>

        </div>

        <!-- The navigation icons were generated by including an <svgicon> element
         and copying the generated HTML, since the attributes do not support
         Vue components. -->
        <b-pagination
          v-if="total > pageSize"
          :value="page"
          :total-rows="total"
          :per-page="pageSize"
          class="pagination-secondary pt-2 my-0"
          size="md"
          align="center"
          hide-goto-end-buttons
          @change="$emit('page-change', $event)"
        >
          <template #prev-text>
            <svgicon
              icon="caret"
              width="1rem"
              height="1rem"
              dir="left"
            />
          </template>
          <template #next-text>
            <svgicon
              icon="caret"
              width="1rem"
              height="1rem"
              dir="right"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import SearchResult from './SearchResult.vue'
import ParentSearchResult from './ParentSearchResult.vue'

export default {
  emits: ['page-change'],
  components: {
    SearchResult,
    ParentSearchResult,
  },

  props: {
    results: {
      type: Array,
      default: () => [],
    },
    titleClass: {
      type: String,
      default: '',
    },
    colLimit: {
      type: String,
      default: '',
    },
    page: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: '',
    },
    displayChildrenAsDropdown: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    total: {
      type: Number,
      default (props) {
        return props.results.length
      },
    },

  },

  computed: {

    resultsOffset () {
      return this.pageSize * (this.page - 1)
    },

    firstIndex () {
      // First result of current page
      return this.resultsOffset + 1
    },

    lastIndex () {
      return Math.min(this.resultsOffset + this.pageSize, this.total)
    },

  },

}
</script>
