export const clientSitesToSiteKeys = (clientSites) => clientSites.map(({ client, site }) => `${client}/${site}`)

export const siteKeysToClientSites = (keys) => keys.map(key => {
  key = key.split('/')
  return {
    client: key[0],
    site: key[1],
  }
})

export const clientSiteOptionsToSiteKeys = (options) => Object.values(options).reduce(
  (options, { options: subset, value }) => {
    if (!subset) {
      return options.concat(value)
    }
    return options.concat(subset.map(({ value }) => value))
  },
  [],
)

export const splitClientSitesByOptions = (clientSites, options) => {
  const keys = clientSitesToSiteKeys(clientSites)
  options = clientSiteOptionsToSiteKeys(options)

  const selected = []
  const notSelected = []

  for (const key of keys) {
    if (options.includes(key)) {
      selected.push(key)
    }
    else {
      notSelected.push(key)
    }
  }
  return { selected, notSelected }
}

const tagCount = (notification, tags) => notification.tags.filter(tag => tags.includes(tag)).length

// Sorts notifications primarily by provided tags, secondarily by createdDate
export const sortNotificationsByTags = (tags) => (a, b) => {
  const aTagCount = tagCount(a, tags)
  const bTagCount = tagCount(b, tags)
  if (aTagCount > bTagCount) {
    return 1
  }
  if (aTagCount < bTagCount) {
    return -1
  }
  if (a.dateToUse > b.dateToUse) {
    return 1
  }
  if (a.dateToUse < b.dateToUse) {
    return -1
  }
  return 0
}
