<template>
  <b-row v-if="display">
    <b-col
      cols="auto"
      class="font-weight-bold text-light-gray"
    >
      {{ display.type }}
    </b-col>

    <b-col class="value">
      <AisHighlight
        :attribute="display.path"
        :class-names="highlight"
        :hit="suggestion"
      />
    </b-col>
  </b-row>
</template>

<script>
import { AisHighlight } from 'vue-instantsearch'
import { createNamespacedHelpers } from 'vuex'
import { payables } from '../mixins/payables.js'

/*
  There are incompatibilities between @vue/compat and the Vue 3 versions
  (vue-instantsearch/vue3/es) of the Algolia components. Since we can't use
  the Vue 3 versions, the next best option is to opt into Vue 3 mode by default
  (MODE: 3) and only enable compat for certain features when necessary.
*/
AisHighlight.compatConfig = { MODE: 3 }

const { mapState } = createNamespacedHelpers('GovHubSearch')

export default {
  components: {
    AisHighlight,
  },

  props: {
    suggestion: {
      type: Object,
      required: true,
    },
  },

  mixins: [payables],

  data () {
    return {}
  },

  computed: {
    display: function () {
      return this.findPayablesMatch(this.suggestion)
    },

    ...mapState([
      'highlight',
    ]),
  },
}
</script>
