<template>
  <div>
    <b-link
      class="form-toggle"
      @click="visible = !visible"
    >
      <svgicon
        :class="classes"
        :fill="false"
        dir="right"
        icon="caret"
        height="1rem"
        width="1rem"
      />

      <span class="align-middle title">
        <slot name="title" />
      </span>
    </b-link>

    <b-collapse v-model="visible">
      <slot />
    </b-collapse>
  </div>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/caret.js'

export default {
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      visible: !this.collapsed,
    }
  },

  computed: {
    classes () {
      return {
        show: this.visible,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-toggle {
  .svg-icon {
    stroke: $body-color;
    transform: rotate(90deg);
    transition: transform .35s;
  }

  .svg-icon.show {
    transform: rotate(180deg);
  }

  .title {
    margin-left: .5rem;
  }
}
</style>
