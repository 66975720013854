import Vue from 'vue'

// The thrown error can we caught to handle actual failures. However null
// represents an intentional early return (e.g.: login callbacks).
export const installModule = async (installer, opts, name = 'PSC Module') => {
  const ok = await installer(Vue, opts)
  // Alternatively !(ok ?? !ok)
  if (!ok && ok !== null) {
    throw new Error(`${name}: Install Failed`)
  }
  // Note, some modules pass through truthy values that are depended upon. Might
  // want to make that pattern more explicit.
  return ok
}

/**
 * TODO: Use real TypeScript types here after converting to TS
 *
 * @param {object & any} params
 * @param {VNode} [params.component]
 * @param {string} [params.props]
 * @param {any} [params.on]
 * @param {any} [params.scopedSlots]
 */
export const getAppInstance = ({
  component,
  props,
  on,
  scopedSlots,
  ...options
}) => new Vue({
  // UX-236: We have to use a render function instead of a template string
  // because template strings break old iOS versions.
  // https://stackoverflow.com/questions/42124812/component-rendered-as-comment-in-vuejs-jspm/42252599#42252599
  render: createElement => createElement(
    component,
    { props, on, scopedSlots },
  ),
  ...options,
})

export const checkRequiredOpts = ({ opts, required = [], moduleName = '' }) => required.forEach((arg) => {
  if (!opts[arg]) {
    throw new Error(`${moduleName ? `${moduleName} ` : ''}Error: You must pass a \`${arg}\``)
  }
})

export class LoadError extends Error {
  constructor (abortLoad, ...params) {
    // Pass along remaining arguments (including vendor specific ones)
    super(...params)

    // Maintains proper stack trace (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LoadError)
    }
    this.name = 'LoadError'

    // Custom flag
    this.abortLoad = abortLoad
  }
}
