<template>
  <main class="page-content">
    <section
      :class="redirect.display_type"
      class="content-card m-3 account history"
    >
      <div class="row no-gutters align-items-center breadcrumbs">
        <div class="col-auto">
          <b-link :href="lastRoute.fullPath">
            <div class="row no-gutters flex-nowrap align-items-center icon-link pagination-back">
              <div class="col link-text">
                Search
              </div>
            </div>
          </b-link>
        </div>

        <div class="col-auto">
          <span class="icon">
            <svgicon
              icon="caret"
              dir="right"
              height=".65rem"
              width=".65rem"
              :fill="false"
            />
          </span>
        </div>

        <div class="col-auto">
          Account Summary
        </div>
      </div>

      <div class="account-header">
        <div class="row no-gutters">
          <h1 class="col col-md">
            <span
              v-if="!isGsgx"
              class="category"
            >
              {{ redirect.item_category }}
            </span>

            {{ redirect.custom_parameters.external_type }}
            <span v-if="!isGsgx">#</span>{{ redirect.external_id }}

            <span
              v-if="isGsgx"
              class="address"
            >
              &mdash; {{ redirect.custom_parameters.entities[0].address }}
            </span>
          </h1>
        </div>
      </div>

      <div class="loading-bars">
        <div /><div /><div /><div />
      </div>
    </section>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('GovHubSearch')

export default {
  computed: {
    ...mapState([
      'category',
      'lastRoute',
      'redirect',
    ]),

    isGsgx () {
      return this.redirect.display_type.startsWith('gsgx')
    },
  },
}
</script>

<style lang="scss" scoped>
#content, .page-content {
  .govhub-search {
    h1 {
      font-size: 1.625rem;
      font-weight: 700;
      margin: 0;
      margin-bottom: 2rem;
    }
  }
}

.breadcrumbs {
  margin-bottom: 1rem;
}

.icon {
  margin: 0 .5rem;

  svg {
    margin-top: -.15rem;
  }
}
</style>
