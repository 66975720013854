import isArray from 'lodash/isArray.js'
import isPlainObject from 'lodash/isPlainObject.js'

const tokensRx = /\b(?<attribute>[^.]+)_tokens(?:\.\d+)?\b/

const indexableAttributes = [
  /\balternate_key$/,
  /\bexternal_id$/,
  /\bsearchable_ids$/,
  tokensRx,
]

export const payables = {
  methods: {
    /*
     * When Algolia decides a record is a match, it returns the Payables-like
     * data intact, but adds some metadata attributes to it.  The most important
     * of those metadata attributes is "_highlightResult", which mirrors the
     * structure of the original record, but provides details about whether the
     * attribute matched the query. If so, it also provides a version of the
     * value with any characters matching the query highlighted.
     *
     * This method recurses through the record, looking for the any attributes
     * that Algolia reported as a match.  If there is a "matchLevel" attribute
     * set to "full", then we stop looking and immediately return the match.  If
     * it's set to "partial", we keep checking other attributes to make sure
     * there's no better match, otherwise we return the first one found.  Any
     * other attributes are ignored.
     *
     * If there was a match, this returns an object with the following keys:
     *
     * attribute:  Path of the configured attribute.  Can be used for filtering.
     * matchLevel: Either "partial" or "full", based on what Algolia reports.
     * object:     The object that contains the matching attribute.
     * path:       Exact path to the attribute, including array indexes.  Can be
     *             passed to AisHighlight.
     * type:       Description of the attribute.
     * value:      Plain-text value of the attribute.
     */
    findPayablesMatch (suggestion) {
      return this._searchPayablesObject({
        attribute: '',
        match: suggestion._highlightResult,
        path: '',
        value: suggestion,
      })
    },

    /*
     * For a plain attribute, this verifies it's in the allowed list, and then
     * checks whether it's a match.  It also converts any token-based matches
     * into the original attribute used to generate the list and ensures that
     * its value is highlighted correctly.
     *
     * Any objects are recursed into using their associated search method.
     */
    _checkPayablesAttribute (opt) {
      const { attribute, match, parentMatch, parentValue, path, value } = opt

      if (isArray(value)) {
        return this._searchPayablesArray(opt)
      }

      if (isPlainObject(value)) {
        return this._searchPayablesObject(opt)
      }

      let matchLevel = 'none'

      if (indexableAttributes.find(rx => rx.test(attribute)) && match.matchLevel) {
        matchLevel = match.matchLevel
      }

      if (matchLevel === 'none') {
        return
      }

      let ret = {
        object: parentValue,
        attribute,
        matchLevel,
        path,
        value,
      }

      const tokens = tokensRx.exec(path)

      if (tokens) {
        const originalAttribute = tokens.groups.attribute
        const originalMatch = parentMatch[originalAttribute]
        const originalValue = parentValue[originalAttribute]

        // Tokenized partial matches may hit more than once.  Just take the first.
        if (/<mark/.test(originalMatch.value)) {
          return null
        }

        const parts = /^([^<]*)(<[^>]+>)([^<]+)(<\/[^>]+>)(.*)$/.exec(match.value.replaceAll(' ', ''))
        const start = parts[1].length
        const end = start + parts[3].length

        let highlighted = ''
        let pos = 0

        // Highlight the correct positions, ignoring non-alphanumeric characters.
        for (const character of originalMatch.value) {
          if (/[A-Za-z0-9]/.test(character)) {
            if (pos === start) {
              highlighted += parts[2]
            }

            if (pos === end) {
              highlighted += parts[4]
            }

            pos += 1
          }

          highlighted += character
        }

        parentMatch[originalAttribute] = {
          ...originalMatch,
          ...match,
          value: highlighted,
        }

        ret = {
          attribute: attribute.replace(tokensRx, '$<attribute>'),
          path: path.replace(tokensRx, '$<attribute>'),
          value: originalValue,
          matchLevel,
        }
      }

      if (/\.alternate_key$/.test(ret.attribute)) {
        ret.type = 'Alternate Key'
      }

      if (!ret.type) {
        ret.type = parentValue.external_type || parentValue.custom_parameters.external_type
      }

      return ret
    },

    // Iterates through a list of suggestions checking for a match.
    _searchPayablesArray ({ attribute, match, parentMatch, parentValue, path, value }) {
      let ret = null

      for (const i in value) {
        const found = this._checkPayablesAttribute({
          attribute,
          match: match[i],
          path: path + '.' + i,
          value: value[i],
          parentMatch,
          parentValue,
        })

        if (found && (!ret || found.matchLevel === 'full')) {
          ret = found
        }

        if (ret && ret.matchLevel === 'full') {
          break
        }
      }

      return ret
    },

    /*
     * Loops through an object's attributes looking for a match.  It ignores
     * keys that start with an underscore, and tries to loop through them in
     * their relative priority.
     */
    _searchPayablesObject ({ attribute, match, path, value }) {
      const delimiter = attribute.length > 0 ? '.' : ''
      const keys = Object.keys(value).filter((key) => !key.startsWith('_'))
      let ret = null

      for (const move of ['custom_parameters', 'child_groups']) {
        const from = keys.indexOf(move)

        if (from > -1) {
          keys.push(keys.splice(from, 1)[0])
        }
      }

      for (const key of keys) {
        if (!match[key] || !value[key]) {
          continue
        }

        const found = this._checkPayablesAttribute({
          attribute: attribute + delimiter + key,
          match: match[key],
          parentMatch: match,
          parentValue: value,
          path: path + delimiter + key,
          value: value[key],
        })

        if (found && (!ret || found.matchLevel === 'full')) {
          ret = found
        }

        if (ret && ret.matchLevel === 'full') {
          break
        }
      }

      return ret
    },
  },
}
