<!-- Note progress buttons become multi-line if the text is too long -->
<template>
  <b-button
    v-bind="$attrs"
    class="no-gutters align-items-stretch text-center text-wrap"
    :class="{
      'inline-block': inline,
      'opacity-1': waiting,
    }"
    :disabled="disabled || waiting"
    :variant="variant"
    :size="size"
    @click="$emit('click', $event)"
  >

    <div class="position-relative">
      <div
        v-if="waiting"
        class="btn-loading-bars-overlay"
      >
        <LoadingBars
          :include-text="false"
          :white-bars="!/light|outline/.test(variant)"
          :small="size === 'sm'"
        />
      </div>
      <span
        :class="{
          'opacity-0': waiting,
        }"
      >
        <slot />
      </span>
    </div>
  </b-button>
</template>

<script>
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'

export default {
  emits: ['click'],
  components: {
    LoadingBars,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    waiting: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'secondary',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  min-width: $progress-button-min-width;
}
</style>
