export default [
  {
    value: 'disableCardPayments',
    text: 'Disable card payments',
  },
  {
    value: 'disableBankPayments',
    text: 'Disable bank payments',
  },
]
