import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import ModuleLoadError from '../components/ModuleLoadError.vue'

// We can't list @grantstreet/sentry as a package.json dependency to avoid a
// circular dependency. See PSC-9153.
// eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
import type { SentryMessageFunction } from '@grantstreet/sentry'
import { AsyncComponentLoader, defineAsyncComponent } from 'vue'

/**
 * Creates a mixin that supports dynamically importing+rendering a module's
 * "entry components" (the top-level module components that can be rendered in
 * an app, e.g., <DeliveryMethod />).
 *
 * This mixin pattern lets us enforce code splitting by default and, when
 * combined with the strict-modules ESLint rule (from
 * @grantstreet/eslint-plugin), ensures that consumers support code splitting
 * instead of importing the entry components statically.
 *
 * Specifically, this mixin:
 *
 *  - Adds a `component` prop that accepts the desired entry component name
 *  - Validates that name against the list of supportedComponents
 *  - Dynamically imports the component
 *  - Reports any errors to the module's Sentry project via sentryException
 *  - Handles rendering component loading and error states
 *  - Proxies any explicitly supported proxyMethods calls (methods supported via
 *    $refs with the componentRefName) to the child component
 *
 * Usage:
 *
 *   <template>
 *     <!-- This mixin computes vueComponent based on supportedComponents -->
 *     <component
 *       :is="vueComponent"
 *       v-bind="$attrs"
 *     />
 *   </template>
 *
 *   <script>
 *   import { createModuleLoaderMixin } from '@grantstreet/psc-vue/utils/module-loader-mixin'
 *   import { sentryException } from './sentry'
 *
 *   export default {
 *     mixins: [
 *       createModuleLoaderMixin({
 *         moduleName: 'Donations',
 *         supportedComponents: {
 *           'Donations': () => import('./components/Donations.vue'),
 *         },
 *         exceptionLogger: sentryException,
 *       }),
 *     ],
 *   }
 *   </script>
 *
 * Notice that you must proxy the props ($attrs) and listeners ($listeners)
 * through to the child component. If you want to proxy slots, you can do so
 * like this:
 *
 * <template>
 *   <component
 *     :is="vueComponent"
 *     v-bind="$attrs"
 *     v-on="$listeners"
 *   >
 *     <!-- Proxy any slots -->
 *     <template
 *       v-for="(_, slot) of $scopedSlots"
 *       #[slot]="scope"
 *     >
 *       <slot
 *         :name="slot"
 *         v-bind="scope"
 *       />
 *     </template>
 *   </component>
 * </template>
 *
 * If you want to support calling methods on the child component, add a `ref` to
 * the `<component>` and then pass `componentRefName` and `proxyMethods` to this
 * mixin factory.
 */
export const createModuleLoaderMixin = ({
  moduleName,
  supportedComponents,
  exceptionLogger,
  componentRefName,
  proxyMethods,
}: {
  /** The name of this module (for errors) */
  moduleName: string

  /** A map of component names to functions that import them */
  supportedComponents: { [key: string]: AsyncComponentLoader }

  exceptionLogger: SentryMessageFunction

  /** The component's ref value. This must be provided if proxyMethods are passed. */
  componentRefName?: string

  /** Method names that should be callable directly on the child component */
  proxyMethods?: string[]
}) => {
  if (proxyMethods?.length && !componentRefName) {
    throw new Error('componentRefName must be provided if proxyMethods are passed')
  }

  return {
    props: {
      component: {
        type: String,
        required: true,
      },
    },

    computed: {
      vueComponent () {
        // @ts-expect-error Vue TS is confused by this mixin reference, but we
        // will need to refactor this into a composable during the Vue 3
        // migration so we can ignore for now.
        const component = this.component

        if (!Object.keys(supportedComponents).includes(component)) {
          const error = new Error(`Unsupported ${moduleName} component '${component}'`)
          console.error(error)
          exceptionLogger(error)
          return ModuleLoadError
        }

        return defineAsyncComponent({
          loader: supportedComponents[component],
          loadingComponent: LoadingBars,
          errorComponent: ModuleLoadError,
        })
      },
    },

    methods: {
      ...(proxyMethods || []).reduce(
        (methods, method) => {
          methods[method] = function (...parameters) {
            // @ts-expect-error see above
            return this.$refs[componentRefName][method](...parameters)
          }
          return methods
        }, {},
      ),
    },

    errorCaptured (error) {
      exceptionLogger(error)
      return false
    },

    // Prevent Vue from literally rendering props passed to the loader and
    // proxied to the child component in the generated HTML (which results in
    // code like `cart="[object Object]"` in the HTML, even though the actual
    // object value is being correctly passed to the child):
    inheritAttrs: false,
  }
}
