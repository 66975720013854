import store, { InstallPublicParams } from './store.ts'

// // --- Installer

export const installUserVerification = (params: InstallPublicParams) =>
  store.commit('initialize', params)

// --- Dynamic Component Loader

export { default as UserVerificationLoader } from './UserVerificationLoader.vue'

// // --- Methods

export const hasRequestedVerification = (accountPayableSavePath, returnVerifyStatus) =>
  store.getters.hasRequestedVerification(accountPayableSavePath, returnVerifyStatus)

export const hasVerified = (accountPayableSavePath, returnVerifyStatus) =>
  store.getters.hasVerified(accountPayableSavePath, returnVerifyStatus)

export const requestVerification = (payload) => store.dispatch('requestVerification', payload)
export const enterVerification = (payload) => store.dispatch('enterVerification', payload)

export const UserVerificationPayables = () => store.state.statuses
export const HasUserVerificationLoaded = () => store.state.loaded
export const LoadUserVerificationData = () => store.dispatch('loadData')
