import BulkUploadConfig, { PaymentOptionType, UploadResult, ValidatedUploadResults } from '../BulkUploadConfig.ts'
import templateFile from '../example-templates/california.js'

class SacramentoConfig extends BulkUploadConfig {
  // Sacramento wants a specific statement rendered for the next steps when directing a user
  nextStepsStatement: { [type: string]: string}
  wireTransferNote: { [type: string]: string}
  constructor () {
    super()

    this.paymentOptions = [
      {
        type: PaymentOptionType.Online,
      },
      {
        type: PaymentOptionType.WireTransfer,
        description: [],
      },
      {
        type: PaymentOptionType.InPerson,
        description: [
          {
            label: 'bulk_upload.in_person.office_location',
            value: '700 H Street, Room 1710 (First Floor)\nSacramento, CA 95814',
          },
          {
            label: 'bulk_upload.in_person.office_hours',
            value: 'Mon-Fri 8:00am - 5:00pm',
          },
        ],
      },
    ]
    this.exampleTemplate = templateFile
    this.csvColumns = {
      parcelNumber: {
        columnKey: 'parcelNumber',
        columnName: 'Parcel Number',
        sortOrder: 100,
      },
      billNumber: {
        columnKey: 'billNumber',
        columnName: 'Bill Number',
        sortOrder: 200,
      },
      installment: {
        columnKey: 'installment',
        columnName: 'Installment',
        sortOrder: 300,
      },
    }
    this.nextStepsStatement = {
      'en': 'The Tax Collector only accepts wires of $250,000 and above or for payments of 20 parcels or more. If your payment meets the minimum requirement, please contact 916-874-1769 or email <a href="mailto:finance-ras@saccounty.gov">finance-ras@saccounty.gov</a>. Thank you!',
      'es': 'El recaudador de impuestos solo acepta transferencias de $250,000 o más para pagos de 20 o más parcelas. Si su pago cumple con el requisito mínimo, comuníquese al 916-874-1769 o envíe un correo electrónico a <a href="mailto:finance-ras@saccounty.gov">finance-ras@saccounty.gov</a>. Gracias!',
    }
    this.bulkSearchBatchSize = 50

    this.wireTransferNote = {
      'en': 'Note: Wire payments are only permitted for payments of $250,000 and above or for payments of 20 parcels or more.',
      'es': 'Si su pago cumple con el requisito mínimo, comuníquese al 916-874-1769 o envíe un correo electrónico a finance-ras@saccounty.gov. ¡Gracias!',
    }

    this.omitInstructions = true
  }

  /**
   * Overrides the validateSearchResults method from the base BulkUploadConfig
   * class to provide extra validation for payment choice payables. This ensures
   * that search results with multiple payment choices from the same base
   * payable conflict with each other.
   *
   * E.g., A CSV file with multiple unpaid installments for the same account
   * (like with San Bernardino County) will display those installments as errors
   * when included in the same CSV file.
   *
   * @returns A set of validated bulk search results containing successes and
   * failures
   */
  validateSearchResults (searchResults: UploadResult[]): ValidatedUploadResults {
    // Unfortunately, we need to loop through the results twice: First to build
    // a map of installments encountered using the payable's base source ID
    // (which is shared amongst common payment choice payables), and again to
    // actually catch those conflicting payment choices.
    //
    // This first lap is to create a map of base source IDs mapping to paths.
    const installmentsSeen = searchResults.reduce((installments, searchResult) => {
      const { payable } = searchResult
      if (payable?.paymentChoices?.length > 0) {
        const baseSourceId = payable.baseSourceId
        const path = payable.path

        if (!installments.hasOwnProperty(baseSourceId)) {
          installments[baseSourceId] = {}
        }

        installments[baseSourceId][path] = true
      }

      return installments
    }, {})

    const validatedResults: ValidatedUploadResults = {
      successes: [],
      failures: [],
    }

    // This second lap is to look for payment choice payables that we've seen
    // more than one base source ID of.
    for (const searchResult of searchResults) {
      const { displayItem, payable } = searchResult

      if (payable?.paymentChoices?.length > 0) {
        const baseSourceId = payable.baseSourceId

        // More than one payment choice for a base payable seen
        if (Object.keys(installmentsSeen[baseSourceId]).length > 1) {
          displayItem.errorKey = 'bulk_upload.installment.error'
          validatedResults.failures.push(displayItem)
        }
        else {
          validatedResults.successes.push(searchResult)
        }
      }
      // Payable without payment choices doesn't need this validation
      else {
        validatedResults.successes.push(searchResult)
      }
    }

    return validatedResults
  }
}

export default SacramentoConfig
