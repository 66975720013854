import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // widget-bundles-prod & -nonprod projects
    prod: 'https://ede9009db22b459b876fce8c865fb967@o168195.ingest.sentry.io/4504765405396992',
    nonprod: 'https://1e63ed6c5ec545bcb67d5015d10186db@o168195.ingest.sentry.io/4504765403168768',
  },
})
