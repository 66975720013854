import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // announcements-vue-prod & -nonprod projects
    prod: 'https://22e87eb3d0f54e2f9da1960e807691fa@o168195.ingest.sentry.io/5943557',
    nonprod: 'https://70144fa819ec462f9bb3feec00fa1e8b@o168195.ingest.sentry.io/5943558',
  },
})
