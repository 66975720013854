<template>
  <div
    class="py-3 border-top border-bottom"
    :style="isSelected() ? 'background-color: #F8F8F8' : ''"
  >
    <b-row
      class="text-center justify-content-between"
      align-v="center"
      variant="active"
    >
      <b-row
        class="mx-2 col col-sm-8"
        align-v="center"
      >
        <img
          class="mx-auto mx-sm-0"
          width="116"
          height="60"
          :src="imgUrl"
        >
        <h4
          class="mx-auto mx-sm-2"
        >
          {{ displayName }}
        </h4>
      </b-row>
      <b-button
        v-if="isCurrentPlate()"
        align-v="center"
        disabled
        size="sm"
        variant="link"
        class="col"
      >
        {{ t('rex.plate_change.current_plate') }}
      </b-button>
      <div class="col">
        <b-row align-v="center">
          <h3> {{ isCurrentPlate() ? '' : fees[selectedDuration].totalAddl.display }} </h3>
          <b-button
            v-if="!isCurrentPlate()"
            :disabled="isSelected()"
            size="sm"
            class="text-decoration-none mx-2 px-1"
            :variant="'link'"
            @click="onSelect"
          > {{ isSelected() ? t('rex.plate_change.selected') : t('rex.plate_change.select') }} </b-button>
          <span
            v-if="!isCurrentPlate() && fees.annual.totalAddl.number > 0"
            ref="breakdown"
          >
            <svgicon
              icon="question-mark-circle"
              width="1rem"
              height="1rem"
              class="icon mx-1"
            />
          </span>
        </b-row>
      </div>
    </b-row>
    <b-tooltip
      v-if="!isCurrentPlate()"
      :id="tooltipId"
      variant="outline-primary"
      :target="() => $refs.breakdown"
    >
      <div class="px-2">
        <div class="text-center"><b><i18n-t
          :i18n="i18n"
          keypath="rex.plate_change.fee_breakdown.title"
        /></b></div>
        <b-row
          v-if="fees.annual.annualFee.number > 0"
          class="justify-content-between"
        >
          <div class="px-2">{{ fees.annual.annualFee.display }}{{ conditionalMultiplier }}</div><div>(<i18n-t
            :i18n="i18n"
            keypath="rex.plate_change.fee_breakdown.annual_fee"
          />)</div>
        </b-row>
        <b-row
          v-if="fees.annual.annualProcessingFee.number > 0"
          class="justify-content-between"
        >
          <div class="px-2">{{ fees.annual.annualProcessingFee.display }}{{ conditionalMultiplier }}</div><div>(<i18n-t
            :i18n="i18n"
            keypath="rex.plate_change.fee_breakdown.annual_processing_fee"
          />)</div>
        </b-row>
        <b-row
          v-if="fees.annual.newPlateReplacementFee.number > 0"
          class="justify-content-between"
        >
          <div class="px-2">{{ fees.annual.newPlateReplacementFee.display }}</div><div>(<i18n-t
            :i18n="i18n"
            keypath="rex.plate_change.fee_breakdown.plate_replacement_fee"
          />)</div>
        </b-row>
        <b-row
          class="justify-content-between border-top"
        >
          <div class="px-2">{{ fees[selectedDuration].totalAddl.display }}</div><div>(<i18n-t
            :i18n="i18n"
            keypath="total.label"
          />)</div>
        </b-row>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  emits: ['new-plate-selected'],
  components: {},

  setup () {
    const i18n = useI18n()

    return {
      i18n,
      t: i18n.t,
    }
  },

  props: {
    payable: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: '',
    },
    darkDisplay: {
      type: Boolean,
      default: false,
    },
    smallDescription: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    selectedPlateCode: {
      type: String,
      default: '',
    },
    renewal: {
      type: Object,
      required: true,
    },
    selectedDuration: {
      type: String,
      default: '12',
    },
  },

  computed: {
    fees () {
      return this.payable.rexHubCustomParameters.fees
    },

    conditionalMultiplier () {
      return this.selectedDuration === '12' ? '' : ' x 2'
    },
  },

  data () {
    return {
      imgUrl: this.payable.customParameters?.image_url,
      displayName: this.payable.displayName,
      plateCode: this.payable.customParameters?.new_plate_code,
      renewalPlateCode: this.renewal.customParameters?.registrationDetail?.licensePlateCode,
      tooltipId: 'tooltip-plate-change-' + this.payable.customParameters?.new_plate_code,
    }
  },

  methods: {
    onSelect () {
      this.$emit('new-plate-selected', this.plateCode)
    },

    isSelected () {
      return this.selectedPlateCode === this.plateCode
    },

    isCurrentPlate () {
      return this.plateCode === this.renewalPlateCode
    },
  },
}
</script>
