import * as csv from 'papaparse'

/**
 * The stringsToCSV function takes an array of string arrays and transforms it
 * into a string representation of a CSV. This relies on Papa Parse's "unparse"
 * method.
 *
 * @returns A string representation of a CSV file
 */
export const stringsToCSV = (
  csvData: string[][],
  config: { [key: string]: unknown } = {},
): string => csv.unparse(csvData, config)

/**
 * The mergeCSVFiles function takes two CSV files, as arrays of string arrays,
 * and merges them together using a number of spacer columns. This can be useful
 * when combining something like a set of secondFile with an example CSV file
 * for users to download and modify.
 *
 * The leftmost CSV file after merging will always be the first arg. The
 * rightmost CSV file will be aligned with the last column from the first CSV
 * file, so columns with missing data will be taken into consideration.
 *
 * The number of spacer columns must be a non-negative integer; any value less
 * than 0 will be set to 0, and any decimal places will be rounded down.
 *
 * @example
 * CSV file A:
 * Planet,Number of Moons,Year Discovered
 * Mercury,0
 * Venus,0
 * Earth,1
 * Mars,2
 * Jupiter,95
 * Saturn,146
 * Uranus,27,1781
 * Neptune,14,1846
 *
 * ```javascript
 * const planetsCSV = 'Planet,Number of Moons,Year Discovered\nMercury,0\n...'
 * ```
 *
 * CSV file B:
 * This is a listing of solar system planets and their number of satellites.
 * The year the planet was discovered is also included (if applicable).
 * Only natural satellites are considered in this data.
 * Please note: Pluto considered a dwarf planet and is not included here.
 *
 * ```javascript
 * const noteToUser = 'This is a listing of solar system...'
 * ```
 *
 * Merged with two spacer columns:
 * Planet,Number of Moons,Year Discovered,,,This is a listing of solar...
 * Mercury,0,,,,The year the planet was discovered is also included (if...
 * Venus,0,,,,Only natural satellites are considered in this data.
 * Earth,1,,,,Please note: Pluto considered a dwarf planet and is not...
 * Mars,2
 * Jupiter,95
 * Saturn,146
 * Uranus,27,1781
 * Neptune,14,1846
 *
 * const mergedFile = mergeCSVFiles(planetsCSV, noteToUser, 2)
 *
 * @returns An array of string arrays containing the merged CSV file
 */
export const mergeCSVFiles = (
  firstFile: string[][],
  secondFile: string[][],
  numberOfSpacerColumns: number = 1,
): string[][] => {
  if (numberOfSpacerColumns < 0) {
    numberOfSpacerColumns = 0
  }

  numberOfSpacerColumns = Math.floor(numberOfSpacerColumns)

  // This tracks the maximum number of columns from the first file so we know
  // what column to start the second file from
  const maxColumns = firstFile.reduce((max, line) => {
    return line.length > max ? line.length : max
  }, 0)

  const maxLineCount = firstFile.length > secondFile.length
    ? firstFile.length
    : secondFile.length
  const mergedFile = new Array(maxLineCount)

  for (let lineNo = 0; lineNo < maxLineCount; lineNo++) {
    // Use the next line in the first file, otherwise create an "empty row"
    let currentRow = lineNo >= firstFile.length
      ? []
      : firstFile[lineNo]

    // We have more lines from the second file to compile
    if (lineNo < secondFile.length) {
      const lineToMerge = secondFile[lineNo]
      const currentColumns = currentRow.length

      // Check if spacer columns are needed to keep second file aligned
      // Calculate the number of spacer columns needed
      const emptyColumnCount = maxColumns - currentColumns

      // A spacer column count of zero will still concatenate correctly
      const spacerColumns = new Array(emptyColumnCount + numberOfSpacerColumns).fill('')

      // Tack on an empty column between the template and the secondFile
      currentRow = currentRow.concat(spacerColumns, lineToMerge)
    }

    mergedFile[lineNo] = currentRow
  }

  return mergedFile
}
