<template>
  <div>
    <div v-if="isDonation">
      <h4
        class="mb-0"
        :class="classes.displayName"
      >
        {{ payable.displayName }}
      </h4>
    </div>
    <div v-else>
      <RenewalPayableDescription
        v-bind="{...$props, ...$attrs, 'data-test': undefined}"
        ref="renewal"
      />
    </div>
  </div>
</template>

<script>
import RenewalPayableDescription from './RenewalPayableDescription.vue'

export default {
  compatConfig: {
    // This is necessary for child-emitted events to propagate via $attrs
    // (https://github.com/vuejs/core/issues/4566#issuecomment-917997056)
    INSTANCE_LISTENERS: false,
  },
  components: {
    RenewalPayableDescription,
  },

  props: {
    payable: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: '',
    },
    darkDisplay: {
      type: Boolean,
      default: false,
    },
    smallDescription: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showPlateFees: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes () {
      return {
        displayName: `text-${this.darkDisplay ? 'dark' : 'body'}`,
        description: this.smallDescription ? 'small' : '',
      }
    },

    isDonation () {
      return this.payable.path.search(/type=donation/) >= 0
    },
  },

  methods: {
    validate () {
      return this.$refs.renewal ? this.$refs.renewal.validate() : true
    },

    setRExRenewalDuration (renewalDuration) {
      return this.$refs.renewal.setRExRenewalDuration(renewalDuration)
    },

    setRExIsInRentalPark (isInRentalPark) {
      return this.$refs.renewal.setRExIsInRentalPark(isInRentalPark)
    },
  },
}
</script>
