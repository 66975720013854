export default [
  {
    id: 'cardDown',
    value: 'cardDown',
    text: 'Card payments are down',
    methods: ['card'],
    bannerText: {
      en: 'We are currently experiencing some technical difficulties processing credit and debit card payments. If you have a problem, please try again later or try a different payment method. We are actively working to restore service. We apologize for the inconvenience.',
      es: 'Actualmente estamos experimentando algunas dificultades técnicas para procesar pagos con tarjeta de crédito y débito. Si tiene algún problema, vuelva a intentarlo más tarde o pruebe con un método de pago diferente. Estamos trabajando activamente para restaurar el servicio. Nos disculpamos por cualquier inconveniente.',
    },
  },
  {
    id: 'cardDownAmex',
    value: 'cardDownAmex',
    text: 'American Express',
    methods: ['card'],
    bannerText: {
      en: 'American Express credit and debit card processing is currently unavailable. If you receive an error when attempting to pay by American Express, please try again later or use a different payment method.',
      es: 'El procesamiento de tarjetas de crédito y débito American Express no está disponible actualmente. Si recibe un error al intentar pagar con American Express, por favor vuelva a intentarlo más tarde o utilice un método de pago diferente.',
    },
    type: 'card',
  },
  {
    id: 'cardDownDiscover',
    value: 'cardDownDiscover',
    text: 'Discover',
    methods: ['card'],
    bannerText: {
      en: 'Discover credit and debit card processing is currently unavailable. If you receive an error when attempting to pay by Discover, please try again later or use a different payment method.',
      es: 'El procesamiento de tarjetas de crédito y débito Discover no está disponible actualmente. Si recibe un error al intentar pagar con Discover, por favor vuelva a intentarlo más tarde o utilice un método de pago diferente.',
    },
    type: 'card',
  },
  {
    id: 'cardDownMasterCard',
    value: 'cardDownMasterCard',
    text: 'MasterCard',
    methods: ['card'],
    bannerText: {
      en: 'MasterCard credit and debit card processing is currently unavailable. If you receive an error when attempting to pay by MasterCard, please try again later or use a different payment method.',
      es: 'El procesamiento de tarjetas de crédito y débito MasterCard no está disponible actualmente. Si recibe un error al intentar pagar con MasterCard, por favor vuelva a intentarlo más tarde o utilice un método de pago diferente.',
    },
    type: 'card',
  },
  {
    id: 'cardDownVisa',
    value: 'cardDownVisa',
    text: 'Visa',
    methods: ['card'],
    bannerText: {
      en: 'Visa credit and debit card processing is currently unavailable. If you receive an error when attempting to pay by Visa, please try again later or use a different payment method.',
      es: 'El procesamiento de tarjetas de crédito y débito Visa no está disponible actualmente. Si recibe un error al intentar pagar con Visa, por favor vuelva a intentarlo más tarde o utilice un método de pago diferente.',
    },
    type: 'card',
  },
  {
    id: 'bankDown',
    value: 'bankDown',
    text: 'Bank payments are down',
    methods: ['bank'],
    bannerText: {
      en: 'We are currently experiencing some technical difficulties processing bank account payments. If you have a problem, please try again later or try a different payment method. We are actively working to restore service. We apologize for the inconvenience.',
      es: 'Actualmente estamos experimentando algunas dificultades técnicas para procesar pagos de cuentas bancarias. Si tiene algún problema, vuelva a intentarlo más tarde o pruebe con un método de pago diferente. Estamos trabajando activamente para restaurar el servicio. Nos disculpamos por cualquier inconveniente.',
    },
  },
  {
    id: 'paypalDown',
    value: 'paypalDown',
    text: 'PayPal payments are down',
    methods: ['paypal'],
    bannerText: {
      en: 'We are currently experiencing some technical difficulties processing PayPal payments. If you have a problem, please try again later or try a different payment method. We are actively working to restore service. We apologize for the inconvenience.',
      es: 'Estamos experimentando algunas dificultades técnicas actualmente al procesar pagos utilizando PayPal. Si tiene algún problema, por favor vuelva a intentarlo más tarde o pruebe con un método de pago diferente. Estamos trabajando activamente para restaurar el servicio. Pedimos disculpas por las molestias.',
    },
  },
  {
    id: 'googlePayDown',
    value: 'googlePayDown',
    text: 'Google Pay payments are down',
    methods: [],
    bannerText: {
      en: 'We are currently experiencing some technical difficulties processing Google Pay payments. If you have a problem, please try again later or try a different payment method. We are actively working to restore service. We apologize for the inconvenience.',
      es: 'Estamos experimentando algunas dificultades técnicas actualmente al procesar pagos utilizando Google Pay. Si tiene algún problema, por favor vuelva a intentarlo más tarde o pruebe con un método de pago diferente. Estamos trabajando activamente para restaurar el servicio. Pedimos disculpas por las molestias.',
    },
  },
  {
    id: 'applePayDown',
    value: 'applePayDown',
    text: 'Apple Pay payments are down',
    methods: [],
    bannerText: {
      en: 'We are currently experiencing some technical difficulties processing Apple Pay payments. If you have a problem, please try again later or try a different payment method. We are actively working to restore service. We apologize for the inconvenience.',
      es: 'Estamos experimentando algunas dificultades técnicas actualmente al procesar pagos utilizando Apple Pay. Si tiene algún problema, por favor vuelva a intentarlo más tarde o pruebe con un método de pago diferente. Estamos trabajando activamente para restaurar el servicio. Pedimos disculpas por las molestias.',
    },
  },
  {
    id: 'cardAndBankDown',
    value: 'cardAndBankDown',
    text: 'All payments are down',
    methods: ['card', 'bank', 'paypal'],
    bannerText: {
      en: 'We are currently experiencing some technical difficulties processing payments. If you have a problem, please try again later. We are actively working to restore service. We apologize for the inconvenience.',
      es: 'Actualmente estamos experimentando algunas dificultades técnicas para procesar pagos. Si tiene un problema, intente nuevamente más tarde. Estamos trabajando activamente para restaurar el servicio. Disculpe las molestias.',
    },
  },
  {
    id: 'moduleDown',
    value: 'moduleDown',
    text: 'A PSP Module is down',
    methods: [],
    bannerText: {
      en: '',
      es: '',
    },
  },
]
