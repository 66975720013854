/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'print': {
    width: 16,
    height: 16,
    viewBox: '1944 1220 24.001 24',
    data: '<g transform="translate(1944.5 1220.5)" _fill="none" _stroke="#666"><path pid="0" data-name="Path 1112" d="M18 17h4.005A1.024 1.024 0 0023 15.96V8.6a2 2 0 00-.335-1.1L21.005 5H2L.336 7.5A2 2 0 000 8.606v7.354A1.024 1.024 0 00.995 17H5" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" data-name="Rectangle 653" stroke-linecap="round" stroke-linejoin="round" d="M5 0h13v5H5z"/><path pid="2" data-name="Rectangle 654" stroke-linecap="round" stroke-linejoin="round" d="M5 14h13v9H5z"/><path pid="3" data-name="Line 1166" stroke-linecap="round" stroke-linejoin="round" d="M8 17h7"/><path pid="4" data-name="Line 1167" stroke-linecap="round" stroke-linejoin="round" d="M8 20h5"/><circle pid="5" data-name="Ellipse 376" cx=".5" cy=".5" r=".5" transform="translate(15.5 8.5)" stroke-miterlimit="10"/><circle pid="6" data-name="Ellipse 377" cx=".5" cy=".5" r=".5" transform="translate(19.5 8.5)" stroke-miterlimit="10"/><path pid="7" data-name="Line 1168" stroke-linecap="round" stroke-linejoin="round" d="M3 14h17"/></g>'
  }
})
