<template>
  <div
    @click="toggleCollapse"
  >
    <b-alert
      :variant="variant"
      :show="show"
      :dismissible="dismissible"
      :class="`d-flex align-items-${alignItems}`"
      @dismissed="$emit('dismissed')"
    >

      <svgicon
        v-if="showIcon"
        :icon="computedIcon"
        :color="computedIconColor"
        :fill="false"
        width="1.9rem"
        height="1.9rem"
        class="mr-3 thin"
      />

      <slot />

      <template #dismiss>
        <svgicon
          :fill="false"
          icon="x"
          width=".9rem"
          height=".9rem"
          class="align-middle"
        />
      </template>
    </b-alert>
  </div>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/x.js'
import '@grantstreet/bootstrap/icons/js/check-circle.js'
import '@grantstreet/bootstrap/icons/js/alert-megaphone.js'

export default {
  emits: ['dismissed', 'toggle-collapse'],
  props: {
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'success',
    },
    show: {
      type: Boolean,
      default: true,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    alignItems: {
      type: String,
      default: 'center',
    },
    shouldCollapse: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isCollapsed: true,
    }
  },

  methods: {
    toggleCollapse () {
      if (this.shouldCollapse) {
        this.isCollapsed = !this.isCollapsed
        this.$emit('toggle-collapse', this.isCollapsed)
      }
    },
  },

  computed: {
    computedIcon () {
      return this.icon ? this.icon
        : this.variant === 'danger' ? 'alert-exclamation-triangle'
        : this.variant === 'warning' ? 'alert-exclamation-triangle'
        : this.variant === 'info' ? 'alert-megaphone'
        : 'check-circle'
    },

    computedIconColor () {
      return this.iconColor ? this.iconColor
        : this.variant === 'danger' ? '#721C27'
        : this.variant === 'warning' ? '#856404'
        : this.variant === 'info' ? '#004085'
        : '#155724' // success (green)
    },
  },
}
</script>
