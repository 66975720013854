/* eslint-disable vue/one-component-per-file */
import Vue, { createApp } from 'vue'
import { setEnvironment } from '@grantstreet/psc-environment/environment.js'
import { vueErrorHandler, sentryException } from './sentry.js'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import { checkRequiredOpts } from '@grantstreet/psc-vue/utils/install-utils.js'
import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'

import EBillingIframeAccordionLink from './components/EBillingIframeAccordionLink.vue'
import EBillingIframeSubscription from './components/EBillingIframeSubscription.vue'
import { v4 as uuid } from 'uuid'

import { i18n, loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'

import { createVueWait } from 'vue-wait'

Vue.use(VueDOMPurifyHTML, sanitizeConfig)

export const attach = (linkSelector, subscriptionSelector, opts) => {
  checkRequiredOpts({
    opts,
    required: [
      'environment',
      'payableSavePath',
    ],
    moduleName: 'Ebilling iframe',
  })

  const {
    environment,
    payableSavePath,
    parentWhisperer,
  } = opts

  setEnvironment(environment)

  initBootstrapVue(Vue)

  Vue.use(VueSVGIcon)
  loadTranslations(sentryException)

  const sharedUUID = uuid()

  // Create the vue instance for the Accordion link
  createApp(EBillingIframeAccordionLink, {
    payableSavePath,
    parentWhisperer,
    sharedUUID,
  })
    .use(i18n)
    .use(createVueWait())
    .use(vueErrorHandler)
    .mount(linkSelector)

  // Create the vue instance for the Subscription Management
  createApp(EBillingIframeSubscription, {
    payableSavePath,
    parentWhisperer,
    sharedUUID,
  })
    .use(i18n)
    .use(createVueWait())
    .use(vueErrorHandler)
    .mount(subscriptionSelector)
}
