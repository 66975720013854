<!--
  This component renders the e-billing signup and subscription management UI.
  Because this UI is needed within a TS iframe, this component cannot access any
  Vuex state or make any API calls to the ebilling backend. Instead, the
  necessary information will be passed into this component via props, and all
  changes to subscriptions will be emitted as events such that a parent
  component can submit the api request.

  See
  @grantstreet/e-billing-public-/src/components/EBillingSubscriptionStoreWrapper
  for the component mounted on GovHub which wraps this component with the
  necessary data and interractions.

  See @grantstreet/e-billing-iframe/src/components/EBillingIframeSubscription
  for the component that is mountable in a TaxSys Iframe, which will wrap this
  component with the necessary data and interractions.
-->
<template>
  <div
    id="ebilling-ui"
    aria-live="polite"
  >
    <b-collapse
      class="ebilling-container"
      :visible="eBillingMode !== 'closed' || eBillingMode === 'closed' && Boolean(subscriptionError)"
    >
      <b-collapse :visible="Boolean(subscriptionError)">
        <div
          class="ebilling-error rounded d-flex align-items-center mt-3 p-3"
          data-test="subscriptionError"
        >
          <svgicon
            original
            class="mr-3"
            icon="x-circle"
            width="2rem"
            height="2rem"
          />
          {{ subscriptionErrorText || ( hasEBillingSubscription ? $t('ebilling.unsubscribe_error') : $t('ebilling.subscribe_error')) }}
        </div>
      </b-collapse>

      <LoadingBars
        v-if="loading && !subscriptionError"
        :include-text="false"
        class="py-2"
      />

      <template v-if="subscriptionError" />

      <!-- If there are no errors show the subscription create & management UI -->
      <div
        v-else
        class="ebilling mt-3 p-3 p-md-4 no-gutters"
        data-test="show-e-billing-accordion-text"
      >
        <!-- Authorized user subscription creation / management -->
        <AuthSubManagement
          v-if="isAuthenticated"
          :payable-save-path="payableSavePath"
          :subscription="subscription"
          :e-billing-mode="eBillingMode"
          :has-e-billing-subscription="hasEBillingSubscription"
          :allow-paperless-ebilling="allowPaperlessEbilling"
          :disable-paperless="disablePaperless"
          :paperless-text="paperlessText"
          :show-terms-of-use="showTermsOfUse"
          :explicit-terms-of-use="explicitTermsOfUse"
          :client-title="clientTitle"
          @change-e-billing-mode="$emit('change-e-billing-mode', $event)"
          @subscribe="$emit('subscribe', $event)"
          @modify="$emit('modify', $event)"
          @unsubscribe="$emit('unsubscribe')"
        >
          <template #dashboard-reference>
            <slot name="dashboard-reference" />
          </template>
        </AuthSubManagement>
        <!-- Anonymous subscription creation / management -->
        <AnonSubManagement
          v-else
          :payable-save-path="payableSavePath"
          :subscription="subscription"
          :e-billing-mode="eBillingMode"
          :show-terms-of-use="showTermsOfUse"
          :client-title="clientTitle"
          @change-e-billing-mode="$emit('change-e-billing-mode', $event)"
          @subscribe="$emit('subscribe', $event)"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import AnonSubManagement from './AnonSubManagement.vue'
import AuthSubManagement from './AuthSubManagement.vue'
import '@grantstreet/bootstrap/icons/js/x-circle.js'

export default {
  emits: ['subscribe', 'unsubscribe', 'change-e-billing-mode', 'modify'],
  components: {
    LoadingBars,
    AnonSubManagement,
    AuthSubManagement,
  },

  props: {
    payableSavePath: {
      type: String,
      required: true,
    },
    eBillingMode: {
      type: String,
      default: 'closed',
    },
    hasEBillingSubscription: {
      type: Boolean,
      required: true,
    },
    allowPaperlessEbilling: {
      type: Boolean,
      required: true,
    },
    disablePaperless: {
      type: Boolean,
      required: true,
    },
    showTermsOfUse: {
      type: Boolean,
      required: true,
    },
    explicitTermsOfUse: {
      type: Boolean,
      required: true,
    },
    clientTitle: {
      type: String,
      required: true,
    },
    subscription: {
      type: Object,
      default: null,
    },
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    subscriptionError: {
      type: Boolean,
      required: true,
    },
    subscriptionErrorText: {
      type: String,
      default: '',
      required: true,
    },
  },

  computed: {
    paperlessText () {
      if (this.disablePaperless) {
        return this.$t('ebilling.preference.email') + ' ' + this.$t('ebilling.requires.verification')
      }
      else {
        return this.$t('ebilling.preference.email')
      }
    },
  },

  mounted () {
    // If the parent asked us to auto-expand into the subscribe mode (e.g.,
    // because the user just logged in), but the user has already subscribed,
    // close ourselves again.
    if (this.eBillingMode === 'subscribe' && this.hasEBillingSubscription) {
      this.$emit('change-e-billing-mode', 'closed')
    }
  },
}
</script>

<style lang="scss" scoped>

.ebilling-container {
  width: 100%;
}

.ebilling {
  width: 100%;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  justify-content: space-between;
}

.ebilling-error {
  color: #721C27;
  background-color: #F9DCDF;
  border: 1px solid #F5C6CB;
}

.loading-bars-btn {
  height: 1.5rem
}

.placeholder {
  height: 4rem
}

</style>
