/**
 * Client for the MQ Gateway
 */

import GenericApiClient from '@grantstreet/api-client'
import { GSG_ENVIRONMENT, isProd } from '@grantstreet/psc-environment'
import { v4 as uuid } from 'uuid'

/** request-service (or Elastic Search) gets confused by dots
 * in eg. feature flags which can cause silent failures,
 * eg. when recording
 *
 *  use-payhub.val: true
 *  use-payhub: true
 *
 * it thinks that use-payhub is supposed to be both an object
 * (via its interpretation of the dot in the first line) and a bool
 * (the second line).
 *
 * To fix, we preflatten the keys of any diagnostics we send to
 * this service and (for sanitization's sake) replace any dots with
 * hashes.
 */
const flattenDiag = (obj, prefix = '') => {
  const ret = {}

  Object.keys(obj).forEach(key => {
    const k = prefix + key.replace(/\./g, '#')

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(ret, flattenDiag(obj[key], k + '/'))
    }
    else {
      ret[k] = obj[key]
    }
  })

  return ret
}

const truncateReplacement = '... (truncated)'
// This represents the Lucene limit 32766 bytes
// assuming the worst case of 4 bytes/character for simplicity
const truncate = (obj, maxCharLength = 8191) => {
  const truncateTo = maxCharLength - truncateReplacement.length
  const ret = {}

  Object.keys(obj).forEach(key => {
    let value = obj[key]
    if (value?.length > maxCharLength) {
      value = value.substring(0, truncateTo) + truncateReplacement
    }

    ret[key] = value
  })

  return ret
}

export default class Client extends GenericApiClient {
  constructor (opts = {}) {
    let host = 'beta-mq-gateway'
    let token = 'aIYhGpMxBqXHyZE7z7w7UdU9Ob4SkfolcntmJvTAaAM='

    if (isProd()) {
      host = 'mq-gateway'
      token = 'li/ov09qxRpCS68S0rQHWt/dvVdSa2og7PI+YMxOREw='
    }
    opts.jwt = token
    super(opts)

    this.app = opts.app || 'govhub'
    this.site = opts.site || window?.location?.host || 'govhub.com'
    this.type = 'diag'
    this.server = 'browser'

    this.baseUrl = `https://${host}.grantstreet.com/v1/request`
  }

  getAuthHeader () {
    return this.jwt
  }

  diag (args) {
    // args = {
    //   diagnostics: { ... },
    //   site
    // }
    //
    // The diagnostics can contain any data you like. Please send only
    // flat values. Nested objects are liable to confuse ElasticSearch.

    const ts = Date.now() / 1000

    /* eslint-disable camelcase */
    const body = {
      app: this.app,
      site: 'unknown',

      isa: 'GSG::Message::Request::DiagnosticLog',
      type: 'diag',

      uuid: uuid(),
      epoch: Math.floor(ts),
      epoch_hires: ts,

      server: 'browser',
      environment: GSG_ENVIRONMENT,

      ...args,

      diagnostics: {
        url: window.location.href,
        ...args.diagnostics,
      },
    }

    body.diagnostics = truncate(flattenDiag(body.diagnostics))

    return this.post(`/${this.app}.diag`, body)
  }

  logRequest (args) {
    // args = {
    //   url,
    // }

    const ts = Date.now() / 1000

    /* eslint-disable camelcase */
    const body = {
      app: this.app,
      site: this.site,

      isa: 'GSG::Message::Request::HTTP',
      type: 'request',

      uuid: uuid(),
      epoch: Math.floor(ts),
      epoch_hires: ts,

      server: 'browser',
      environment: GSG_ENVIRONMENT,

      ...args,
    }

    return this.post(`/${this.app}.request`, body)
  }
}
