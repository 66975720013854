<!--
⚠️ After making changes to this component, please go to
@grantstreet/widget-bundles, run `yarn build`, and copy
widget-bundles/dist/current/taxsys-iframe-public.[js|css] to
govhub-ui/public/[js|css]/current/taxsys-iframe-public.[js|css]. This is used by the TaxSys
demo page, viewable in a sandbox at /sunshine/demo/taxsys
-->
<template>
  <div
    v-if="useUserVerification"
    :id="'user-verification-accordion-link-' + id"
    :key="'user-verification-accordion-link-'
      + id"
    class="iframe-styles"
  >
    <LoadingBars
      v-if="!loaded"
      :include-text="false"
      class="py-2"
    />
    <span v-else-if="errorMessage">{{ errorMessage }}</span>

    <!-- If we let users verify unauthenticated, take this out. -->
    <span v-else-if="!isAuthenticated">You must be logged in to enter or request a PIN.</span>

    <div v-else>
      <UserVerificationLoader
        component="UserVerificationAccordionLink"
        :payable-save-path="payableSavePath"
        :has-verified="hasVerified"
        :is-authenticated="isAuthenticated"
        @change-user-verification-mode="changeUserVerificationMode"
      />
    </div>
  </div>
</template>

<script>
import { UserVerificationLoader } from '@grantstreet/user-verification-public'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import { v4 as uuid } from 'uuid'
export default {
  components: {
    LoadingBars,
    UserVerificationLoader,
  },
  props: {
    payableSavePath: {
      type: String,
      required: true,
    },
    payables: {
      type: Array,
      required: true,
    },
    parentWhisperer: {
      type: iframeWhisperer.ToParent,
      required: true,
    },
    sharedUUID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    id: uuid(),
    loaded: false,
    // When this is false, the user verification link will not be rendered
    // This gets updated in the mounted lifecycle to reflect the actual site setting.
    useUserVerification: false,
    hasRequestedVerification: false,
    hasVerified: false,
    verificationStatus: {},
    isAuthenticated: false,
    errorMessage: '',
    namespace: 'user-verification',
  }),
  computed: {
    /**
     * This component will whisper to its parent using the vanilla namespace.
     * On mount, this will register this ID with the parent.
     * The parent will whisper directly to each child using this uidNamespace.
     */
    uidNamespace () {
      return `${this.namespace}-${this.id}`
    },
  },
  methods: {
    handleError () {
      this.loaded = true
      this.errorMessage = 'We were unable to complete your request. Please try again later.'
    },
    changeUserVerificationMode () {
      this.parentWhisperer.message({
        action: `${this.namespace}.changeUserVerificationMode`,
        payload: {
          payableSavePath: this.payableSavePath,
          payables: this.payables,
          sharedUUID: this.sharedUUID,
          mode: 'enterPIN',
        },
      })
    },
  },
  async mounted () {
    // register state change listener
    this.parentWhisperer.on(`${this.uidNamespace}.UserVerificationStateChanged`, ({
      hasRequestedVerification, hasVerified, isAuthenticated, verificationStatus, useUserVerification,
    }) => {
      this.hasRequestedVerification = hasRequestedVerification
      this.hasVerified = hasVerified
      this.isAuthenticated = isAuthenticated
      this.verificationStatus = verificationStatus
      this.useUserVerification = useUserVerification
      this.loaded = true
    })

    // Register this UID with parent whisperer to receive individual messages
    try {
      this.parentWhisperer.message({
        action: `${this.namespace}.register`,
        payload: {
          payableSavePath: this.payableSavePath,
          payables: this.payables,
          iframeId: this.id,
          sharedUUID: this.sharedUUID,
          component: 'accordionLink',
        },
      })
    }
    catch {
      this.handleError()
    }
  },
}
</script>
