<template>
  <b-link
    class="align-self-center ml-2"
    @click="$emit('openModal')"
  >
    <svgicon
      :fill="false"
      icon="question-mark-circle"
      width="1.125rem"
      height="1.125rem"
    />
  </b-link>
</template>
