import GenericConfigClient from '.'

export default class NonProdConfigClient extends GenericConfigClient {
  constructor (opts) {
    super(opts)

    // If the JWT is a sandbox token, we have to use the `payhub-sandbox` config
    // service app. Otherwise we use `payhub`, which affects real PayHub sites.
    if (this.isSandbox) {
      this.app = 'payhub-sandbox'
    }
    else {
      this.app = 'payhub'
    }

    this.baseUrl = 'https://beta-config.grantstreet.com/api/v1'
    this.lambdaFunction = 'SiteSettingsImageUploadNonProd'
    this.lambdaBucket = 'test-cdn-grantstreet-com'
  }

  uploadAWS ({ fileObject, token, callback }) {
    return this.uploadAWSGeneric({ fileObject, token, callback, isProd: false })
  }

  // ---------------------------------------------------------------------------
  // <DANGER ZONE> (deletions - modify carefully!):

  // Deletes all payhub-sandbox settings. This is defined separately here and in
  // prod.js so we can explicitly set the config app in the path to prevent
  // accidental non-sandbox deletion.
  deleteSandboxSettings () {
    return this.delete('/payhub-sandbox/data-pub/settings')
  }

  // </DANGER ZONE>
  // --------------------------------------------------------------------------
}
