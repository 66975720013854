import VueCookies from 'vue-cookies'
import { v4 as uuid } from 'uuid'
import safeLocalStorage from '@grantstreet/psc-js/utils/safe-local-storage.js'
import { SessionIdKey } from './session-id.ts'

// The current session ID from the cookie or a fallback for this app
// session if user is blocking cookies.
let sessionId

export default {
  get id () {
    // This can be called from Node, in which case cookies/localStorage are
    // inaccessible
    if (typeof document === 'undefined') {
      return
    }

    // The e2e session ID might show up later, but it should take priority
    const e2eSessionId = safeLocalStorage.getItem(SessionIdKey)
    const cookieSessionId = VueCookies.get(SessionIdKey)

    const newId = e2eSessionId || cookieSessionId || uuid()

    if (newId !== sessionId) {
      sessionId = newId
      VueCookies.set(
        SessionIdKey,
        newId,
        null, // default expiry (1d)
        null, // default path (/)
        null, // default domain (none)
        true, // Secure
        // SameSite - Set to Strict because this cookie is not used by
        // backend services. It is only used by the frontend to populate
        // the session ID so it can be sent as a header in API requests.
        'Strict',
      )
    }

    return sessionId
  },
}
