import Payable from './Payable.ts'

type GSGError = {
  errorCode: string
  errorMessage: string
  displayMessage?: string
}

type BulkSearchResult = {
  results: Payable[]
  error?: GSGError
}

type BulkSearchResponse = {
  [type: string]: BulkSearchResult
}

/**
 * This class takes the response from Payables API /bulk_search and reformats it
 * into a more JavaScript friendly format. It traverses the initial "payables"
 * key from the response and inflates all the found payables into a
 * Payable-classed object. What gets returned is a direct map of identifiers to
 * Payable objects.
 *
 * @param payables {object} An object matching the response of the /bulk_search
 *                          Payables API endpoint, traversed one level in.
 *                          I.e., response["payables"]
 * @param inflate {Function} The `inflateDisplayType` getters function from the
 *                           Payables store.
 */
export default class BulkSearchPayables {
  payables: BulkSearchResponse

  constructor ({
    payables,
    inflate,
  }) {
    this.payables = Object.keys(payables).reduce((inflatedPayables, identifier) => {
      inflatedPayables[identifier] = {
        results: payables[identifier].results.map(result => new Payable({
          raw: result,
          configDisplayType: inflate({ displayType: result.display_type }),
        })),
      }

      if (payables[identifier].error) {
        const { errorCode, errorMessage, displayMessage } = payables[identifier].error
        const error: GSGError = { errorCode, errorMessage, displayMessage }

        inflatedPayables[identifier].error = error
      }

      return inflatedPayables
    }, {})
  }
}
