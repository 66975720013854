<template>
  <div class="iframe-styles">
    <EBillingLoader
      component="EBillingSubscription"
      :payable-save-path="payableSavePath"
      :e-billing-mode="eBillingMode"
      :has-e-billing-subscription="hasSubscription"
      :subscription="subscription"
      :allow-paperless-ebilling="allowPaperlessEbilling"
      :pin-gate-ebilling="pinGateEbilling"
      :disable-paperless="disablePaperless"
      :show-terms-of-use="showTermsOfUse"
      :explicit-terms-of-use="explicitTermsOfUse"
      :client-title="clientTitle"
      :is-authenticated="isAuthenticated"
      :loading="!loaded"
      :subscription-error="subscriptionError"
      :subscription-error-text="subscriptionErrorText"
      @change-e-billing-mode="changeEBillingMode"
      @subscribe="subscribe"
      @modify="modify"
      @unsubscribe="unsubscribe"
    >
      <template #dashboard-reference>
        <span>{{ $t('ebilling.dashboard.title') }}.</span>
      </template>
    </EBillingLoader>
  </div>
</template>

<script>
import { EBillingLoader } from '@grantstreet/e-billing-public'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import { v4 as uuid } from 'uuid'

export default {
  components: {
    EBillingLoader,
  },
  data: () => ({
    id: uuid(),
    loaded: false,
    loadError: false,
    subscription: undefined,
    hasSubscription: false,
    isAuthenticated: false,
    allowPaperlessEbilling: false,
    pinGateEbilling: false,
    disablePaperless: true,
    showTermsOfUse: false,
    explicitTermsOfUse: false,
    clientTitle: '',
    subscriptionError: false,
    subscriptionErrorText: '',
    eBillingMode: 'closed',
    namespace: 'e-billing',
  }),
  props: {
    payableSavePath: {
      type: String,
      required: true,
    },
    parentWhisperer: {
      type: iframeWhisperer.ToParent,
      required: true,
    },
    sharedUUID: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * This component will whisper to its parent using the vanilla namespace.
     * On mount, this will register this ID with the parent.
     * The parent will whisper directly to each child using this uidNamespace.
     */
    uidNamespace () {
      return `${this.namespace}-${this.id}`
    },
  },
  methods: {
    changeEBillingMode (mode) {
      this.parentWhisperer.message({
        action: `${this.namespace}.changeEBillingMode`,
        payload: {
          mode,
          payableSavePath: this.payableSavePath,
          sharedUUID: this.sharedUUID,
        },
      })
    },
    toggleWait (enable) {
      if (enable) {
        this.$wait.start(`e-billing subscription ${this.payableSavePath}`)
      }
      else {
        this.$wait.end(`e-billing subscription ${this.payableSavePath}`)
      }
    },
    subscribe (event) {
      this.toggleWait(true)
      this.parentWhisperer.message({
        action: `${this.namespace}.subscribe`,
        payload: {
          payableSavePath: this.payableSavePath,
          email: event.email,
          name: event.name,
          paperless: event.paperless,
        },
      })
    },
    modify (event) {
      this.toggleWait(true)
      this.parentWhisperer.message({
        action: `${this.namespace}.modify`,
        payload: {
          subscriptionId: event.subscriptionId,
          paperless: event.paperless,
          payableSavePath: this.payableSavePath,
        },
      })
    },
    unsubscribe () {
      this.toggleWait(true)
      this.parentWhisperer.message({
        action: `${this.namespace}.unsubscribe`,
        payload: {
          payableSavePath: this.payableSavePath,
          sharedUUID: this.sharedUUID,
        },
      })
    },
  },
  async mounted () {
    // register state change listener
    this.parentWhisperer.on(`${this.uidNamespace}.EBillingStateChanged`, ({
      hasSubscription, isAuthenticated, mode, subscription, subscriptionError, subscriptionErrorText, waiting, allowPaperlessEbilling, disablePaperless, showTermsOfUse, explicitTermsOfUse, clientTitle,
    }) => {
      this.hasSubscription = hasSubscription
      this.isAuthenticated = isAuthenticated
      this.allowPaperlessEbilling = allowPaperlessEbilling
      this.disablePaperless = disablePaperless
      this.showTermsOfUse = showTermsOfUse
      this.explicitTermsOfUse = explicitTermsOfUse
      this.clientTitle = clientTitle
      this.eBillingMode = mode
      this.subscription = subscription
      this.subscriptionError = subscriptionError || false
      this.subscriptionErrorText = subscriptionErrorText || ''
      this.loaded = true
      this.toggleWait(waiting)
    })

    // register component with parent whisperer
    // Register this UID with parent whisperer to receive individual messages
    try {
      this.parentWhisperer.message({
        action: `${this.namespace}.register`,
        payload: {
          payableSavePath: this.payableSavePath,
          iframeId: this.id,
          sharedUUID: this.sharedUUID,
          component: 'subscription',
        },
      })
    }
    catch {
      this.loaded = true
      this.loadError = true
    }
  },
}
</script>
