import { isNavigationFailure, NavigationFailureType } from 'vue-router'

export const routing = {
  data () {
    const router = this.$router

    const store = this.$store
    const state = store.state.GovHubSearch

    return {
      routing: {
        router: {
          // Read query params from VueRouter, and save them in Vuex.
          read () {
            const query = router.currentRoute.value.query

            if (query.redirect && !store.redirect) {
              delete query.redirect

              router.replace(query).catch((error) => {
                if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
                  throw error
                }
              })
            }

            if (query) {
              if (query.search_query) {
                query.search_query = decodeURIComponent(query.search_query) // eslint-disable-line camelcase
              }

              store.commit('GovHubSearch/setParameters', query)
            }

            return query
          },

          // Store query params when changing the URL.
          write (routeState) {
            store.commit('GovHubSearch/setParameters', routeState || {})
          },

          // Creates a URL specific to each search result.
          createURL (routeState) {
            return router.resolve({ query: routeState }).href
          },

          // Respond to external navigation.
          onUpdate (callback) {
            this._onPopState = ({ routeState }) => {
              callback(routeState || this.read())
            }

            window.addEventListener('popstate', this._onPopState)
          },

          dispose () {
            window.removeEventListener('popstate', this._onPopState)
            this.write()
          },
        },

        /* eslint-disable camelcase, testcafe-community/noSkip */

        // Convert the TaxSys parameter names to/from the Algolia versions.
        stateMapping: {
          stateToRoute (uiState) {
            const indexState = uiState[state.index]
            const ret = {}

            if (indexState) {
              if (indexState.query) {
                ret.search_query = indexState.query
              }

              if (indexState.page && indexState.page > 1) {
                ret.skip = (indexState.page - 1) * state.perPage
              }
            }

            return ret
          },

          routeToState (routeState) {
            const ret = {}

            if (routeState.search_query) {
              ret.query = routeState.search_query
            }

            if (routeState.skip) {
              ret.page = (routeState.skip / state.perPage) + 1
            }

            return { [state.index]: ret }
          },
          /* eslint-enable */
        },
      },
    }
  },

  // Turns duplicate requests for the same route into no-ops instead of errors,
  methods: {
    pushRoute (route) {
      this.$router.push(route).catch((error) => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
          throw error
        }
      })
    },
  },
}
