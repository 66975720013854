import {
  App,
  markRaw,
  // *DO NOT* use getCurrentInstance outside of plugin development. It's an
  // intentionally undocumented api. It's not intended for application
  // development but is exposed and widely used for plugin development. This is
  // exactly how pinia and other libraries work.
  getCurrentInstance as dangerouslyGetCurrentInstance,
  inject,
  reactive,
} from 'vue'
import User from './models/User.ts'

export type GsgUserUtilities = {
  user: User
}

type GsgUserOptions = Record<string, never>

export type GsgUserPlugin = {
  install: (app: App, gsgUserOptions: GsgUserOptions) => void
  _app: App
  _utilities: GsgUserUtilities
}

// Used to inject the plugin instance for this module's own use. Please don't
// expose this elsewhere.
const GsgUserSymbol = Symbol('@grantstreet/user')

/**
 * @function useGsgUser Returns utilities for a plugin instance. Within vue
 * components this will infer the current vue application and plugin instances.
 * In all other cases the plugin instance must be passed in.
 * @param  {GsgUserPlugin | App} [context] - Optional plugin or vue app instance to
 * get utilities from.
 * @return {GsgUserUtilities} - The utilities.
 */
export const useGsgUser = (context?: GsgUserPlugin | App) => {
  // In vue contexts (inside components etc) the plugin instance will be
  // injected. Otherwise it will need to be passed in.

  let plugin: GsgUserPlugin | undefined

  if (!context && dangerouslyGetCurrentInstance()) {
    // Using getCurrentInstance allows us to inject the vue app in vue contexts.
    // That means that the plugin instance data can be stored on the vue
    // instance rather than inside this package. That in turn means each vue
    // instance can keep track of its own plugin across package boundaries
    // without the need to import anything from the application package.
    //
    // E.g. cart-vue can useGsgUser() and get the correct plugin instance for
    // the govhub-vue application without the need to import anything directly
    // from govhub-vue, which it can't do.
    //
    // This is what allows @grantstreet/user to stick to its own concerns and
    // avoid managing the access of instance data directly.
    plugin = inject(GsgUserSymbol, undefined)
  }
  else if (context) {
    plugin = '_utilities' in context ? context : context.config.globalProperties.$gsgUserPlugin
  }

  if (!plugin) {
    throw new TypeError('@grantstreet/user: useGsgUser was called but there was no active instance. Are you trying to useGsgUser before calling "app.use(createUserPlugin())" or, calling useGsgUser outside of a Vue application context without passing a plugin instance?')
  }
  if (!plugin._app) {
    throw new TypeError('@grantstreet/user: The plugin instance passed to useGsgUser has not yet been installed.')
  }

  return plugin._utilities
}

/**
 * @function createUserPlugin
 * This creates a in instance of the vue plugin which should then be
 * app.use()ed.
 * @return  {GsgUserPlugin} - The plugin instance
 */
export const createUserPlugin = (): GsgUserPlugin => {
  // This plugin object can't be a class instance. Vue will bind the install
  // method to it's own instance so `this` wouldn't be helpful. It's easier to
  // create the object in a closure and let it use the scoped reference to
  // itself.
  const plugin = markRaw({
    install (...[
      app,
    ]: [App, GsgUserOptions]) {
      plugin._app = app
      // In vue contexts (inside components etc) the plugin instance will be
      // injected. This is how useGsgUser works.
      app.provide(GsgUserSymbol, plugin)
      app.config.globalProperties.$gsgUserPlugin = plugin

      // These are what is returned from useGsgUser
      plugin._utilities = {
        user: reactive(new User()),
      }
    },

    // It would be nice if this could be a Definite Assignment Assertion but
    // apparently ts doesn't support those in this syntax. It _is_ definitely
    // assigned during install.
    _app: null as unknown as App,
    _utilities: {} as GsgUserUtilities,
  } as GsgUserPlugin)

  return plugin
}
