/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'edit-outline': {
    width: 16,
    height: 16,
    viewBox: '1294.207 1220.116 23.677 23.677',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1314.707 1233.293v8a2 2 0 01-2 2h-16a2 2 0 01-2-2v-16a2 2 0 012-2h7.625"/><path pid="1" d="M1307.707 1234.293h-4v-4L1313 1221a1 1 0 011.414 0l2.586 2.586a1 1 0 010 1.414zM1310.707 1223.293l4 4M1303.707 1234.293l-1 1"/></g>'
  }
})
