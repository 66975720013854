import { getTimelessDateFromString } from '@grantstreet/psc-js/utils/date.js'
import { sortByProp } from '@grantstreet/psc-js/utils/sort.js'
import { displayFormat, parseNumber } from '@grantstreet/psc-js/utils/numbers.js'
import {
  FormattedRExHubCustomParameters,
  RawPlateData,
  RawPlateFees,
  RawRExHubCustomParameters,
  RawRenewalFees,
  formatRExHubCustomParameters,
} from './REx.ts'

/* eslint-disable camelcase */

type IsPayableMessage = {
  html_display: string
  display: string
  code: string
}

type PaymentRestriction = {
  disabled_tender_types: string[]
  effective_until: string
  previous_payment: object
}

type ChildGroup = {
  // We have to disable this eslint rule because this is a circular reference (a
  // payable child has a reference to its parent) and eslint isn't happy with
  // that. Type checking still works in spite of this.
  //
  // eslint-disable-next-line no-use-before-define
  children: Array<Payable>
  policy: string
}

type PaymentChoice = {
  amount: number | string
  display_name: string
  id: string
  path: string
}

type RawPayable = {
  adaptor: string
  allowed_tender_types: string[]
  amount: number | string
  base_source_id?: string
  can_have_children: boolean
  cart_restriction_level: string
  cachesafe_search_pairs: Array<{[key: string]: string}>
  child_groups: ChildGroup[]
  client: string
  component_paths: string[]
  custom_parameters: {
    shippingAddress?
    taxsys_ca_installment_data?

    // RenewExpress fees can take several forms and new variations might be
    // added. Vehicle renewals will have one type, specialty plates will have
    // another, and renewals with plate changes will have RawPlateData
    // containing the plate's RawPlateFees under the plate_change key.
    // Subject to change without warning, void where prohibited by law, not
    // responsible for death, dismemberment, or defenestration.
    fees?: RawRenewalFees | RawPlateFees | unknown
    renewalDurationMonths?
    registrationDetail?
    plate_change?: RawPlateData
    renewal_duration_options?: Array<{[key: string]: unknown}>
  }
  department_code: string
  description: string
  disabled_card_brands: string[]
  display_name: string
  display_type: string
  due_at: string
  external_id: string
  is_payable: boolean
  is_payable_message: IsPayableMessage
  is_quantity_modifiable: boolean
  item_category: string
  links: object
  maximum_amount: number | string
  minimum_amount: number | string
  parent: object
  path: string
  payable_type: string
  payment_choices: PaymentChoice[]
  payment_restrictions: PaymentRestriction[]
  required_user_input_fields: object
  requires_confirmation: boolean
  requires_confirmation_message: string
  requires_shipping: boolean
  save_path: string
  should_delay_payment: boolean
  unique_id: string
  verification_jwt: string
}

type SchepFrequencyOptionsRaw = {
  before_due?: string[]
  on_date?: string[]
  monthly_on_date?: string[]
  yearly_on_date?: string[]
  every_x_weeks?: string[]
}
type SchepFrequencyOptions = {
  beforeDue?: string[]
  onDate?: string[]
  monthlyOnDate?: string[]
  yearlyOnDate?: string[]
  everyXWeeks?: string[]
}

type AllowedSchepType = {
  beforeDue?: string[]
  onDate?: string[]
  everyXWeeks?: string[]
  yearlyOnDate?: string[]
}

type AllowedSchepTypes = {
  oneTime?: AllowedSchepType
  recurring?: AllowedSchepType
}

type ScheduledPaymentsConfig = {
  allowedIntervalsEveryXWeeks: number[]
  allowedTypes: AllowedSchepTypes
  endOnZeroAmountDue: boolean
  minDaysBeforeDue: number
  maxDaysBeforeDue: number
  disallowOneTimePastDue: boolean
  disablePause: boolean
  limitToYearlyDate: string
  denyDuplicatePlans: boolean
  schedulablePaymentChoices: string[]
}

type FreeformField = {
  reportingKey: string
  label: string
  placeholder: string
  tooltip: string
  textArea: boolean
  charLimit: number
  required: boolean
}
/* eslint-enable camelcase */

const mapSpecs = (
  rawFrequency: SchepFrequencyOptionsRaw,
): SchepFrequencyOptions => {
  const frequencyConfig: SchepFrequencyOptions = {}
  if (rawFrequency.before_due) {
    frequencyConfig.beforeDue = mapAmounts(rawFrequency.before_due, [])
  }
  if (rawFrequency.on_date) {
    frequencyConfig.onDate = mapAmounts(rawFrequency.on_date, [])
  }
  if (rawFrequency.monthly_on_date) {
    frequencyConfig.onDate = mapAmounts(rawFrequency.monthly_on_date, frequencyConfig.onDate || [])
  }
  if (rawFrequency.yearly_on_date) {
    frequencyConfig.yearlyOnDate = mapAmounts(rawFrequency.yearly_on_date, frequencyConfig.yearlyOnDate || [])
  }
  if (rawFrequency.every_x_weeks) {
    frequencyConfig.everyXWeeks = mapAmounts(rawFrequency.every_x_weeks, frequencyConfig.everyXWeeks || [])
  }
  return frequencyConfig
}

const mapAmounts = (
  rawAmounts: string[],
  amounts: string[],
): string[] => {
  rawAmounts.forEach(rawAmount => {
    const amount = rawAmount === 'amount_due' ? 'amountDue' : 'fixedAmount'
    if (!amounts.includes(amount)) {
      amounts.push(amount)
    }
  })
  return amounts
}

export const sanitizePayablePath = path => path.replace(/\//g, '-')

export default class Payable {
  raw: RawPayable
  configDisplayType?: object
  shortGeneratedDescription?: string
  longGeneratedDescription?: string
  isChildFlag: boolean
  nextPayment?: string
  scheduledPaymentsConfig?: ScheduledPaymentsConfig
  freeformFields?: FreeformField[]

  constructor ({
    raw,
    configDisplayType = undefined,
    isChild = false,
  }) {
    this.configDisplayType = configDisplayType

    // populated when we render a PayableDescription
    this.shortGeneratedDescription = ''
    this.longGeneratedDescription = ''

    // directly from the Payables service
    this.raw = raw

    this.isChildFlag = isChild
    this.nextPayment = ''

    if (!raw.scheduled_payments_config) {
      return
    }

    // Re-case the config to match FE
    const scheduledPaymentsConfig: ScheduledPaymentsConfig = {
      allowedIntervalsEveryXWeeks: [],
      allowedTypes: {},
      endOnZeroAmountDue: raw.scheduled_payments_config.end_on_zero_amount_due,
      minDaysBeforeDue: raw.scheduled_payments_config.min_days_before_due,
      maxDaysBeforeDue: raw.scheduled_payments_config.max_days_before_due,
      disallowOneTimePastDue: raw.scheduled_payments_config.disallow_one_time_past_due,
      disablePause: Boolean(raw.scheduled_payments_config.disable_pause),
      limitToYearlyDate: raw.scheduled_payments_config.limit_to_yearly_date,
      denyDuplicatePlans: raw.scheduled_payments_config.deny_duplicate_plans,
      schedulablePaymentChoices: raw.scheduled_payments_config.schedulable_payment_choices || [],
    }

    const oneTimeFrequency = raw.scheduled_payments_config.allowed_types.one_time
    if (oneTimeFrequency) {
      scheduledPaymentsConfig.allowedTypes.oneTime = mapSpecs(oneTimeFrequency)
    }

    const recurringFrequency = raw.scheduled_payments_config.allowed_types.recurring
    if (recurringFrequency) {
      scheduledPaymentsConfig.allowedTypes.recurring = mapSpecs(recurringFrequency)
    }

    if (raw.scheduled_payments_config.allowed_intervals_every_x_weeks) {
      scheduledPaymentsConfig.allowedIntervalsEveryXWeeks = raw.scheduled_payments_config.allowed_intervals_every_x_weeks
    }

    this.scheduledPaymentsConfig = scheduledPaymentsConfig

    // Re-case freeform fields too
    if (raw.freeform_fields) {
      const freeformFields: FreeformField[] = raw.freeform_fields.map((freeformField): FreeformField => {
        const field: FreeformField = {
          reportingKey: freeformField.reporting_key,
          label: freeformField.label,
          placeholder: freeformField.placeholder,
          tooltip: freeformField.tooltip,
          textArea: freeformField.text_area,
          charLimit: freeformField.char_limit || 100, // The payable sources variant defaults to 100 as well
          required: freeformField.required,
        }

        return field
      })

      this.freeformFields = freeformFields
    }
  }

  /*****************************************************************************
   * PAYABLE WRAPPER FUNCTIONS
   *
   * These let us access the raw payable data nicely. Why have them? 1) We want
   * our own canonical reference with documentation. 2) We do not want to mix
   * the raw fields returned from Payables with our additional fields in case
   * the Payables spec changes and conflicts. 3) snake_case in a camelCase repo
   * is ugly.
   */

  // ----- GENERIC PAYABLE -----

  get path (): string {
    return this.raw.path
  }

  get sanitizedPath (): string {
    return sanitizePayablePath(this.raw.path)
  }

  // Item/Bill/Account
  get payableType (): string {
    return this.raw.payable_type
  }

  get paymentChoices (): PaymentChoice[] {
    const rawChoices = this.raw.payment_choices
    if (!Array.isArray(rawChoices) || rawChoices.length === 0) {
      return []
    }

    return rawChoices.map(
      choice => ({
        ...choice,
        displayAmount: `${displayFormat(parseNumber(choice.amount || ''))}`,
      }),
    )
  }

  // amount should only be used for calculations and certain
  // code evaluations. This function does not represent the
  // amount that should be displayed to a user. For displaying
  // the amount, we should default to using displayAmount
  get amount (): number {
    // For zero-amount payables, adaptors can return 0 or null.
    return Number(this.raw.amount || 0) || 0
  }

  // Because the api can return null it's important to distinguish between 0 and
  // falsey values
  get hasAmount (): boolean {
    // Typeof string check might be better
    return typeof this.raw.amount !== 'undefined' && this.raw.amount !== null
  }

  // This returns a string value to display to the user. Payables can return
  // 'undefined' and null amounts. These values are set explicity by payables
  // and should be displayed as '' rather than 0.00
  get displayAmount (): string {
    if (!this.hasAmount) {
      return ''
    }
    return `${displayFormat(parseNumber(this.amount))}`
  }

  // By keeping the extra operation here we can maintain control and ensure that
  // the hasAmount check is done properly
  get absoluteDisplayAmount (): string {
    if (!this.hasAmount) {
      return ''
    }
    return `${displayFormat(Math.abs(parseNumber(this.amount)))}`
  }

  get minAmount (): number | string {
    return this.raw.minimum_amount
  }

  get maxAmount (): number | string {
    return this.raw.maximum_amount
  }

  get isAmountModifiable (): boolean {
    return this.maxAmount !== this.minAmount
  }

  get isQuantityModifiable (): boolean {
    return this.raw.is_quantity_modifiable
  }

  get displayName (): string {
    return this.raw.display_name
  }

  get displayType (): string {
    return this.raw.display_type
  }

  get requiresConfirmation (): boolean {
    return this.raw.requires_confirmation
  }

  get requiresConfirmationMessage (): string {
    return this.raw.requires_confirmation_message
  }

  // dueAt is a simple date, represented as midnight in the
  // client's local time zone. It should be rendered without
  // any attempt to convert to the client's TZ.
  get dueAt (): Date | null {
    if (this.raw.due_at) {
      return getTimelessDateFromString(this.raw.due_at)
    }
    else {
      return null
    }
  }

  get client (): string {
    return this.raw.client
  }

  get departmentCode (): string {
    return this.raw.department_code
  }

  // The category that PayCore will use when processing the payment (e.g.,
  // Non-Tax)
  get itemCategory (): string {
    return this.raw.item_category
  }

  // The payable resource for future actions; If undefined, this means this
  // payable cannot be saved to "My Items", and precludes scheduling payments.
  get savePath (): string {
    return this.raw.save_path
  }

  // Tender types by which this payable can be paid
  get allowedTenderTypes (): string[] {
    return this.raw.allowed_tender_types
  }

  get shouldDelayPayment (): boolean {
    return this.raw.should_delay_payment
  }

  get allowedSchepTypes (): AllowedSchepTypes | undefined {
    if (this.scheduledPaymentsConfig?.allowedTypes === undefined) {
      return undefined
    }
    const allowedTypes = {}
    /**
     * The allowed types may have a value of:
     * {
     *    "oneTime": {
     *        "beforeDue": [],
     *        "onDate": []
     *    },
     *    "recurring": {
     *        "beforeDue": [],
     *        "onDate": [],
     *        "yearlyOnDate": [],
     *        "everyXWeeks": []
     *    }
     * }
     * For oneTime or recurring to actually be allowed, they must have a
     * frequency rule that contains either "amountDue" or "fixedAmount" strings.
     */
    for (const [type, frequencies] of Object.entries(this.scheduledPaymentsConfig?.allowedTypes || {})) {
      if (Object.values(frequencies).find((schedulableAmounts) => (schedulableAmounts || []).length)) {
        allowedTypes[type] = frequencies
      }
    }
    return allowedTypes
  }

  get canScheduleOneTimePayment (): boolean {
    const oneTimeTypes = this.scheduledPaymentsConfig?.allowedTypes?.oneTime
    return Boolean((oneTimeTypes?.beforeDue || []).length + (oneTimeTypes?.onDate || []).length)
  }

  get hasAnyAllowedSchepTypes (): boolean {
    return Boolean(Object.keys(this.allowedSchepTypes || {}).length)
  }

  // Additional payable information
  get description (): string {
    return this.raw.description
  }

  get isPayable (): boolean {
    return this.raw.is_payable
  }

  get isPayableMessage (): IsPayableMessage {
    return this.raw.is_payable_message
  }

  get isPayableMessageDisplay (): string {
    return this.isPayableMessage?.html_display ||
      this.isPayableMessage?.display ||
      ''
  }

  // A map of required user input fields and information about what is required
  get requiredUserInputFields (): object {
    return this.raw.required_user_input_fields
  }

  get requiresShipping (): boolean {
    return this.raw.requires_shipping
  }

  get customParameters () {
    return this.raw.custom_parameters || {}
  }

  get rexHubCustomParameters (): FormattedRExHubCustomParameters {
    return formatRExHubCustomParameters(this.customParameters as RawRExHubCustomParameters)
  }

  get cachesafeSearchPairs () {
    return this.raw.cachesafe_search_pairs || {}
  }

  /**
   * Returns the renewal duration for this payable if set, or a default if it's
   * possible to determine one.
   */
  get renewalDurationMonths (): number | undefined {
    // This would be manually set by something external (duration dropdown)
    if (this.customParameters.renewalDurationMonths) {
      return this.customParameters.renewalDurationMonths
    }

    // Default to the first possible option

    let displayName
    // REx V0 adaptor uses child payables
    if (this?.childPayables?.length) {
      displayName = this.childPayables[0].displayName
    }
    // REx V1 adaptor uses payment choices
    else if (this?.paymentChoicesPayables?.length) {
      displayName = this.paymentChoicesPayables[0].displayName
    }
    // Construct manually
    // XXX: When is this supposed to happen?
    else if (this.customParameters.renewal_duration_options?.length) {
      displayName = this.customParameters.renewal_duration_options[0].display_name
    }

    // Bail out if we have no duration options for this payable.
    // This can occur when payables are ineligible for renewal.
    // ex: mock payable with pin 106502
    if (!displayName) {
      return
    }

    // Not the cleanest thing ever but atm this is the accepted method
    return displayName.match(/^(\d+)/)[1]
  }

  /**
   * Returns the RExHub vehicle renewal fees (or specialty plate fees) for the
   * selected (or default) renewal duration. (See REx.ts types.)
   */
  get selectedRExFees () {
    return this.renewalDurationMonths ? this.rexHubCustomParameters.fees?.[this.renewalDurationMonths] : undefined
  }

  /**
   * Returns the RExHub plate change fees for the selected (or default) renewal
   * duration. (See REx.ts types.)
   */
  get selectedPlateChangeFees () {
    return this.renewalDurationMonths ? this.rexHubCustomParameters.plateChange?.fees?.[this.renewalDurationMonths] : undefined
  }

  get installmentData (): boolean {
    return this.raw.custom_parameters?.taxsys_ca_installment_data || {}
  }

  get sortedPaymentRestrictions (): PaymentRestriction[] {
    const restrictions = this.raw.payment_restrictions
    if (!restrictions) {
      return []
    }
    return restrictions.slice().sort(sortByProp('effective_until')).reverse()
  }

  get canHaveChildren (): boolean {
    return this.raw.can_have_children
  }

  get childGroups (): ChildGroup[] {
    const groups = this.raw.child_groups || []
    if (!groups.length) {
      return groups
    }
    return groups.map(({ children, policy }) => ({
      children: children.map(raw => new Payable({ raw })),
      policy,
    }))
  }

  get parent (): Payable | undefined {
    if (!this.raw.parent) {
      return undefined
    }
    return new Payable({ raw: this.raw.parent })
  }

  get isChild (): boolean {
    return Boolean(this.raw.parent) || this.isChildFlag
  }

  get isStandalone (): boolean {
    return !this.canHaveChildren && !this.isChild && !this.paymentChoices.length
  }

  get adaptor (): string {
    return this.raw.adaptor
  }

  get cartRestrictionLevel (): string {
    return this.raw.cart_restriction_level
  }

  get componentPaths (): string[] {
    return this.raw.component_paths
  }

  get disabledCardBrands (): string[] {
    return this.raw.disabled_card_brands
  }

  get links (): object {
    return this.raw.links
  }

  get uniqueId (): string {
    return this.raw.unique_id
  }

  get baseSourceId (): string {
    return this.raw.base_source_id || ''
  }

  get verificationJwt (): string {
    return this.raw.verification_jwt || ''
  }

  /* END PAYABLE WRAPPER FUNCTIONS -----
   ****************************************************************************/

  /*****************************************************************************
   * NESTED CHILDREN
   *
   * These are convenience getters for nested payables.
   */

  // Returns an array of payables constructed from the first child group
  // *(assumes only 1)* The returned payables will be full payables with copies
  // of all keys and values that used to exist on the parent, as well as a
  // .parent relation. The .parent property does *not* point back to this
  // payable. It points to a new raw object containing only a diff from the
  // values of the child.
  // TODO: PSC-8175 This just begs for js' prototypal inheritance.
  // TODO: PSC-7753
  get childPayables (): Array<Payable> {
    const children: Array<Payable> = []
    if (!this.canHaveChildren || !this.raw.child_groups[0]?.children?.length) {
      return children
    }

    const parentKeys = Object.keys(this.raw)

    for (const rawChild of this.raw.child_groups[0].children) {
      type RawParentDiff = {
        can_have_children?: boolean
      }
      const newRawChild: {
        can_have_children?: boolean
        parent?: RawParentDiff
      } = {}
      // This is just a diff of the child and parent .raw
      const rawParentDiff: RawParentDiff = {}

      // unioned list of parent and child keys
      const unionedKeys = [...new Set([...parentKeys, ...Object.keys(rawChild)])]
      unionedKeys.forEach(key => {
        if (key === 'child_groups') return
        if (key in rawChild) {
          newRawChild[key] = rawChild[key]
          rawParentDiff[key] = this.raw[key]
        }
        else {
          newRawChild[key] = this.raw[key]
        }
      })

      // eslint-disable-next-line camelcase
      rawParentDiff.can_have_children = true
      // eslint-disable-next-line camelcase
      newRawChild.can_have_children = false
      newRawChild.parent = rawParentDiff
      const child = new Payable({ raw: newRawChild, isChild: true })
      children.push(child)
    }
    return children
  }

  // Like childPayables above, this assumes the payable only has one
  // child group and returns the policy of that child group.
  get childPolicy (): string {
    if (!this.canHaveChildren || !this.raw.child_groups?.[0]) {
      return ''
    }

    return this.raw.child_groups[0].policy
  }

  /**
   * Returns array of child payables that are unpayable. This only looks at the
   * children in the first child group.
   * @return {Array<Payable>} array of unpayable children, empty array if all
   * children are payable
   */
  get unpayableChildren (): Array<Payable> {
    return this.childPayables?.filter(
      child => !child?.isPayable,
    )
  }

  /* PAYABLE MESSAGE CODES
   *
   * These are convenience getters for handling various codes from
   * "isPayableMessage".
   */

  get creditBalanceExceeded (): boolean {
    return this.isPayableMessage?.code === 'CREDIT BALANCE EXCEEDED'
  }

  isTenderRestricted (tenderType): boolean {
    return Boolean(this.getLongestTenderRestriction(tenderType))
  }

  getRestrictingConfirmationNumber (tenderTypes): string {
    let restriction
    for (const tenderType of tenderTypes) {
      const tenderRestriction = this.getLongestTenderRestriction(tenderType)
      if (!tenderRestriction) {
        continue
      }
      if (!restriction || tenderRestriction.effective_until > restriction.effective_until) {
        restriction = tenderRestriction
      }
    }

    return restriction?.previous_payment.confirmation_number || ''
  }

  getLongestTenderRestriction (tenderType): PaymentRestriction | null {
    const now = new Date()
    for (const restriction of this.sortedPaymentRestrictions) {
      if (!restriction.disabled_tender_types.includes(tenderType)) {
        continue
      }

      if (new Date(restriction.effective_until) > now) {
        return restriction
      }
    }

    return null
  }

  // Returns this payable (if it is payable) and any child payables.
  get allPayables (): Array<Payable> {
    let items: Array<Payable> = []
    if (!this.canHaveChildren || this.isPayable) {
      items.push(this)
    }
    if (this.canHaveChildren) {
      items = items.concat(this.childPayables)
    }
    return items
  }

  // Returns the payment choices as payables by merging the payment
  // choices params with the payable params of the base payable.
  // Payment choices payables will have is_payable set to true.
  get paymentChoicesPayables (): Array<Payable> {
    return this.raw.payment_choices?.length
      ? this.raw.payment_choices.map((paymentChoice) => new Payable({
        raw: {
          ...this.raw,
          // eslint-disable-next-line camelcase
          custom_parameters: {
            ...this.raw.custom_parameters,
          },
          amount: paymentChoice.amount,
          path: paymentChoice.path,
          'display_name': paymentChoice.display_name,
          'is_payable': true,
        },
      }))
      : []
  }

  // Gets the delivery fee amount for RenewExpress payable
  getRExDeliveryFees (): Record<string, string> {
    const renewalDurationMonths = parseNumber(this.customParameters.renewalDurationMonths)

    if (this.customParameters.fees && !isNaN(renewalDurationMonths)) {
      // Get the mailFeeAmount specific to the currently selected renewal duration
      for (const durationFees of Object.values(this.customParameters.fees)) {
        if (durationFees?.duration === renewalDurationMonths) {
          return durationFees.delivery
        }
      }
    }

    return {}
  }

  // Returns the shipping fee for this Payable
  get shippingFee (): number {
    // REx payable
    if (this.displayType === 'rex-vehicle-registration') {
      const deliveryFees = this.getRExDeliveryFees()
      const fee = deliveryFees.shipping || 0
      return parseNumber(fee)
    }

    return 0
  }

  // Returns the pick up fee for this Payable
  get pickUpFee (): number {
    // REx payable
    if (this.displayType === 'rex-vehicle-registration') {
      const deliveryFees = this.getRExDeliveryFees()
      const fee = deliveryFees.pick_up || 0
      return parseNumber(fee)
    }

    return 0
  }

  // Returns external id for payable (may not exist)
  get externalId (): string {
    return this.raw.external_id
  }

  // Returns undefined if the payable is not limited to a yearly date
  // Otherwise returns a date object representing the day of the year this
  // payable can be autopaid on.
  get schepLimitToYearlyDate (): Date|undefined {
    const limitToYearlyDate = this.scheduledPaymentsConfig?.limitToYearlyDate
    if (!limitToYearlyDate) {
      return undefined
    }
    const year = new Date().getFullYear()
    // limitToYearlyDate will be in a format such as "01-01" for January first
    const [month, day] = limitToYearlyDate.split('-')
    // note: months are 0 indexed but days are not
    return new Date(year, Number(month) - 1, Number(day))
  }

  get isAutopayEligible (): boolean {
    return Boolean(this.scheduledPaymentsConfig?.allowedTypes?.recurring?.beforeDue?.includes('amountDue'))
  }

  get isYearlyAutopayEligible (): boolean {
    // Must not be eligible for regular autopay
    return !this.isAutopayEligible &&
      // Must allow 'amountDue' payments on the yearlyOnDate recurring frequency
      Boolean(this.scheduledPaymentsConfig?.allowedTypes?.recurring?.yearlyOnDate?.includes('amountDue')) &&
      // Must have a specified limit to yearly date value (date of payments)
      Boolean(this.schepLimitToYearlyDate)
  }
}
