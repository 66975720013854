/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'magnifying-glass': {
    width: 16,
    height: 16,
    viewBox: '1894 1120 24.207 24.207',
    data: '<g transform="translate(1894.5 1120.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><circle pid="0" cx="9" cy="9" r="9"/><path pid="1" d="M23 23l-7.64-7.64"/></g>'
  }
})
