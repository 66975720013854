<template>
  <div
    class="pb-4 px-0"
  >
    <div
      class="bg-white rounded-xl py-3"
    >

      <div class="px-4 px-md-8">
        <h5>{{ $t("common.search") }}</h5>

        <div
          class="d-sm-flex align-items-center pb-2"
        >
          <div
            v-for="(input, index) of searchInputs"
            :key="input.payablesKey"
            :class="{ 'mr-2': searchInputs.length > 1 || index !== searchInputs.length - 1 }"
            class="flex-grow-1 flex-basis-0 mb-2 mb-sm-0"
          >
            <SearchBox
              ref="searchBoxes"
              v-model="inputValues[input.payablesKey]"
              :show-icon="searchInputs.length === 1"
              :placeholder="input.placeholder"
              :data-test="input.payablesKey"
              :name="input.payablesKey"
              :required="input.required"
              @search="search"
            />
            <div
              v-if="input.helpText"
              class="mt-1"
            >
              {{ input.helpText }}
            </div>
          </div>

          <template v-if="searchInputs.length > 1">
            <SearchButton
              :spinning="$wait.is('searching')"
              hide-before-breakpoint="sm"
              @click="search"
            />

            <ProgressButton
              :waiting="$wait.is('searching')"
              class="d-block d-sm-none mt-3 w-100"
              aria-label="Search"
              variant="primary"
              @click="search"
            >
              {{ $t('common.search') }}
            </ProgressButton>
          </template>
        </div>
      </div>

      <slot name="search-ancillary" />

      <Alert
        v-if="showError"
        class="mt-3 mx-4 mx-md-8"
        variant="danger"
        data-test="field-search-error"
      >
        {{ errorMessage }}
      </Alert>

    </div>

    <!-- Two options
    1. Use default search results and pass your own slots for the various pieces
       of add in content.
    2. Override the results entirely. -->
    <slot
      v-if="pageResults"
      name="results"
      :results="pageResults"
      :page="page"
      :page-size="pageSize"
      :on-page-change="paginate"
      :total="total"
    >
      <SearchResults
        ref="searchResults"
        :results="pageResults"
        data-test="search-results"
        :page="page"
        :page-size="pageSize"
        :total="total"
        @page-change="paginate"
      >
        <!-- Pass slots through to child component -->
        <template #title>
          <slot name="title" />
        </template>
        <template #cornerIndicator="props">
          <slot
            name="cornerIndicator"
            v-bind="props"
          />
        </template>
        <template #payableActions="props">
          <slot
            name="payableActions"
            v-bind="props"
          />
        </template>
        <template #addButton="props">
          <slot
            name="addButton"
            v-bind="props"
          />
        </template>
        <template #payableAncillary="props">
          <slot
            name="payableAncillary"
            v-bind="props"
          />
        </template>
        <!-- Pass through optional override for entire result component -->
        <template #result="props">
          <slot
            name="result"
            v-bind="props"
          />
        </template>
      </SearchResults>
    </slot>

    <div
      v-if="showExampleImageText || exampleImage"
      class="bg-white rounded mt-3 px-4 px-md-8 py-3"
    >
      <p
        v-if="showExampleImageText"
        v-dompurify-html="exampleImageText[$i18n.locale] || ''"
        data-test="search-example-text"
      />
      <b-img
        v-if="exampleImage"
        :src="exampleImage"
        :alt="exampleImageAltText[$i18n.locale] || ''"
        fluid
        data-test="search-example-image"
      />
    </div>
  </div>
</template>

<script>
// Ok to import
import SearchResults from './SearchResults.vue'
import SearchBox from '@grantstreet/psc-vue/components/SearchBox.vue'
import SearchButton from '@grantstreet/psc-vue/components/SearchButton.vue'
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import { redactProps } from '@grantstreet/psc-js/utils/objects.js'
import store from '../../store/index.ts'

let setStorage
let getStorage
let removeStorage

const nopeStorage = () => {
  console.error('Cannot access local storage due to incognito window')
  const noOp = () => {}
  setStorage = noOp
  getStorage = noOp
  removeStorage = noOp
}

setStorage = (key, value) => {
  try {
    localStorage.setItem(key, value)
  }
  catch (error) {
    nopeStorage()
  }
}

getStorage = key => {
  try {
    return localStorage.getItem(key)
  }
  catch (error) {
    nopeStorage()
  }
}

removeStorage = key => {
  try {
    localStorage.removeItem(key)
  }
  catch (error) {
    nopeStorage()
  }
}

export default {
  emits: ['search', 'search-button-clicked'],
  components: {
    SearchBox,
    SearchButton,
    ProgressButton,
    Alert,
    SearchResults,
  },

  props: {
    payablesAdaptor: {
      type: String,
      required: true,
    },
    adapterOptions: {
      type: Object,
      default: () => null,
    },
    searchInputs: {
      type: Array,
      required: true,
    },
    exampleImage: {
      type: String,
      default: '',
    },
    exampleImageText: {
      type: Object,
      default: () => null,
    },
    exampleImageAltText: {
      type: Object,
      default: () => ({}),
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    isIndexSearch: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      // These need to be initialized for children's prop validators and
      // querystring replacement
      inputValues: this.$props.searchInputs.reduce((values, { payablesKey }) => {
        values[payablesKey] = ''
        return values
      }, {}),

      results: null,
      page: 1,
      showError: false,
      errorMessage: '',

      // Used to indicate we reloaded page and should search again immediately
      autoSearch: false,

      usePaginationApi: /Taxsys-GovHub\/v\d|Sunshine-PropertyTax\/v\d/.test(this.payablesAdaptor),
      limit: this.pageSize,
      total: 0,
    }
  },

  computed: {
    showExampleImageText () {
      return Object.keys(this.exampleImageText || {}).length
    },

    pageResults () {
      return this.results?.[this.page]
    },
  },

  created () {
    if (this.$route.query) {
      // Populate the search based on URL query params

      // We support auto searching in two cases:
      // 1. One or more query params match the payablesKey(s) specified for
      //    input(s) in SiteSettings. This works for any number of inputs.
      //
      // 2. Exactly one input is configured and the passed query param is 'q1'.
      //    This is done to support a requirement from Sacramento, and is a
      //    tolerable shorthand for other clients.
      //
      // Context:
      // https://support.grantstreet.com/browse/PSC-9628?focusedCommentId=2483135&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-2483135

      // Sets autoSearch to true if anything's found
      if (this.searchInputs.length === 1 && this.$route.query.q1) {
      // Scenario 2
        this.autofillInput(this.searchInputs[0].payablesKey, 'q1')
      }
      else {
      // Scenario 1
        this.searchInputs.forEach(({ payablesKey }) => this.autofillInput(payablesKey))
      }
      // Quit if any inputs were auto-filled from query params, since we've
      // already started a search
      if (this.autoSearch) {
        return
      }
    }

    // If we don't have URL query params, look at local storage
    for (const input of this.searchInputs) {
      // Repopulate search incase of login
      const storedVal = getStorage(window.location.href + input.payablesKey)
      if (!storedVal) {
        continue
      }

      removeStorage(window.location.href + input.payablesKey)
      this.inputValues[input.payablesKey] = storedVal
      this.autoSearch = true
    }

    // Start on same page as previous search
    // Don't clear pagination, so state is maintained through subsequent loads
    this.page = Number(getStorage(window.location.href)) || this.page
  },

  beforeMount () {
    // When checkout is complete (or whenever requested) we should clear any
    // saved search data.
    store.state.eventBus?.$on('cart.checkout', this.clearSearches)
  },

  mounted () {
    if (this.autoSearch) {
      this.search({ firstSearch: true })
    }
  },

  beforeUnmount () {
    // Don't forget to remove handlers
    store.state.eventBus?.$off('cart.checkout', this.clearSearches)
  },

  methods: {

    storePage (page) {
      // Store pagination index
      setStorage(window.location.href, JSON.stringify(page))
      this.page = page
    },

    async paginate (page) {
      if (this.usePaginationApi && !this.results?.[page]) {
        await this.search({ page })
      }

      // Set page after search has completed. This is what triggers children to
      // update
      this.storePage(page)
    },

    clearSearches () {
      // Clear inputs
      for (const { payablesKey } of this.searchInputs) {
        this.inputValues[payablesKey] = ''
        removeStorage(window.location.href + payablesKey)
      }
      // Clear stored pagination index
      removeStorage(window.location.href)

      // Clear current state
      this.results = null
      this.showError = false
      this.errorMessage = ''
    },

    async search ({ firstSearch, page } = {}) {
      if (this.$wait.is('searching')) {
        return
      }

      let valid = true
      for (const box of this.$refs.searchBoxes) {
        // Each box should show it's own errors etc.
        const boxValid = box.validate()
        valid = boxValid && valid
      }
      if (!valid) {
        return
      }

      this.$wait.start('searching')

      this.showError = false
      this.errorMessage = ''
      this.autoSearch = false

      // Is the user actively paging through an existing search?
      const paginating = typeof page === 'number'

      if (!paginating) {
        if (!firstSearch) {
          this.storePage(1)
        }
        // Set target page for new searches.
        page = this.page
      }

      try {
        const query = {}
        for (const { payablesKey } of this.searchInputs) {
          query[payablesKey] = this.inputValues[payablesKey]
          // Store search parameters in case user logs in on page
          if (!paginating) {
            setStorage(window.location.href + payablesKey, this.inputValues[payablesKey])
          }
        }

        // Replace query params after subsequent searches
        // Do this on search (not earlier) to preserve state through subsequent
        // reloads
        if (!firstSearch && !paginating && this.$route) {
          // If there are other query params, preserve them while removing
          // search related ones
          const otherParams = Object.keys(this.$route.query).length > 0
            ? redactProps(this.$route.query, [
              // Note that this will remove q1 and below it will be replaced
              // with the proper payablesKey. If the user is paying a lot of
              // attention they might notice, but it shouldn't in any way impede
              // UX. And, this is probably necessary for taxsys pages to get the
              // correct params.
              'q1',
              ...Object.keys(this.inputValues),
            ])
            : {}

          this.$router.replace({
            query: {
              // If it's not a search key then propagate it.
              ...otherParams,
              // inputValues were initialized in data hook.
              ...query,
            },
          })
        }

        // There's some risk that we end up with duplicates etc if results in BE
        // update in between cached FE requests. We don't think this is a
        // significant risk, or would pose any danger even if it happened.
        //
        // If we ever decide this is worth worrying about we can easily add
        // timestamps and give the cache a lifespan.
        //
        // If we ever feel like we need to worry about the total results cached
        // in memory we could add lru invalidation to the cache.

        // TODO: Infer api pagination compatibility from data and use that to
        // decide whether to paginate
        const searchResults = await store.dispatch('searchPayables', {
          payablesAdaptor: this.payablesAdaptor,
          data: {
            query,
            ...(this.adapterOptions ? { options: this.adapterOptions } : {}),
            ...(this.usePaginationApi ? {

              limit: this.limit,
              // Pages are 1 indexed
              offset: this.pageSize * (page - 1),
            } : {}),
          },
          language: this.$i18n.locale,
        }) || []

        const { payables, total = payables.length } = searchResults
        this.total = Number(total)
        if (this.usePaginationApi) {
          // Reset results on new searches (or set initial value)
          if (!paginating || !this.results) {
            this.results = []
          }
          this.results[page] = payables
        }
        else {
          // Build out structure for pages
          // Pages are 1 indexed. Initial value is an empty first page.
          const results = [null, []]
          let i = 0
          while (i < payables.length) {
            // Pages are 1 indexed
            const pageCursor = Math.floor(i / this.pageSize) + 1
            if (!results[pageCursor]) {
              results[pageCursor] = []
            }
            results[pageCursor][i % this.pageSize] = payables[i]
            i++
          }
          this.results = results
        }

        // Only emit on new search
        if (!paginating) {
          this.$emit('search', { results: this.results, query })
        }
      }
      catch (error) {
        console.error('Search error:', error)
        this.showError = true
        this.errorMessage = error?.response?.data?.displayMessage ||
          this.$t('search_error.try_again')
      }
      finally {
        this.$wait.end('searching')
        // If it's not the search on page-load (with cached search input)
        // that means user clicked search, so focus on results section
        if (!firstSearch) {
          if (this.isIndexSearch) {
            // Search results are a child component within FieldSearch
            this.focusSearchResults()
          }
          else {
            // Search results are in fieldSearchWrapper so emit to that
            // component to handle focusing
            this.$emit('search-button-clicked')
          }
        }
      }
    },

    focusSearchResults () {
      this.$nextTick(() => {
        setTimeout(() => {
          const searchResults = this.$refs.searchResults

          if (searchResults) {
            const resultsSection = searchResults.$refs.resultsSection

            if (resultsSection) {
              resultsSection.focus()
            }
          }
        }, 100)
      })
    },

    autofillInput (payablesKey, queryKey = payablesKey) {
      // Clear each input
      this.inputValues[payablesKey] = ''

      const queryValue = this.$route.query[queryKey]
      if (!queryValue) {
        return
      }

      this.inputValues[payablesKey] = queryValue
      this.autoSearch = true
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
