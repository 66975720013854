/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // forms-public-legacy-prod & -nonprod projects
    prod: 'https://203fdf6dc23c4c5981445e9391ced383@o168195.ingest.sentry.io/5943562',
    nonprod: 'https://92166f2b6ced41c78423f316aae32089@o168195.ingest.sentry.io/5943560',
  },
})
