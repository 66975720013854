<template>
  <div class="iframe-styles">
    <UserVerificationLoader
      component="UserVerificationPIN"
      :payable-save-path="payableSavePath"
      :payables="payables"
      :user-verification-mode="userVerificationMode"
      :has-requested-verification="hasRequestedVerification"
      :status="status"
      :is-authenticated="isAuthenticated"
      :loading="!loaded"
      :status-error="statusError"
      :status-error-text="statusErrorText"
      @change-user-verification-mode="changeUserVerificationMode"
      @request-pin="requestPIN"
      @enter-pin="enterPIN"
    />
  </div>
</template>

<script>
import { UserVerificationLoader } from '@grantstreet/user-verification-public'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import { v4 as uuid } from 'uuid'

export default {
  components: {
    UserVerificationLoader,
  },
  data: () => ({
    id: uuid(),
    loaded: false,
    loadError: false,
    status: undefined,
    hasRequestedVerification: false,
    isAuthenticated: false,
    statusError: false,
    statusErrorText: '',
    userVerificationMode: 'closed',
    namespace: 'user-verification',
  }),
  props: {
    payableSavePath: {
      type: String,
      required: true,
    },
    payables: {
      type: Array,
      required: true,
    },
    parentWhisperer: {
      type: iframeWhisperer.ToParent,
      required: true,
    },
    sharedUUID: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * This component will whisper to its parent using the vanilla namespace.
     * On mount, this will register this ID with the parent.
     * The parent will whisper directly to each child using this uidNamespace.
     */
    uidNamespace () {
      return `${this.namespace}-${this.id}`
    },
  },
  methods: {
    changeUserVerificationMode (mode) {
      this.parentWhisperer.message({
        action: `${this.namespace}.changeUserVerificationMode`,
        payload: {
          mode,
          payableSavePath: this.payableSavePath,
          payables: this.payables,
          sharedUUID: this.sharedUUID,
        },
      })
    },
    toggleWait (enable) {
      if (enable) {
        this.$wait.start(`user verification status ${this.payableSavePath}`)
      }
      else {
        this.$wait.end(`user verification status ${this.payableSavePath}`)
      }
    },
    requestPIN (event) {
      this.toggleWait(true)
      this.parentWhisperer.message({
        action: `${this.namespace}.requestPIN`,
        payload: {
          payableSavePath: event.payableSavePath,
          payables: event.payables,
        },
      })
    },
    enterPIN (event) {
      this.toggleWait(true)
      this.parentWhisperer.message({
        action: `${this.namespace}.enterPIN`,
        payload: {
          payableSavePath: event.payableSavePath,
          payables: event.payables,
          sharedUUID: this.sharedUUID,
          PIN: event.PIN,
        },
      })
    },
  },
  async mounted () {
    // register state change listener
    this.parentWhisperer.on(`${this.uidNamespace}.UserVerificationStateChanged`, ({
      hasRequestedVerification, isAuthenticated, mode, status, statusError, statusErrorText, waiting,
    }) => {
      this.hasRequestedVerification = hasRequestedVerification
      this.isAuthenticated = isAuthenticated
      this.userVerificationMode = mode
      this.status = status
      this.statusError = statusError || false
      this.statusErrorText = statusErrorText || ''
      this.loaded = true
      this.toggleWait(waiting)
    })

    // register component with parent whisperer
    // Register this UID with parent whisperer to receive individual messages
    try {
      this.parentWhisperer.message({
        action: `${this.namespace}.register`,
        payload: {
          payableSavePath: this.payableSavePath,
          payables: this.payables,
          iframeId: this.id,
          sharedUUID: this.sharedUUID,
          component: 'status',
        },
      })
    }
    catch {
      this.loaded = true
      this.loadError = true
    }
  },
}
</script>
