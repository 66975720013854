/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star-outline': {
    width: 16,
    height: 16,
    viewBox: '1494.5 1170 23 24',
    data: '<path pid="0" d="M1506 1170.5l3 9h8l-6 5 3 9-8-6-8 6 3-9-6-5h8z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
