// This is the TaxSys API wrapper. Make sure to keep this API interface in sync
// with the TaxSysIframe component in @grantstreet/govhub-ui.

import iframeWhisperer from '@grantstreet/iframe-whisperer'

export default class TaxSysIframeApi {
  #interface
  #namespace = 'taxsysIframe'

  constructor ({ actions, messageSource = 'gsg-taxsys-iframe' } = {}) {
    if (!iframeWhisperer.isInIframe()) {
      throw new Error('TaxSysIframeApi needs to be instantiated inside an iframe.')
    }

    this.#interface = new iframeWhisperer.ToParent({ actions, messageSource })
  }

  #getAction (action) {
    return `${this.#namespace}.${action}`
  }

  #message (action, payload) {
    return this.#interface.message({
      action: this.#getAction(action),
      payload,
    })
  }

  sendLoadError (payload) {
    this.#interface.sendLoadError(payload)
  }

  /**
     * @function navigate
     * Navigates to specified destination.
     * *This documentation is duplicated. Please keep instances in sync*
     * Ways to navigate:
     *            | vue              | browser
     * -----------+------------------+------------------------------------------
     * navigation | router.push()    | window.location.href = url
     * push       | N/A              | history.pushState()
     * replace    | router.replace() | history.replaceState()
     * external   | N/A              | window.open(url, '_blank')
     *
     * Navigation mechanism is controlled by the requested type (default:
     * 'navigation'). And the navigator/utility used is controlled by the
     * structure of `to`.
     * When making type `navigation`, `replace`, or `external` call, if `to` is
     * an object or relative url, then vue-router is used.
     * Examples:
     * api.navigate({ to: '/sunshine/demo/checkout' })
     * api.navigate({ to: { path: '/sunshine/demo/checkout' } })
     * api.navigate({ to: { path: '/checkout', delegateBaseUrl: true } })
     * api.navigate({ to: { name: 'checkout' } })
     *
     * If `to` is an absolute url (including protocol), or if using the `push`
     * option, then native api is used.
     * Examples:
     * api.navigate({ to: 'https://govhub.com/sunshine/demo/checkout' })
     * api.navigate({ to: 'https://example.com', type: 'external' })
     * api.navigate({ to: 'https://example.com', type: 'push' })
     * api.navigate({ to: '/sunshine/demo/taxsys/2', type: 'push' })
     * @throws {Error}                   - Throws if argument combinations are
     * not compatible.
     * @param  {string|object} to        - An absolute or relative url; or a
     * vue-router compatible object with `path` or `name` attributes.
     * @param  {string} [type]           - String indicating the type of
     * navigation to execute.
     * @param  {boolean} delegateBaseUrl - If true, the parent application
     * prepends whatever base it would like to relative urls.
     */
  navigate ({
    to,
    type = 'navigation',
    delegateBaseUrl = false,
  }) {
    return this.#message('navigate', { to, type, delegateBaseUrl })
  }

  addToCart (items) {
    return this.#message('addToCart', items)
  }

  requestParentScrollTo (y) {
    return this.#message('scrollToPosition', y)
  }

  getJWT () {
    return this.#message('getJWT')
  }

  get iframeWhisperer () {
    return this.#interface
  }
}
