export const makeRouteKey = ({ path, name, meta: { title, link } = {} }) => `${path}-${name || link}}-${title?.en}`

export const formatParams = ({ client, site, ...rest } = {}) => {
  return {
    client: client?.toLowerCase ? client.toLowerCase() : client,
    site: site?.toLowerCase ? site.toLowerCase() : site,
    ...rest,
  }
}

/**
 * Navigates to the given route, storing arbitrary state that can be retrieved
 * by the next route via the browser History API
 * (https://developer.mozilla.org/en-US/docs/Web/API/History/state).
 *
 * The next route can retrieve the state in its mounted hook via
 * window.history.state.
 *
 * This is a helper function because we have to explicitly JSON stringify/parse
 * the state object, otherwise the History API (or Vue router) will completely
 * fail to store these state values if it encounters a non-vanilla JS object
 * (e.g., Transaction model objects that are part of the `payment` object passed
 * to receipt pages).
 *
 * Example:
 *
 *   // In the initial route component:
 *   pushRouteWithState(this.$router, {
 *     name: 'receipt',
 *     state: { payment: { ... } },
 *   })
 *
 *   // In the receipt route's mounted hook:
 *   const payment = window.history.state.payment
 *
 * @param {Router} router
 * @param {RouteLocationRaw} to
 */
export const pushRouteWithState = (router, to) => {
  if (!to.state) {
    throw new Error('no state passed to pushRouteWithState')
  }

  to.state = JSON.parse(JSON.stringify(to.state))

  return router.push(to)
}
