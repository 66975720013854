<template>
  <div v-if="!redirect">
    <div>
      <div
        v-if="query === ''"
        class="content-card"
      >
        <div v-dompurify-html="helpText" />
      </div>

      <div
        v-else-if="pages > 0"
        class="smaller"
      >
        <span class="label">Page</span> {{ page + 1 }} of {{ pages }}
      </div>

      <div
        v-else
        class="content-card"
      >
        <div v-dompurify-html="noResults" />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState } = createNamespacedHelpers('GovHubSearch')

export default {
  props: {
    pages: {
      type: Number,
      required: true,
    },
    // zero-indexed page number
    page: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters([
      'query',
    ]),

    ...mapState([
      'helpText',
      'noResults',
      'redirect',
    ]),
  },
}
</script>

<style lang="scss" scoped>

.label {
  color: $headings-color;
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
  text-transform: capitalize;
}

</style>
