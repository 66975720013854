<template>
  <div>
    <div v-if="variation === 'short'">
      <h4
        class="mb-0"
        :class="textClasses.displayName"
      >
        {{ payable.displayName }}
      </h4>
      <div
        v-if="expirationDate"
        :class="textClasses.description"
      >{{ $t("expires.label") }} {{ expirationDate }}</div>
    </div>
    <div v-else>
      <h4
        class="mb-0"
        :class="textClasses.displayName"
      >
        {{ payable.displayName }}
      </h4>
      <div
        v-if="vehicleType"
        :class="textClasses.description"
      >{{ vehicleType }}</div>
      <div
        v-if="expirationDate"
        :class="textClasses.description"
      >{{ $t("expires.label") }} {{ expirationDate }}</div>
      <div
        v-if="vehicleDetails"
        :class="textClasses.description"
      >{{ vehicleDetails }}</div>
      <div
        v-if="payable.accountIdentifier && variation === 'long'"
        class="mr-1 mt-2"
        :class="textClasses.description"
      >{{ $t("payments.plate") }}<b>{{ payable.accountIdentifier }}</b></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    textClasses () {
      return {
        displayName: `text-${this.darkDisplay ? 'dark' : 'body'}`,
        description: this.smallDescription ? 'small' : '',
      }
    },
  },
  props: {
    payable: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: '',
    },
    darkDisplay: {
      type: Boolean,
      default: false,
    },
    smallDescription: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      vehicleType: this.payable.customParameters.vehicle_type,
      expirationDate: this.payable.customParameters.expiration_date,
      vehicleDetails: this.payable.customParameters.vehicle_details,
    }
  },
}
</script>
