<template>
  <b-form
    id="confidentialSearchForm"
    action="/public/search/confidential"
  >

    <b-form-group>
      <b-row>
        <b-col>
          Please enter the information below for the current tax year to view and pay your bill.
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <label for="confidential-tax-type">
            Tax type
          </label>
        </b-col>

        <b-col md="4">
          <b-form-select
            v-model="taxType"
            :options="taxTypes"
            :state="!v$.taxType.$error"
            @input="v$.taxType.$touch()"
          />
          <ValidationErrors
            :errors="formError.taxType"
            :validator="v$.taxType"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <label :for="confidential.id">
            {{ confidential.label }}
          </label>
        </b-col>

        <b-col md="4">
          <b-form-input
            v-model="account"
            :state="!v$.account.$error"
            @input="v$.account.$touch()"
          />
          <ValidationErrors
            :errors="formError.account"
            :validator="v$.account"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <label for="confidential-combined-tax-assessment">
            Combined tax and assessment
          </label>
        </b-col>

        <b-col md="4">
          <b-form-input
            v-model="assessment"
            type="number"
            min="0.00"
            step="0.01"
            :state="!v$.assessment.$error"
            @input="v$.assessment.$touch()"
          />
          <ValidationErrors
            :errors="formError.assessment"
            :validator="v$.assessment"
          />
        </b-col>
      </b-row>

      <b-row v-if="confidential.example">
        <b-col>
          <b-link :href="confidential.example">
            Where do I find this information on my bill?
          </b-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="7"
          class="d-flex justify-content-end"
        >
          <ProgressButton
            :disabled="isPending"
            :waiting="isPending"
            variant="primary"
            @click="submit"
          >
            View bill
          </ProgressButton>
        </b-col>
      </b-row>
    </b-form-group>
  </b-form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { decimal, required, requiredIf } from '@vuelidate/validators'
import { createNamespacedHelpers } from 'vuex'
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import { routing } from '../mixins/routing.js'

const { mapMutations, mapState } = createNamespacedHelpers('GovHubSearch')

export default {
  components: {
    ProgressButton,
    ValidationErrors,
  },

  mixins: [
    routing,
  ],

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    taxTypes: {
      type: Array,
      default: function () {
        return [{
          value: 'ca',
          text: 'Central',
        }, {
          value: 're',
          text: 'Real Estate',
        }, {
          value: 'tp',
          text: 'Tangible',
        }]
      },
    },
  },

  data () {
    return {
      account: '',
      assessment: '',
      isPending: false,
      taxType: 're',
    }
  },

  computed: {
    ...mapState([
      'confidential',
    ]),

    formError () {
      return {
        account: 'Please specify the ' + this.confidential.label.toLowerCase(),
        assessment: 'Please specify a dollar value',
        taxType: 'Tax type must be either Central, Real Estate, or Tangible',
      }
    },
  },

  methods: {
    ...mapMutations([
      'setConfidentialPage',
    ]),

    setError (error) {
      if (!error) {
        error = 'Something went wrong, please try again later.'
      }

      document.getElementById('message_container').innerHTML =
        '<div class="col-12"><div class="alert alert-danger" role="alert">' + error + '</div></div>'
    },

    submit () {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.isPending = true
      document.getElementById('message_container').innerHTML = ''

      const req = new XMLHttpRequest()

      req.onerror = () => {
        this.setError()
      }

      req.onload = () => {
        if (req.status !== 200) {
          this.setError()
          return
        }

        const xml = req.responseXML
        const errors = xml.querySelector('response[type=element_errors]')

        if (errors && errors.childElementCount > 0) {
          this.setError(errors[0])
          return
        }

        const res = xml.querySelector('response[type=json]')

        if (res) {
          const json = JSON.parse(res.childNodes[0].data)

          if (json.error) {
            this.setError(json.error)
            return
          }

          if (json.content) {
            this.pushRoute({ query: { confidentialBill: 1 } })
            this.setConfidentialPage(json.content)

            return
          }
        }

        this.setError()
      }

      req.onloadend = () => {
        this.isPending = false
      }

      const runmode = new URL('/public/search/confidential', window.location.href)
      const params = runmode.searchParams

      params.set('ajax_request', 1)
      params.set('tax_assessment', this.assessment)
      params.set('tax_type', this.taxType)
      params.set(this.confidential.name, this.account)

      req.open('GET', runmode.toString(), true)
      req.send()
    },
  },

  validations: {
    account: {
      required,
    },

    assessment: {
      required,
      decimal,
    },

    taxType: {
      required: requiredIf(function (value) {
        return value && this.taxTypes.find((type) => value === type)
      }),
    },
  },
}
</script>
