import { firstToUpperCase } from '@grantstreet/psc-js/utils/cases.js'

/**
 * Constructs a new notification.
 * @class Notification
 */
export default class Notification {
  /**
   * @constructor
   * @param  {object} params  A parameters object.
   * @return {object}         A new instance of class Notification.
   */
  constructor ({
    id,
    clientSites = {},
    subject = {},
    text = {},
    tags,
    active = false,
    locale = 'en',
    createdAt,
    createdDate = new Date(createdAt),
    clientsText = '',
    sitesText = '',
    futureDate,
  }) {
    Object.assign(this, {
      id,
      clientSites,
      subject: {
        en: subject.en || '',
        es: subject.es || '',
      },
      text: {
        en: text.en || '',
        es: text.es || '',
      },
      // Handles api returning null
      tags: tags || [],
      active,
      locale,
      createdDate,
      clientsText,
      sitesText,
      futureDate,
    })
  }

  get dateToUse () {
    return this.futureDate ? new Date(this.futureDate).getTime() : this.createdDate
  }
}

// This factory returns a getter function that will return the localized version
// of a given prop.
const getLocalizedGetter = (prop) => function () {
  return this[prop][this.locale] || this[prop].en
}

// Set localization getters for several properties
// This will allow for things like:
// notification.localizedText // 'I'm localized text'
// notification.locale = 'es'
// notification.localizedText // 'I'm text in Espanol but you can't tell because
//                            the author of this comment doesn't speak Spanish.'
for (const prop of ['text', 'subject']) {
  const localizedProp = 'localized' + firstToUpperCase(prop)
  Object.defineProperty(Notification.prototype, localizedProp, {
    get: getLocalizedGetter(prop),
  })
}
