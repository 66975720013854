/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert-megaphone': {
    width: 16,
    height: 16,
    viewBox: '1444 1172 24 20',
    data: '<g _fill="none" _stroke="#666" stroke-linejoin="round"><path pid="0" d="M1458.5 1188.5l-.7 2.092a1 1 0 01-1.223.645l-5.012-1.437a1 1 0 01-.654-1.333l.589-1.467"/><path pid="1" d="M1465.207 1172.793l-.707.707-17 5h-2a1 1 0 00-1 1v5a1 1 0 001 1h2l17 5 .707.707a1 1 0 00.707.293h.586a1 1 0 001-1v-17a1 1 0 00-1-1h-.586a1 1 0 00-.707.293zM1447.5 1178.5v7" stroke-linecap="round"/></g>'
  }
})
