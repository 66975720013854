<template>
  <!-- Here, we're *intentionally* overriding the default container margins -->
  <div
    class="container-fluid bg-white rounded px-4 px-md-8 py-5 mb-2"
  >

    <slot
      name="cornerIndicator"
      :payable="payable"
    />

    <div class="row">
      <div class="col-12 col-md-9 details d-block">
        <h4 class="text-body mb-0">{{ payable.displayName }}</h4>
        <!-- .preserve-newlines means that this has to *not* have any
          whitespace between the tags and the output -->
        <div
          v-if="payable.description"
          class="preserve-newlines"
        >{{ payable.description }}</div>
      </div>

      <div class="col-12 col-md-3 mt-3 mt-lg-0">
        <slot
          name="payableActions"
          :payable="payable"
        />
      </div>
    </div>

    <!-- Optional Parent Pay All -->
    <div
      v-if="payable.childPayables.length > 4"
      class="py-3 py-md-2 position-relative"
    >
      <SearchResult
        :payable="payable"
        :title-class="titleClass"
        :col-limit="colLimit"
        is-parent-item
      >
        <template #payableActions>
          <!-- These multi-results don't get the full treatment -->
          <slot
            v-if="payable.childPayables.length > 1 && payable.amount > 0"
            name="addButton"
            :payable="payable"
          />
        </template>
      </SearchResult>
    </div>

    <!-- List of Children -->
    <div
      v-for="(child, index) in payable.childPayables"
      :key="child.path"
      data-test="child"
      class="py-3 py-md-2 position-relative"
      :class="{
        'border-top': index > 0
      }"
    >
      <SearchResult
        :payable="child"
        :title-class="titleClass"
        :col-limit="colLimit"
        is-child-item
      >
        <template #payableActions>
          <slot
            name="addButton"
            :payable="child"
          />
        </template>
      </SearchResult>
    </div>

    <!-- Parent Pay All -->
    <div
      v-if="payable.childPayables.length !== 1"
      data-test="parent"
      class="py-3 py-md-2 position-relative"
    >
      <SearchResult
        :payable="payable"
        :title-class="titleClass"
        :col-limit="colLimit"
        is-parent-item
      >
        <template #payableActions>
          <slot
            v-if="payable.childPayables.length > 1 && payable.amount > 0"
            name="addButton"
            :payable="payable"
          />
        </template>
      </SearchResult>
    </div>

    <!-- EBilling signup component is passed to this ancillary slot -->
    <slot
      name="payableAncillary"
      :payable="payable"
    />

  </div>
</template>

<script>
import SearchResult from './SearchResult.vue'

export default {
  components: {
    SearchResult,
  },

  props: {
    payable: {
      type: Object,
      required: true,
    },
    titleClass: {
      type: String,
      default: '',
    },
    colLimit: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
