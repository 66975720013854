/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  addGlobalVueSentryErrorHandler,
  chainClient,
} = sentryFactory({
  // Logs to the User Verification Sentry projects.
  dsns: {
    // user-verification-ui-prod
    prod: 'https://9ede5766ca773d10787223efcea222a1@o168195.ingest.sentry.io/4506773626880000',
    // user-verification-ui-nonprod
    nonprod: 'https://e05856a87fbe3d56063555db9c01a166@o168195.ingest.sentry.io/4506773623668736',
  },
})
