<template>
  <div
    class="transition-padding px-3 py-2 px-lg-5 text-left position-relative"
    :class="{
      'py-lg-4': expanded,
    }"
    :data-test="`notification-id-${notification.id}`"
  >

    <div class="notification-title mb-1 mb-sm-0">
      <strong>{{ notification.localizedSubject }}</strong>
    </div>

    <b-collapse
      :visible="expanded"
      data-test="notification-collapse"
      class="mb-1 mb-sm-0"
    >
      <div v-dompurify-html="notification.localizedText" />
      <template
        v-if="debug"
      >
        <br>
        <br>
        Tags: <b-badge
          v-for="tag of notification.tags"
          :key="tag"
          variant="secondary"
          class="mr-1"
        >{{ tag }}</b-badge>
        <br>
        <br>
      </template>
    </b-collapse>

    <div class="posted-date font-italic mt-1">
      <span class="d-none d-sm-inline">{{ $t('notifications.posted_on') }}: </span>{{ postedDate }}
    </div>

    <b-link
      v-if="collapsible"
      data-test="notification-expand"
      class="expand-link d-block"
      :class="{expanded}"
      @click="toggle"
    >
      {{ expanded ? $t('notifications.less') : $t('notifications.more') }}
    </b-link>

  </div>
</template>

<script>
import { formatDate } from '@grantstreet/psc-js/utils/date.js'

export default {
  emits: ['toggled'],
  props: {
    notification: {
      type: Object,
      required: true,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    initOpen: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      expanded: this.initOpen || !this.collapsible,
    }
  },

  computed: {
    postedDate () {
      return formatDate(this.notification.createdDate, false, this.$i18n.locale)
    },
  },

  methods: {
    toggle () {
      this.expanded = !this.expanded
      this.$emit('toggled', this.expanded)
      setTimeout(() => {
        this.$emit('toggled', this.expanded)
        // .35s is the collapse time of b-collapse
      }, 350)
    },
  },
}
</script>

<style lang="scss" scoped>

.notification-title {
  // At < sm sizes the link sits beside the date, not the title

  @include media-breakpoint-up(sm) {
    // This allows room for the title to not overlap the View Details link
    padding-right: 7.25rem !important;
  }
}

// Transitioning sizing properties like this is horribly inefficient since it
// requires the browser to do a reflow. Unfortunately the effect we want isn't
// Attainable using transform properties, and the other alternatives to this are
// worse.
.transition-padding {
  // .35s is the collapse time of b-collapse
  transition: padding-top .35s, padding-bottom .35s;
}

.expand-link {
  background-color: $input-bg;
  position: absolute;
  // When unexpanded the link sits in line with the title. This means it sits
  // .5rem up to account for the collapsed padding. Then it translates up
  // another 1.25rem for to account for the 1 line-height of the date.
  bottom: map-get($spacers, 2);
  right: map-get($spacers, 5);
  // At < sm sizes the link sits directly beside the date
  transform: translateY(0);
  transition: transform .35s;

  @include media-breakpoint-up(sm) {
    // See comment above
    transform: translateY(-1.25rem);
  }

  &.expanded {
    transform: translateY(0);

    // When expanded the link sits in line with the date. Swap the -1.25rem for
    // 1rem which is the difference between the parent's expanded padding and
    // it's collapsed padding.
    // I.e. map-get($spacers, 4) = map-get($spacers, 3) + map-get($spacers, 2)
    @include media-breakpoint-up(lg) {
      transform: translateY(- map-get($spacers, 3));
    }
  }
}

</style>
