// This file exists because in order to use the genereated SVG icon JS files you
// have to have access to the vue-svgicon singleton from this package. Otherwise
// if you `import 'vue-svgicon'` in your own package you will be referring to
// your own singleton (and the icons will be registered with the singleton in
// the @grantstreet/bootstrap package).

import 'vue-svgicon/dist/polyfill.js' // make icons work in IE11
import VueSVGIcon from 'vue-svgicon'

export default VueSVGIcon
