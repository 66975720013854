<!--
⚠️ After making changes to this component, please go to
@grantstreet/widget-bundles, run `yarn build`, and copy
widget-bundles/dist/current/taxsys-iframe-public.[js|css] to
govhub-ui/public/[js|css]/current/taxsys-iframe-public.[js|css]. This is used by the TaxSys
demo page, viewable in a sandbox at /sunshine/demo/taxsys
-->
<template>
  <div
    v-if="useEbilling"
    :id="'e-billing-accordion-link-' + id"
    :key="'e-billing-accordion-link-'
      + id"
    class="iframe-styles"
  >
    <LoadingBars
      v-if="!loaded"
      :include-text="false"
      class="py-2"
    />
    <span v-else-if="errorMessage">{{ errorMessage }}</span>

    <EBillingLoader
      v-else
      component="EBillingAccordionLink"
      :payable-save-path="payableSavePath"
      :has-subscription="hasSubscription"
      :is-authenticated="isAuthenticated"
      @change-e-billing-mode="changeEBillingMode"
    />
  </div>
</template>

<script>
import { EBillingLoader } from '@grantstreet/e-billing-public'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import { v4 as uuid } from 'uuid'
export default {
  components: {
    LoadingBars,
    EBillingLoader,
  },
  props: {
    payableSavePath: {
      type: String,
      required: true,
    },
    parentWhisperer: {
      type: iframeWhisperer.ToParent,
      required: true,
    },
    sharedUUID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    id: uuid(),
    loaded: false,
    // When this is false, the e-billing link will not be rendered
    // This gets updated in the mounted lifecycle to reflect the actual site setting.
    useEbilling: false,
    hasSubscription: false,
    isAuthenticated: false,
    errorMessage: '',
    namespace: 'e-billing',
  }),
  computed: {
    /**
     * This component will whisper to its parent using the vanilla namespace.
     * On mount, this will register this ID with the parent.
     * The parent will whisper directly to each child using this uidNamespace.
     */
    uidNamespace () {
      return `${this.namespace}-${this.id}`
    },
  },
  methods: {
    handleError () {
      this.loaded = true
      this.errorMessage = 'We were unable to complete your request. Please try again later.'
    },
    changeEBillingMode () {
      const mode = this.hasSubscription
        ? 'unsubscribe'
        : 'subscribe'
      this.parentWhisperer.message({
        action: `${this.namespace}.changeEBillingMode`,
        payload: {
          payableSavePath: this.payableSavePath,
          sharedUUID: this.sharedUUID,
          mode,
        },
      })
    },
  },
  async mounted () {
    // register state change listener
    this.parentWhisperer.on(`${this.uidNamespace}.EBillingStateChanged`, ({
      hasSubscription, isAuthenticated, useEbilling,
    }) => {
      this.hasSubscription = hasSubscription
      this.isAuthenticated = isAuthenticated
      this.useEbilling = useEbilling
      this.loaded = true
    })

    // Register this UID with parent whisperer to receive individual messages
    try {
      this.parentWhisperer.message({
        action: `${this.namespace}.register`,
        payload: {
          payableSavePath: this.payableSavePath,
          iframeId: this.id,
          sharedUUID: this.sharedUUID,
          component: 'accordionLink',
        },
      })
    }
    catch {
      this.handleError()
    }
  },
}
</script>
