<template>
  <div class="container root d-flex flex-column align-items-center">
    <!-- Primary Icon -->
    <svgicon
      :name="`${status ? 'progress-bar-checkmark' : 'mail-circle'}`"
      height="10rem"
      width="10rem"
      :color="`${status ? 'r-#6A9D35 white' : ''}`"
    />

    <!-- Invalid URL error -->
    <div
      v-if="!isValidUrl || showGenericError"
      class="ebilling-error rounded d-flex align-items-center mt-3 p-3"
    >
      <svgicon
        original
        class="mr-3"
        icon="x-circle"
        width="2rem"
        height="2rem"
      />
      {{ invalidUrlText }}
    </div>

    <!-- activation content -->
    <template v-else-if="action === 'activate'">
      <!-- Loading bar for activation action -->
      <LoadingBars
        v-if="status === undefined"
        :include-text="false"
        class="py-2"
      />
      <template v-else-if="status">
        <h3 class="mt-5">{{ $t('ebilling.email_verified') }}</h3>
        <b-button
          variant="primary"
          class="mt-3"
          :to="({ name: 'home', params: $route.params })"
        >{{ $t('ebilling.email.return_link') }}</b-button>
      </template>
      <template v-else-if="!status">
        <h3 class="mt-5">{{ $t('ebilling.email_expired') }}</h3>
        <p class="mt-3 text-center">{{ $t('ebilling.email_resent') }}</p>
      </template>
    </template>

    <!-- Unsubscribe content -->
    <template v-else-if="action==='unsubscribe'">
      <h3 class="mt-5">{{
        status
          ? $t('ebilling.email_unsubscribe', {display_type: subscription?.displayType})
          : $t('ebilling.confirmation_code.title') }}
      </h3>
      <b-button
        v-if="status"
        variant="primary"
        class="mt-3"
        :to="({ name: 'home', params: $route.params })"
      >{{ $t('ebilling.email.return_link') }}</b-button>

      <div
        v-if="!status"
        class="container confirmation-code-container bg-white rounded-xl p-4"
      >
        <!-- Errors specific to the unsubscribe code -->
        <b-collapse :visible="status === false">
          <div
            class="ebilling-error rounded d-flex align-items-center mt-3 p-3 mb-4"
          >
            <svgicon
              original
              class="mr-3"
              icon="x-circle"
              width="2rem"
              height="2rem"
            />
            {{ $t('ebilling.incorrect_code') }}
          </div>
        </b-collapse>
        <!-- description -->
        <p>{{ $t('ebilling.confirmation_code.description') }}</p>

        <!-- Wait for the unsub code request before displaying the form -->
        <LoadingBars
          v-if="!subscription && status === undefined"
          :include-text="false"
          class="py-2"
        />
        <template v-else-if="subscription">
          <!-- Unsubscribe code form -->
          <b-row>
            <b-col
              sm="12"
              md="8"
            >
              <b-form-input
                v-model="code"
                name="code"
                placeholder="####"
                :state="!v$.code.$error && status !== false"
                @blur="v$.code.$touch()"
                @keydown.enter="submitCode"
              />
              <ValidationErrors
                v-if="v$.code.$error"
                :validator="v$.code"
                :errors="{
                  required: $t('self_serve.required'),
                  alphaNum: $t('bank.account_number_only_alphanumeric'),
                }"
              />
            </b-col>
            <b-col
              sm="12"
              md="4"
            >
              <ProgressButton
                ref="submit"
                :waiting="$wait.is(`e-billing subscription`)"
                variant="primary"
                class="ml-0 ml-md-2 mt-2 mt-md-0"
                :disabled="!isValidCode"
                @click="submitCode"
              >
                {{ $t('submit.label') }}
              </ProgressButton>
            </b-col>
          </b-row>

          <!-- Resend code link -->
          <!-- Let user know a request was made when the link is clicked. -->
          <!-- Stop the user from clicking repeatedly. -->
          <div class="mt-3">
            <p v-if="requestUnsubscribeCount >= 2">
              {{ $t('ebilling.confirmation_code.resent_message') }}
            </p>
            <b-link
              v-else
              @click.prevent="requestUnsubscribeCode"
            >{{ $t('ebilling.confirmation_code.resend_code') }}</b-link>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import { version, validate } from 'uuid'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, alphaNum } from '@vuelidate/validators'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import { sentryException } from '../sentry.ts'
import store from '../store.ts'
import '@grantstreet/bootstrap/icons/js/x-circle.js'
export default {
  emits: ['set-locale'],
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  components: {
    LoadingBars,
    ValidationErrors,
    ProgressButton,
  },
  data: () => ({
    status: undefined,
    showGenericError: false,
    code: '',
    subscription: null,
    requestUnsubscribeCount: 0,
  }),
  validations: {
    code: {
      required,
      alphaNum,
    },
  },
  computed: {
    subscriptionId () {
      const subscriptionId = this.$route.params.subscriptionId

      // Ensure id is a valid v4 uuid
      if (validate(subscriptionId) && version(subscriptionId) === 4) {
        return subscriptionId
      }
      return ''
    },
    authCode () {
      return this.$route.params.authCode
    },
    action () {
      const action = this.$route.params.action
      if (action !== 'activate' && action !== 'unsubscribe') {
        return null
      }
      return action
    },
    language () {
      return this.subscription?.language
    },
    isValidUrl () {
      if (this.action === 'activate') {
        return this.subscriptionId && this.authCode
      }
      if (this.action === 'unsubscribe') {
        return this.subscriptionId
      }
      return false
    },
    invalidUrlText () {
      if (this.isValidUrl && !this.showGenericError) {
        return ''
      }
      if (this.action === 'unsubscribe') {
        return this.$t('ebilling.unsubscribe_error')
      }
      // The user is trying to verify.
      return this.$t('ebilling.unable_confirm')
    },
    isValidCode () {
      return !this.v$.$invalid
    },
  },
  async mounted () {
    // Submit activation request if valid url
    if (!this.isValidUrl) {
      return
    }

    if (this.action === 'activate') {
      this.activateSubscription()
    }
    if (this.action === 'unsubscribe') {
      this.requestUnsubscribeCode()
    }
  },
  methods: {
    async requestUnsubscribeCode () {
      try {
        const res = await store.dispatch('requestUnsubscribeCode', this.subscriptionId)
        // Store the subscription for the successful unsubscribe message
        if (res?.status === 200 && res?.data) {
          this.subscription = res?.data
          // Don't let the user request a code if they've clicked the link
          // after the initial load of the page.
          this.requestUnsubscribeCount++
        }
      }
      catch (error) {
        // Display generic try again later error if the subscription doesn't exist
        this.showGenericError = true
        sentryException(error)
      }
    },
    async activateSubscription () {
      try {
        const res = await store.dispatch('activateSubscription', {
          subscriptionId: this.subscriptionId,
          authCode: this.authCode,
        })
        // Store the subscription - this will update the GovHub language
        if (res.data) {
          this.subscription = res.data
        }
        if (res.status === 200) {
          this.status = true
        }
      }
      catch (error) {
        switch (error?.response?.status) {
        case 200: break
        case 400:
          // This indicates the auth code expired. A spefici error message will
          // be displayed
          this.status = false
          break
        default:
          // Any other status code indicates something generically went wrong
          this.status = false
          this.showGenericError = true
          sentryException(error)
          break
        }
      }
    },
    async submitCode () {
      this.status = undefined
      if (!this.isValidCode) {
        this.v$.$touch()
        return
      }
      this.$wait.start('e-billing subscription')
      try {
        // unsubscribe payables returns undefined if successful
        const res = await store.dispatch('unsubscribePayable', {
          accountPayableSavePath: '',
          email: '',
          unsubscribeCode: this.code,
          subscriptionId: this.subscriptionId,
        })
        this.status = res?.status === 200
      }
      catch (error) {
        // Display invalid code error
        this.status = false
        // Don't log invalid code errors to sentry
        if (error?.response?.status !== 403) {
          sentryException(error)
        }
      }
      finally {
        this.$wait.end('e-billing subscription')
      }
    },
  },
  watch: {
    language (newLanguage, oldLanguage) {
      if (newLanguage === oldLanguage) {
        return
      }
      this.$emit('set-locale', newLanguage)
    },
  },
}
</script>

<style lang="scss" scoped>
.root {
  margin-top: 100px;
}

.confirmation-code-container {
  max-width: 700px;
}

h3 {
  font-size: 32px;
}
.ebilling-error {
  color: #721C27;
  background-color: #F9DCDF;
  border: 1px solid #F5C6CB;
}
</style>
