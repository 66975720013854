/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'document': {
    width: 16,
    height: 16,
    viewBox: '1647 1270 18 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1663.5 1293.5h-15a1 1 0 01-1-1v-17l5-5h11a1 1 0 011 1v21a1 1 0 01-1 1z"/><path pid="1" d="M1652.5 1270.5v4a1 1 0 01-1 1h-4zM1652.5 1282.5h8M1652.5 1287.5h8"/></g>'
  }
})
