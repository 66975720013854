import BulkUploadConfig, { PaymentOptionType } from '../BulkUploadConfig.ts'
import templateFile from '../example-templates/florida.js'

class FloridaConfig extends BulkUploadConfig {
  constructor () {
    super()

    this.paymentOptions = [{ type: PaymentOptionType.Online }]
    this.exampleTemplate = templateFile
    this.csvColumns = {
      year: {
        columnKey: 'year',
        columnName: 'Year',
        sortOrder: 100,
      },
      propertyType: {
        columnKey: 'propertyType',
        columnName: 'Property Type',
        sortOrder: 200,
      },
      parcelNumber: {
        columnKey: 'parcelNumber',
        columnName: 'Parcel Number',
        sortOrder: 300,
      },
    }

    this.bulkSearchBatchSize = 50
  }
}

export default FloridaConfig
