<!--
  This component is a wrapper to the EBillingSubscription component.
  The EBillingSubscription component must be mountable within a TaxSys iframe;
  the vuex store and api client are not available to components mounted within
  the iframe.

  This provides e-billing-public Vuex state via props to the EBilling
  subscription, and makes requests to the e-billing api when events are emitted.
-->
<template>
  <div>
    <EBillingSubscription
      :payable-save-path="payable.raw.save_path"
      :e-billing-mode="eBillingMode"
      :has-e-billing-subscription="hasEBillingSubscription"
      :subscription="subscription"
      :is-authenticated="loggedIn"
      :loading="loading"
      :subscription-error="subscriptionError"
      :subscription-error-text="subscriptionErrorText"
      :allow-paperless-ebilling="allowPaperlessEbilling"
      :disable-paperless="disablePaperless"
      :show-terms-of-use="showTermsOfUse"
      :explicit-terms-of-use="explicitTermsOfUse"
      :client-title="clientTitle"
      @change-e-billing-mode="$emit('change-e-billing-mode', $event)"
      @subscribe="subscribe($event)"
      @unsubscribe="unsubscribe"
      @modify="modify($event)"
    >
      <template #dashboard-reference>
        <slot name="dashboard-reference" />
      </template>
    </EBillingSubscription>
  </div>
</template>

<script>
import store from '../store.ts'
import EBillingSubscription from './EBillingSubscription.vue'
import { sentryException } from '../sentry.ts'
import { decode } from 'js-base64'
import { hasVerified } from '@grantstreet/user-verification-public'
export default {
  emits: ['change-e-billing-mode', 'scroll-to-payable'],
  components: {
    EBillingSubscription,
  },
  data: () => ({
    loading: true,
    subscriptionError: false,
    subscriptionErrorText: '',
  }),
  props: {
    payable: {
      type: Object,
      required: true,
    },
    eBillingMode: {
      type: String,
      default: 'closed',
    },
    queryPayablePath: {
      type: String,
      default: '',
    },
    allowPaperlessEbilling: {
      type: Boolean,
      default: false,
    },
    pinGateEbilling: {
      type: Boolean,
      default: false,
    },
    showTermsOfUse: {
      type: Boolean,
      default: false,
    },
    explicitTermsOfUse: {
      type: Boolean,
      default: false,
    },
    clientTitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    decodedQueryPayablePath () {
      return decode(this.queryPayablePath)
    },
    hasEBillingSubscription () {
      return store.getters.hasEBillingSubscription(this.payable.raw.save_path)
    },
    subscription () {
      return store.getters.hasEBillingSubscription(this.payable.raw.save_path, true)
    },
    loggedIn () {
      return store.state.user?.loggedIn
    },
    // Disable the paperless option if user verifiation is on and the
    // user hasn't verified the payable
    disablePaperless () {
      return this.pinGateEbilling && !hasVerified(this.payable.raw.save_path)
    },
  },
  methods: {
    async subscribe ({ name, email, paperless }) {
      this.$wait.start(`e-billing subscription ${this.payable.raw.save_path}`)

      const payload = {
        payable: this.payable,
        email,
        name,
        paperless,
      }

      try {
        store.state.logRequest({
          ...payload,
          'e_billing': true,
          'terms': this.$t('ebilling.owner'),
        })

        await store.dispatch('subscribePayable', payload)
      }
      // Any non-200 response will lead to an error so handle error messages
      // in the catch block
      catch (error) {
        sentryException(error)
        this.subscriptionError = error
        // There's only one 400-level error that E-Billing returns a user-friendly error for so
        // report BE-reported errors for 400-499 and use default error wording for 500-level
        this.subscriptionErrorText = error.response?.status >= 500 ? this.$t('ebilling.subscribe_error') : error.response?.data?.errorMessage || ''
        this.$emit('change-e-billing-mode', 'closed')
      }
      finally {
        this.$wait.end(`e-billing subscription ${this.payable.raw.save_path}`)
      }
    },

    async unsubscribe () {
      this.$wait.start(`e-billing subscription ${this.payable.raw.save_path}`)

      try {
        await store.dispatch('unsubscribePayable', {
          accountPayableSavePath: this.payable.raw.save_path,
          email: '',
        })
      }
      // Any non-200 response will lead to an error so handle error messages
      // in the catch block
      catch (error) {
        sentryException(error)
        this.subscriptionError = error
        this.subscriptionErrorText = this.$t('ebilling.unsubscribe_error')
      }
      finally {
        this.$wait.end(`e-billing subscription ${this.payable.raw.save_path}`)
      }

      this.$emit('change-e-billing-mode', 'closed')
    },

    async modify ({ name, email, paperless, subscriptionId }) {
      this.$wait.start(`e-billing subscription ${this.payable.raw.save_path}`)

      const payload = {
        paperless,
        subscriptionId,
      }

      try {
        store.state.logRequest({
          ...payload,
          'e_billing': true,
          'terms': this.$t('ebilling.owner'),
        })

        await store.dispatch('modifySubscription', payload)
      }
      // Any non-200 response will lead to an error so handle error messages
      // in the catch block
      catch (error) {
        sentryException(error)
        this.subscriptionError = error
        this.subscriptionErrorText = this.$t('ebilling.modify_error')
        this.$emit('change-e-billing-mode', 'closed')
      }
      finally {
        this.$wait.end(`e-billing subscription ${this.payable.raw.save_path}`)
      }
    },
  },
  async mounted () {
    try {
      await store.dispatch('loadData')

      // MSI auto expand accordion
      if (
        this.payable?.path === this.decodedQueryPayablePath &&
        this.hasEBillingSubscription
      ) {
        this.$emit('change-e-billing-mode', 'unsubscribe')
        this.$emit('scroll-to-payable')
      }
    }
    // If loading data (i.e. user's subs) errors out, report error to Sentry but
    // don't show errors on the page since this will cause it to show for every payable
    catch (error) {
      sentryException(error)
      store.state.loaded = true
    }
    finally {
      this.loading = false
    }
  },
  watch: {
    eBillingMode (newMode, oldMode) {
      if (oldMode === 'closed' && newMode !== oldMode) {
        // User opened the subscription signup. Clear the error message
        this.subscriptionError = false
        this.subscriptionErrorText = ''
      }
    },
  },
}
</script>
