import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment'
export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = () => process.env?.GSG_USER_VERIFICATION_SERVICE || `${baseGovHubUrl}/svc/user-verification`
  }

  /**
     * Gets all active verify statuses for the current user.
     */
  getVerifyStatuses () {
    return this.get('/v1/verify_statuses')
  }

  /**
     * Request a PIN.
     */
  requestVerification ({ accountPayableSavePath, client, site }) {
    // For /sunshine/demo/taxsys demo components, use ca-sanmateo/services.
    if (client === 'sunshine' && (site === 'demo' || site === 'yay-hub')) {
      client = 'ca-sanmateo'
      site = 'services'
    }
    return this.post('/v1/verify_statuses/pin', {
      accountPayableSavePath,
      client,
      site,
    })
  }

  /**
     * Enter a PIN.
     */
  enterVerification ({ accountPayableSavePath, client, site, pin }) {
    // For /sunshine/demo/taxsys demo components, use ca-sanmateo/services.
    if (client === 'sunshine' && (site === 'demo' || site === 'yay-hub')) {
      client = 'ca-sanmateo'
      site = 'services'
    }
    return this.post('/v1/verify_statuses/claim', {
      accountPayableSavePath,
      client,
      site,
      pin,
    })
  }

  prepNonprodTests ({ userId }) {
    return this.post('/v1/prepNonprodTests', {
      userId,
    })
  }
}
