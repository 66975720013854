<template>
  <div
    :class="{ 'spinny-small': small }"
    class="spinny"
  />
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
$border: .15rem solid;
.spinny {
  border: .15rem solid $primary-very-faded;
  border-left: .15rem solid $primary;
  animation: spinny 1.1s infinite linear;

  // TaxSys overrides (for payable iframes):
  visibility: visible;
  position: relative;
  top: 0;
  left: 0;
}

.spinny,
.spinny:after {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
}

.spinny-small,
.spinny-small:after {
  height: 1rem;
  width: 1rem;
}

@keyframes spinny {
  0%   { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
</style>
