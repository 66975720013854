<template>
  <Dropdown
    ref="popper"
    placement="top"
    :disabled="!error"
    :boundary="boundary"
    class="d-inline"
    popper-class="popper-danger"
  >
    <span>
      <slot name="button" />
    </span>

    <template #popper>
      <div class="popper">{{ error }}</div>
    </template>
  </Dropdown>
</template>

<script>
import errorMixin from '../utils/errorMixin.js'
import { Dropdown } from 'floating-vue'

export default {
  components: {
    Dropdown,
  },

  mixins: [ errorMixin ],

  props: {
    // Dropdown will bound itself within the selected element
    boundary: {
      type: [String, undefined],
      default: undefined,
      validator: selector => typeof selector === 'undefined' || selector.length,
    },
  },

  data () {
    return {
      error: '',
    }
  },

  methods: {
    showError (error) {
      // Show an error tooltip
      this.error = this.userFriendlyError(error)
      this.$nextTick(this.$refs.popper.show)
    },
  },
}
</script>
