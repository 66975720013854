import { v4 as uuid } from 'uuid'
import cloneDeep from 'lodash/cloneDeep.js'
import type { HistoryObject, Setting } from '../types/settings.ts'
import Site from './Site.ts'

/**
 * A class for holding a GovHub client definition.
 * @class
 */
export default class Client {
  id: string
  name: string
  logoUrl: string
  useClientOnlyUrl: boolean
  defaultSiteUrl: string
  sites: Site[]
  history: HistoryObject[] = []

  /**
   * @constructor
   *
   * @param {Object} opts                  client options
   * @param {String} opts.id               unique ID (used in URLs)
   * @param {String} opts.name             client name
   * @param {String} opts.logoUrl          path URL of client logo
   *                                       (/bar.jpg and not foo.com/bar.jpg)
   * @param {String} opts.useClientOnlyUrl only support govhub.com/$client
   *                                       (without site portion)?
   * @param {String} opts.defaultSiteUrl   default site for govhub.com/$client
   * @param {Array}  opts.sites            array of Sites in this client
   */
  constructor ({
    id,
    name,
    logoUrl,
    useClientOnlyUrl,
    defaultSiteUrl,
    sites,
  }) {
    this.id = id
    this.name = name
    this.logoUrl = logoUrl
    this.useClientOnlyUrl = useClientOnlyUrl
    this.defaultSiteUrl = defaultSiteUrl
    this.sites = sites || []
  }

  addHistoryEntry (module: string, setting: string, config: Setting) {
    // Keep track of prod history
    const historyObj: HistoryObject = {
      id: uuid(),
      author: 'jSnow', // TODO we don't have authors yet
      date: (new Date()).toLocaleString('en-us', {
        timeZone: 'UTC',
        hour12: false,
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        year: '2-digit',
      }),
      mod: module,
      setting: typeof config.name === 'string' ? config.name : '',
      old: cloneDeep(this[module][setting]),
      new: config,
    }
    this.history.unshift(historyObj)
  }
}
