/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'padlock-green-large': {
    width: 17,
    height: 26,
    viewBox: '0 0 17 26',
    data: '<g transform="translate(-1380 -590)"><ellipse pid="0" cx="7.5" cy="7.3" rx="7.5" ry="7.3" transform="translate(1381 600.4)" _fill="none" _stroke="#467e11" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path pid="1" d="M1383.1 602.6v-6.4a5.267 5.267 0 015.4-5.2 5.267 5.267 0 015.4 5.2v6.4M1388.5 607.7v2.6" _fill="none" _stroke="#467e11" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><circle pid="2" cx="2.3" cy="2.3" r="2.3" transform="translate(1386.2 604.4)" _fill="#467e11"/></g>'
  }
})
